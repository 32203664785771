import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonText, useIonAlert } from "@ionic/react";
import HeadFoot from "../Head";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import "./report.css"
import UserService from "../../Services/auth.service";
import Authservice from "../../Services/user.service";
import { saveAs } from 'file-saver';
import { addOutline } from "ionicons/icons";
import { AppContext } from "../../components/AppContext";
import CommonDataTable from "../../components/datatable";
import DataTable from "react-data-table-component";
import TawkToWidget from "./Donationmanage";
import CommonModal from "../../components/popipInfo";

const FundDisbursed: React.FC = () => {


    const userRole = sessionStorage.getItem("UserData")
    const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
    const [userId]: any = useState(sessionStorage.getItem("UserId"))


    const [Alert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [reportData, setReportData] = useState<any>([]);
    const [filterText, setFilterText] = useState("");
    const { exportCSV } = useContext(AppContext)!

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds]: any = useState([]);
    const [confirmed, setConfirmed] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [actualData, setActualData] = useState<any>([])
    const [editableData, setEditableData] = useState<Record<string, any>>({});
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")

    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

    const groupedData = reportData.reduce((acc: any, row: any) => {
        if (row.SrlNo === "1") {
            acc.push({ ...row, details: [] });
        } else {
            const parent = acc.find((header: any) => header.BookingOrderNumber === row.BookingOrderNumber);
            if (parent) {
                parent.details.push(row);
            }
        }
        return acc;
    }, []);


    useEffect(() => {
        setShowLoading(true);
        const getCurrentDateTime = () => {
            const now = new Date();
            return now.getFullYear() +
                '-' + String(now.getMonth() + 1).padStart(2, '0') +
                '-' + String(now.getDate()).padStart(2, '0') +
                ' ' + String(now.getHours()).padStart(2, '0') +
                ':' + String(now.getMinutes()).padStart(2, '0') +
                ':' + String(now.getSeconds()).padStart(2, '0');
        };
        const date = getCurrentDateTime();
        console.log("date", date)
        UserService.getFundDisbursed(usertoken, userId, date).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                setActualData(response.data)
                const data = response.data.reduce((acc: any, row: any) => {
                    if (row.SrlNo === "1") {
                        acc.push({ ...row, details: [] });
                    } else {
                        const parent = acc.find((header: any) => header.BookingOrderNumber === row.BookingOrderNumber);
                        if (parent) {
                            parent.details.push(row);
                        }
                    }
                    return acc;
                }, []);;
                setReportData(data);
                setShowLoading(false);
            }
        }).catch((error: any) => {
            setShowLoading(false);
            console.log("error", error)
        })
    }, []
    )

    useEffect(() => {
        if (confirmed) {
            console.log('Selected rows:', selectedRowIds, reportData);
            setFilteredData(reportData.filter((row: any) => selectedRowIds.includes(row.BookingOrderNumber)));
        } else {
            setFilteredData(reportData);
        }
    }, [confirmed, selectedRowIds, reportData]);

    const tableData = filteredData.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const modalClose = () => {
        setCommonmodal(false)
    }



    const handleInputChange = (id: string, field: string, value: string) => {
        setFilteredData((prevData: any) =>
            prevData.map((row: any) =>
                row.BookingOrderNumber === id ? { ...row, [field]: value } : row
            )
        );
    };

    const handleSubmit = (row: any) => {
        console.log("Submitting data for:", row.BookingOrderNumber, row, row);
        Authservice.updateFundForDisbursed(usertoken, userId, usertoken, row.BookingOrderNumber, row.FundRef1, row.FundRef2, row.PaymentOrderNo).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                setCommonModalMesg("Disbursement Details are updated successfully.")
                setCommonmodaltitle("Information")
                setCommonmodal(true)
                setShowLoading(false);
            }
        }).catch((error: any) => {
            setShowLoading(false);
            console.log("error", error)
        })
        // Add API call or logic to handle submission
    };

    // Header columns
    const headerColumns = [
        {
            name: "Booking Order Number",
            selector: (row: any) => row.BookingOrderNumber,
            sortable: true,
            width: "200px",
        },
        {
            name: "First Name",
            selector: (row: any) => row.FirstName || "N/A",
            sortable: true,
            width: "220px",
        },
        {
            name: "Total Amount",
            selector: (row: any) => row.TotalAmount,
            sortable: true,
            width: "150px",
        },
        {
            name: "Disbursement Number",
            selector: (row: any) => row.DisbursementNumber,
            sortable: true,
            width: "150px",
        },
        {
            name: "Payment Order Number",
            cell: (row: any) => (
                <input
                    type="text"
                    value={row.PaymentOrderNo || ""}
                    onChange={(e) => handleInputChange(row.BookingOrderNumber, "PaymentOrderNo", e.target.value)}
                />
            ),
            width: "200px",
        },
        {
            name: "Fund Ref1",
            cell: (row: any) => (
                <input
                    type="text"
                    value={row.FundRef1 || ""}
                    onChange={(e) => handleInputChange(row.BookingOrderNumber, "FundRef1", e.target.value)}
                />
            ),
            width: "200px",
        },
        {
            name: "Fund Ref2",
            cell: (row: any) => (
                <input
                    type="text"
                    value={row.FundRef2 || ""}
                    onChange={(e) => handleInputChange(row.BookingOrderNumber, "FundRef2", e.target.value)}
                />
            ),
            width: "200px",
        },
        {
            name: "Action",
            cell: (row: any) => (
                <button onClick={() => handleSubmit(row)}>Submit</button>
            ),
            width: "150px",
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];
    // Detail columns
    const detailColumns = [
        {
            name: 'Fund Category',
            selector: (row: any) => row.FundCategory || 'N/A',
        },
        {
            name: 'Pooja Code',
            selector: (row: any) => row.PoojaCode || 'N/A',
        },
        {
            name: 'Fund ID',
            selector: (row: any) => row.FundId || 'N/A',
        },
        {
            name: 'Booking Detail Number',
            selector: (row: any) => row.BookingDetailNumber,
        },
        {
            name: 'Amount',
            selector: (row: any) => row.TotalAmount,
            right: true,
        },
    ];

    // Expandable row component
    const ExpandedComponent = ({ data }: any) => (
        <DataTable
            columns={detailColumns}
            data={data.details}
            noHeader
            pagination={false}
        />
    );

    const groupDataByBookingOrderNumber = (data: any) => {
        const groupedData: any = {};

        data.forEach((row: any) => {
            if (!groupedData[row.BookingOrderNumber]) {
                groupedData[row.BookingOrderNumber] = [];
            }
            groupedData[row.BookingOrderNumber].push(row);
        });

        return Object.values(groupedData);
    };
    const prepareDataForExport = (groupedData: any) => {
        const exportData: any = [];

        groupedData.forEach((group: any) => {
            // Add the main row (with primary details)
            const mainRow = group[0]; // Assuming the first entry is the main row
            exportData.push({
                FundCategory: mainRow.FundCategory,
                PoojaCode: mainRow.PoojaCode,
                OrderedBy: mainRow.OrderedBy,
                FirstName: mainRow.FirstName,
                FundId: mainRow.FundId,
                SrlNo: mainRow.SrlNo,
                BookingDetailNumber: mainRow.BookingDetailNumber,
                TotalAmount: mainRow.TotalAmount,
                BookingOrderNumber: mainRow.BookingOrderNumber,
                isMainRow: true,
            });

            // Add the details rows under the same order number (only if applicable)
            group.forEach((item: any, index: any) => {
                if (index > 0) {
                    exportData.push({
                        FundCategory: item.FundCategory,
                        PoojaCode: item.PoojaCode,
                        OrderedBy: item.OrderedBy,
                        FirstName: item.FirstName,
                        FundId: item.FundId,
                        SrlNo: item.SrlNo,
                        BookingDetailNumber: item.BookingDetailNumber,
                        TotalAmount: item.TotalAmount,
                        BookingOrderNumber: item.BookingOrderNumber,
                        isMainRow: false, // Detail row
                    });
                }
            });
        });

        return exportData;
    };


    const exportData = () => {
        const headers = [
            { name: 'FundCategory' },
            { name: 'PoojaCode' },
            { name: 'OrderedBy' },
            { name: 'FirstName' },
            { name: 'FundId' },
            { name: 'BookingDetailNumber' },
            { name: 'TotalAmount' },
            { name: 'BookingOrderNumber' },
        ]; // Define the CSV headers   
        const groupedData = groupDataByBookingOrderNumber(actualData);
        const preparedData = prepareDataForExport(groupedData);
        console.log("preparedData", preparedData)
        exportCSV(headers, preparedData, "Fund Report")
    }



    const subHeaderComponent = useMemo(() => {

        return (
            <IonRow className="table_btn_container">
                <div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <IonButton className="admin_add_btn" onClick={() => exportData()}>
                        Export
                    </IonButton>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, reportData]);

    const handleRowSelect = (id: any) => {
        console.log('Selected row:', id);
        setSelectedRowIds((prevSelected: any) =>
            prevSelected.includes(id)
                ? prevSelected.filter((rowId: any) => rowId !== id) // Deselect row
                : [...prevSelected, id] // Select row
        );
    };

    const handleConfirm = () => {
        setConfirmed(true);
    };

    const handleDistribute = () => {
        const distributeData: any = [];
        selectedRowIds.forEach((bookingOrderNumber: any) => {
            const selectedRow = reportData.find((row: any) => row.BookingOrderNumber === bookingOrderNumber);
            if (selectedRow) {
                selectedRow.details.forEach((detail: any) => {
                    distributeData.push({
                        orderId: selectedRow.BookingOrderNumber,
                        fundId: detail.FundId,
                    });
                });
            }
        });
        const uniqueDistributeData = Array.from(
            new Map(distributeData.map((item: any) => [`${item.orderId}-${item.fundId}`, item])).values()
        );
        UserService.procesFundDisb(usertoken, userId, uniqueDistributeData).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                Alert({
                    header: "Atmagram",
                    message: "Disburse completed successfully.",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            handler: () => {
                                exportCSV(headerColumns, filteredData, "Fund Report")
                                window.location.reload()
                            }
                        }
                    ]
                })
                setSelectedRowIds([]);
                setConfirmed(false);
            }
        }).catch((error: any) => {
        });
    };
    const handleCancel = () => {
        setConfirmed(false);
        setSelectedRowIds([]);
    };

    const handleRowClicks = (row: any) => {
        console.log(row)
        setExpandedRows((prev: any) => ({
            ...prev,
            [row.id]: !prev[row.id], // Toggle expand state for clicked row
        }));
    };

    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Fund Disbursed"} />
                <Sidebar /></>}
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Fund Disbursed' />}
                <IonGrid className={userRole === "ROLE_ADMIN" ? "page-wrapper report_container_middle" : "page_content_padding tableContainev2"}>
                    <CommonDataTable
                        columns={headerColumns}
                        expandableRows={true}
                        data={tableData}
                        defaultSortFieldId="name"
                        striped
                        noDataComponent="Sorry ! No result found"
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                        expandableRowsComponent={ExpandedComponent}
                        onRowClick={handleRowClicks}
                        expandedRows={(row: any) => !!expandedRows[row.id]}
                        selectableRows={false} />
                </IonGrid>
            </IonContent>
            <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
        </IonPage>
    )

}
export default FundDisbursed;