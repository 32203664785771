import React, { useEffect, useRef, useState } from "react";
import {
    IonContent,
    IonItemDivider,
    IonRow,
    IonCol,
    IonPage,
    IonGrid,
    IonText,
    IonLoading,
    IonCard,
    IonIcon,
    IonImg,
    IonButton,
    IonLabel,
    IonInput,
    IonTextarea,
    IonFab,
    useIonAlert
} from "@ionic/react";
import "./Myorder.css"
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import moment from "moment";
import { Modal, ModalHeader } from "react-bootstrap";
import { calendarOutline, checkmarkCircleSharp, close, downloadOutline, informationCircleSharp, timeOutline } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router";
import HeadFoot from "./Head";
import { infoicon, tick } from "../theme/imagePath";
import { Capacitor } from "@capacitor/core";
import { saveAs } from 'file-saver';
const Mygrammerce: React.FC = () => {
    const [token, settoken]: any = useState(sessionStorage.getItem("token"));
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const [orders, setOrders]: any = useState([]);
    const [grammerce, setGrammerce] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory()
    const uFlag: any = useState(sessionStorage.getItem("uFlag"))
    const formatter = new Intl.NumberFormat('en-IN')
   
    const renderFlag = useRef(false)

    useEffect(() => {
        if (renderFlag.current === false) {
            renderFlag.current = true;
           
            if (userdata[0] === null) {
                history.push("/login")
            }
            else if (userdata[0] !== "ROLE_USER") {
                history.push("/home")
            }
            else if (uFlag[0] === "false") {
                history.push("/home")
            }
            else {
                setShowLoading(true);
                Authservice.Grammerceorder(token).then((respone: any) => {
                    console.log(respone.data)
                    setOrders(respone.data);
                    setGrammerce(respone.data.grammerce);
                    var data = respone.data;
                   
                    setShowLoading(false);
                })
                    .catch((error) => {
                        setOrders([]);
                        setShowLoading(false);
                    });
            }
        }
    }, [])
    const groupedOrders = orders.reduce((acc: any, item: any) => {
        const { paymentId } = item;
        if (!acc[paymentId]) {
            acc[paymentId] = [];
        }
        acc[paymentId].push(item);
        return acc;
    }, {} as { [key: string]: any[] });
    const [returnmodal, setReturnmodal] = useState(false);
    const [reason, setReason] = useState("")
    const [pickUpDate, setPickUpDate] = useState("")
    const [pickUpTime, setPickUpTime] = useState("")
    const [gram, setGram]: any = useState("")
    const [num, setNum] = useState("")
    const [confrommodal, setConfrommodal] = useState(false)

    const conformation = (item: any, num: any) => {
        setNum(num)
        setGram(item);
        if (num === "1") {
            returnset()
        }
        else {
            setConfrommodal(true)
        }
    }

    const cancelaction = () => {
        setNum("")
        setGram("")
        setConfrommodal(false)
    }

    const returnset = () => {
        setReturnmodal(true)
        setConfrommodal(false)
        console.log(num, gram)
    }

    const closemodal = () => {
        setReturnmodal(false)
        setPickUpDate("")
        setPickUpTime("")
        setFormerror({})
    }

    const handleDivClick = () => {
        console.log('Div clicked!');
    }

    const handleIconClick = (event: any) => {
        console.log('Icon clicked!');
        event.stopPropagation();  // Stop the event from propagating to the parent div
    };

    const datelimit = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        // return yyyy + "-" + mm + "-" + dd;
        if (Capacitor.getPlatform() === "ios") {
            const currentDate = new Date().toISOString().split('T')[0];
            return currentDate;
        }
        else {
            const today1 = new Date();
            const tomorrow = new Date(today1);
            tomorrow.setDate(tomorrow.getDate() + 1);


            return tomorrow.toISOString().split('T')[0];
        }
    };

    const setdatechange = (e: any) => {
        var val = e.detail.value;
        const date = moment(val).format("YYYY-MM-DD")
        setPickUpDate(date)
    }

    const setTime = (e: any) => {

        var ii = e.detail.value.split(":");
        var t: any;
        var a: any;
        if (ii[0] > 12) {
            t = ii[0] - 12;
            a = "PM";

        } else if (ii[0] < 12) {
            t = ii[0]
            a = 'AM';
        }
        else if (ii[0] == 12) {
            t = ii[0]
            a = 'PM';
        }
        var str = t + ":" + ii[1] + " " + a;
        setPickUpTime(str)
        console.log(str)
    }

    const [formerror, setFormerror]: any = useState({})
    const [Alert] = useIonAlert()

    const cancel = (item: any) => {
        const error: any = {}
        if (num === "1") {
            setShowLoading(true)
            setReturnmodal(false)
            Authservice.grammercecancel(token, item.id, reason).then((respone: any) => {
                console.log(respone)
                if (respone.data.refundId !== null || respone.data.refundId !== "") {
                    Alert({
                        header: "Atmagram",
                        message: "Order Cancelled Successfully",
                        backdropDismiss: false,
                        buttons: [
                            {
                                text: "ok",
                                handler: () => {
                                    window.location.reload()
                                }
                            }
                        ]
                    })
                }
                else {
                    Alert({
                        header: "Atmagram",
                        message: "Something went wrong, please try again later.",
                        backdropDismiss: false,
                        buttons: [
                            {
                                text: "ok",
                            }
                        ]
                    })
                }
                setShowLoading(false)
            })
        }
        else if (num === "2") {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            if (pickUpDate === "") {
                error.pickUpDate = "Please select the Date"
            }
            else if (tomorrow.toISOString().split('T')[0] > new Date(pickUpDate).toISOString().split('T')[0]) {
                error.pickUpDate = "Please select future date"
            }
            if (pickUpTime === "") {
                error.pickUpTime = "Please select the Time"
            }
            setFormerror(error)
            if (Object.keys(error).length === 0) {
                setReturnmodal(false)
                setShowLoading(true)
                Authservice.grammercereturn(token, item.id, pickUpDate, pickUpTime, reason).then((respone: any) => {
                    console.log(respone.data)
                    if (respone.data.packageStatus === "OPEN") {
                        Alert({
                            header: "Atmagram",
                            message: "Order Return Initiated Successfully",
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: "ok",
                                    handler: () => {
                                        window.location.reload()
                                    }
                                }
                            ]
                        })
                    }
                    else {
                        Alert({
                            header: "Atmagram",
                            message: "Something went wrong, please try again later.",
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: "ok",
                                }
                            ]
                        })
                    }
                    setShowLoading(false)
                })
            }
        }
        else if (num === "3") {
            if (pickUpDate === "") {
                error.pickUpDate = "Please select the Date"
            }
            if (pickUpTime === "") {
                error.pickUpTime = "Please select the Time"
            }
            setFormerror(error)
            if (Object.keys(error).length === 0) {
                setReturnmodal(false)
                setShowLoading(true)
                Authservice.grammercereplace(token, item.id, pickUpDate, pickUpTime, reason).then((respone: any) => {
                    console.log(respone.data)
                    if (respone.data.packageStatus === "OPEN") {
                        Alert({
                            header: "Atmagram",
                            message: "Order Replace Initiated Successfully",
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: "ok",
                                    handler: () => {
                                        window.location.reload()
                                    }
                                }
                            ]
                        })
                    }
                    else {
                        Alert({
                            header: "Atmagram",
                            message: "Something went wrong, please try again later.",
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: "ok",
                                }
                            ]
                        })
                    }
                    setShowLoading(false)
                })
            }
        }
    }
    const downInvoice = (payId:any) => {
        setShowLoading(true);
        Authservice.grammerceInvoice(token,payId).then((response) => {
            console.log(response);
            
            
    
    // Decode the base64 string
    const decodedData = atob(response.data);
    const filename = "ProductInvoice.pdf";
    
    // Convert the decoded data to a Uint8Array
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
    }
    
    // Convert the Uint8Array to a Blob
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    
    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
           setShowLoading(false);
        }) .catch((error) => {
            setShowLoading(false);
            Alert("Something went wrong, please try again later.")
           
        });
    
    
    
    }
    const [productimage, setProductimage] = useState("")
    const [productname, setProductname] = useState("")
    const [imageshow, setImageshow] = useState(false)
    const itemimage = (item: any) => {
        console.log(item.grammerceDto)
        setProductimage(item.grammerceDto.grammerceImgsUrl)
        setProductname(item.grammerceDto.productName)
        setImageshow(true)
    }

    const Validation = (label: any) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (label === "pickupdate") {
            if (pickUpDate === "") {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "Please select the Date" }
                })
            }
            else if (tomorrow.toISOString().split('T')[0] > new Date(pickUpDate).toISOString().split('T')[0]) {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "Please select future date" }
                })
            }
            else {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "" }
                })
            }
        }

        if (label === "pickuptime") {
            if (pickUpTime === "") {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpTime: "Please select the Time" }
                })
            }
            else {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpTime: "" }
                })
            }
        }

    }

    const ChangeValidation = (label: any, e: any) => {
        if (label === "pickupdate") {
            const pickUpDate = e.target.value;
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            if (pickUpDate === "") {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "Please select the Date" }
                })
            }
            else if (tomorrow.toISOString().split('T')[0] > new Date(pickUpDate).toISOString().split('T')[0]) {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "Please select future date" }
                })
            }
            else {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpDate: "" }
                })
            }
        }

        if (label === "pickuptime") {
            const pickUpTime = e.target.value;

            if (pickUpTime === "") {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpTime: "Please select the Time" }
                })
            }
            else {
                setFormerror((prev: any) => {
                    return { ...prev, pickUpTime: "" }
                })
            }
        }

    }

    const orderdetailsopen = (item: any, i: any, e: any) => {
        console.log(item, "sasasasasa")
        if (e === 1) {
            const arr = orders.map((item1: any, index: any) => {
                if (i === index) {
                    return { ...item1, orderDetailShow: true }
                }
                return item1;
            })
            setOrders(arr)
        }
        else if (e === 2) {
            const hide = orders.map((item1: any, index: any) => {
                if (i === index) {
                    return { ...item1, orderDetailShow: false }
                }
                return item1;
            })
            setOrders(hide)
        }
    }

    const dateInputRef: any = useRef(null)
    const timeInputRef: any = useRef(null)

    return (
        <IonPage>
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}

                // duration={5000}
                />
                {imageshow && (
                    <Lightbox
                        mainSrc={productimage + "?v=" + new Date()}
                        onCloseRequest={() => setImageshow(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                <HeadFoot User={"login"} Role={'Role'} Name='Home,My Orders' />
                <div className="backgroundorders page_content_padding">
                    {showLoading === false && orders.length == 0 ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonText className="no_data">No Data Available</IonText>
                    </div> : <div>
                        <IonGrid>
                            {Object.entries(groupedOrders).map(([key, value]) => {
                                console.log(key,"sjkdbf");
                                var valArr:any=value;
                                return (
                                    <div key={key} style={{ border: "1px solid red"  }} className="ordercard">
                                        <div style={{ display: "flex", justifyContent: "flex-end",margin:"10px",alignItems:"center" }}>
                                            <IonButton size="small" className="order_invoice_button" onClick={()=>downInvoice(key)}>
                                                <IonIcon src={downloadOutline} style={{ fontSize: "20px"}} />
                                                <span className="order_button_padding" style={{ marginLeft: "10px" }}>
                                                    Invoice
                                                </span>
                                            </IonButton>
                                        </div>
                                       {(valArr).map((item: any, i: any) => {
                                var dateSplit = item.orderDate.split("T")
                                const odate = moment(dateSplit[0]).format("DD-MM-YYYY");
                                var totamt = item.totalPrice.split(".")
                                const tamt = totamt[0];
                                var refundStatus = "";
                                var pickDate = "";
                                if (item.shipmentDate === "" || item.shipmentDate === null) {
                                    pickDate = ""
                                }
                                else {
                                    var date = moment(item.shipmentDate).format("DD-MM-YYYY")
                                    pickDate = date;
                                }
                                if (item.refundFlag === true) {
                                    refundStatus = "Your refund is under process"
                                }
                                else {
                                    refundStatus = "Your refund will processed within 5-7 working days"
                                }
                                var expDate: any;
                                if (item.expDeliveryDate != null && item.expDeliveryDate != "" && item.expDeliveryDate != undefined) {
                                    expDate = moment(item.expDeliveryDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MM-YYYY");
                                }
                                else {
                                    expDate = ""
                                }
                                return (
                                    <div>
                                        <IonCard >
                                            <IonRow className="order_header">
                                                <IonCol size="6" style={{ display: "flex", alignItems: "center" }}>

                                                    <IonText className="order_id"> {item.orderNumber}</IonText>
                                                </IonCol>
                                                <IonCol size="6" style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
                                                    <IonText className="order_id">₹ {formatter.format(item.totalPaidAmount)}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow >
                                                <IonCol sizeMd="12" sizeXl="3" sizeLg="3" sizeSm="12" sizeXs="12" style={{ display: 'flex', justifyContent: "center" }}>
                                                    <IonImg className="material_img" onClick={() => itemimage(item)} src={item.grammerceDto.grammerceImgsUrl} />
                                                </IonCol>
                                                <IonCol sizeMd="12" sizeXl="9" sizeLg="9" sizeSm="12" sizeXs="12" >
                                                    <IonRow style={{ paddingTop: '5px' }}>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Order Date</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5" >
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{odate}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Order Status</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{item.status}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Product Name</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{item.grammerceDto.productName}</IonText>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Product Price</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">₹ {formatter.format(item.totalPrice)}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                                                        {item.orderDetailShow === true ?
                                                            <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 2)}>
                                                                see Less
                                                            </IonText> : <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 1)}>
                                                                order details
                                                            </IonText>}
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            {item.orderDetailShow === true ?
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <div style={{ marginRight: "25px" }}>
                                                        <IonItemDivider className="divider_order_details" />
                                                    </div>
                                                    <IonRow style={{ display: 'flex', justifyContent: "center" }}>
                                                        <IonText className="order_details_head">
                                                            Order Details
                                                        </IonText>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                                            <IonRow style={{ paddingTop: "5px" }}>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Brand Name</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.grammerceDto.brandName}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Quantity</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.quantity}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Delivery Charge</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.pdAmt)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Total Price</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.totalPaidAmount)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {item.status === "OPEN" || item.status === "SCHEDULED" || item.status === "SHIPPED" || item.status === "REPLACE_ADMIN_SHIPPED" ? <>
                                                                {expDate != null && expDate != "" ?
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Expected Delivery Date</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">{expDate}</IonText>
                                                                        </IonCol>
                                                                    </IonRow> : <></>}
                                                            </> : <></>}
                                                            {item.awbNumber != "" ?
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">AWB Number</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{item.awbNumber}</IonText>
                                                                    </IonCol>
                                                                </IonRow> : <></>}
                                                            {item.refundFlag === true ? <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Refund Amount</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.refundAmt)}</IonText>
                                                                </IonCol>
                                                            </IonRow> : <></>}
                                                            {item.refundFlag === true ? <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Cancellation Fee</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.cancellationFee)}</IonText>
                                                                </IonCol>
                                                            </IonRow> : <></>}
                                                            {item.giftFlag && <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Gifted By</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.giftedBy}</IonText>
                                                                </IonCol>
                                                            </IonRow>}
                                                        </IonCol>
                                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                                            <IonRow style={{ paddingTop: '5px' }}>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Name</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.name}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Contact Number</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{Authservice.formatPhone(item.contactNumber)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Mail Id</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.emailId}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Delivery Address</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.deliveryAddress}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {item.giftFlag && <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Gift Note</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.giftNote}</IonText>
                                                                </IonCol>
                                                            </IonRow>}
                                                            {item.shipmentDate === null || item.shipmentDate === "" || item.status === "COMPLETED" || item.status === "OPEN" || item.status === "SHIPPED" || item.status === "SCHEDULED" ?
                                                                <></> :
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Pickup Date</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{pickDate}</IonText>
                                                                    </IonCol>
                                                                </IonRow>}
                                                            {item.shipmentTime === null || item.shipmentTime === "" || item.status === "COMPLETED" || item.status === "OPEN" || item.status === "SHIPPED" || item.status === "SCHEDULED" ?
                                                                <></> :
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Pickup Time</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{item.shipmentTime}</IonText>
                                                                    </IonCol>
                                                                </IonRow>}
                                                            {item.status === "CANCELLED" || item.status === "RETURNED" ? <>
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Refund Status</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{refundStatus}</IonText>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </> : <></>}
                                                        </IonCol>
                                                    </IonRow>
                                                </div> : <></>}
                                            <IonRow style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                                {item.status === "OPEN" || item.status === "SCHEDULED" ?
                                                    <div>
                                                        <IonButton size="small" onClick={() => conformation(item, "1")}>
                                                            <span className="button_padding">
                                                                Cancel
                                                            </span>
                                                        </IonButton>
                                                    </div> : item.status === "COMPLETED" ?
                                                        <IonRow>
                                                            <div>
                                                                <IonButton onClick={() => conformation(item, "2")}>
                                                                    <span className="button_padding">
                                                                        Return
                                                                    </span>
                                                                </IonButton>
                                                            </div>
                                                            <div>
                                                                <IonButton onClick={() => conformation(item, "3")}>
                                                                    <span className="button_padding">
                                                                        Replacement
                                                                    </span>
                                                                </IonButton>
                                                            </div>
                                                        </IonRow> : <></>
                                                }
                                            </IonRow>
                                        </IonCard>
                                    </div>
                                )
                            })}
                                    </div>
                                )
                            })}
                            {/* {orders.map((item: any, i: any) => {
                                var dateSplit = item.orderDate.split("T")
                                const odate = moment(dateSplit[0]).format("DD-MM-YYYY");
                                var totamt = item.totalPrice.split(".")
                                const tamt = totamt[0];
                                var refundStatus = "";
                                var pickDate = "";
                                if (item.shipmentDate === "" || item.shipmentDate === null) {
                                    pickDate = ""
                                }
                                else {
                                    var date = moment(item.shipmentDate).format("DD-MM-YYYY")
                                    pickDate = date;
                                }
                                if (item.refundFlag === true) {
                                    refundStatus = "Your refund is under process"
                                }
                                else {
                                    refundStatus = "Your refund will processed within 5-7 working days"
                                }
                                var expDate: any;
                                if (item.expDeliveryDate != null && item.expDeliveryDate != "" && item.expDeliveryDate != undefined) {
                                    expDate = moment(item.expDeliveryDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MM-YYYY");
                                }
                                else {
                                    expDate = ""
                                }
                                return (
                                    <div>
                                        <IonCard className="ordercard">
                                            <IonRow className="order_header">
                                                <IonCol size="6" style={{ display: "flex", alignItems: "center" }}>

                                                    <IonText className="order_id">{i + 1}. {item.orderNumber}</IonText>
                                                </IonCol>
                                                <IonCol size="6" style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
                                                    <IonText className="order_id">₹ {formatter.format(item.totalPaidAmount)}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow >
                                                <IonCol sizeMd="12" sizeXl="3" sizeLg="3" sizeSm="12" sizeXs="12" style={{ display: 'flex', justifyContent: "center" }}>
                                                    <IonImg className="material_img" onClick={() => itemimage(item)} src={item.grammerceDto.grammerceImgsUrl} />
                                                </IonCol>
                                                <IonCol sizeMd="12" sizeXl="9" sizeLg="9" sizeSm="12" sizeXs="12" >
                                                    <IonRow style={{ paddingTop: '5px' }}>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Order Date</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5" >
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{odate}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Order Status</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{item.status}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Product Name</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">{item.grammerceDto.productName}</IonText>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                        </IonCol>
                                                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                            <IonText className="order_detail">Product Price</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                            <IonText className="order_detail">₹ {formatter.format(item.totalPrice)}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                                                        {item.orderDetailShow === true ?
                                                            <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 2)}>
                                                                see Less
                                                            </IonText> : <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 1)}>
                                                                order details
                                                            </IonText>}
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            {item.orderDetailShow === true ?
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <div style={{ marginRight: "25px" }}>
                                                        <IonItemDivider className="divider_order_details" />
                                                    </div>
                                                    <IonRow style={{ display: 'flex', justifyContent: "center" }}>
                                                        <IonText className="order_details_head">
                                                            Order Details
                                                        </IonText>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                                            <IonRow style={{ paddingTop: "5px" }}>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Brand Name</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.grammerceDto.brandName}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Quantity</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.quantity}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Delivery Charge</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.pdAmt)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Total Price</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.totalPaidAmount)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {item.status === "OPEN" || item.status === "SCHEDULED" || item.status === "SHIPPED" || item.status === "REPLACE_ADMIN_SHIPPED" ? <>
                                                                {expDate != null && expDate != "" ?
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Expected Delivery Date</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">{expDate}</IonText>
                                                                        </IonCol>
                                                                    </IonRow> : <></>}
                                                            </> : <></>}
                                                            {item.awbNumber != "" ?
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">AWB Number</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{item.awbNumber}</IonText>
                                                                    </IonCol>
                                                                </IonRow> : <></>}
                                                            {item.refundFlag === true ? <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Refund Amount</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.refundAmt)}</IonText>
                                                                </IonCol>
                                                            </IonRow> : <></>}
                                                            {item.refundFlag === true ? <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Cancellation Fee</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.cancellationFee)}</IonText>
                                                                </IonCol>
                                                            </IonRow> : <></>}
                                                            {item.giftFlag && <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Gifted By</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.giftedBy}</IonText>
                                                                </IonCol>
                                                            </IonRow>}
                                                        </IonCol>
                                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                                            <IonRow style={{ paddingTop: '5px' }}>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Name</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.name}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Contact Number</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{Authservice.formatPhone(item.contactNumber)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Mail Id</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.emailId}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Delivery Address</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.deliveryAddress}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {item.giftFlag && <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                    <IonText className="order_detail">Gift Note</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                    <IonText className="order_detail">{item.giftNote}</IonText>
                                                                </IonCol>
                                                            </IonRow>}
                                                            {item.shipmentDate === null || item.shipmentDate === "" || item.status === "COMPLETED" || item.status === "OPEN" || item.status === "SHIPPED" || item.status === "SCHEDULED" ?
                                                                <></> :
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Pickup Date</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{pickDate}</IonText>
                                                                    </IonCol>
                                                                </IonRow>}
                                                            {item.shipmentTime === null || item.shipmentTime === "" || item.status === "COMPLETED" || item.status === "OPEN" || item.status === "SHIPPED" || item.status === "SCHEDULED" ?
                                                                <></> :
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Pickup Time</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{item.shipmentTime}</IonText>
                                                                    </IonCol>
                                                                </IonRow>}
                                                            {item.status === "CANCELLED" || item.status === "RETURNED" ? <>
                                                                <IonRow>
                                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                        <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                    </IonCol>
                                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                        <IonText className="order_detail">Refund Status</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                        <IonText className="order_detail">:</IonText>
                                                                    </IonCol>
                                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                        <IonText className="order_detail">{refundStatus}</IonText>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </> : <></>}
                                                        </IonCol>
                                                    </IonRow>
                                                </div> : <></>}
                                            <IonRow style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                                {item.status === "OPEN" || item.status === "SCHEDULED" ?
                                                    <div>
                                                        <IonButton size="small" onClick={() => conformation(item, "1")}>
                                                            <span className="button_padding">
                                                                Cancel
                                                            </span>
                                                        </IonButton>
                                                    </div> : item.status === "COMPLETED" ?
                                                        <IonRow>
                                                            <div>
                                                                <IonButton onClick={() => conformation(item, "2")}>
                                                                    <span className="button_padding">
                                                                        Return
                                                                    </span>
                                                                </IonButton>
                                                            </div>
                                                            <div>
                                                                <IonButton onClick={() => conformation(item, "3")}>
                                                                    <span className="button_padding">
                                                                        Replacement
                                                                    </span>
                                                                </IonButton>
                                                            </div>
                                                        </IonRow> : <></>
                                                }
                                            </IonRow>
                                        </IonCard>
                                    </div>
                                )
                            })} */}
                        </IonGrid>
                    </div>}
                </div>
                <Modal show={confrommodal} className="gramerce-modal" centered>
                    <Modal.Body>
                        <IonRow>
                            <IonCol style={{ textAlign: "center" }}>
                                {num === "2" ? <h5 className="mt-2">Do you sure want to return your order?</h5> :
                                    <h5 className="mt-2">Do you sure want to replace your order?</h5>}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-12">
                            <IonCol size="6" style={{ textAlign: "end" }}>
                                <IonButton onClick={() => returnset()}><span className="button_padding">
                                    Yes </span></IonButton>
                            </IonCol>
                            <IonCol size="6">
                                <IonButton onClick={(e) => cancelaction()}>
                                    <span className="button_padding">No</span></IonButton>
                            </IonCol>
                        </IonRow>
                    </Modal.Body>
                </Modal>
                <Modal show={returnmodal} className="mygramerce-modal" centered>
                    <ModalHeader className="modal-header">
                        <h5 className="modal-title">Atmagram</h5>
                        <div>
                            <IonIcon src={close} className="close_icon" onClick={() => closemodal()} />
                        </div>
                    </ModalHeader>
                    <Modal.Body>
                        <div className={gram.status === "COMPLETED" ? "action_div_adjust" : "action_div_adjust1"}>
                            {gram.status === "COMPLETED" ? <>
                                <IonRow style={{ marginTop: "3%" }}>
                                    <IonCol size="12">
                                        <IonLabel>
                                            Pick Up Date <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12" className="grammercedefaulticon">
                                        <IonInput type="date" min={datelimit()} onBlur={() => Validation("pickupdate")} className="pick_date_gram"
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Tab' && e.key !== ' ') {
                                                    e.preventDefault();
                                                }
                                                else {
                                                    if (dateInputRef.current) {
                                                        dateInputRef.current.setFocus();
                                                    }
                                                }
                                            }}
                                            ref={dateInputRef}
                                            onIonChange={(e: any) => { setdatechange(e); ChangeValidation("pickupdate", e) }} />
                                        <div style={{ display: "flex", marginTop: "2%" }}>
                                            {formerror.pickUpDate && <span className="alert_input_bottom ">{formerror.pickUpDate}</span>}
                                        </div>
                                    </IonCol>
                                    <IonCol className="grammerceordericon" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                        <div style={{ position: "absolute", width: "100%" }}>
                                            <IonInput type="date" min={datelimit()} className="pick_date_gram"
                                                onKeyDown={(e: any) => e.preventDefault()}
                                                onIonChange={(e: any) => { setdatechange(e); ChangeValidation("pickupdate", e) }}
                                                onBlur={() => Validation("pickupdate")}
                                            />
                                        </div>
                                        <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingRight: "5px", paddingTop: "10px" }}>
                                            <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={calendarOutline} />
                                        </div>
                                        <div style={{ display: "flex", marginTop: "4%" }}>
                                            {formerror.pickUpDate && <span className="alert_input_bottom ">{formerror.pickUpDate}</span>}
                                        </div>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: "5%" }}>
                                    <IonCol size="12">
                                        <IonLabel>
                                            Pick Up Time <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="grammercedefaulticon" size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <IonInput type="time" onBlur={() => Validation("pickuptime")} className="pick_time_gram"
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Tab' && e.key !== ' ') {
                                                    e.preventDefault();
                                                }
                                                else {
                                                    if (timeInputRef.current) {
                                                        timeInputRef.current.setFocus();
                                                    }
                                                }
                                            }}
                                            ref={timeInputRef}
                                            onIonChange={(e: any) => { setTime(e); ChangeValidation("pickuptime", e) }}></IonInput>
                                        <div style={{ display: "flex", marginTop: "2%" }}>
                                            {formerror.pickUpTime && <span className="alert_input_bottom ">{formerror.pickUpTime}</span>}
                                        </div>
                                    </IonCol>
                                    <IonCol className="grammerceordericon" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                        <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center" }}>
                                            <IonInput className="pick_time_gram" type="time" onKeyDown={(e: any) => e.preventDefault()} onBlur={() => Validation("pickuptime")} onIonChange={(e: any) => { setTime(e); ChangeValidation("pickuptime", e) }}></IonInput>
                                        </div>
                                        <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingRight: "5px", paddingTop: "10px" }}>
                                            <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={timeOutline} />
                                        </div>
                                        <div style={{ display: "flex", marginTop: "4%" }}>
                                            {formerror.pickUpTime && <span className="alert_input_bottom ">{formerror.pickUpTime}</span>}
                                        </div>
                                    </IonCol>
                                </IonRow>

                                <IonRow style={{ marginTop: "5%" }}>
                                    <IonCol size="12">
                                        <IonLabel>
                                            Reason
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                            </> : <IonRow style={{ marginTop: "5%" }}>
                                <IonCol size="12">
                                    <IonLabel>
                                        Do you sure want to cancel your order?
                                    </IonLabel>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                    <IonTextarea placeholder="Enter the reason" autoGrow value={reason} className="reason_textarea" onIonChange={(e: any) => setReason(e.detail.value)} />
                                </IonCol>
                            </IonRow>
                            <IonRow style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
                                <IonButton onClick={() => cancel(gram)}><span className="button_padding">Submit</span></IonButton>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>
                <Foot />
            </IonContent >
        </IonPage >
    )
}

export default Mygrammerce;