import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonTextarea,
  IonText,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonCheckbox,
  IonCard,
  IonSearchbar,
  useIonAlert,
  IonList
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import Head from "../Head";
import Foot from "../Foot";
import DataTable from "react-data-table-component"
import "./Deitymanage.css";
import { useHistory } from "react-router";
import { Modal } from "react-bootstrap";
import UserService from "../../Services/auth.service";
import moment from "moment";
import { format } from "path";
import { Capacitor } from "@capacitor/core";
import Authservice from "../../Services/user.service";
import { Camera, CameraResultType } from "@capacitor/camera";
import { calendarOutline, close, closeCircle, closeCircleOutline, documentAttachOutline, timeOutline } from "ionicons/icons";
import Select, { components } from 'react-select';
import Lightbox from "react-image-lightbox";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";


const OrderAdm: React.FC = () => {
  const [Alert] = useIonAlert();
  const formatter = new Intl.NumberFormat('en-IN')
  const [isOpendel, setIsOpendel] = useState(false);
  const [receiptdata, setReceiptdata] = useState("")
  const [receiptimage, setReceiptimage] = useState("")
  const [wholedata, setWholedata] = useState("")
  const [awbisopen, setAwbisopen] = useState(false)
  const [showshipdate, setShowshipdate] = useState("")
  const [showshiptime, setShowshiptime] = useState("")
  const [initisopen, setInitisopen] = useState(false)
  const [initofferId, setInitofferid] = useState("")
  const [initstatusid, setInitstatusid] = useState("")
  const [initshipdate, setInitshipdate] = useState("")
  const [initawb, setInitAwb] = useState("")
  const [initshiptime, setInitshiptime] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [ordernumber, setOrdernumber] = useState("")
  const [mindate, setMindate] = useState("")
  const [orderedBy, setOrderedBy] = useState("")
  const [orderByStar, setOrderedByStar] = useState("")
  const [showtempleName, setShowTempleName] = useState("")
  const [offeringName, setOfferingname] = useState("")
  const [orderNo, setOrderNo] = useState("")
  const [statusList, setStatusList]: any = useState([])
  const [orderStatus, setOrdStatus]: any = useState("OPEN")
  const [awb , setAwb]:any= useState("")
  const [shipStatus,setShipStatus]: any=useState("")
  const [shipStatusError, setShipStatusError]:any = useState("")
 
  const [filterStatus, setFilterStatus]: any = useState({value:0, label:"Incomplete Orders"})
  const orderStatusList:any[] = [
    {value:2, label:"All"},
   
{value:1, label:"Complete Orders"},
{value:0, label:"Incomplete Orders"},]

  const toggleDrawer = (item: any, i: any) => {
    console.log(item, "ujisjdbjk")
    if (prasadhamflag === false) {
      setIsOpendel(true);
    }
    else if (item.awbNumber !== null) {
      setAwb(item.awbNumber)
      setAwbisopen(true)
      var date = moment(item.shipmentDate).format("DD-MM-YYYY")
      setShowshipdate(date)
      setShowshiptime(item.shipmentTime)
    }
    else {
      console.log(initshipdate, "dateeeee")
      setInitisopen(true)
    }
    setInitstatusid(item.id)
    setInitofferid(item.poojaOfferingOrders.id)
    setReceiptdata(item.receipt)
    setShipStatus(item.status)
    setOrdernumber(item.poojaOfferingOrders.orderNumber)
    setWholedata(item)
    setInitshipdate("")
    setInitAwb("")
    setShiperror({})
    var orderdate: any = item.orderedDate.split("T")
    var format = moment(orderdate[0]).format("DD-MM-YYYY")
    setOrderDate(format)
    console.log(initshipdate, "dateee2222")
    setMindate(orderdate[0])
    setReceiptimage(item.imageName)
    setOrderedBy(item.poojaOfferingOrders.userCartDetails.firstName)
    setOrderedByStar(item.poojaOfferingOrders.userCartDetails.star)
  };

  const columns: any = [
    // {
    //   name: "S.No",
    //   selector: (item: any, i: any) => item.serial,
    //   sortable: true,
    //   width: "90px",
    //   cell: (item: any) => (
    //     <div className="center_col_tab" onClick={() => viewdetails(item)}>
    //       <div style={{ cursor: "pointer" }}>{item.serial}</div>
    //     </div>
    //   )
    // },
    {
      name: "Order Number",
      sortable: true,
      selector: (item: any, i: any) => item.poojaOfferingOrders.orderNumber,
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <IonText style={{ cursor: "pointer" }}>{item.poojaOfferingOrders.orderNumber}</IonText>
        </div>
      ),
    },
    {
      name: "Order Date",
      selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
      sortable: true,
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <div style={{ cursor: "pointer" }}>{item.localOrderDate}</div>
        </div>
      )
    },
    
    {
      name: "Temple Name",
      sortable: true,
      selector: (item: any, i: any) => item.poojaOfferingOrders.poojaOfferings.temple.name,
      cell: (item: any) => (
        <div className="left_col" onClick={() => viewdetails(item)}>
          <IonText style={{ cursor: "pointer" }}>{item.poojaOfferingOrders.poojaOfferings.temple.name}</IonText>
        </div>
      )
    },
    {
      name: "Offering Name",
      selector: (item: any, i: any) => item.poojaOfferingOrders.poojaOfferings.name,
      sortable: true,
      cell: (item: any) => (
        <div className="left_col" onClick={() => viewdetails(item)}>
          <IonText style={{ cursor: "pointer" }}>{item.poojaOfferingOrders.poojaOfferings.name}</IonText>
        </div>
      ),
    },
    
    {
      name: "Price (₹)",
      sortable: true,
      selector: (item: any) => parseInt(item.poojaOfferingOrders.poojaOfferings.price, 10),
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <IonText style={{ cursor: "pointer" }}>{formatter.format(item.poojaOfferingOrders.poojaOfferings.price)}</IonText>
        </div>
      ),
    },
    {
      name: "Manage Orders",
      sortable: false,
      cell: (item: any, i: any) => (
        <div className="center_col_tab">
          <IonButton className="manage_order_btn" style={{ textTransform: "none" }} onClick={() => Ship(item, i)}>
            <span>
              Manage Orders
            </span>
          </IonButton>
        </div>
      )
    },
  ]

  const [usertoken] = useState(sessionStorage.getItem("token"));
  const history = useHistory();
  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [modalopen, setModalopen] = useState(false);
  const [name, setName] = useState("");
  const [offDes, setOffDes] = useState("");
  const [offPrc, setOffPrc]: any = useState("");
  const [offTime, setOffTime] = useState("");
  const [dakAmt, setDakAmt]: any = useState("");
  const [donAmt, setDonAmt]: any = useState("");
  const [fDate, setFDate] = useState("");
  const [tDate, setTDate] = useState("");
  const [lDate, setLDate] = useState("");
  const [lmnt, setmnth] = useState("");
  const [dAdd, setDAdd] = useState("");
  const [Prasadamdel, setPrasadamdel] = useState(false);
  const [Prasadam, setPrasadam] = useState("");
  const [data, setData] = useState([]);
  const [dakshinaAmt, setDakshinaAmt] = useState(true);
  const [donationAmt, setDonationAmt] = useState(true);
  const [fromAndTo, setFromAndTo] = useState(true)
  const [noofmont, setNoofmonth] = useState(true)
  const [listofdates, setListofdates] = useState(true)
  if (data != undefined && data.length > 0) {
    data.forEach((item: any, index: any) => { item.serial = index + 1; });
    data.forEach((item: any, index: any) => {
      var date = item.poojaOfferingOrders.orderDate.split("T");
      const od = moment(date[0]).format("DD-MM-YYYY");
      item.localOrderDate = od;
    });
  }

  const [Statusmodal, setStatusmodal] = useState(false);
  const [offeringorderList, setOfferingorderList]: any = useState([]);
  const [tempAdmList, setTempAdmList]: any = useState([]);
  const dataFetchedRef = useRef(false);
  const [tempAdm, setTempAdm] = useState("");
  const [shipNewlist, setShipNewlist]: any = useState([])
  const [totalpaidamount, setTotalpaidamount]: any = useState("")
  const [prasadhamflag, setPrasadhamflag] = useState(false)
  const [web, setWeb] = useState(false)
  const [android, setAndroid] = useState(false)
  const [ship, setShip]: any = useState([])
  const [shipmodal, setShipmodal] = useState(false);
  const [receiptmodalcheck, setReceiptmodalcheck] = useState(false)
  const [imagesend, setImagesend] = useState("")
  const [showLoading, setShowLoading] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [files, setFiles]: any = useState("")
  const [file, setFile] = useState("")
  const [fileview, setFileview] = useState("")
  const [imageuri, setImageuri]: any = useState("")
  const [receiptmodal, setReceiptmodal] = useState(false)
  const [androidreceiptmodalcheck, setAndroidreceiptmodalcheck] = useState(false)
  const [isSearch, setIsSearch] = useState(false);
  const uFlag: any = useState(sessionStorage.getItem("uFlag"))
  const [filterArr , setFilterArr]:any = useState([])
  const loadData :any = useRef([])
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_ADMIN") {
      history.push("/home")
    }
    else if (uFlag[0] === "false") {
      history.push("/home")
    }
    else {
      console.log("jhvhjv")
      getStates();
      getstatusList();
    }
    // showOrders()
    // showTempleAdmList();
  }, [])
  const getstatusList = () =>{
    setShowLoading(true)
    Authservice.poojaorderStatusList(usertoken).then((response) => {
        console.log(response);
        setStatusList(response.data)
        
        setShowLoading(false);
       
    })
        .catch((error) => {
            alert(error)
            setShowLoading(false);
        });
}
const changeStatus = (status:any) => {
  setOrdStatus(status)

   }
  const getStates = () => {
    setShowLoading(true)
    Authservice.getAllstates()
      .then((response) => {
        console.log(response)
        setStates(response.data)
        setShowLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setShowLoading(false)
      })
  }
  const groupedItems = ship.reduce((acc:any, item:any) => {
    const { status } = item;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(item);
    return acc;
  }, {} as { [key: string]: any[] });
  const showOrders = (e: any) => {
    setShowLoading(true);
    setData([])
    loadData.current = [];
    Authservice.OrderTemp(usertoken, e).then((response: any) => {
      console.log("order=>", response);
      console.log(response.data)
      setData(response.data)
      setFilterStatus({value:0, label:"Incomplete Orders"})
      loadData.current=response.data;
     
      setIsSearch(true)
      shipNewlist.length = 0;


      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].poojaOfferingOrdersStatusList.length > 0) {
          //setShip((e:any)=>([...e, ...response.data[i].poojaOfferingOrdersStatusList]))
          const newData: any = response.data[i].poojaOfferingOrdersStatusList.map((e: any) => {
            return { ...e, shipDate: "", shipTime: "", offeringFlag: e.offeringFlag, disableFlag: e.offeringFlag, imageName: "" }
          })
          shipNewlist.push(newData)

        }

      }
      setShowLoading(false);
      handleOrderChange({value:0, label:"Incomplete Orders"})
    })
      .catch((error: any) => {
        console.log(error);
        setShowLoading(false);
      });
  }
  const Ship = (item: any, i: any) => {
    console.log(item, "item11111")
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setAndroidreceiptmodalcheck(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShipErrors({})
    setPrasadhamflag(item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag)
    if (Capacitor.getPlatform() === "web") {
      setWeb(true)
      setAndroid(false)
    }
    else {
      setWeb(false)
      setAndroid(true)
    }
    console.log(ship, "ship")
    var obj = shipNewlist[i]
    setShip(item.poojaOfferingOrdersStatusList);
    setOfferingname(item.poojaOfferingOrders.poojaOfferings.name)
    setOrderNo(item.poojaOfferingOrders.orderNumber)
    setShowTempleName(item.poojaOfferingOrders.poojaOfferings.temple.name)
    
    setShipmodal(true);
    setOrdStatus("OPEN")
  }
  const viewstatus = (item: any) => {
    setOfferingorderList(item.poojaOfferingOrdersStatusList)
    setStatusmodal(true)
  }
  const Modalclose = () => {
    setModalopen(false)
  }
  const [adminname, setAdminname] = useState("");
  const [templename, setTemplename] = useState("");
  const [lddate, setLddate]: any = useState("");
  const [ordernum, setOrdernum] = useState("");
  const viewdetails = (item: any) => {
    setOrdernum(item.poojaOfferingOrders.orderNumber)
    setName(item.poojaOfferingOrders.poojaOfferings.name)
    setOffDes(item.poojaOfferingOrders.poojaOfferings.description)
    setOffPrc(item.poojaOfferingOrders.poojaOfferings.price)
    setOffTime(item.poojaOfferingOrders.time)
    setTotalpaidamount(item.poojaOfferingOrders.totalPaidAmount);
    setDakAmt(item.poojaOfferingOrders.userCartDetails.dakshinaAmountForPriest)
    setDonAmt(item.poojaOfferingOrders.userCartDetails.dakshinaAmountToTemple)
    setDAdd(item.poojaOfferingOrders.userCartDetails.deliveryAddress)
    setPrasadamdel(item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag)
    setAdminname(item.poojaOfferingOrders.poojaOfferings.temple.createdBy.firstName)
    setTemplename(item.poojaOfferingOrders.poojaOfferings.temple.name)
    if (item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag == true) {
      setPrasadam("Yes")
    }
    else {
      setPrasadam("No")
    }
    if (item.poojaOfferingOrders.userCartDetails.fromDate === "" || item.poojaOfferingOrders.userCartDetails.fromDate === null) {
      setFromAndTo(false)
    }
    else {
      var fromdate = item.poojaOfferingOrders.userCartDetails.fromDate.split("T");
      const fd = moment(fromdate[0]).format("DD-MM-YYYY");
      var todate = item.poojaOfferingOrders.userCartDetails.toDate.split("T");
      const td = moment(todate[0]).format("DD-MM-YYYY");
      setFDate(fd)
      setTDate(td)
      setFromAndTo(true)
    }
    if (item.poojaOfferingOrders.userCartDetails.noOfMonths < 0 || item.poojaOfferingOrders.userCartDetails.noOfMonths === null) {
      setNoofmonth(false)
    } else {
      setmnth(item.poojaOfferingOrders.userCartDetails.noOfMonths)
      setNoofmonth(true)
    }
    if (item.poojaOfferingOrders.userCartDetails.listOfDates === null || item.poojaOfferingOrders.userCartDetails.listOfDates === "") {
      setListofdates(false)
    }
    else {
      var ld = item.poojaOfferingOrders.userCartDetails.listOfDates.split("||");
      var ld2 = ld.splice(-1);
      var ld3 = ld.toString();
      const ld4 = ld3.split('T12:00:00,').join().split("T12:00:00").join();
      const ld5 = ld4.split(",");
      const ld6 = ld5.splice(-1);
      const ldm2 = [];
      for (let i = 0; i < ld5.length; i++) {
        var ldm = moment(ld5[i]).format("DD-MM-YYYY")
        // var ldm2 
        ldm2.push(ldm);
        // console.log(ldm)
      }
      setLddate(ldm2.join(", "));
      // const l = lddate;
      setListofdates(true)
    }
    // console.log(lddate, "date")
    if (item.poojaOfferingOrders.userCartDetails.dakshinaAmountForPriest == null) {
      setDakshinaAmt(false)
    }
    else {
      setDakshinaAmt(true)
    }
    if (item.poojaOfferingOrders.userCartDetails.dakshinaAmountToTemple === null) {
      setDonationAmt(false)
    }
    else {
      setDonationAmt(true);
    }
    if (item.poojaOfferingOrders.userCartDetails.deliveryAddress === "" || item.poojaOfferingOrders.userCartDetails.deliveryAddress === null) {
      setDAdd("N/A")
    }
    else (
      setDAdd(item.poojaOfferingOrders.userCartDetails.deliveryAddress)
    )

    setModalopen(true);
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  var tableData: any = [];
  if (data != undefined && data.length > 0) {
    tableData = data.filter(
      (item: any) =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
  }

  var orderTableData: any = [];
  if (ship != undefined && ship.length > 0) {
    orderTableData = data.filter(
      (item: any) =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
  }
  const handleOrderChange = (select:any) => {
    setFilterArr([])
    setData([])
  
   
    setFilterStatus(select)
    if(select.value == 2){
      setData(loadData.current);
return
    }
   
    
    if (loadData.current != undefined && loadData.current.length > 0) {

        for(var i=0;i<loadData.current.length;i++){
            var compFlag= 1;
            var IncomFlag = 0;
            var subOrder= loadData.current[i].poojaOfferingOrdersStatusList;
            for(var j=0;j<subOrder.length;j++){
               
                    if(subOrder[j].status != "COMPLETED"){
                        compFlag = 0;
                       
                    }
                

            }
            if(select.value == 0 && compFlag == 0){
                filterArr.push(loadData.current[i]);
            }

            if(select.value == 1 && compFlag == 1){
                filterArr.push(loadData.current[i]);
            }



        }
        
        tableData = filterArr;
        setData(filterArr)
       
    }
    
   }
  const dateChange = (event: any) => {
    //ship[i].shipDate = event.target.value
    var val = event.target.value;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());
    const shiperror: any = {}
    if (val == "") {
      setShiperror((e: any) => {
        return { ...e, shipDate: "Please choose the Shipment Date" }
      })
    }
    else if (tomorrow.toISOString().split('T')[0] > new Date(val).toISOString().split('T')[0]) {
      setShiperror((e: any) => {
        return { ...e, shipDate: "Please select future date" }
      })
    }
    else {
      setShiperror((e: any) => {
        return { ...e, shipDate: "" }
      })
    }
    setShipErrors(shiperror)
    if (val === "") {
      setInitshipdate("")
    }
    else {
      const date = moment(val).format("YYYY-MM-DD")
      setInitshipdate(date)
    }
  };

  const handleIconClick = (event: any) => {
    console.log('Icon clicked!');
    event.stopPropagation();  // Stop the event from propagating to the parent div
  };

  const DateInputRef: any = useRef(null);
  const timeInputRef: any = useRef(null);

  const datelimit = () => {
    const date = new Date(mindate)
    const current = new Date()
    if (date < current) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
    else {
      const today = new Date(mindate);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };
  const showTempleAdmList = () => {
    setShowLoading(true);
    Authservice.templeAdmList(usertoken)
      .then((response) => {
        console.log(response.data);
        var list: any = response.data;
        for (let i = 0; i < list.length; i++) {
          tempAdmList.push(list[i]);
        }
        setShowLoading(false);
      })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
        setShowLoading(false);
      });
  }
  const chooseTempAdms = (e: any) => {
    setTempAdm(e);

    showOrders(e);

  }
  const changeOfferDoneproceed = (i: any, item: any) => {

    // let id = ship[i].id;
    console.log(usertoken, statusId, item.offeringFlag)
    Authservice.changeOfferDone(usertoken, statusId, item.offeringFlag).then((response) => {
      console.log(response.data);
      if (response.data == "PoojaOfferingDone") {
        alert("OfferingStatus Changed successfully")

      } else {
        alert("OfferingStatus Changed failed, Try again")

      }

      window.location.reload();
    })
      .catch((error) => {
        alert(error)
      });
  }

  const receiptview = (item: any) => {
    console.log(item)
    setFileview(item.receipt)
    setReceiptmodal(true)
  }
  const receiptmodalclose = () => {
    setReceiptmodalcheck(false)
    setShipmodal(true)
  }

  const receiptimageref: any = useRef(null)
  const receiptimageref1: any = useRef(null)
  const receiptimageref2: any = useRef(null)

  const handleButtonClick = (e: any) => {
    if (e == 0) {
      if (receiptimageref.current) {
        receiptimageref.current.click();
      }
    }
    else if (e == 1) {
      if (receiptimageref1.current) {
        receiptimageref1.current.click();
      }
    }
    else {
      if (receiptimageref2.current) {
        receiptimageref2.current.click();
      }
    }
  };

  const handleimage = (e: any, i: any) => {
    setReceiptmodalcheck(false)
    setShipmodal(true)
    setOrdStatus("OPEN")
    var file: any = e.target.files[0];
    const splited: any = file.name.split(".")
    if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "jpeg"
      || splited[splited.length - 1] === "png") {
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl, "qwertyui")
      setFiles(file);
      setReceiptimage(file.name)
      const imagenameset = ship.map((item: any, index: any) => {
        if (i === item.id) {
          console.log(item, "itemmmm", i, "iiiii")
          return { ...item, imageName: file.name }
        }
        return item
      })
      setShip(imagenameset)
      setFile(objectUrl);
      // ship[i].receipt = objectUrl
      console.log(files)
    } else {
      alert(file.name + " is Unsupported File Format, Supported File Formats are JPG,JPEG and PNG")
      if (receiptimageref.current) {
        receiptimageref.current.value = null
      }
      if (receiptimageref1.current) {
        receiptimageref1.current.value = null
      }
      if (receiptimageref2.current) {
        receiptimageref2.current.value = null
      }
      return false;
    }
  };

  const receiptimagecheck = (e: any, item: any) => {
    console.log(item, "sdfsdzf")
    if (item.receipt === null) {
      handleimage(e, item.id)
    }
    else {
      setImagesend(e)
      setReceiptmodalcheck(true)
    }
  }

  const androidreceiptimagecheck = (item: any) => {
    console.log(item, "sdfsdzf")
    if (item.receipt === null) {
      takePicture()
    }
    else {
      setAndroidreceiptmodalcheck(true)
    }
  }

  const takePicture = async () => {
    const image: any = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64
    });
    console.log(Camera, "gud")
    console.log(image, 'ghc')
    setImageuri(image)
  }

  const checkmodalclose = () => {
    setAndroidreceiptmodalcheck(false)
    setShipmodal(true)
    setOrdStatus("OPEN")
  }

  const [formErrors, setFormErrors]: any = useState({});

  const fileupload = (item: any) => {
    const imageerrors: any = {}

    if (android === true) {
      if (imageuri.length <= 0) {
        imageerrors.androidimage = "Choose a Receipt"
      }
      if (Object.keys(imageerrors).length === 0) {
        const rawData = atob(imageuri.base64String);
        const bytes = new Array(rawData.length);
        for (var x = 0; x < rawData.length; x++) {
          bytes[x] = rawData.charCodeAt(x);
        }
        const arr = new Uint8Array(bytes);
        console.log(arr, "sdgvd")
        const blob = new File([arr], "receipt", { type: imageuri.format });
        console.log(blob, "image")
        Authservice.Reciept(usertoken, item.id, blob).then((response) => {
          console.log(response)
          if (response.data === "Receipt uploaded") {
            alert("Receipt Uploaded Successfully")
            setShipmodal(false)
            showOrders(tempAdm)
          }
          else {
            alert("Receipt Upload Failed")
            setShipmodal(false)
            showOrders(tempAdm)
          }
          // window.location.reload()
        })
        console.log("qwerty")
      }
    }
    else {
      console.log("1111")
      if (files.length <= 0) {
        console.log("2222")
        imageerrors.fileimage = "Choose a Receipt"
        console.log(imageerrors, "yhfy")
      }
      if (Object.keys(imageerrors).length === 0) {
        console.log(item.id, "iddd")
        console.log(usertoken, 'token', item.id, 'id', files, "image")
        Authservice.Reciept(usertoken, item.id, files).then((response) => {
          console.log(response.data)
          if (response.data === "Receipt uploaded") {
            alert("Receipt Uploaded Successfully")
            setShipmodal(false)
            showOrders(tempAdm)
          }
          else {
            alert("Receipt Upload Failed")
            setShipmodal(false)
            showOrders(tempAdm)
          }
          // window.location.reload()
        })
      }
    }
    setFormErrors(imageerrors)
  }
  const ref: any = useRef([])
  const dateRef: any = useRef([])
  const [timeref, setTimeref] = useState("")

  const time = (e: any) => {
    console.log(initshipdate, "date")
    setTimeref(e.detail.value)
    if (e.detail.value !== "") {
      console.log(e.detail.value, "eeeee")
      var ii = e.detail.value.split(":");
      console.log(isNaN(ii))
      var t: any;
      var a: any;
      // const date = new Date();
      // const dates = new Date(initshipdate + " " + e.detail.value)
      // console.log(dates, "datesssssss")
      // if (dates < date) {
      //   alert("Select Proper Time")
      //   setInitshiptime("")
      //   setInitshipdate("")
      //   return
      // }
      // else {
      if (parseInt(ii[0]) > 12) {
        t = parseInt(ii[0]) - 12;
        a = "PM";
      } else if (parseInt(ii[0]) < 12) {
        t = ii[0]
        a = 'AM';
      }
      else if (parseInt(ii[0]) == 12) {
        t = ii[0]
        a = 'PM';
      }
      // }
      console.log(t, "tttt")
      console.log(ii[1], "iiiiii")
      console.log(a, "aaaaaa")
      var str = t + ":" + ii[1].toString() + " " + a;
      console.log(str, "strrrr")
      setInitshiptime(str)
    }
  }
  const [shipErrors, setShipErrors]: any = useState({})
  const [shiperror, setShiperror]: any = useState({})

  const validation = (num: any) => {
    setShipErrors({})
    if(num === "6"){
      if(initawb.trim() == ""){
        setShiperror((e: any) => {
          return { ...e, awbAlert: "Invalid AWB number" }
        })
       
      }else{
        setShiperror((e: any) => {
          return { ...e, awbAlert: "" }
        })
      }
    }
    if (num === "1") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate());
      if (initshipdate == "") {
        setShiperror((e: any) => {
          return { ...e, shipDate: "Please choose the Shipment Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(initshipdate).toISOString().split('T')[0]) {
        setShiperror((e: any) => {
          return { ...e, shipDate: "Please select future date" }
        })
      }
      else {
        setShiperror((e: any) => {
          return { ...e, shipDate: "" }
        })
      }
    }
    if (num === "2") {
      if (initshiptime == "") {
        setShiperror((e: any) => {
          return { ...e, shipTime: "Please choose the Shipment Time" }
        })
      }
      else {
        setShiperror((e: any) => {
          return { ...e, shipTime: "" }
        })
      }
    }
    if (num === "3") {
      if (state.length === 0) {
        setFormErrors((e: any) => {
          return { ...e, state: "Select a State" }
        })
      }
      else {
        setFormErrors((e: any) => {
          return { ...e, state: "" }
        })
      }
    }
    if (num === "4") {
      if (state.length > 0) {
        if (district.length === 0) {
          setFormErrors((e: any) => {
            return { ...e, district: "Select a District" }
          })
        }
        else {
          setFormErrors((e: any) => {
            return { ...e, district: "" }
          })
        }
      }
    }
    if (num === "5") {
      if (tempAdm.length === 0) {
        setFormErrors((e: any) => {
          return { ...e, admin: "Select a Temple admin" }
        })

      }
      else {
        setFormErrors((e: any) => {
          return { ...e, admin: "" }
        })
      }
    }
  }

  const initShip = () => {
    setShiperror({})
    // console.log(initofferId, initshipdate, initshiptime, initstatusid)
    const shiperror: any = {}
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate())
    if (initshipdate == "") {
      shiperror.shipDate = "Please choose the Shipment Date"
    }
    else if (tomorrow.toISOString().split('T')[0] > new Date(initshipdate).toISOString().split('T')[0]) {
      shiperror.shipDate = "Please select future date"
    }
    if (initshiptime == "") {
      shiperror.shipTime = "Please choose the Shipment Time"
    }
    if(initawb.trim() == ""){
      shiperror.awbAlert = "Invalid AWB number"
    }
    setShipErrors(shiperror)
    if (Object.keys(shiperror).length === 0) {
      // const date1 = new Date();
      // const time: any = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      // const formattedDate = moment(date1.toLocaleDateString()).format("YYYY-MM-DD");

      // const time1Parts = time.split(/:| /);
      // const time2Parts = initshiptime.split(/:| /);

      // const date2: any = new Date();
      // date2.setHours(parseInt(time1Parts[0], 10) % 12 + (time1Parts[2] === "PM" ? 12 : 0));
      // date2.setMinutes(parseInt(time1Parts[1], 10));
      // date2.setSeconds(0);

      // const date3: any = new Date();
      // date3.setHours(parseInt(time2Parts[0], 10) % 12 + (time2Parts[2] === "PM" ? 12 : 0));
      // date3.setMinutes(parseInt(time2Parts[1], 10));
      // date3.setSeconds(0);

      // const differenceInMilliseconds = Math.abs(date3 - date2);
      // const hours = Math.floor(differenceInMilliseconds / 3600000);
      // const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000);

      // if (initshipdate === formattedDate && (hours === 0 && minutes <= 30)) {
      //   alert("Minimum Time Difference Is 30 Minutes");
      //   setInitshiptime("");
      //   setTimeref("");
      // }
      // else {
      Authservice.initShipForOffering(usertoken, initofferId, initshipdate, initshiptime, initstatusid,initawb).then((response) => {
        console.log(response.data);
        if (response.data.errorMessage == null) {
          alert("Shipment Initiated Successfully");
          // window.location.reload();
          showOrders(tempAdm)
        } else {
          alert(response.data.errorMessage);
          //window.location.reload();
          showOrders(tempAdm)
        }

      })
        .catch((error) => {
          alert("Problem in Initiating Shipment");
        });
      setShipmodal(false)

    }
    // }
  }
  const check = (e: any, item: any) => {
    console.log(item)
    // if (e.detail.checked) {
    //   setStatusId(item.id)
    //   setOfferDone(true)
    // }
    // else {
    //   setOfferDone(false)
    // }
    const offdone = ship.map((items: any) => {
      if (item.id === items.id) {
        console.log({ ...items, offeringFlag: !items.offeringFlag }, "kbb")
        return { ...items, offeringFlag: !items.offeringFlag }
      }
      return items
    })
    setShip(offdone)
    console.log(item.id, "idd")
    setStatusId(item.id)
  }
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };


    return (
      <IonRow>
        <IonCol className="ion-text-left" size="12" style={{ paddingRight: '0px' }}>
          <IonSearchbar style={{ paddingRight: '0px' }}
            value={filterText}
            onIonChange={(e: any) => setFilterText(e.detail.value)}
            placeholder="Search"
            className="table_search"
          />
        </IonCol>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle]);

  const useOutsideAlerter = (refer: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setModalopen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const modal1 = useRef(null);
  useOutsideAlerter(modal1);

  const [receipt, setReceipt]: any = useState("")
  const [showreceipt, setShowreceipt] = useState(false)
  const viewreceipt = (item: any) => {
    console.log(item, "itemmmmm")
    setReceipt(item.receipt)
    setShowreceipt(true)
  }

  const drawermodal = () => {
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShiperror({})
    setShipErrors({})
  }

  const mainmodalclose = () => {
    setShipmodal(false)
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShipErrors({})
  }


  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

  const handleChange = (selectedOption: any) => {
    if (selectedOption !== null) {
      setFormErrors({ ...formErrors, admin: "" })
      setTempAdm(selectedOption.value);
    }
    else {
      setTempAdm("");
      setFormErrors({ ...formErrors, admin: "Select a Temple admin" })
    }
    setSelectedOption(selectedOption);
    setData([])
    showOrders(selectedOption.value);
    // showOrders(selectedOption.value);
    console.log(selectedOption, "kmkkk")
  };

  const tempAdmListOptions = tempAdmList
    .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
    .map((tempAdm: any, index: any) => ({
      value: tempAdm.id,
      label: tempAdm.firstName
    }));

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid black",
      boxShadow: 'none',
      fontSize: '15px',
      width: '100%',
      outline: 'none',
      textAlign: "left"
    }),
  };

  const [states, setStates] = useState([]);

  const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);
  const [districtOption, setDistrictOption] = useState<{ value: string; label: string } | null>(null);

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [templeName, setTempleName] = useState("");
  const [nameView, setNameView] = useState(false);

  const [districtList, setDistrictList] = useState([]);
  var stateLiistOptions: any = [];
  if (states != undefined && states.length > 0) {
    stateLiistOptions = states.map((e: any, index: any) => ({
      value: e,
      label: e
    }));
  }
const statusProceed = () => 
{
 
  if (shipStatus == "SCHEDULED") {
    setShipStatusError("Choose order status")
     
  }
  else {
    setShipStatusError("")
      var data =
      {
          "status": shipStatus,
          "id": initstatusid
      }
      Authservice.updateOrderStatusForOffering(usertoken, data).then((response) => {
          console.log(response.data);
          alert("Status Changed Successfully")
          showOrders(tempAdm)
          setShipmodal(false)
      })
          .catch((error) => {
            alert(error)
              
          });
      
  }
}

  const handleStateChange = (e: any) => {
    setTempAdmList([])
    setData([])
    if (e !== null) {
      setFormErrors((e: any) => {
        return { ...e, state: "" }
      })
    }
    else {
      setState("");
      setFormErrors((e: any) => {
        return { ...e, state: "Select a State" }
      })
    }
    setDistrictOption(null)
    setDistrictList([])
    setStateOption(e);
    setState(e.value);
    getDistricts(e.value);
  }

  const getDistricts = (e: any) => {
    setDistrictOption(null)
    setDistrictList([])
    setShowLoading(true);
    Authservice.getAllDistricts([e])
      .then((response) => {
        console.log(response)
        setDistrictList(response.data)
        setShowLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setShowLoading(false)
      })
  }
  var districtLiistOptions: any = [];
  if (districtList != undefined && districtList.length > 0) {
    districtLiistOptions = districtList.map((e: any, index: any) => ({
      value: e,
      label: e
    }));
  }
  

  const handleDistrictChange = (e: any) => {
    setTempAdmList([])
    setData([])
    if (e !== null) {
      setFormErrors((e: any) => {
        return { ...e, district: "" }
      })
      setDistrict(e.value)
    }
    else {
      setDistrict("")
      setFormErrors((e: any) => {
        return { ...e, district: "Select a District" }
      })
    }
    setDistrictOption(e)
  }

  const handleSearch = () => {
    const errors: any = {};
    if (state.length === 0) {
      errors.state = 'Select a State';
    }
    if (district.length === 0) {
      errors.district = 'Enter the District';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      console.log(usertoken, templeName, city, state, district)
      setShowLoading(true)
      Authservice.tempadminsort(usertoken, templeName, city, state, district)
        .then((response) => {
          console.log(response)
          if (response.data.length != 0) {
            setSelectedOption(null)
            setData([])
            setTempAdmList(response.data);
            setNameView(true)
          }
          else {
            Alert("No Results Found !")
            setTempAdmList([]);
          }
          setShowLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false)
        })
    }
  }


  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"Offering Order Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        {/* <Head User="deity" Role={userdata[0]} /> */}
        {/* <Modal show={showreceipt}>
          <Modal.Header
            closeButton
            onClick={() => closemodal()}>
            <div>
              <IonText>
                Receipt
              </IonText>
            </div>
          </Modal.Header>
          <Modal.Body ref={receiptmodal}>
            <Modal.Body>
              <IonImg src={receipt} style={{ backgroundImage: "cover" }} />
            </Modal.Body>
          </Modal.Body>
        </Modal> */}
        <div className="container_middle_table page-wrapper admin_background_color">
          <IonRow>
            <IonCol size="3" size-md="3" size-sm="12" size-xs="12">
              <IonRow style={{ textAlign: "left" }}>
                <IonLabel className="admin_search_label">
                  State <span style={{ color: "red" }}>*</span>
                </IonLabel>
              </IonRow>
              <Select
                options={stateLiistOptions}
                value={stateOption}
                onChange={handleStateChange}
                placeholder="Enter the State"
                isClearable={true}
                onBlur={() => validation(1)}
                styles={customStyles}
                className="custom-select"
              />
              <div style={{ height: "10px" }}>
                {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
              </div>
            </IonCol>
            <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left", position: "relative", zIndex: "999" }}>
              <IonRow style={{ textAlign: "left" }}>
                <IonLabel className="admin_search_label">
                  District <span style={{ color: "red" }}>*</span>
                </IonLabel>
              </IonRow>
              <Select
                options={districtLiistOptions}
                value={districtOption}
                onChange={handleDistrictChange}
                placeholder="Enter the District"
                isClearable
                onBlur={() => validation(2)}
                styles={customStyles}
                className="custom-select" />
              {/* <IonInput value={district} placeholder="Enter District" onIonChange={(e: any) => setDistrict(e.detail.value)} /> */}
              <div style={{ height: "10px" }}>
                {formErrors.district && <span className="alert_input_bottom ">{formErrors.district}</span>}
              </div>
            </IonCol>
            <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left" }}>
              <IonRow style={{ textAlign: "left" }}>
                <IonLabel className="admin_search_label">
                  City
                </IonLabel>
              </IonRow>
              <IonInput value={city} placeholder="Enter City" className="admin_search_input" onIonChange={(e: any) => setCity(e.detail.value)} />
            </IonCol>
            <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left" }}>
              <IonRow style={{ textAlign: "left" }}>
                <IonLabel className="admin_search_label">
                  Temple Name
                </IonLabel>
              </IonRow>
              <IonInput value={templeName} placeholder="Enter Temple Name" className="admin_search_input" onIonChange={(e: any) => setTempleName(e.detail.value)} />
            </IonCol>
          </IonRow>
          <IonRow>
          <IonCol size="12" size-md="12" size-sm="12" size-xs="12" style={{ padding: "0px", display: "flex", justifyContent: "center" }}>
              <IonButton
                className="admin_add_btn"
                onClick={() => handleSearch()}
              >
                <span className="button_padding">Search</span>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow style={{  marginBottom: "2%" }}>
            <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12" style={{ zIndex: "998" }} >
              {tempAdmList.length === 0 ? <></> : <>
               <IonRow style={{ textAlign: "left" }}>
               <IonLabel className="admin_search_label">
                 Admin List 
               </IonLabel>
             </IonRow>
              <Select
                options={tempAdmListOptions}
                value={selectedOption}
                onChange={(e: any) => handleChange(e)}
                placeholder="Select a Temple Admin"
                isClearable
                styles={customStyles}
                className="custom-select"
              /></>}
            </IonCol>
            <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12" style={{ zIndex: "998" }} >
              {(tempAdmList.length === 0 || selectedOption ==null) ? <></> : 
               <>
               <IonRow style={{ textAlign: "left" }}>
               <IonLabel className="admin_search_label">
                 Order Status 
               </IonLabel>
             </IonRow> <Select
        options={orderStatusList}
        value={filterStatus}
        
        onChange={handleOrderChange}
        placeholder="Select Order Status"
        isClearable
        
        styles={customStyles}
        className="custom-select"
    /></>
}
            </IonCol>
           
          </IonRow>
          {selectedOption !== null ?
            <DataTable
              columns={columns}
              data={tableData}
              striped
              noDataComponent="Sorry ! No result found"
              pagination
              customStyles={customTableStyle}
              onRowClicked={(item: any) => viewdetails(item)}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              subHeader
              subHeaderComponent={(isSearch === true || tempAdmList.length !== 0) && selectedOption != null ? subHeaderComponent : ""}
            /> : <></>}
        </div>
        <Modal show={Statusmodal} className="modal_dialog_width1">
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title">Order Status</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setStatusmodal(false)}>
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div>
              <IonRow className="row_bot" style={{ textAlign: "center" }}>
                <IonCol size="3">
                  <IonLabel style={{ fontWeight: 'bold' }} >
                    Order Date
                  </IonLabel>
                </IonCol>
                <IonCol size="1" style={{ textAlign: "center" }}></IonCol>
                <IonCol size="3" style={{ textAlign: "center", fontWeight: 'bold' }}>
                  <IonText>Order Status</IonText>
                </IonCol>
                <IonCol size="1" />
                <IonCol size="4">
                  <IonText style={{ fontWeight: 'bold' }}>Receipt</IonText>
                </IonCol>
              </IonRow>
              {offeringorderList.map((item: any) => {
                var date1 = item.orderedDate.split("T");
                const od1 = moment(date1[0]).format("DD-MM-YYYY");
                var receipt;
                if (item.receipt === null) {
                  receipt = "Not Available"
                }
                else {
                  const splited: any = item.receipt.split("/")
                  receipt = splited[splited.length - 1]
                }
                return (
                  <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                    <IonCol size="3" style={{ textAlign: "center" }}>
                      <IonLabel>
                        {od1}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="1" />
                    <IonCol size="3" style={{ textAlign: "center" }}>
                      <IonText>{item.status}</IonText>
                    </IonCol>
                    <IonCol size="1" />
                    <IonCol size="4" style={{
                      textAlign: "center",
                      width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                    }}>
                      <IonText onClick={() => viewreceipt(item)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>{receipt}</IonText>
                    </IonCol>
                  </IonRow>
                )

              })}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modalopen} className="admin_modal">
          <Modal.Body ref={modal1}>
            <div className="donation-modal-header admin-user-modal-header">
              <h5 className="donation-modal-title">Order Details</h5>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => Modalclose()} />
              </div>
            </div>
            <div className="">
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Order Number
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{ordernum}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Offering Name
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{name}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Offering Description
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{offDes}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" >
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: 'bold' }} >
                    Temple Admin
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{adminname}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" >
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: 'bold' }} >
                    Temple Name
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText> {templename}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Offering Price (₹)
                  </IonLabel>
                </IonCol>

                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{offPrc}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonText style={{ fontWeight: "bold" }}>Total Amount</IonText>
                </IonCol>

                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>₹ {formatter.format(totalpaidamount)}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Prasadam Delivery
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{Prasadam}</IonText>
                </IonCol>
              </IonRow>
              {Prasadamdel == false ? <></> : <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                <IonCol size="5">
                  <IonLabel style={{ fontWeight: "bolder" }}>
                    Delivery Address
                  </IonLabel>
                </IonCol>
                <IonCol size="7">
                  <IonText style={{ paddingRight: "2%" }}>:</IonText>
                  <IonText>{dAdd}</IonText>
                </IonCol>
              </IonRow>}
              {dakshinaAmt &&
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      Dakshina Amount
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>Rs.{formatter.format(dakAmt)}</IonText>
                  </IonCol>
                </IonRow>
              }
              {donationAmt &&
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      Donation Amount
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>Rs.{formatter.format(donAmt)}</IonText>
                  </IonCol>
                </IonRow>
              }
              {fromAndTo && <div>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      From Date
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{fDate}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      To Date
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{tDate}</IonText>
                  </IonCol>
                </IonRow>
              </div>}
              {listofdates &&
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      Offering Date(s)
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{lddate}</IonText>
                  </IonCol>
                </IonRow>}
              {noofmont &&
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonLabel style={{ fontWeight: "bolder" }}>
                      No.Of Months
                    </IonLabel>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{lmnt}</IonText>
                  </IonCol>
                </IonRow>}
              <IonRow className="row_bot">
                <IonCol size="11" size-lg="7" style={{ textAlign: 'center' }}>
                  <IonButton
                    onClick={Modalclose}
                    className="admin_add_btn"
                  >
                    <span className="button_padding">Close</span>
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={androidreceiptmodalcheck} className="gramerce-modal" centered >
          <Modal.Body>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <h5 className="mt-2">Do You Want To Upload Again</h5>
              </IonCol>
            </IonRow>
            <IonRow className="mt-12">
              <IonCol size="6" style={{ textAlign: "end" }}>
                <IonButton onClick={() => takePicture()}>Yes</IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton onClick={(e) => checkmodalclose()}>No</IonButton>
              </IonCol>
            </IonRow>
          </Modal.Body>
        </Modal>
        <Modal show={shipmodal} className="admin_modal">
          <Modal.Body>
            <div className="donation-modal-header admin-user-modal-header">
              <h5 className="donation-modal-title">Shipping Details</h5>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => mainmodalclose()} />
              </div>
            </div>
            <IonRow>
              <IonCol size="3">
                <IonText style={{ fontWeight: "bold" }}>
                  Temple Name
                </IonText>
              </IonCol>
              <IonCol size="0.5">
                <IonText style={{ fontWeight: "bold" }}>
                  :
                </IonText>
              </IonCol>
              <IonCol size="6" style={{ textAlign: "left" }}>
                <IonText>
                  {showtempleName}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="3">
                <IonText style={{ fontWeight: "bold" }}>
                  Offering Name
                </IonText>
              </IonCol>
              <IonCol size="0.5">
                <IonText style={{ fontWeight: "bold" }}>
                  :
                </IonText>
              </IonCol>
              <IonCol size="6" style={{ textAlign: "left" }}>
                <IonText>
                  {offeringName}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="3">
                <IonText style={{ fontWeight: "bold" }}>
                  Order No
                </IonText>
              </IonCol>
              <IonCol size="0.5">
                <IonText style={{ fontWeight: "bold" }}>
                  :
                </IonText>
              </IonCol>
              <IonCol size="6" style={{ textAlign: "left" }}>
                <IonText>
                  {orderNo}
                </IonText>
              </IonCol>
            </IonRow>
            <div>
                        
        <IonList className="horizontal-scroll-list">
            
            {statusList.map((item:any, i:any) => (
                <div key={item} className={(orderStatus == item) ? "chooseOrderTab center_col":"horizontal-scroll-item center_col"}
                onClick={()=>{changeStatus(item)}}>

{item}
{(groupedItems != undefined&& groupedItems[item]!= undefined)?<>
                    ( {groupedItems[item].length} )
                </> :<>
                ( 0 )</>}</div>
           
            ))}
       

        
        
      
      </IonList>
     
     
                        </div>
                        {!groupedItems.hasOwnProperty(orderStatus) ? <div style={{width:"100%", height:"200px"}} className="center_col">
                <IonLabel style={{fontSize:"20px"}}>
                    Order not available
                </IonLabel>
             
              </div>: 
            <div style={{ marginTop: "20px" }} className="manageorderscroll">
              <table style={{ width: "100%" }}>
                <tr>
                  <th>
                    S.No
                  </th>
                  
                  <th>
                    Offering Date
                  </th>
                  {/* <th>
                    Order Status
                  </th> */}
                  <th>
                    Action
                  </th>
                </tr>
                {Object.entries(groupedItems).map(([status, items1]) => (
            <>
              {(status== orderStatus) && <>
             
                {(items1 as any).map((item:any, i:any) => {
                  var dateSplit = item.orderedDate.split("T")
                  const format = moment(dateSplit[0]).format("DD-MM-YYYY")
                  return (
                    <>
                      <Modal show={receiptmodalcheck} className="gramerce-modal" centered >
                        <Modal.Body>
                          <IonRow>
                            <IonCol style={{ textAlign: "center" }}>
                              <h5 className="mt-2">Do You Want To Upload Again</h5>
                            </IonCol>
                          </IonRow>
                          <IonRow className="mt-12">
                            <IonCol size="6" style={{ textAlign: "end" }}>
                              <IonButton onClick={() => {
                                setReceiptmodalcheck(false)
                                setTimeout(() => {
                                  handleimage(imagesend, i)
                                }, 1000);
                              }}>Yes</IonButton>
                            </IonCol>
                            <IonCol size="6">
                              <IonButton onClick={(e) => setReceiptmodalcheck(false)}>No</IonButton>
                            </IonCol>
                          </IonRow>
                        </Modal.Body>
                      </Modal>
                      <tr className="material-shipment-row">
                        <td>
                          <IonText>{i + 1}</IonText>
                        </td>
                        {/* <td>
                          <IonText>{item.poojaOfferingOrders.orderNumber}</IonText>
                        </td> */}
                        <td>
                          <IonText>{format}</IonText>
                        </td>
                        {/* <td>
                          <IonText>{item.status}</IonText>
                        </td> */}
                        <td>
                          <IonButton className="admin_add_btn" onClick={() => toggleDrawer(item, i)}>
                            <span className="button_padding">
                              Manage
                            </span>
                          </IonButton>
                        </td>
                      </tr>
                    </>
                  )
                })}
                </>}
               
              </>
                ))}
              </table>
              <div className={`drawer ${initisopen ? 'open' : ''}`}>
                <div className="drawer-toggle">
                  <div style={{ width: "90%" }}>
                    <IonText>Atmagram</IonText>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                  </div>
                </div>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Offering Date
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {orderDate}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Order Number
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {ordernumber}
                    </IonText>
                  </IonCol>
                </IonRow>
                {orderedBy === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Name
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderedBy}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                {orderByStar === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Star
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderByStar}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                <div style={{ textAlign: "center", height: "10%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Initiate Shipment
                  </IonText>
                </div>
                <IonRow>
                  <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm="5" size-xs="5">
                    <IonText>
                      Shipment Date :
                    </IonText>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="offdefaulticons">
                    <IonInput onBlur={() => validation("1")}
                      onKeyDown={(e: any) => {
                        if (e.key !== 'Tab' && e.key !== ' ') {
                          e.preventDefault();
                        }
                        else {
                          if (DateInputRef.current) {
                            DateInputRef.current.setFocus();
                          }
                        }
                      }}
                      ref={DateInputRef}
                      value={initshipdate} type="date"
                      onIonChange={(e: any) => dateChange(e)} min={datelimit()} className="time_off status-ship"
                      color="black"
                      style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }} />
                    <div style={{ display: "flex", marginTop: "2%" }}>
                      {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                      {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                    </div>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" style={{ paddingLeft: "10px", paddingRight: "10px" }} className="officons">
                    <div style={{ position: "absolute", width: "100%", justifyContent: "flex-end" }}>
                      <IonInput onBlur={() => validation("1")} onKeyDown={(e: any) => e.preventDefault()} value={initshipdate} type="date" onIonChange={(e: any) => dateChange(e)} min={datelimit()} className="time_off status-ship" />
                    </div>
                    <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "3%" }}>
                      <IonIcon className="calaendariconorders date_icon_style" onClick={handleIconClick} src={calendarOutline} />
                    </div>
                    <div style={{ display: "flex", marginTop: "7%" }}>
                      {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                      {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                    </div>
                  </IonCol>
                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" />
                </IonRow>
                <IonRow>
                  <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm='5' size-xs="5">
                    <IonText>
                      Shipment Time :
                    </IonText>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="offdefaulticons">
                    <IonInput onBlur={() => validation("2")} type="time" value={timeref}
                      onKeyDown={(e: any) => {
                        if (e.key !== 'Tab' && e.key !== ' ') {
                          e.preventDefault();
                        }
                        else {
                          if (timeInputRef.current) {
                            timeInputRef.current.setFocus();
                          }
                        }
                      }}
                      ref={timeInputRef}
                      style={{}} onIonChange={(e: any) => time(e)} className="time_off status-ship" />
                    <div style={{ display: "flex", marginTop: "2%" }}>
                      {shiperror.shipTime && <span className="alert_input_bottom ">{shiperror.shipTime}</span>}
                      {shipErrors.shipTime && <span className="alert_input_bottom ">{shipErrors.shipTime}</span>}
                    </div>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="officons">
                    <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center" }}>
                      <IonInput onBlur={() => validation("2")} value={timeref} onKeyDown={(e: any) => e.preventDefault()} type="time" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onIonChange={(e: any) => time(e)} className="time_off status-ship" />
                    </div>
                    <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "3%", paddingRight: "5px" }}>
                      <IonIcon className="calaendariconorders date_icon_style" onClick={handleIconClick} src={timeOutline} />
                    </div>
                    <div style={{ display: "flex", marginTop: "7%" }}>
                      {shiperror.shipTime && <span className="alert_input_bottom ">{shiperror.shipTime}</span>}
                      {shipErrors.shipTime && <span className="alert_input_bottom ">{shipErrors.shipTime}</span>}
                    </div>
                  </IonCol>
                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" />
                </IonRow>
                <IonRow>
                <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm='5' size-xs="5">
                    <IonText>
                      AWB number :
                    </IonText>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" >
                  
              <IonInput type="text" placeholder="AWB number" style={{textAlign:"left"}}
                                                              onBlur={() => validation("6")}
                                                              
                                                              onIonChange={(e: any) =>{
                                                                  validation("6")
                                                                  setInitAwb(e.detail.value.trim()) 
                                                              } }></IonInput>
                                                                <div style={{ display: "flex", marginTop: "3%" }}>
                                                              {shiperror.awbAlert && <span className="alert_input_bottom">{shiperror.awbAlert}</span>}
                                                              {shipErrors.awbAlert && <span className="alert_input_bottom ">{shipErrors.awbAlert}</span>}

                                                              </div>
                    </IonCol>

                </IonRow>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                  <IonButton className="admin_add_btn" onClick={() => initShip()}>
                    <span className="button_padding">
                      Initiate Shipment
                    </span>
                  </IonButton>
                </div>
                

                <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Receipt Upload
                  </IonText>
                </div>
                <div style={{ marginTop: "2%" }}>
                  {/* Drawer content goes here */}
                  {web &&
                    <>
                      <IonRow>
                        <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          <input className="receipt" style={{ display: "none" }} ref={receiptimageref} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                          <IonButton
                            onClick={() => handleButtonClick(0)}
                            className="receipt_img_btn image_content"
                          ><span className="button_padding">Choose File</span>
                          </IonButton>
                          <div style={{ display: "flex", marginTop: "2%" }}>
                            {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          {receiptdata === null ?
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText>{receiptimage}</IonText>
                            </div> :
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow>
                      </IonRow>
                    </>
                  }
                  {android &&
                    <>
                      <IonRow style={{ margintop: '2%' }}>
                        <IonCol size="1">
                        </IonCol>
                        <IonCol size="6" style={{ display: "flex", alignItems: "center" }}>
                          <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                            <IonText style={{ fontSize: "10px" }}>
                              Choose file
                            </IonText>
                          </IonButton>
                        </IonCol>
                        <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                          {receiptdata === null ?
                            <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                            <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="3">
                        </IonCol>
                        <IonCol size="9">
                          {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                        </IonCol>
                      </IonRow>
                    </>
                  }
                </div>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px" }}>
                  <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                    <span className="button_padding">
                      Upload
                    </span>
                  </IonButton>
                </div>
              </div>
              <div className={`drawer ${awbisopen ? 'open' : ''}`}>
                <div className="drawer-toggle">
                  <div style={{ width: "90%" }}>
                    <IonText>Atmagram</IonText>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                  </div>
                </div>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Offering Date
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {orderDate}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Order Number
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {ordernumber}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      AWB Number
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {awb}
                    </IonText>
                  </IonCol>
                </IonRow>
                {orderedBy === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Name
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderedBy}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                {orderByStar === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Star
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderByStar}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                <div style={{ textAlign: "center", height: "10%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Initiate Shipment
                  </IonText>
                </div>
                <IonRow>
                  <IonCol size="6" style={{ textAlign: "right" }}>
                    <IonText>
                      Shipment Date :
                    </IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText>
                      {showshipdate}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" style={{ textAlign: "right" }}>
                    <IonText>
                      Shipment Time :
                    </IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText>
                      {showshiptime}
                    </IonText>
                  </IonCol>
                </IonRow>
                <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Receipt Upload
                  </IonText>
                </div>
                <div style={{ marginTop: "2%" }}>
                  {/* Drawer content goes here */}
                  {web &&
                    <>
                      <IonRow>
                        <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          <input className="receipt" style={{ display: "none" }} ref={receiptimageref1} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                          <IonButton
                            onClick={() => handleButtonClick(1)}
                            className="receipt_img_btn image_content"
                          ><span className="button_padding">Choose File</span>
                          </IonButton>
                          <div style={{ display: "flex", marginTop: "2%" }}>
                            {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          {receiptdata === null ?
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText>{receiptimage}</IonText>
                            </div> :
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                    </>
                  }
                  {android &&
                    <>
                      <IonRow style={{ margintop: '2%' }}>
                        <IonCol size="3">
                        </IonCol>
                        <IonCol size="4" style={{ display: "flex", alignItems: "center" }}>
                          <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                            <IonText style={{ fontSize: "10px" }}>
                              Choose file
                            </IonText>
                          </IonButton>
                        </IonCol>
                        <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                          {receiptdata === null ?
                            <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                            <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="3">
                        </IonCol>
                        <IonCol size="9">
                          {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                        </IonCol>
                      </IonRow>
                    </>
                  }
                </div>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                  <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                    <span className="button_padding">
                      Upload
                    </span>
                  </IonButton>
                </div>
                <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Order Status
                  </IonText>
                </div>
                <div className="center_col">
                 
                  <select style={{ fontSize: "13px" }} 
                  className="select-status-admin" value={shipStatus} onChange={(e: any) => {setShipStatus(e.target.value)
                    
                  }} placeholder="Select Status">
                                                                    
                                                                    
             <option value="SCHEDULED" selected disabled>Select Status</option>
                                                                    <option value="SHIPPED">SHIPPED</option>
                                                                    <option value="COMPLETED">COMPLETED</option>
                                                                </select>
                                                                </div><div className="center_col">
                                                                <IonButton className="datatable-activate-btn" onClick={() => {statusProceed() }}><span className="button_padding">Update</span></IonButton>
                 
                
                </div>
                
                {shipStatusError!= "" && <IonText className="center_col" style={{fontSize:"10px",color:"red"}}>{shipStatusError}</IonText>}
              </div>
              <div className={`drawer ${isOpendel ? 'open' : ''}`}>
                <div className="drawer-toggle">
                  <div style={{ width: "90%" }}>
                    <IonText>Atmagram</IonText>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                  </div>
                </div>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Offering Date
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {orderDate}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      Order Number
                    </IonText>
                  </IonCol>
                  <IonCol size="0.5">
                    <IonText style={{ fontWeight: "bold" }}>
                      :
                    </IonText>
                  </IonCol>
                  <IonCol size="6" style={{ textAlign: "left" }}>
                    <IonText>
                      {ordernumber}
                    </IonText>
                  </IonCol>
                </IonRow>
                {orderedBy === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Name
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderedBy}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                {orderByStar === "" ? <></> :
                  <IonRow>
                    <IonCol size="5.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        Star
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderByStar}
                      </IonText>
                    </IonCol>
                  </IonRow>
                }
                <div style={{ textAlign: "center", height: "15%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Receipt Upload
                  </IonText>
                </div>
                <div style={{ marginTop: "2%" }}>
                  {/* Drawer content goes here */}
                  {web &&
                    <>
                      <IonRow>
                        <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          <input className="receipt" style={{ display: "none" }} ref={receiptimageref2} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                          <IonButton
                            onClick={() => handleButtonClick(2)}
                            className="receipt_img_btn image_content"
                          ><span className="button_padding">Choose File</span>
                          </IonButton>
                          <div style={{ display: "flex", marginTop: "2%" }}>
                            {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                          {receiptdata === null ?
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText>{receiptimage}</IonText>
                            </div> :
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                    </>
                  }
                  {android &&
                    <>
                      <IonRow style={{ margintop: '2%' }}>
                        <IonCol size="3">
                        </IonCol>
                        <IonCol size="4" style={{ display: "flex", alignItems: "center" }}>
                          <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                            <IonText style={{ fontSize: "10px" }}>
                              Choose file
                            </IonText>
                          </IonButton>
                        </IonCol>
                        <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                          {receiptdata === null ?
                            <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                            <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                            </div>
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="3">
                        </IonCol>
                        <IonCol size="9">
                          {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                        </IonCol>
                      </IonRow>
                    </>
                  }
                </div>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                  <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                    <span className="button_padding">
                      Upload
                    </span>
                  </IonButton>
                </div>
              </div>
            </div>}
          </Modal.Body>
        </Modal>
        {receiptmodal && (
          <Lightbox
            mainSrc={fileview + "?v=" + new Date()}
            onCloseRequest={() => setReceiptmodal(false)}
            onImageLoad={() => {
              window.dispatchEvent(new Event('resize'));
            }}
          />
        )}
        {/* <Modal show={receiptmodal} ref={receiptref}>
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title">Receipt</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => receiptmodalclose()}>
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <IonRow>
              <IonCol size="12">
                <IonImg style={{ background: "cover" }} src={fileview + "?v=" + new Date()} />
              </IonCol>
            </IonRow>
          </Modal.Body>
        </Modal> */}
        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};

export default OrderAdm;