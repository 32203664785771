import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonImg,
    IonModal,
    IonTextarea,
    IonLoading,
    useIonAlert,
    IonSearchbar,
    IonGrid,
    IonSelect,
    IonText
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";

import { useForm } from "react-hook-form";

import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, addOutline, arrowBack, close } from "ionicons/icons";
import DataTable from "react-data-table-component"
import Select, { components } from 'react-select';
import "./Deitymanage.css";
import { useHistory } from "react-router";
import Authservice from "../../Services/user.service";
import { Capacitor } from "@capacitor/core";
import Lightbox from "react-image-lightbox";
import customTableStyle from "../../components/CustomTableStyle";
import Sidebar from "./sidebar";
import AdminHead from "./adminHead";

const Grammercemanage: React.FC = () => {

    const formatter = new Intl.NumberFormat('en-IN')

    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("")
    const [fileName, setFileName] = useState("");

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            width: "90px",
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item.id)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Product Image",
            sortable: false,

            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item.id)}>
                    <div style={{ cursor: "pointer" }}>{item.grammerceImgsUrl === null ? <IonImg style={{ width: "70px", height: "70px" }} src="assets/icon/noimage.png" /> : <IonImg style={{ width: "70px", height: "70px" }} src={item.grammerceImgsUrl[0] + "?=v1"} />}</div>
                </div>
            )

        },

        {
            name: "Grammerce Name",
            selector: (item: any) => item.productName,
            sortable: true,

            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item.id)}>
                    <div style={{ cursor: "pointer" }}>{item.productName}</div>
                </div>
            )
        },
        // {
        //     name: "Significance",
        //     selector: (item: any) => item.significance,
        //     sortable: true,

        //     cell: (item: any) => (
        //         <div className="left_col" onClick={() => viewdetails(item.id)} style={{ width: "100%" }}>
        //             <div style={{ cursor: "pointer", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.significance}</div>
        //         </div>
        //     )
        // },
        {
            name: "Price (₹)",
            selector: (item: any) => parseInt(item.price, 10),
            sortable: true,

            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item.id)}>
                    <div style={{ cursor: "pointer" }}>{formatter.format(parseInt(item.price, 10))}</div>
                </div>
            )
        },
        {
            name: "Material Stock",
            selector: (item: any) => parseInt(item.stock, 10),
            sortable: true,

            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item.id)}>
                    <div style={{ cursor: "pointer" }}>{parseInt(item.stock, 10)}</div>
                </div>
            )
        },
        {
            name: "Status",
            sortable: false,

            selector: (item: any) => item.activeFlag,
            cell: (item: any) => (
                <div className="center_col_tab">
                    {item.activeFlag === "true" ? (

                        <IonButton color="danger" className="datatable-activate-btn"
                            onClick={() =>
                                presentAlert({
                                    header: 'Alert',
                                    message: ' If you deactivate, the customers will not be able to view the selected product to place orders. Do you want to deactivate?',
                                    buttons: [
                                        {
                                            text: 'No',
                                            role: 'cancel',
                                            handler: () => {
                                                // setHandlerMessage('Alert canceled');
                                            },
                                        },
                                        {
                                            text: 'Yes',
                                            role: 'confirm',
                                            handler: (alertData) => {
                                                handleStatus(false, item.id, alertData.comment)
                                            },
                                        },
                                    ],
                                    inputs: [
                                        {
                                            name: 'comment',
                                            type: 'text',
                                            value: comment,
                                            placeholder: 'Enter your comment'
                                        }
                                    ]
                                })

                            }><span className="button_padding">Deactivate
                            </span></IonButton>

                    ) : (
                        <IonButton color="success" className="datatable-activate-btn" style={{ textTransform: "none" }} onClick={() =>
                            presentAlert({
                                header: 'Alert',
                                message: 'If you activate, the customers will be able to view the selected product to place orders. Do you want to activate?',
                                buttons: [
                                    {
                                        text: 'No',
                                        role: 'cancel',
                                        handler: () => {
                                            // setHandlerMessage('Alert canceled');
                                        },
                                    },
                                    {
                                        text: 'Yes',
                                        role: 'confirm',
                                        handler: (alertData) => {
                                            handleStatus(true, item.id, alertData.comment)
                                        },
                                    },
                                ],
                                inputs: [
                                    {
                                        name: 'comment',
                                        type: 'text',
                                        value: comment,
                                        placeholder: 'Enter your comment'
                                    }
                                ]
                            })}><span className="button_padding">Activate
                            </span></IonButton>

                    )}
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,
        //     width: "10%",
        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={() => viewdetails(item.id)} style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // }
    ]
    const {
        handleSubmit,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const history = useHistory();
    const modal = useRef<HTMLIonModalElement>(null);
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const [grammercelist, setGrammercelist] = useState([]);
    const [modalopen, setModalopen] = useState(false);
    const [name, setName] = useState("");
    const [image, setImage]: any = useState([]);
    const [images, setImages]: any = useState("");
    const [isedit, setIsedit] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [viewgrammerce, setViewgrammerce]: any = useState([]);
    const [productname, setProductname] = useState("");
    const [price, setPrice] = useState("");
    const [significance, setSignification] = useState("");
    const [stock, setStock] = useState("");
    const [glry, setGlry] = useState([]);
    const [gid, setGid] = useState("");
    const [reverselist, setReverselist] = useState([])
    if (grammercelist != undefined && grammercelist.length > 0) {
        grammercelist.forEach((item: any, index: any) => { item.serial = index + 1; });
    }

    const [showLoading, setShowLoading] = useState(false);
    const [delleadtime, setDelleadtime]: any = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [stateOption, setStateOption]: any = useState([]);
    const [countryOption, setCountryOption]: any = useState([{ value: "India", label: "India" }]);
    const [districtOption, setDistrictOption]: any = useState([]);
    const [stateMenu, setStateMenu] = useState(false)
    const [distrctMenu, setDistrictMenu] = useState(false)
    const [state, setState] = useState("");
    const [index, setIndex] = useState(0)
    const [districtsSelect, setDistrictsSelect] = useState(false)


    const [states, setStates]: any = useState([]);
    const [districts, setDistricts]: any = useState([]);
    const [country, setCountry]: any = useState(["India"]);
    const [alertModal, setAlertModal]: any = useState(false)
    const [alertmsg, setAlertMsg]: any = useState("")





    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "none",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const getAllStates = () => {
        setShowLoading(true);
        UserService.getAllstates().then((response) => {
            console.log(response.data);
            setStates(response.data)
            setShowLoading(false);
        })

            .catch((error) => {
                setShowLoading(false);

                setAlertMsg(error)
                setAlertModal(true)

            });
    }
    const getAllDistricts = (data: any) => {
        setShowLoading(true)
        console.log(data)
        if (data.length > 0) {
            const arr = data.map((e: any) => {
                return e.value
            })
            UserService.getAllDistrictsByState(arr).then((response) => {
                console.log(response);
                const data = Object.entries(response.data).map(([state, districts]) => ({
                    state,
                    districts,
                }))
                const arr = data.map((e: any, index: any) => ({
                    label: e.state,
                    options: e.districts.map((item: any) => ({
                        value: e.state + "||" + item,
                        label: item
                    }))
                }))
                setDistrictLiistOptions(arr)
                setDistrictsSelect(true)
                setStateMenu(false)
                setShowLoading(false)
            })

                .catch((error) => {

                    setShowLoading(false);
                    setAlertMsg(error)
                    setAlertModal(true)
                });
        } else {
            setDistrictOption([])
            setStateMenu(false)
            setDistrictMenu(false)
            setDistrictsSelect(false)
            setShowLoading(false)
        }
    }
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }

    const [districtLiistOptions, setDistrictLiistOptions]: any = useState([])
    const countryLiistOptions = country.map((e: any, index: any) => ({
        value: e,
        label: e
    }));
    const handleCountryChange = (e: any) => {
        if (e !== "" || e !== null) {
            setFormerrors({ ...formerrors, country: "" })
        }
        else {
            setFormerrors({ ...formerrors, country: "Choose the Country" })
        }
        setCountryOption(e);
    }
    const handleDistrictChange = (e: any) => {
        console.log(e)
        setDistrictOption(e);
    }
    const handleStateChange = (e: any) => {
        if (e.length > 0) {
            const arr = districtLiistOptions.filter((ee: any) => e.some((obj: any) => obj.value == ee.label))
            const data = districtOption.filter((e: any) =>
                arr.some((obj: any) => obj.options.some((val: any) => val.value === e.value))
            )
            setDistrictOption(data)
            setDistrictLiistOptions(arr)
            setStateOption(e);
        } else {
            setDistrictLiistOptions([])
            setStateOption(e)
            setDistrictOption([])
            setDistrictsSelect(false)
        }
    }
    const clearAll = () => {
        setStateOption([])
    }
    const customIsClearable = (value: any, defaultValue: any) => {
        return value !== defaultValue;
    };
    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            showallgrammerce();
            getAllStates()
        }

    }, []);
    const showallgrammerce = () => {
        setShowLoading(true);
        UserService.Grammerceadm(usertoken).then((response) => {
            console.log(response.data);
            // setGrammercelist(response.data);
            var data = response.data
            const array: any = [...data].reverse();
            setGrammercelist(array)
            setShowLoading(false);
            console.log(array, "reverse")

        })

            .catch((error) => {

                setShowLoading(false);
                setAlertMsg(error)
                setAlertModal(true)
            });
    }
    const handleStatus = (status: any, id: any, comm: any) => {
        Authservice.statusAdmgram(usertoken, status, id, comm).then((response) => {
            console.log(response)
            window.location.reload()
        })
            .catch((error) => {

                setAlertMsg("Something went wrong, please try again later.")
                setAlertModal(true)
            });

    }
    const [viewcomment, setViewcomment] = useState("")
    const [inactivecomment, setInactivecomment] = useState("")
    const [viewFlag, setViewFlag] = useState("")

    const viewdetails = (item: any) => {
        setImage([])
        setShowLoading(true)
        UserService.Viewgrammerceadm(usertoken, item).then((response) => {
            console.log(response.data, "dataaa");
            setGid(item)
            console.log(response.data.activeFlag, 'flag')
            setViewFlag(response.data.activeFlag)
            if (response.data.brandName === null) {
                setName("");
            } else {
                setName(response.data.brandName);
            }
            if (response.data.activeComment === null) {
                setViewcomment("");
            } else {
                setViewcomment(response.data.activeComment);
            }
            if (response.data.inactiveComment === null) {
                setInactivecomment("");
            } else {
                setInactivecomment(response.data.inactiveComment);
            }
            if (response.data.productName === null) {
                setProductname("");
            } else {
                setProductname(response.data.productName);
            }
            if (response.data.price === null) {
                setPrice("");
            } else {
                setPrice(response.data.price);
            }
            if (response.data.significance === null) {
                setSignification("");
            } else {
                setSignification(response.data.significance);
            }
            if (response.data.stock === null) {
                setStock("");
            } else {
                setStock(response.data.stock);
            }
            if (response.data.stock === null) {
                setDelleadtime("");
            } else {
                setDelleadtime(response.data.deliveryLeadTime);
            }
            if (response.data.country == null) {
                setCountryOption([{ value: "India", label: "India" }])
            }
            else {
                setCountry(response.data.country)
            }
            if (response.data.state == null) {
                setStateOption([])
            }
            else {
                const data = response.data.state.map((e: any) => ({
                    value: e,
                    label: e
                }))
                setStateOption(data)
                getAllDistricts(data)
            }
            if (response.data.district == null) {
                setDistrictOption([])
            }
            else {
                const data = response.data.district.map((e: any) => ({
                    value: e,
                    label: e
                }))
                setDistrictOption(data)
                setDistrictsSelect(true)
            }
            if (response.data.grammerceImgsUrl == null) {
                setImage([])
            }
            else {
                setImage(response.data.grammerceImgsUrl)
            }
            console.log(response.data.grammerceImgsUrl)
            setShowLoading(false)
        })
        setIsedit(true)
        // setModalopen(true);
        setDistrictMenu(false)
        setStateMenu(false)
        setShowLoading(false)
        setModalopen(true)
    };
    const handleCheckboxChange = (option: any) => {
        console.log("lsnc")
        const selectedValues = stateOption.map((selectedOption: any) => selectedOption.value);
        console.log(selectedValues)
        if (selectedValues.includes(option.value)) {
            const updatedOptions = stateOption.filter((selectedOption: any) => selectedOption.value !== option.value);
            setStateOption(updatedOptions);
        } else {
            setStateOption([...stateOption, option]);
        }
    };
    const handleCheckboxChangeDistrict = (option: any) => {
        const selectedValues = districtOption.map((selectedOption: any) => selectedOption.value);
        console.log(selectedValues)
        if (selectedValues.includes(option.value)) {
            const updatedOptions = districtOption.filter((selectedOption: any) => selectedOption.value !== option.value);
            setDistrictOption(updatedOptions);
        } else {
            setDistrictOption([...districtOption, option]);
        }
    };
    const { Option, Menu } = components;
    const CheckboxOption = ({ innerProps, label, isSelected, value }: any) => {
        return (
            <div>
                <div {...innerProps}>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleCheckboxChange({ value: value, label })}
                        style={{ margin: "0px 5px" }}
                    />
                    {label}
                </div>
            </div>
        )
    };
    const DistrictCheckOption = ({ innerProps, label, isSelected, value }: any) => {
        return (
            <div>
                <div {...innerProps} style={{ textAlign: "left" }}>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleCheckboxChangeDistrict({ value: value, label })}
                        style={{ margin: "0px 5px" }}
                    />
                    {label}
                </div>
            </div>
        )
    };
    const BtnOption = (props: any) => {
        return (
            <Menu {...props} style={{ ...props.style, width: '100%', maxHeight: '100px' }}>
                {props.children}
                <div style={{ borderTop: '1px solid #e6e6e6', marginTop: '10px', padding: '5px', display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <IonButton className="admin_add_btn" onClick={() => clearAll()}><span className="button_padding">Clear
                        </span></IonButton>
                    </div>
                    <div >
                        <IonButton className="admin_add_btn" onClick={() => getAllDistricts(stateOption)}><span className="button_padding">Ok</span></IonButton>
                    </div>
                </div>
            </Menu >
        )
    };
    const inputFileStyle = {
        color: 'white',          // Change the text color
        backgroundColor: 'blue', // Change the background color
        border: '2px solid red', // Add a border (optional)
        borderRadius: '5px',     // Add rounded corners (optional)
        padding: '10px 20px',    // Add padding (optional)
    };
    const DistrictBtnOption = (props: any) => {
        return (
            <Menu {...props} style={{ ...props.style, width: '100%', maxHeight: '100px' }}>
                {props.children}
                <div style={{ borderTop: '1px solid #e6e6e6', marginTop: '10px', padding: '5px', display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <IonButton className="admin_add_btn" onClick={() => setDistrictOption([])}><span className="button_padding">Clear
                        </span></IonButton>
                    </div>
                    <div >
                        <IonButton className="admin_add_btn" onClick={() => setDistrictMenu(false)}><span className="button_padding">Ok
                        </span></IonButton>
                    </div>
                </div>
            </Menu >
        )
    };
    const openmodal = () => {
        setIsedit(false)
        setSignification("");
        setName("");
        setStock("");
        setPrice("");
        setProductname("");
        setDelleadtime("");
        setDistrictOption([])
        setStateOption([])
        setDistrictsSelect(false)
        // setModalopen(true);
        setFormerrors({})
        setImage([])
        setImages("")
        setFileName("")

        setModalopen(true)
    };

    const Modalclose = () => {
        showallgrammerce();
        setModalopen(false)
    }

    const imageinputref: any = useRef(null)

    const handleimage = (e: any) => {
        console.log(e.target.files[0].name.split("."));
        var file: any = e.target.files[0];
        const splited: any = file.name.split(".")
        setImage([])
        setImages([])
        if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png"
            || splited[splited.length - 1] === "jpeg") {
            console.log(images)
            const objectUrl = URL.createObjectURL(file);
            setImages(file);
            setImage(objectUrl);
            setFileName(file.name);
        } else {
            setAlertMsg(file.name + " is Unsupported File Format, Supported File Formats Are JPG, PNG,JPEG")
            setAlertModal(true)
            // showAlert();
            // presentAlert(file.name + " is Unsupported File Format, Supported File Formats Are JPG, PNG,JPEG")
            if (imageinputref.current) {
                imageinputref.current.value = null
            }
            return false;
        }
    }

    const [formerrors, setFormerrors]: any = useState({})
    const validation = (num: any) => {
        if (num === 1) {
            if (productname === "") {
                setFormerrors({ ...formerrors, productName: "Enter the Product name" })
            }
            else if (productname.charAt(0) === " ") {
                setFormerrors({ ...formerrors, productName: "Empty space at Product name" })
            }
            else {
                setFormerrors({ ...formerrors, productName: "" })
            }
        }
        else if (num === 2) {
            if (name === "") {
                setFormerrors({ ...formerrors, brandname: "Enter the Brand name" })
            }
            else if (name.charAt(0) === " ") {
                setFormerrors({ ...formerrors, brandname: "Empty space at Brand name" })
            }
            else {
                setFormerrors({ ...formerrors, brandname: "" })
            }
        }
        else if (num === 3) {
            if (price === "") {
                setFormerrors({ ...formerrors, price: "Enter the Price" })
            }
            else {
                setFormerrors({ ...formerrors, price: "" })
            }
        }
        else if (num === 4) {
            if (significance === "") {
                setFormerrors({ ...formerrors, significance: "Enter the Significance" })
            }
            else {
                setFormerrors({ ...formerrors, significance: "" })
            }
        }
        else if (num === 5) {
            if (stock === "") {
                setFormerrors({ ...formerrors, stock: "Enter the Stock" })
            }
            else {
                setFormerrors({ ...formerrors, stock: "" })
            }
        }
        else if (num === 6) {
            if (delleadtime === "") {
                setFormerrors({ ...formerrors, delleadtime: "Enter the Delivery Lead Time" })
            }
            else {
                setFormerrors({ ...formerrors, delleadtime: "" })
            }
        }
        else if (num === 7) {
            if (countryOption.length < 0) {
                setFormerrors({ ...formerrors, country: "Choose the Country" })
            }
            else {
                setFormerrors({ ...formerrors, country: "" })
            }
        }
    }

    const onChangevalidation = (num: any, e: any) => {
        if (num === 1) {
            var name = e.detail.value!;
            if (name === "") {
                setFormerrors({ ...formerrors, productName: "Enter the Product name" })
            }
            else if (name.charAt(0) === " ") {
                setFormerrors({ ...formerrors, productName: "Empty space at Product name" })
            }
            else {
                setFormerrors({ ...formerrors, productName: "" })
            }
            setProductname(e.detail.value!)
        }
        else if (num === 2) {
            var bname = e.detail.value!;
            if (bname === "") {
                setFormerrors({ ...formerrors, brandname: "Enter the Brand name" })
            }
            else if (bname.charAt(0) === " ") {
                setFormerrors({ ...formerrors, brandname: "Empty space at Brand name" })
            }
            else {
                setFormerrors({ ...formerrors, brandname: "" })
            }
            setName(e.detail.value!)
        }
        else if (num === 3) {
            var price = e.detail.value!;
            if (price === "") {
                setFormerrors({ ...formerrors, price: "Enter the Price" })
            }
            else {
                setFormerrors({ ...formerrors, price: "" })
            }
            setPrice(e.detail.value!)
        }
        else if (num === 4) {
            var significance = e.detail.value!;
            if (significance === "") {
                setFormerrors({ ...formerrors, significance: "Enter the Significance" })
            }
            else {
                setFormerrors({ ...formerrors, significance: "" })
            }
            setSignification(e.detail.value!)
        }
        else if (num === 5) {
            var stock = e.detail.value!;
            if (stock === "") {
                setFormerrors({ ...formerrors, stock: "Enter the Stock" })
            }
            else {
                setFormerrors({ ...formerrors, stock: "" })
            }
            setStock(e.detail.value!)
        }
        else if (num === 6) {
            var time = e.detail.value!;
            if (time === "") {
                setFormerrors({ ...formerrors, delleadtime: "Enter the Delivery Lead Time" })
            }
            else {
                setFormerrors({ ...formerrors, delleadtime: "" })
            }
            setDelleadtime(e.detail.value!)
        }
    }

    const onSubmit = () => {
        console.log(images)
        const country = countryOption.map((e: any) => {
            return e.value
        })
        console.log(country, districtOption)

        var stat: any = [], dist: any = []
        if (stateOption.length > 0) {
            const state = stateOption.map((e: any) => {
                return e.value
            })
            stat = state
        }
        if (districtOption.length > 0) {
            const district = districtOption.map((e: any) => {
                return e.value
            })
            dist = district
        }

        console.log(images, "images")

        UserService.updategrammerce(usertoken, isedit, name, images, productname, price, significance, stock, gid, delleadtime, country, stat, dist).then((response) => {

            if (response.data.responseText.startsWith("SUCCESS")) {
                setModalopen(false)
                if (isedit) {
                    presentAlert({
                        header: 'Atmagram',
                        cssClass: 'custom-alert',
                        message: 'Updated Successfully',
                        buttons: [
                            {
                                text: 'ok',
                                role: 'cancel',
                                handler: () => {

                                    showallgrammerce();
                                    window.location.reload();
                                },
                            }
                        ]
                    })

                }
                else {
                    presentAlert({
                        header: 'Atmagram',
                        message: 'Added Successfully',
                        cssClass: 'custom-alert',

                        buttons: [
                            {
                                text: 'ok',
                                role: 'cancel',
                                handler: () => {

                                    showallgrammerce();
                                    window.location.reload();
                                },
                            }
                        ]
                    })

                }


            } else {
                setAlertMsg("Not Updated")
                setAlertModal(true)

            }
        })
            .catch((error) => {
                setAlertMsg("Something went wrong, please try again later.");

                setAlertModal(true)
            });
    };
    var tableData: any = [];
    if (grammercelist != undefined && grammercelist.length > 0) {
        tableData = grammercelist.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    const handleButtonClick = () => {
        if (imageinputref.current) {
            imageinputref.current.click();
        }
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };


        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={openmodal} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span> Add Product</span>

                    </IonButton>

                </div>
                <div>
                    <IonSearchbar style={{ paddingRight: '0px' }}
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search" className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);

    const useOutsideAlerter = (refer: any, alert: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen(false)
                }
                if (alert.current && !alert.current.contains(event.target)) {
                    setAlertModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer, alert]);
    }
    const grammercemodal = useRef(null);
    const alertRef = useRef(null);
    useOutsideAlerter(grammercemodal, alertRef);

    const preventdata = (e: any) => {
        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
        e.target.addEventListener('keydown', function (u: any) {
            if (u.which === 38 || u.which === 40) {
                u.preventDefault();
            }
        })
        // e.target.addEventListener("keydown", function (d: any) { d.preventDefault() })
    }

    const [showModal, setShowModal] = useState(false)
    const imagenew = () => {
        setShowModal(true)
    }

    const addupgram = () => {
        setModalopen(false)
        setFormerrors({})
    }

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Product List"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body >
                        <div className="donation-modal-header admin-user-modal-header">
                            {isedit === true ? <h5 className="modal-title">Update Grammerce</h5>
                                : <h5 className="modal-title">Add Grammerce</h5>}
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <IonRow className="row_bot" style={{ marginTop: "0%" }}>
                                <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">
                                    <div>
                                        <IonLabel className="label_align" >
                                            Product Name <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                        <IonInput

                                            type="text"
                                            value={productname}
                                            onBlur={() => validation(1)}
                                            onIonChange={(e) => onChangevalidation(1, e)}
                                            required
                                            readonly={isedit}
                                        ></IonInput></div>

                                    <IonRow style={{ height: "2px" }}>
                                        {formerrors.productName && <span className="alert_input_bottom ">{formerrors.productName}</span>}
                                    </IonRow>
                                </IonCol>
                                <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">
                                    <div>
                                        <IonLabel className="label_align" >
                                            Brand Name <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                        <IonInput

                                            type="text"
                                            value={name}
                                            onBlur={() => validation(2)}
                                            onIonChange={(e) => onChangevalidation(2, e)}
                                            required
                                        ></IonInput>
                                    </div>
                                    <IonRow style={{ height: "2px" }}>
                                        {formerrors.brandname && <span className="alert_input_bottom ">{formerrors.brandname}</span>}
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot" style={{ marginTop: "0%" }}>
                                
                                <IonCol size-lg="12" size-md="12" size-xs="12" size-sm="12">

                                    <IonLabel className="label_align" >
                                        Significance <span style={{ color: "red" }}>*</span>
                                    </IonLabel>
                                    <IonTextarea
                                        inputMode="text"
                                        autoGrow
                                        className="significant_css"
                                        onBlur={() => validation(4)}
                                       
                                        value={significance}
                                        onIonChange={(e) => onChangevalidation(4, e)}
                                        required
                                    ></IonTextarea>

                                    <IonRow style={{ height: "2px" }}>
                                        {formerrors.significance && <span className="alert_input_bottom ">{formerrors.significance}</span>}
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot" style={{ marginTop: "0%" }}>
                            <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">

<IonLabel className="label_align" >
    Price (₹) <span style={{ color: "red" }}>*</span>
</IonLabel>
<IonInput

    type="number"
    min={0}
    onBlur={() => validation(3)}
    onFocus={(e) => preventdata(e)} value={price}
    onIonChange={(e) => onChangevalidation(3, e)}
    required
></IonInput>

<IonRow style={{ height: "2px" }}>
    {formerrors.price && <span className="alert_input_bottom ">{formerrors.price}</span>}
</IonRow>
</IonCol>
                                <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">

                                    <IonLabel className="label_align">
                                        Stock <span style={{ color: "red" }}>*</span>
                                    </IonLabel>
                                    <IonInput

                                        type="number"
                                        onFocus={(e) => preventdata(e)} min={0}
                                        value={stock}
                                        onBlur={() => validation(5)}
                                        onIonChange={(e) => onChangevalidation(5, e)}
                                        required
                                    ></IonInput>

                                    <IonRow style={{ height: "2px" }}>
                                        {formerrors.stock && <span className="alert_input_bottom ">{formerrors.stock}</span>}
                                    </IonRow>
                                </IonCol>
                                
                            </IonRow>
                            <IonRow className="row_bot" style={{ marginTop: "0%" }}>
                            <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">

<IonLabel className="label_align">
    Delivery Lead Time <span style={{ color: "red" }}>*</span>
</IonLabel>
<IonInput

    type="number"
    min={0}
    onBlur={() => validation(6)}
    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
    value={delleadtime}
    onIonChange={(e) => onChangevalidation(6, e)}
    required
></IonInput>

<IonRow style={{ height: "2px" }}>
    {formerrors.delleadtime && <span className="alert_input_bottom ">{formerrors.delleadtime}</span>}
</IonRow>
</IonCol>
                                <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12" >

                                    <IonLabel className="label_align" >
                                        Country <span style={{ color: "red" }}>*</span>
                                    </IonLabel>
                                    <Select
                                        className="custom-select custom-select-dark"
                                        options={countryLiistOptions}

                                        value={countryOption}
                                        onChange={handleCountryChange}
                                        onBlur={() => validation(7)}
                                        placeholder="Select a Country"
                                        isClearable={false}
                                        isMulti={true}

                                    />

                                    <IonRow style={{ height: "2px" }}>
                                        {formerrors.country && <span className="alert_input_bottom ">{formerrors.country}</span>}
                                    </IonRow>
                                </IonCol>
                               
                            </IonRow>
                            <IonRow className="row_bot" style={{ marginTop: "0%", justifyContent: "left" }}>
                            <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12" >

<IonLabel className="label_align" >
    State
</IonLabel>
<Select
    className="stateSelect custom-select custom-select-dark"
  
    options={stateLiistOptions}
    value={stateOption}
    placeholder="Select a State"
    isClearable={false}
    isMulti={true}

    onChange={handleStateChange}
    components={{ Option: CheckboxOption, Menu: BtnOption }} menuIsOpen={stateMenu}
    onMenuOpen={() => {
        setStateMenu(true)
        setDistrictMenu(false)
    }
    }
/>

</IonCol>
                                {districtsSelect && <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12" >

                                    <IonLabel className="label_align">
                                        District
                                    </IonLabel>
                                    <Select
                                        className="custom-select custom-select-dark"
                                        options={districtLiistOptions}
                                        value={districtOption}
                                        isMulti={true}
                                        onChange={handleDistrictChange}
                                        components={{ Option: DistrictCheckOption, Menu: DistrictBtnOption }}
                                        placeholder="Select a District"
                                        isClearable={false}

                                        menuIsOpen={distrctMenu}
                                        onMenuOpen={() => {
                                            setStateMenu(false)
                                            setDistrictMenu(true)
                                        }}
                                    />

                                </IonCol>}
                                {isedit === true ? <>
                                    {viewFlag === "true" ?
                                        <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12" >
                                            {viewcomment === "" ? <></> :
                                                <>
                                                    <IonLabel className="label_align" >
                                                        Comment
                                                    </IonLabel>
                                                    <IonTextarea
                                                        inputMode="text"
                                                        autoGrow
                                                        value={viewcomment}
                                                        readonly
                                                    ></IonTextarea>
                                                </>
                                            }
                                        </IonCol>
                                        :
                                        <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12" >
                                            {inactivecomment === "" ? <></> : <>

                                                <IonLabel className="label_align" >
                                                    Comment
                                                </IonLabel>

                                                <IonTextarea
                                                    inputMode="text"
                                                    autoGrow
                                                    style={{ maxHeight: "56px" }}
                                                    value={inactivecomment}
                                                    readonly
                                                ></IonTextarea>
                                            </>
                                            }
                                        </IonCol>
                                    }
                                </>
                                    : <></>}
                            </IonRow>
                        </form>
                        <IonRow style={{ justifyContent: "center", paddingBottom: "30px" }}>
                            <IonCol sizeLg="6" sizeSm="12" sizeXl="6" sizeMd="12" sizeXs="12" size="6">
                                <IonRow className="image_div">
                                    <IonCol size="6" className="center_col image_content">
                                        {images != "" ? <span className="image_content_spn">{fileName}</span> :
                                            isedit ? <span className="image_content_spn">Select a File <span style={{ color: "red" }}>*</span></span>
                                                :
                                                <span className="image_content_spn">No file Chosen <span style={{ color: "red" }}>*</span></span>}
                                    </IonCol>
                                    <IonCol size="6" className="image_content">
                                        <input
                                            className={images != "" ? "" : "image_chose"}
                                            type="file"
                                            ref={imageinputref}
                                            onChange={(e) => handleimage(e)}
                                            style={{ display: 'none' }}
                                        />
                                        <IonButton
                                            onClick={handleButtonClick}
                                            className="admin_img_btn image_content"
                                        >
                                            <span>
                                                Choose File
                                            </span>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>

                                <div className='image-container'>
                                    {image.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        <IonRow>
                                            <IonCol className="Col_1">
                                                <div onClick={() => imagenew()}>
                                                    <IonImg className="image_design_deity" src={image}></IonImg>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </div>
                                {showModal && (
                                    <Lightbox
                                        mainSrc={image}
                                        onCloseRequest={() => setShowModal(false)}
                                        onImageLoad={() => {
                                            window.dispatchEvent(new Event('resize'));
                                        }}
                                    />
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow className="row_bot">
                            <IonCol size="12" style={{ textAlign: 'center' }}><IonRow>

                                <IonCol size="6" style={{ textAlign: "end" }}>
                                    <IonButton className="admin_add_btn"

                                        disabled={
                                            name.length === 0 ||
                                            productname.length === 0 ||
                                            price.length === 0 ||
                                            significance.length === 0 ||
                                            stock.length === 0 ||
                                            delleadtime.length === 0 ||
                                            image.length === 0 ||
                                            countryOption.length === 0
                                        }
                                        onClick={onSubmit}
                                        type="submit"
                                    ><span className="button_padding">
                                            Submit
                                        </span>

                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" style={{ textAlign: "start" }}>
                                    <IonButton
                                        className="admin_cencel_btn" fill="outline"
                                        onClick={() => setModalopen(false)}
                                    >
                                        <span className="button_padding">
                                            Cancel
                                        </span>
                                    </IonButton>
                                </IonCol></IonRow>
                            </IonCol>
                        </IonRow>
                    </Modal.Body></Modal>
                <div>
                    {index == 0 ?
                        <div>
                            <div className="container_middle_table page-wrapper admin_background_color">
                                <div className="data_table">
                                    <DataTable
                                        columns={columns}
                                        data={tableData}
                                        striped
                                        noDataComponent="Sorry ! No result found"
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                        style={{ width: "100%" }}
                                        onRowClicked={(item: any) => viewdetails(item.id)}
                                        customStyles={customTableStyle}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <></>}
                </div>
            </IonContent>
        </IonPage >
    );
};
export default Grammercemanage;


