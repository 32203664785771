import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonItemDivider,
    IonLoading,
    IonSearchbar,
    IonIcon,
    useIonAlert
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import Authservice from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import { arrowBack, close } from "ionicons/icons";

import { saveAs } from 'file-saver';
import CommonDataTable from "../../components/datatable";
import Select from "react-select";
import UserService from "../../Services/auth.service";
import * as XLSX from "xlsx";
import CommonModal from "../../components/popipInfo";


const ImportFile: React.FC = () => {


    const [usertoken] = useState(sessionStorage.getItem("token"));
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const userID: any = useState(sessionStorage.getItem("userId"));
    const [showLoading, setShowLoading] = useState(false);
    const [dateTableData, setDateTableData]: any = useState([]);
    const [templeOptions, setTempleOptions] = useState([]);
    const [poojaSearchText, setPoojaSearchText] = useState("");
    const [templeCode, setTempleCode] = useState("");
    const [tname, setTname] = useState("");
    const [poojaTableFlag, setPoojaTableFlag] = useState(false);
    const [poojaTableData, setPoojaTableData]: any = useState([]);
    const [index, setIndex] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [excelData, setExcelData] = useState<any[]>([]);
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")




    const dateColumn = [
        {
            name: "Name",
            selector: (item: any) => item.firstName,
            sortable: true,
            // width: "400px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)} >{item.templeName}</div>
                </div>
            )
        },
        {
            name: "Main deity",
            selector: (item: any) => item.mainDeity,
            sortable: true,
            // width: "300px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)} >{item.mainDeity}</div>
                </div>
            )
        },
        {
            name: "Offerings",
            selector: (item: any) => item.poojaOfferings,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)}>{item.poojaOfferings}</div>
                </div>
            )
        },
        {
            name: "Antiquity",
            selector: (item: any) => item.antiquity,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)}>{item.antiquity}</div>
                </div>
            )
        },
    ]
    const offeringsColumn = [
        {
            name: "Pooja Name",
            selector: (item: any) => item.PoojaName ?? item.poojaName ?? "",
            sortable: true,
            cell: (item: any) => (
                <div style={{ cursor: "pointer" }}>
                    {item.PoojaName ?? item.poojaName ?? ""}
                </div>
            ),
        },
        {
            name: "Pooja Description",
            selector: (item: any) =>
                item.PoojaDesc ?? item.poojaDesc ?? item.PoojaDescription ?? item.poojaDescription ?? "",
            sortable: true,
            cell: (item: any) => (
                <div style={{ cursor: "pointer" }}>
                    {item.PoojaDesc ?? item.poojaDesc ?? item.PoojaDescription ?? item.poojaDescription ?? ""}
                </div>
            ),
        },
        {
            name: "Time of Pooja",
            selector: (item: any) => item.TimeofPooja ?? item.timeOfPooja ?? "",
            sortable: true,
            cell: (item: any) => (
                <div>{item.TimeofPooja ?? item.timeOfPooja ?? ""}</div>
            ),
        },
        {
            name: "Cost",
            selector: (item: any) => item.Amount ?? item.cost ?? "",
            sortable: true,
            cell: (item: any) => <div>{item.Amount ?? item.cost ?? ""}</div>,
        },
        {
            name: "Deity Name",
            selector: (item: any) => item.Deities ?? item.deityName ?? "",
            sortable: true,
            cell: (item: any) => <div>{item.Deities ?? item.deityName ?? ""}</div>,
        },
    ];





    const offeringDetails = (item: any, str: any, from: any) => {
        setShowLoading(true)
        Authservice.getPoojaOfferingDetails(usertoken, userID[0], usertoken, item.templeCode, str).then((response: any) => {
            if (response && response.data !== "") {
                const data = response.data.poojaOfferingsDto.map((e: any, i: any) => {
                    return { ...e, serial: i + 1, selected: false }
                })
                console.log(data, "data")
                setPoojaTableData(data)
                setTname(item.templeName)
                setTempleCode(item.templeCode)
                setShowLoading(false)
                setPoojaTableFlag(true)
                setIndex(1)
            }

        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }


    const handleFileSelect = () => {
        fileInputRef.current?.click();
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        if (!file.name.match(/\.(xlsx|xls|csv)$/)) {
            alert("Invalid file type. Please upload an Excel file (.xlsx or .xls).");
            return;
        }


        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (e) => {
            const binaryStr = e.target?.result as string;
            const workbook = XLSX.read(binaryStr, { type: "binary" });

            // Get first sheet name
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet data to JSON
            let jsonData: any[] = XLSX.utils.sheet_to_json(sheet);
            console.log("Excel Data:", jsonData);

            // Format column headers by removing spaces
            jsonData = jsonData.map((row) => {
                const formattedRow: any = {};
                Object.keys(row).forEach((key) => {
                    const formattedKey = key.replace(/\s+/g, ""); // Remove spaces
                    formattedRow[formattedKey] = row[key];
                });
                return formattedRow;
            });
            setExcelData(jsonData.slice(0, -1));
            setPoojaTableData(jsonData.slice(0, -1));
            setPoojaTableFlag(true); // Show table after data import
            console.log("Formatted Data:", jsonData);
        };
    };




    const Getdatausingsearch = (value: any) => {
        setPoojaTableFlag(false)
        setExcelData([])
        Authservice.searchdataRecords(usertoken, value, userID[0])
            .then((response: any) => {
                console.log(response);
                setShowLoading(false);
                const data = response.data.map((e: any, i: any) => {
                    return { ...e, serial: i + 1 }
                })
                setDateTableData(data);
                setIndex(0)
            })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }

    useEffect(() => {
        Getdatausingsearch("")
    }, [])

    const uploadFile = () => {
        setShowLoading(true)
        const data = poojaTableData.map((e: any) => {
            return {
                poojaCode: "",
                poojaName: e.PoojaName,
                poojaDescription: e.PoojaDesc || "",
                cost: e.Amount,
                timeOfPooja: e.TimeofPooja || "",
                deityName: e.Deities
            }
        })
        Authservice.createOrUpdateTemplePoojaList(usertoken, userID[0], templeCode, data).then((response: any) => {
            console.log(response, "response")
            if (response.data) {
                setShowLoading(false)
                setCommonModalMesg("Data uploaded successfully")
                setCommonmodaltitle("Information")
                setCommonmodal(true)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }

    const modalClose = () => {
        window.location.reload()
    }


    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Test"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <div className="container_middle_table page-wrapper admin_background_color">
                    {index === 1 && <div style={{ gap: "10px", alignItems: "center", display: "flex", justifyContent: "space-between", padding: "20px 0px" }}>
                        <div style={{ gap: "10px", alignItems: "center", display: "flex" }}>
                            <IonIcon onClick={() => Getdatausingsearch("")} src={arrowBack} className="point back" size="large"></IonIcon>
                            <IonText className="offering_temple_name">
                                {tname} {`[${templeCode}]`}
                            </IonText>
                        </div>
                        <div>
                            <IonButton className="btn_v2" onClick={handleFileSelect}>
                                <span className="button_padding_v2">Import</span>
                            </IonButton>
                        </div>
                        <input type="file" accept=".xlsx, .xls, .csv" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileUpload} />
                    </div>
                    }
                    <div className="data_table">
                        {index === 0 ?
                            <CommonDataTable
                                data={dateTableData}
                                columns={dateColumn}
                                isSubHeader={true}
                                pagination={true}
                            /> :
                            <CommonDataTable
                                data={poojaTableData}
                                columns={offeringsColumn}
                                isSubHeader={true}
                                pagination={true}
                            />
                        }
                        {excelData.length > 0 && <div style={{ display: "flex", justifyContent: "end", padding: "20px 0px" }}>
                            <IonButton className="btn_v2" onClick={() => uploadFile()}>
                                <span className="button_padding_v2">Upload</span>
                            </IonButton>
                        </div>}
                    </div>
                    <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
                </div>
            </IonContent>
        </IonPage >
    )
}

export default ImportFile;