import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonLabel, IonLoading, IonPage, IonRow, IonSearchbar, IonText, IonTextarea, useIonAlert } from "@ionic/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Sidebar from "./sidebar";
import AdminHead from "./adminHead";
import Select, { components } from 'react-select';
import { addOutline, close, closeCircle } from "ionicons/icons";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import customTableStyle from "../../components/CustomTableStyle";
import { useHistory } from "react-router";
import Authservice from "../../Services/user.service";
import { AppContext } from "../../components/AppContext";
import CommonModal from "../../components/popipInfo";


const HomePageHeader: React.FC = () => {

    const imageinputref: any = useRef(null)
    const [imageUrl, setImageUrl]: any = useState([])
    const [images, setImages]: any = useState([])
    const [selectedOption, setSelectedOption]: any = useState(null);
    const [selectedValue, setSelectedValue] = useState("")
    const [Alert] = useIonAlert()
    const [modalopen, setModalopen] = useState(false);
    const [description, setDescription]: any = useState("");
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const [carouselData, setCarouselData]: any = useState("")
    const [isActive, SetIsActive]: any = useState(false)
    const [isDelete, SetIsDelete]: any = useState(false)

    const [tableData, settabledata]: any = useState([])
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const history = useHistory();
    const [options, setOptions]: any = useState([])
    const [showLoading, setShowLoading] = useState(false);

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId, setUserId]: any = useState(sessionStorage.getItem("UserId"));
    const { exportCSV } = useContext(AppContext)!
    const [timer, setTimer]: any = useState("")
    const [txtFormat, setTxtFormat] = useState(false)

    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")




    var filteredData: any = []
    if (tableData.length > 0 || tableData !== undefined) {
        filteredData = tableData.filter((item: any) =>
            item.carouselType.toLowerCase().includes(filterText.toLowerCase()) ||
            item.description.toLowerCase().includes(filterText.toLowerCase()) // Add other fields if necessary
        )
    }






    const columns: any = [
        {
            name: "S.No",
            sortable: true,
            width: "10%",
            selector: (item: any) => item.serial,
            cell: (item: any) => (
                <div onClick={() => clickRow(item)}>
                    {item.serial}
                </div>
            ),
        },
        {
            name: "Carousel Type",
            selector: (item: any) => item.carouselType,
            width: "20%",
            sortable: true,
            cell: (item: any) => (
                <div onClick={() => clickRow(item)}>
                    <div style={{ cursor: "pointer" }} >{item.carouselType}</div>
                </div>
            )
        },
        {
            name: "Description",
            width: "35%",
            selector: (item: any) => item.description,
            sortable: false,
            cell: (item: any) => (
                <div onClick={() => clickRow(item)}>
                    <div  >{item.description}</div>
                </div>
            )
        },
        {
            name: "Carousel Image",
            sortable: false,
            selector: (item: any) => item.imageUrls[0],
            width: "35%",
            cell: (item: any) => (
                <div onClick={() => clickRow(item)}>
                    <IonImg style={{ width: "70px", height: "70px", cursor: "pointer" }} alt="img" src={item.imageUrls[0] + "?v=" + new Date()}></IonImg>
                </div>
            )
        },
    ]

    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            getAllCarousels()
            getCarouselsType()
        }
    }, [])

    const getCarouselsType = () => {
        setShowLoading(true)
        Authservice.getAllCarousellist(usertoken, userId).then((response) => {
            console.log(response)
            if (response.data.length > 0) {
                const data = response.data.map((e: any) => {
                    return { value: e.carouselType, label: e.carouselType }
                })
                setOptions(data)
                setShowLoading(false)
            }
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false)
            });
    }

    const getAllCarousels = () => {
        setShowLoading(true)
        Authservice.getAllCarousels(usertoken, userId).then((response) => {
            console.log(response)
            if (response.data && response.data.length > 0) {
                const data = response.data.map((e: any, i: any) => {
                    return { ...e, serial: i + 1 }
                })
                settabledata(data)
            }
            setShowLoading(false)
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false)
            });
    }

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left",
            color: "#333"
        }),
    };

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const modalClose = () => {
        setCommonmodaltitle("")
        setCommonModalMesg("")
        setCommonmodal(false)
        getAllCarousels()
    }

    const openmodal = () => {
        setCarouselData("")
        setSelectedOption(null)
        setDescription("")
        setImageUrl([])
        SetIsActive(false)
        SetIsDelete(false)
        setModalopen(true);
    }

    const subHeaderComponent = useMemo(() => {
        // const handleClear = () => {
        //     if (filterText) {
        //         setResetPaginationToggle(!resetPaginationToggle);
        //         setFilterText("");
        //     }
        // };
        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={openmodal} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span>Add</span>
                    </IonButton>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <IonButton className="admin_add_btn" onClick={() => exportCSV(columns, tableData, "Carousel Details")}>
                        Export
                    </IonButton>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle, tableData]);



    const handleButtonClick = () => {
        if (imageinputref.current) {
            imageinputref.current.click();
        }
    };

    const onchangeImage = (e: any) => {
        const supportedFormats = ["jpg", "jpeg", "png", "mp4"];
        var file: any = e.target.files;
        console.log(file)
        const splited: any = file[0].name.split(".")
        console.log(splited[1])
        const fileExtension = splited[splited.length - 1].toLowerCase();
        if (supportedFormats.includes(fileExtension)) {
            const objectUrl = URL.createObjectURL(file[0]);
            setImageUrl([objectUrl]);
            setImages(file[0])
        }
        else {
            const unsupportedFilesMessage = "Unsupported File Format\n";
            alert(unsupportedFilesMessage + ". Supported formats are JPG, PNG, and JPEG.");
        }
    }

    const DeleteImage = (item: any, i: any) => {
        // Alert({
        //     header: 'Atmagram',
        //     message: 'Do you want to Delete this Image ?',
        //     buttons: [
        //         {
        //             text: 'No',
        //             role: 'cancel',
        //         },
        //         {
        //             text: 'Yes',
        //             role: 'confirm',
        //             handler: () => {
        const updatedImageUrl = imageUrl.filter((_: any, index: any) => index !== i);
        setImageUrl(updatedImageUrl);
        const updatedArr = [...images];
        updatedArr.splice(i, 1);
        setImages(updatedArr); // Assuming you are also updating the state for `images`
        //             },
        //         },
        //     ]
        // })
    }



    const clickRow: any = (item: any) => {
        console.log(item)
        // Authservice.getCarouselsByType("", item.carouselType).then((response) => {
        //     console.log(response)
        setCarouselData(item)
        setSelectedOption({ value: item.carouselType, label: item.carouselType })
        setDescription(item.description)
        console.log(item.imageUrls)
        setImageUrl(item.imageUrls)
        if (item.carouselType === "TXT") {
            setTxtFormat(true)
        }
        SetIsActive(item.isActive || false)
        SetIsDelete(item.isDelete || false)
        setTimer(item.timeInSeconds !== null ? item.timeInSeconds : "")
        setModalopen(true);
        // })
        //     .catch((error) => {
        //         alert("Something went wrong, please try again later.");
        //     });
    }

    const handlechange = (e: any) => {
        console.log(e.detail.value)
        setDescription(e.detail.value)
    }

    const customTableStyle1 = {
        ...customTableStyle,
        headRow: {
            style: {
                borderWidth: "0px",
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "15px",
                paddingLeft: '16px',
                paddingRight: '16px',
                border: "none",
                justifyContent: "left",
                textalign: "left"
            },
        },
        rows: {
            style: {
                borderWidth: "0.5px #E5E3E3",
            }
        },
        cells: {
            style: {
                fontSize: "16px",
                justifyContent: "left",
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '10px',
                paddingBottom: '10px',
                wordBreak: 'break-word',
                border: "none",
            }
        },
    }

    const saveCarousel = () => {
        const data = {
            "carouselType": selectedOption.value,
            "description": description,
            "images": images,
            "deletedImg": [],
            "id": carouselData.id ? carouselData.id : "",
            "timeInSeconds": parseInt(timer),
            "isActive": isActive,
            "isDelete": isDelete
        }
        console.log(data)
        setShowLoading(true)
        Authservice.saveCarouselsByType(usertoken, userId, data).then((response) => {
            console.log(response)
            setModalopen(false)
            setShowLoading(false)
            if (response.data === "success") {
                setCommonmodaltitle("Information")
                setCommonModalMesg("Caoursel Uploaded Successfully")
                setCommonmodal(true)
                //
                // Alert({
                //     header: 'Atmagram',
                //     message: 'Caoursel Uploaded Successfully',
                //     buttons: [
                //         {
                //             text: 'OK',
                //             role: 'confirm',
                //             handler: () => {
                //                 getAllCarousels()
                //             },
                //         },
                //     ]
                // })
            }
        })
            .catch((error) => {
                setShowLoading(false)
                alert("Something went wrong, please try again later.");
            });
    }

    const handleCheckboxChange = (field: any, value: any) => {
        if (field === "isActive") {
            SetIsActive(value)
        }
        else if (field === "isDelete") {
            SetIsDelete(value)
        }
    };


    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Carousel Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <div className="container_middle_table page-wrapper admin_background_color">
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        striped
                        noDataComponent="Sorry ! No result found"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                        onRowClicked={(item: any) => clickRow(item)}
                        style={{ width: "100%" }}
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                        customStyles={customTableStyle1}
                    />
                </div>
                <Modal show={modalopen} className="admin_modal" centered>
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="modal-title">Add Carousel</h5>
                            <div>
                                <IonIcon src={close} className="close_icon" onClick={() => {
                                    setModalopen(false);
                                }} />
                            </div>
                        </div>
                        <IonRow style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                            <IonCol size-lg="6" size-sm="12" size-xs="12" size-md="6">
                                <div>
                                    <IonText className="adm_label_text">Carousel Type <span style={{ color: "red" }}>*</span></IonText>
                                    <Select
                                        options={options}
                                        placeholder="Select the Type"
                                        value={selectedOption}
                                        // isDisabled
                                        styles={customStyles}
                                        onChange={(e: any) => {
                                            console.log(e)
                                            setSelectedOption(e)
                                            setSelectedValue(e.value)
                                        }}
                                        className="custom-select"
                                    />
                                </div>
                                <div>
                                    <IonText className="adm_label_text">Timer <span style={{ color: "red" }}>*</span></IonText>
                                    <IonInput
                                        className="adm_input"
                                        value={timer}
                                        type="number"
                                        onIonChange={(e: any) => setTimer(e.detail.value)}
                                    />
                                </div>
                                {selectedOption?.value !== "TXT" && <div >
                                    <IonText className="adm_label_text">Choose Image <span style={{ color: "red" }}>*</span></IonText>
                                    <IonRow className="image_div">
                                        <IonCol size="6" className="center_col image_content">
                                            <span className="image_content_spn">Select a File </span>
                                        </IonCol>
                                        <IonCol size="6" className="image_content">
                                            <input style={{ display: "none" }} ref={imageinputref} type="file" name="photo" onChange={(e) => onchangeImage(e)} />
                                            <IonButton
                                                onClick={handleButtonClick}
                                                className="admin_img_btn image_content"
                                            ><span className="button_padding">
                                                    Choose File
                                                </span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </div>}
                                <div className="checkbox-container">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isActive}
                                            onChange={(e) => handleCheckboxChange('isActive', e.target.checked)}

                                        /> Is Active
                                    </label>
                                    <br />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isDelete}
                                            onChange={(e) => handleCheckboxChange('isDelete', e.target.checked)}

                                        /> Is Delete
                                    </label>
                                </div>
                            </IonCol>
                            <IonCol size-lg="6" size-sm="12" size-xs="12" size-md="6">
                                <IonText className="adm_label_text">Description <span style={{ color: "red" }}>*</span></IonText>
                                <IonTextarea
                                    className="adm_textarea"
                                    autoGrow
                                    value={description}
                                    onIonChange={(e: any) => handlechange(e)}
                                    required
                                />
                            </IonCol>
                        </IonRow>
                        <IonGrid style={{ marginTop: "10px" }}>
                            <IonRow className="ion-justify-content-center">
                                {imageUrl.map((item: any, i: any) => {
                                    const isVideo = item.endsWith('.mp4');
                                    return (
                                        <IonCol sizeLg="4" size="4">
                                            <div style={{ display: "flex", justifyContent: "right" }}>
                                                <IonIcon src={closeCircle} tabIndex={0} size="small" style={{ cursor: "pointer", color: '#576F9F' }}
                                                    onClick={() => DeleteImage(item, i)}
                                                    onKeyDown={(event) => {
                                                        if (event.key === "Enter" || event.key === " ") {
                                                            DeleteImage(item, i);
                                                        }
                                                    }} />
                                            </div>
                                            {isVideo ? (
                                                <video
                                                    tabIndex={1}
                                                    controls
                                                    className="video"
                                                    style={{ width: '100%' }}
                                                >
                                                    <source src={item} type="video/mp4" />
                                                </video>
                                            ) : (
                                                <IonImg
                                                    src={item}
                                                    tabIndex={1}
                                                    className="img"
                                                />
                                            )}
                                        </IonCol>
                                    )
                                })}
                            </IonRow>
                            <div className="create-project-element">
                                <IonButton
                                    className="admin_add_btn"
                                    disabled={(selectedOption?.value !== "TXT" && imageUrl.length === 0) || description.trim("").length === 0 || selectedOption == null || timer === ""}
                                    onClick={saveCarousel}
                                ><span>
                                        Submit
                                    </span>
                                </IonButton>
                            </div>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </IonContent>
        </IonPage >
    )
}


export default HomePageHeader;