import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonItemDivider,
    IonGrid,
    IonLoading,
    IonModal,
    IonHeader,
    IonCard,
    IonSearchbar,
    IonIcon,
    IonList
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import "./Deitymanage.css";
import Foot from "../Foot";
import UserService from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { calendarOutline, close, timeOutline } from "ionicons/icons";
import customTableStyle from "../../components/CustomTableStyle";
import Sidebar from "./sidebar";
import AdminHead from "./adminHead";
import Select from 'react-select';
import Authservice from "../../Services/user.service";

const Materialordermanage: React.FC = (props: any) => {
    const formatter = new Intl.NumberFormat('en-IN')
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };
    const [filterStatus, setFilterStatus]: any = useState({ value: 0, label: "Incomplete Orders" })
    const [filterArr, setFilterArr]: any = useState([])
    const loadData: any = useRef([])
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const [usertoken] = useState(sessionStorage.getItem("token"));
    const history = useHistory();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [MaterialorderList, setMaterialorderList] = useState([])
    const [filterText, setFilterText] = React.useState("");
    const [productname, setProductname] = useState();
    const [templeName, setTempleName] = useState("")
    const [templeAdd, setTempleAdd] = useState("")
    const [orderNo, setOrderNo] = useState("")
    const [brandname, setBrandname] = useState();
    const [oprice, setoPrice]: any = useState();
    const [totalamt, setTotalamt] = useState("");
    const [oquantity, setoQuantity] = useState();
    const [amtpriest, setAmtpriest] = useState();
    const [amttemple, setAmttemple] = useState();
    const [date, setDate]: any = useState();
    const [number, setNumber] = useState();
    const [opsize, setoPsize] = useState();
    const [opunit, setoPunit] = useState();
    const [oname, setoName] = useState()
    const [shipNewlist, setShipNewlist]: any = useState([])
    const [modalopen1, setModalopen1] = useState(false);
    const [statusList, setStatusList]: any = useState([])
    const [orderStatus, setOrdStatus]: any = useState("OPEN")
    if (MaterialorderList != undefined && MaterialorderList.length > 0) {
        MaterialorderList.forEach((item: any, index: any) => { item.serial = index + 1; });
        MaterialorderList.forEach((item: any, index: any) => {
            var date = item.poojaMaterialOrdDto.orderDate.split("T");
            const od = moment(date[0]).format("DD-MM-YYYY");
            item.localOrderDate = od;

        });
    }


    const [ship, setShip]: any = useState([])
    const [shipmodal, setShipmodal] = useState(false);
    const [shipDate, setShipDate] = useState("");
    const [shipTime, setShipTime] = useState("");
    const [statusId, setStatusId] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [listDates, setListdates]: any = useState("")
    const [statusArray, setStatusArray]: any = useState([])

    const [statusmodal, setStatusmodal] = useState(false);
    const ref: any = useRef([])
    const dateRef: any = useRef([])
    const dateInputRef: any = useRef(null)

    const [alertmsg, setAlertMsg]: any = useState("")
    const [alertModal, setAlertModal]: any = useState(false)
    const [addressModal, setAddressModal]: any = useState(false)
    const alertRef = useRef(null);
    var load = false;
    const orderStatusList: any[] = [
        { value: 2, label: "All" },

        { value: 1, label: "Complete Orders" },
        { value: 0, label: "Incomplete Orders" },]

    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen1(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const list: any = useRef(null);
    useOutsideAlerter(list);
    const status = (i: any, item: any) => {
        setStatusmodal(true)
        setStatusId(i)
    }
    const changeStatus = (status: any) => {
        setOrdStatus(status)

    }
    const getstatusList = () => {
        setShowLoading(true)
        Authservice.materialorderStatusList(usertoken).then((response) => {
            console.log(response);
            setStatusList(response.data)

            setShowLoading(false);

        })
            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const statusproceed = (j: any,items:any) => {
        for(var i=0;i<statusArray.length;i++){
            if(statusArray[i].id=== items.id){
                if (statusArray[i].status == "SCHEDULED") {
                    const alert = ship.map((item: any, index: any) => {
                        if (index === i) {
                            return { ...item, statusAlert: "Please Select the status" }
                        }
                        return item
                    })
                    setShip(alert)
                } else {
                    var data =
                    {
                        "status": statusArray[i].status,
                        "id": statusArray[i].id
                    }
                    console.log(data, statusArray)
                    UserService.updateOrderStatusForMaterial(usertoken, data).then((response) => {
                        console.log(response.data);
                        setAlertMsg("Status Changed Successfully")
                        setAlertModal(true)
                        window.location.reload();    
                    })
                        .catch((error) => {
                            setAlertMsg(error)
                            setAlertModal(true)
                        });
                    // window.location.reload()
                }
            }
        }
       
        
    }
    const datelimit = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const setTime = (e: any, item: any, i: any) => {

        if (e.detail.value !== "") {
            var ii = e.detail.value.split(":");
            var t: any;
            var a: any;

            if (ii[0] > 12) {
                t = ii[0] - 12;
                a = "PM";

            } else if (ii[0] < 12) {
                t = ii[0]
                a = 'AM';
            }
            else if (ii[0] == 12) {
                t = ii[0]
                a = 'PM';
            }

            var str = t + ":" + ii[1] + " " + a;
            // ship[i].shipTime = str
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {
                    console.log(items, "itemssss")
                    return { ...items, shipmentTime: str }
                }
                return items;
            })
            setShip(ti)
        }
        // }

    }
    const data: any = [
        // {
        //     name: "S.No",
        //     selector: (item: any, i: any) => item.serial,
        //     sortable: true,
        //     width: "90px",
        //     cell: (item: any) => (
        //         <div className="center_col_tab" onClick={() => orderview(item)}>
        //             <div style={{ cursor: "pointer" }}>{item.serial}</div>
        //         </div>
        //     )
        // },
        {
            name: "Order Number",
            sortable: true,

            selector: (item: any) => item.poojaMaterialOrdDto.orderNumber,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => orderview(item)}>
                    <div style={{ cursor: "pointer" }} >{item.poojaMaterialOrdDto.orderNumber}</div>
                </div>
            )
        },
        {
            name: "Order Date",
            sortable: true,
            selector: (item: any) => new Date(item.localOrderDate),
            cell: (item: any) => {
                var date = item.poojaMaterialOrdDto.orderDate.split("T");
                const od = moment(date[0]).format("DD-MM-YYYY");
                return (
                    <div className="center_col_tab" onClick={() => orderview(item)}>
                        <div style={{ cursor: "pointer" }} >{od}</div>
                    </div>
                )
            }
        },

        {
            name: "Product Name",
            selector: (item: any) => item.poojaMaterialOrdDto.poojaMaterialDto.productName,
            sortable: true,

            cell: (item: any) => (
                <div className="left_col" onClick={() => orderview(item)}>
                    <div style={{ cursor: "pointer" }} >{item.poojaMaterialOrdDto.poojaMaterialDto.productName}</div>
                </div>
            )

        },
        {
            name: "Price (₹)",
            selector: (item: any) => parseInt(item.poojaMaterialOrdDto.totalAmount, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => orderview(item)}>
                    <div style={{ cursor: "pointer" }} >{formatter.format(item.poojaMaterialOrdDto.totalAmount)}</div>
                </div>
            )
        },
        {
            name: "Quantity",
            selector: (item: any) => parseInt(item.poojaMaterialOrdDto.quantity, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => orderview(item)}>
                    <div style={{ cursor: "pointer" }} >{formatter.format(item.poojaMaterialOrdDto.quantity)}</div>
                </div>
            )
        },

        {
            name: "Manage Orders",
            sortable: false,

            cell: (item: any, i: any) => (
                <div className="center_col_tab">
                    <IonButton className="datatable-activate-btn" onClick={() => Ship(item, i)}><span className="button_padding">Shipment</span></IonButton>
                </div>
            )
        },
    ]
    const fetchData = () => {
        if (userdata[0] === null) {
            history.push("/login")
        } else {
            setShowLoading(true);
            UserService.materialorderList(usertoken, "").then((response) => {
                if (response) {
                    console.log(response, "jhg");
                    shipNewlist.length = 0;
                    setMaterialorderList(response.data);
                    loadData.current = response.data;
                    setShowLoading(false);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].poojaMaterialOrdersStatusList.length > 0) {
                            const newData: any = response.data[i].poojaMaterialOrdersStatusList.map((e: any) => {
                                return { ...e, shipDate: "", shipTime: "", shipDateAlert: "", shipTimeAlert: "", statusAlert: "" }
                            })
                            shipNewlist.push(newData)

                        }
                    }
                    console.log(ship, "ship")
                    handleOrderChange({ value: 0, label: "Incomplete Orders" })
                }
            })
                .catch((error) => {
                    alert(error)
                    setShowLoading(false);
                });

        }

    }

    const handleOrderChange = (select: any) => {
        setFilterArr([])
        setMaterialorderList([])


        setFilterStatus(select)
        if (select.value == 2) {
            setMaterialorderList(loadData.current);
            return
        }


        if (loadData.current != undefined && loadData.current.length > 0) {

            for (var i = 0; i < loadData.current.length; i++) {
                var compFlag = 1;
                var IncomFlag = 0;
                var subOrder = loadData.current[i].poojaMaterialOrdersStatusList;
                for (var j = 0; j < subOrder.length; j++) {

                    if (subOrder[j].status != "COMPLETED") {
                        compFlag = 0;

                    }


                }
                if (select.value == 0 && compFlag == 0) {
                    filterArr.push(loadData.current[i]);
                }

                if (select.value == 1 && compFlag == 1) {
                    filterArr.push(loadData.current[i]);
                }



            }

            tableData1 = filterArr;
            setMaterialorderList(filterArr)
            console.log(filterArr, "filtered data")
        }

    }

    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_VENDOR") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            if (!load) {
                getstatusList();
                fetchData();
                load = true;
            }

        }
    }, []);
    const rowClick: any = (item: any) => {
        console.log(item)
    }
    const Ship = (e: any, i: any) => {
        console.log(e, "gmkamk")
        console.log(shipNewlist[i], "ship")
        setProductname(e.poojaMaterialOrdDto.poojaMaterialDto.productName)
        setTempleName(e.poojaMaterialOrdDto.temples.name)
        setTempleAdd(e.poojaMaterialOrdDto.temples.villageorTown + "," + e.poojaMaterialOrdDto.temples.district
            + "," + e.poojaMaterialOrdDto.temples.state + "," + e.poojaMaterialOrdDto.temples.country)
        setOrderNo(e.poojaMaterialOrdDto.orderNumber);
        setShip(e.poojaMaterialOrdersStatusList);
        setStatusArray(e.poojaMaterialOrdersStatusList)
        setShipmodal(true);
        setOrdStatus("OPEN")

    }

    var tableData1: any = [];
    if (MaterialorderList != undefined && MaterialorderList.length > 0) {
        tableData1 = MaterialorderList.filter(
            (item: any) => JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1

        );
    }


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    const Modalclose1 = () => {
        setModalopen1(false)
    }
    const subHeaderComponent1 = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        }
        return (
            <IonRow className="table_btn_container">
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);
    const orderview = (item: any) => {
        var date = item.poojaMaterialOrdDto.orderDate.split("T");
        const od = moment(date[0]).format("DD-MM-YYYY");
        var ld2 = item.poojaMaterialOrdDto.listOfdates.split("||")
        var ld4 = ld2.splice(-1)
        var ld3 = ld2.join(", ")
        setListdates(ld3)
        setProductname(item.poojaMaterialOrdDto.poojaMaterialDto.productName);
        setBrandname(item.poojaMaterialOrdDto.poojaMaterialDto.brandName);
        setoPrice(item.poojaMaterialOrdDto.totalAmount);
        setTotalamt(item.poojaMaterialOrdDto.totalPaidAmount);
        setoQuantity(item.poojaMaterialOrdDto.quantity);
        setoPsize(item.poojaMaterialOrdDto.poojaMaterialDto.packageSize);
        console.log(item.poojaMaterialOrdDto.poojaMaterialDto.packageSize)
        setoPunit(item.poojaMaterialOrdDto.poojaMaterialDto.packageUnit);
        setDate(od);
        setNumber(item.poojaMaterialOrdDto.orderNumber);
        setAmtpriest(item.poojaMaterialOrdDto.dakshinaAmountForPriest);
        setAmttemple(item.poojaMaterialOrdDto.dakshinaAmountToTemple);
        setoName(item.poojaMaterialOrdDto.name);
        setModalopen1(true);
    };

    const dateListPrev = (e: any) => {
        if (e.key !== 'Tab' && dateInputRef.current) {

            e.preventDefault();

            dateInputRef.current.setFocus();

        }
    }
    const groupedItems = ship.reduce((acc: any, item: any) => {
        const { status } = item;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(item);
        return acc;
    }, {} as { [key: string]: any[] });
    const dateChange = (event: any, i: any, itemShip: any) => {
        var val = event.target.value;
        const date = moment(val).format("YYYY-MM-DD")
        if (val !== "" && val != null) {
            const date = moment(val).format("YYYY-MM-DD")
            const datealert1 = ship.map((item: any, index: any) => {
                if (itemShip.id === item.id) {
                    return { ...item, shipDateAlert: "", shipmentDate: date }
                }
                return item;
            })
            setShip(datealert1)
        }
        console.log(ship)
    };
    const handleStatus = (e: any, i: any, items:any) => {
        const status = statusArray.map((item: any, index: any) => {
            if (item.id === items.id) {
                return { ...item, status: e.target.value }
            }
            return item
        })
        setStatusArray(status)
        // setShip(status)
        console.log(ship)
    };
    const setAwb = (e: any, item: any, i: any) => {
        if(e.detail.value.trim() != ""){
            var val=e.detail.value.trim();
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {
                    console.log(items, "itemssss")
                    return { ...items, shipawbNumber: val , awbAlert :"" }
                }
                return items;
            })
            setShip(ti)

        }else{
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {
                    console.log(items, "itemssss")
                    return { ...items,  shipawbNumber: "" , awbAlert :"Invalid AWB number" }
                }
                return items;
            })
            setShip(ti)
        }

    }
    const validation = (num: any, i: any, items:any) => {
        var shTime = items.shipmentTime;
        var shDate = items.shipmentDate;
        var awb = items.shipawbNumber;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate())
        if (num === "1") {
            if (shDate === null) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "Please Choose the Date" }
                    }
                    return item;
                })
                setShip(datealert)
            } else if (tomorrow.toISOString().split('T')[0] > new Date(shDate).toISOString().split('T')[0]) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "Please select future date" }
                    }
                    return item;
                })
                setShip(datealert)
            }
            else {
                const datealert1 = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "" }
                    }
                    return item;
                })
                setShip(datealert1)
            }
        }
        else if (num === "2") {
            if (shTime === null) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipTimeAlert: "Please Choose the Time" }
                    }
                    return item;
                })
                setShip(datealert)
            }
            else {
                const datealert1 = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipTimeAlert: "" }
                    }
                    return item;
                })
                setShip(datealert1)
            }
        }
        else if(num === "3"){
            if(!awb){
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, awbAlert: "Invalid AWB number" }
                    }
                    return item;
                })
                setShip(datealert)
            }

        }
    }
    const initShip = (i: any,items:any) => {
        
        var shiplist = groupedItems[orderStatus];
        var shipCurent = shiplist[i];
        var shId = shipCurent.id;
        var ordId =shipCurent.poojaMaterialOrders.id;
        var shTime = shipCurent.shipmentTime;
        var shDate = shipCurent.shipmentDate;
        var awb = shipCurent.shipawbNumber;
         const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate());

        if (!shDate || !shTime) {
            const datealert = ship.map((item: any, index: any) => {
                if (items.id === item.id) {
                    if (!shDate && !shTime && !awb){
                        return { ...item, shipDateAlert: "Please Choose the Date", shipTimeAlert: "Please Choose the Time",awbAlert: "Invalid AWB number"  }

                    }
                    if (!shDate && !shTime){
                        return { ...item, shipDateAlert: "Please Choose the Date", shipTimeAlert: "Please Choose the Time" }

                    }
                    if (!shDate ){
                        return { ...item, shipDateAlert: "Please Choose the Date" }

                    }
                    if (!shTime){
                        return { ...item, shipTimeAlert: "Please Choose the Time" }

                    }
                    if(!awb){
                        return { ...item, awbAlert: "Invalid AWB number" }
                    }
                }
                return item;
            })
            setShip(datealert)
        }
        else if (tomorrow.toISOString().split('T')[0] > new Date(shDate).toISOString().split('T')[0]) {
            const datealert = ship.map((item: any, index: any) => {
                if (items.id === item.id) {
                    return { ...item, shipDateAlert: "Please select future date" }
                }
                return item;
            })
            setShip(datealert)
        }
        else {
            // const date1 = new Date();
            // const time: any = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            // const formattedDate = moment(date1.toLocaleDateString()).format("YYYY-MM-DD");

            // const time1Parts = time.split(/:| /);
            // const time2Parts = shTime.split(/:| /);

            // const date2:any = new Date();
            // date2.setHours(parseInt(time1Parts[0], 10) % 12 + (time1Parts[2] === "PM" ? 12 : 0));
            // date2.setMinutes(parseInt(time1Parts[1], 10));
            // date2.setSeconds(0);

            // const date3:any = new Date();
            // date3.setHours(parseInt(time2Parts[0], 10) % 12 + (time2Parts[2] === "PM" ? 12 : 0));
            // date3.setMinutes(parseInt(time2Parts[1], 10));
            // date3.setSeconds(0);

            // const differenceInMilliseconds = Math.abs(date3 - date2);
            // const hours = Math.floor(differenceInMilliseconds / 3600000);
            // const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000);

            // if (shDate === formattedDate && (hours === 0 && minutes <= 30)) {
            //     alert("Minimum Time Difference Is 30 Minutes");
            // }
            // else {
            UserService.initShipForMaterial(usertoken, ordId, shDate, shTime, shId,awb).then((response) => {
                console.log(response.data);
                if (response.data.errorMessage == null) {
                    setAlertMsg("Shipment Initiated Successfully");
                    setAlertModal(true)
                    window.location.reload();
                } else {
                    setAlertMsg(response.data.errorMessage);
                    setAlertModal(true)
                    window.location.reload();
                }

            })
                .catch((error) => {
                    setAlertMsg("Problem in Initiating Shipment");
                    setAlertModal(true)
                });
            // setShipmodal(false)
            // }
        }
    }
    const handleChangeTime = (event: any, i: any, item: any) => {
        var ii = event.detail.value.split(":");
        var t: any;
        var a: any;
        if (ii[0] > 12) {
            t = ii[0] - 12;
            a = "PM";

        } else if (ii[0] < 12) {
            t = ii[0]
            a = 'AM';
        }
        else if (ii[0] == 12) {
            t = ii[0]
            a = 'PM';
        }

        var str = t + ":" + ii[1] + " " + a;



        console.log(ship)
    };

    const modalgetback = () => {
        setStatusmodal(false)
        setShipmodal(true)
        setOrdStatus("OPEN")
    }

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Material Order Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={addressModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Header>
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <IonText style={{ color: "black", fontWeight: "bold" }}>Temple Address</IonText></div>

                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText > {templeAdd}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAddressModal(false) }} className="button_padding">OK</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <Modal show={statusmodal} className="admin_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Status details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => modalgetback()} />
                            </div>
                        </div>
                        <div>
                            <IonRow>
                                <IonCol size="6">
                                    <IonText>Change status</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <select style={{ backgroundColor: "white" }} onChange={(e: any) => handleStatus(e, i)} placeholder="Select Status">
                                        <option value="SCHEDULED" selected>Select Status</option>
                                        <option value="SHIPPED">SHIPPED</option>
                                        <option value="COMPLETED">COMPLETED</option>
                                    </select>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" style={{ textAlign: "right" }}>
                                    <IonButton onClick={() => { statusproceed(statusId) }}>Update</IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal> */}
                <Modal show={shipmodal}
                    className="admin_modal material_order_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Shipping details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => {
                                    setShipmodal(false)
                                    setAddressModal(false)
                                }
                                } />
                            </div>
                        </div>
                        <div>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Product Name</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText className="shipMent-headCells">{productname}</IonText></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Temple Name</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText
                                    className="shipMent-headCells text-hyper" onClick={() => { setAddressModal(true) }}>{templeName}</IonText></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Order No</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText className="shipMent-headCells">{orderNo}</IonText></IonCol>
                            </IonRow>
                        </div>
                        <div>

                            <IonList className="horizontal-scroll-list">

                                {statusList.map((item: any, i: any) => (
                                    <div key={item} className={(orderStatus == item) ? "chooseOrderTab center_col" : "horizontal-scroll-item center_col"}
                                        onClick={() => { changeStatus(item) }}>

                                        {item}
                                        {(groupedItems != undefined && groupedItems[item] != undefined) ? <>
                                            ( {groupedItems[item].length} )
                                        </> : <>
                                            ( 0 )</>}
                                    </div>

                                ))}





                            </IonList>


                        </div>
                        {!groupedItems.hasOwnProperty(orderStatus) ? <div style={{ width: "100%", height: "200px" }} className="center_col">
                            <IonLabel style={{ fontSize: "20px" }}>
                                Order not available
                            </IonLabel>

                        </div> :
                            <div style={{ height: "500px", overflowY: "auto", marginTop: "2%" }}>
                                <table style={{ width: "100%" }}>
                                    <tr style={customTableStyle.headRow.style}>
                                        <th className="shipMent-headCells">
                                            S.No
                                        </th>

                                        <th className="shipMent-headCells">
                                            Order Date
                                        </th>
                                        <th className="shipMent-headCells">
                                            Schedule Date and Time
                                        </th>
                                        <th className="shipMent-headCells">
                                            Status
                                        </th>
                                        <th className="shipMent-headCells">
                                        Action
                                    </th>
                                    </tr>
                                    {Object.entries(groupedItems).map(([status, items1]) => (
                                        <>
                                            {(status == orderStatus) && <>

                                                {(items1 as any).map((item: any, i: any) => {
                                                    var date = item.orderedDate;
                                                   var val =  new Date(date);
                                                    var formatD=  moment(val).format("DD-MM-YYYY")
                                                    const awb = item.awbNumber;
                                                    const disable = item.status;
                                                    const foundObject = statusArray.find((obj:any) => obj.id === item.id);


                                                    const shipformat = moment(item.shipmentDate).format("DD-MM-YYYY")
                                                    const shipd = ship[i].shipdate;
                                                    var status = foundObject.status
                                                    return (
                                                        <>
                                                            {item.awbNumber != null ? <tr className="material-shipment-row">
                                                                <td style={{ textAlign: "center" }}>
                                                                    <IonText >{i + 1}</IonText>
                                                                </td>

                                                                <td style={{ textAlign: "center" }}>
                                                                    <IonText >{formatD}</IonText>
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <IonText >Date : {shipformat}</IonText><br />
                                                                    <IonText >Time : {item.shipmentTime}</IonText>
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <div style={{ display: "grid" }}>
                                                                        <IonText > {item.status}</IonText>
                                                                        <IonText >AWB Number:{item.awbNumber}</IonText>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <select style={{ fontSize: "13px" }} value={status} onChange={(e: any) => handleStatus(e, i,item)} placeholder="Select Status">
                                                                        <option value="SCHEDULED" selected disabled>Select Status</option>
                                                                        <option value="SHIPPED">SHIPPED</option>
                                                                        <option value="COMPLETED">COMPLETED</option>
                                                                    </select>
                                                                    {item.statusAlert === "" ? <></> :
                                                                        <div style={{ display: "flex", marginTop: "2%" }}>
                                                                            <IonText className="alert_input_bottom">
                                                                                {item.statusAlert}
                                                                            </IonText>
                                                                        </div>}
                                                                    <IonButton className="datatable-activate-btn" onClick={() => { statusproceed(i,item) }}><span className="button_padding">Update</span></IonButton>
                                                                </td>
                                                            </tr> : <tr className="material-shipment-row">
                                                                <td style={{ textAlign: "center" }}>
                                                                    <IonText >{i + 1}</IonText>
                                                                </td>

                                                                <td style={{ textAlign: "center" }}>
                                                                    <IonText >{formatD}</IonText>
                                                                </td>
                                                                <td className="materialorderdefault" style={{ textAlign: "center" }}>
                                                                    <IonInput onBlur={() => validation("1", i,item)} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }} ref={(el: any) => (dateRef.current[i] = el)} onKeyDown={(e: any) => dateListPrev(e)} value={item.shipmentDate} type="date" onIonChange={(e: any) => dateChange(e, i, item)} min={datelimit()} className="time_gram" />
                                                                    {item.shipDateAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                        <IonText className="alert_input_bottom">
                                                                            {item.shipDateAlert}
                                                                        </IonText>
                                                                    </div>}
                                                                    <IonInput onBlur={() => validation("2", i,item)} type="time" style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }} onKeyDown={(e: any) => dateListPrev(e)} ref={(el: any) => (ref.current[i] = el)} onIonChange={(e: any) => setTime(e, item, i)} className="time_gram_mat" />
                                                                    {item.shipTimeAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                        <IonText className="alert_input_bottom">
                                                                            {item.shipTimeAlert}
                                                                        </IonText>
                                                                    </div>}
                                                                </td>
                                                                <td className="materialordericon">
                                                                    <div>
                                                                        <IonInput onBlur={() => validation("1", i,item)} style={{}} type="date" onKeyDown={(e: any) => e.preventDefault()} ref={(el: any) => (dateRef.current[i] = el)} onIonChange={(e: any) => dateChange(e, i, item)} min={datelimit()} />
                                                                    </div>
                                                                    <div style={{ marginTop: "-18%", textAlignLast: "right", paddingRight: "5px" }}>
                                                                        <IonIcon size="35" className="calaendariconorders" src={calendarOutline} />
                                                                    </div>
                                                                    {item.shipDateAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                        <IonText className="alert_input_bottom">
                                                                            {item.shipDateAlert}
                                                                        </IonText>
                                                                    </div>}
                                                                    <div style={{ marginTop: "2%" }}>
                                                                        <IonInput onBlur={() => validation("2", i,item)} type="time" onKeyDown={(e: any) => e.preventDefault()} ref={(el: any) => (ref.current[i] = el)} onIonChange={(e: any) => setTime(e, item, i)} />
                                                                    </div>
                                                                    <div style={{ marginTop: "-18%", textAlignLast: "right", paddingRight: "5px" }}>
                                                                        <IonIcon size="35" className="calaendariconorders" src={timeOutline} />
                                                                    </div>
                                                                    {item.shipTimeAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                        <IonText className="alert_input_bottom">
                                                                            {item.shipTimeAlert}
                                                                        </IonText>
                                                                    </div>}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                    <IonText > {item.status}</IonText>
                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                <div>
                                                                <IonInput type="text" placeholder="AWB number" style={{textAlign:"left"}}
                                                                onBlur={() => validation("3",i,item)}
                                                                
                                                                onIonChange={(e: any) =>{
                                                                    validation("3",i,item)
                                                                    setAwb(e, item, i) 
                                                                } }></IonInput>
                                                                  <div style={{ display: "flex", marginTop: "3%" }}>
                                                                {item.awbAlert && <span className="alert_input_bottom">{item.awbAlert}</span>}
                                                               
                                                            </div>
                                                                    <IonButton className="initiate-shipment-btn" onClick={() => initShip(i,item)}><span className="button_padding">Initiate Shipment</span></IonButton>
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                        </>
                                                    )
                                                })}
                                            </>}

                                        </>
                                    ))}</table>
                            </div>}
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen1} className="admin_modal">
                    <Modal.Body ref={list}>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Order details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                            </div>
                        </div>
                        <div className="donation-admin-container">
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Order Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{number}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Order Date
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{date}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{oname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow  >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Product Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{productname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Brand Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{brandname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Material Price
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(oprice)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        List of Date(s)
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{listDates}</IonText>
                                </IonCol>
                            </IonRow>
                            {totalamt == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Total Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(parseInt(totalamt))}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Quantity
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{oquantity}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Package Size
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{opsize}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Package Unit
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{opunit}</IonText>
                                </IonCol>
                            </IonRow>
                            {amtpriest == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Dakshina Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {amtpriest}</IonText>
                                </IonCol>
                            </IonRow>}
                            {amttemple == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {amttemple}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow >
                                <IonCol size="12" style={{ display: "flex", justifyContent: "center" }}>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={Modalclose1}
                                    >
                                        <span className="button_padding"> Close</span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>

                <div>
                    <div className="container_middle_table page-wrapper admin_background_color">
                        <div>
                            <IonRow style={{ zIndex: "999", position: "relative" }} className="select-admin-container">
                                <IonCol size="4">
                                    <IonRow style={{ textAlign: "left" }}>
                                        <IonLabel className="admin_search_label">
                                            Order Status
                                        </IonLabel>
                                    </IonRow>

                                    <Select
                                        options={orderStatusList}
                                        value={filterStatus}

                                        onChange={handleOrderChange}
                                        placeholder="Select Order Status"
                                        isClearable

                                        styles={customStyles}
                                        className="custom-select"
                                    />
                                </IonCol>
                            </IonRow>
                        </div>

                        <DataTable
                            columns={data}
                            data={tableData1}
                            striped
                            noDataComponent="Sorry ! No result found"
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                            pagination
                            subHeader
                            subHeaderComponent={subHeaderComponent1}
                            onRowClicked={(item: any) => orderview(item)}
                            style={{ width: "100%" }}
                            customStyles={customTableStyle}

                        />

                    </div>
                </div>
            </IonContent>
        </IonPage >


    )
}
export default Materialordermanage;


