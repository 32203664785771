// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// infocareer account
// const firebaseConfig = {
//   apiKey: "AIzaSyD6emgm7Dwyo4bG3yGy7XNyCE0ov3_JXOE",
//   authDomain: "vedagram-b61db.firebaseapp.com",
//   projectId: "vedagram-b61db",
//   storageBucket: "vedagram-b61db.appspot.com",
//   messagingSenderId: "479442055683",
//   appId: "1:479442055683:web:53b7a6f438654fd21f9436",
//   measurementId: "G-81B9677C2W"
// };


// atmagram team account
const firebaseConfig = {
  apiKey: "AIzaSyBfCfSpAcBTHcGWRBCBPd-E25rsevdkJng",
  authDomain: "atmagram-50811.firebaseapp.com",
  projectId: "atmagram-50811",
  storageBucket: "atmagram-50811.appspot.com",
  messagingSenderId: "316481366609",
  appId: "1:316481366609:web:51684dc5f4ade9ce1b6670",
  measurementId: "G-9YD135RRJB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();


export { auth, provider, signInWithPopup };