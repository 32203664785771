import {
    IonIcon,
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonText,
    IonTextarea,
    IonGrid,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonButton,
    IonPage,
    IonContent,
    IonItemDivider,
    IonLoading,
    IonCard,
    IonImg,
    useIonAlert
} from "@ionic/react";
import "./Bookdetails.css"
import "./Myorder.css"
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import moment from "moment";
import { analytics, checkmarkCircleSharp, close, closeSharp, downloadOutline, informationCircleSharp } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router";
import HeadFoot from "./Head";
import { infoicon, tick } from "../theme/imagePath";
import { saveAs } from 'file-saver';

const Mymaterial: React.FC = () => {
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const [data, setData]: any = useState([]);
    const [poojaMaterial, setPoojaMaterial] = useState([]);
    const [modalopen, setModalopen] = useState(false);
    const [token, settoken]: any = useState(sessionStorage.getItem("token"));
    const [showLoading, setShowLoading] = useState(false);
    const [status, setStatus]: any = useState([]);
    const history = useHistory()
    const [Alert] = useIonAlert()
    const uFlag: any = useState(sessionStorage.getItem("uFlag"))
    const formatter = new Intl.NumberFormat('en-IN')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // Function to update the screen width when the window is resized
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };
    const changebtnposition = screenWidth < 400;


    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_USER") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            showmaterial();
        }
        window.addEventListener('resize', updateScreenWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, [])
    const downInvoice = (payId:any) => {
        setShowLoading(true);
        Authservice.materialInvoice(token,payId).then((response) => {
            console.log(response);
            
            
    
    // Decode the base64 string
    const decodedData = atob(response.data);
    const filename = "MaterialInvoice.pdf";
    
    // Convert the decoded data to a Uint8Array
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
    }
    
    // Convert the Uint8Array to a Blob
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    
    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
           setShowLoading(false);
        }) .catch((error) => {
            setShowLoading(false);
            Alert("Something went wrong, please try again later.")
           
        });
    
    
    
    }
    const showmaterial = () => {
        setShowLoading(true);
        Authservice.materialOrderUser(token)
            .then((response) => {
                console.log(response.data);
                setData(response.data);
                setPoojaMaterial(response.data.poojaMaterial);
                setShowLoading(false);
            })
            .catch((error) => {
                setData([])
                setShowLoading(false);
            });
    }

    const [orderNumber, setOrderNumber] = useState("")

    const groupedOrders = data.reduce((acc: any, item: any) => {
        const { paymentId } = item.poojaMaterialOrdDto;
        if (!acc[paymentId]) {
            acc[paymentId] = [];
        }
        acc[paymentId].push(item);
        return acc;
    }, {} as { [key: string]: any[] });

    const ViewStatus = (item: any) => {
        setStatus([])
        setOrderNumber(item.poojaMaterialOrdDto.orderNumber)
        console.log(item.poojaMaterialOrdDto.orderNumber, "di");
        var status1 = []
        for (let i = 0; i < item.poojaMaterialOrdersStatusList.length; i++) {
            status1.push(item.poojaMaterialOrdersStatusList[i])
        }
        setStatus(status1)
        console.log(status1, "dijvwbi");
        setModalopen(true);
    }
    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const statusmodal: any = useRef(null);

    useOutsideAlerter(statusmodal);

    const [productimage, setProductimage] = useState("")
    const [productname, setProductname] = useState("")
    const [imageshow, setImageshow] = useState(false)
    const [orderdetailsshow, setOrderdetailsshow] = useState(false)
    const itemimage = (item: any) => {
        console.log(item.poojaMaterialOrdDto)
        setProductimage(item.poojaMaterialOrdDto.poojaMaterialDto.image)
        setProductname(item.poojaMaterialOrdDto.poojaMaterialDto.productName)
        setImageshow(true)
    }

    const orderdetailsopen = (item: any, i: any, e: any) => {
        if (e === 1) {
            const arr = data.map((item1: any, index: any) => {
                if (item.poojaMaterialOrdDto.id === item1.poojaMaterialOrdDto.id) {
                    return { ...item1, orderDetailShow: true }
                }
                return item1;
            })
            setData(arr)
        }
        else if (e === 2) {
            const hide = data.map((item1: any, index: any) => {
                if (item.poojaMaterialOrdDto.id === item1.poojaMaterialOrdDto.id) {
                    return { ...item1, orderDetailShow: false }
                }
                return item1;
            })
            setData(hide)
        }
    }
    return (
        <IonPage>
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}

                // duration={5000}
                />
                {imageshow && (
                    <Lightbox
                        mainSrc={productimage + "?v=" + new Date()}
                        onCloseRequest={() => setImageshow(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                <Modal show={modalopen} className="material_status_modal" centered>
                    <Modal.Body ref={statusmodal}>
                        <>
                            <div className="order_status_header_container">
                                <h5 className="order_status_text">Order Status - {orderNumber}</h5>
                                <div>
                                    <IonIcon src={close} className="close_icon" onClick={() => setModalopen(false)} />
                                </div>
                            </div>
                            <div className="custom-modal-deity scroll_tab" >
                                <IonRow className="web_hide_off_order_status">
                                    <IonCol size="4" style={{ textAlign: "center" }}>
                                        <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>Order Date</IonText>
                                    </IonCol>
                                    <IonCol size="4" style={{ textAlign: "center" }}>
                                        <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>Order Status</IonText>
                                    </IonCol>
                                    <IonCol size="4" style={{ textAlign: "center" }}>
                                        <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>AWB Number</IonText>
                                    </IonCol>
                                </IonRow>
                                {status.map((item: any, i: any) => {
                                    // var ld = item.poojaMaterialOrdDto.listOfdates;
                                    console.log(item, "item")
                                    var ld2 = item.poojaMaterialOrders.listOfdates.split("||")
                                    const Sta = item.status
                                    const awb = item.awbNumber
                                    return (
                                        <>
                                            <IonRow className="web_hide_off_order_status">
                                                <IonCol size="4" style={{ textAlign: "center", marginTop: "2%" }}>
                                                    <IonText className="order_detail_popup">{ld2[i]}</IonText>
                                                </IonCol>
                                                <IonCol size="4" style={{ textAlign: "center", marginTop: "2%" }}>
                                                    <IonText className="order_detail_popup">{Sta}</IonText>
                                                </IonCol>
                                                {awb === "" || awb === null ?
                                                    <IonCol size="4" style={{ textAlign: "center", marginTop: "2%" }}>
                                                        <IonText className="order_detail_popup">Not Yet Initiated</IonText>
                                                    </IonCol> :
                                                    <IonCol size="4" style={{ textAlign: "center", marginTop: "2%" }}>
                                                        <IonText className="order_detail_popup">{awb}</IonText>
                                                    </IonCol>}
                                            </IonRow>
                                            <IonRow className="order_status_offering_mobile">
                                                <IonCol size="12">
                                                    <IonRow>
                                                        <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1">
                                                            <IonText className="order_detail_popup">{i + 1}.</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                            <IonText className="order_detail_popup"> Order Date</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                            <IonText className="order_detail_popup">:</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                            <IonText className="order_detail_popup">{ld2[i]}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1" />
                                                        <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                            <IonText className="order_detail_popup">Order Status</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                            <IonText className="order_detail_popup">:</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                            <IonText className="order_detail_popup">{Sta}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1" />
                                                        <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                            <IonText className="order_detail_popup">AWB Number</IonText>
                                                        </IonCol>
                                                        <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                            <IonText className="order_detail_popup">:</IonText>
                                                        </IonCol>
                                                        {awb === "" || awb === null ?
                                                            <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                                <IonText className="order_detail_popup">Not Yet Initiated</IonText>
                                                            </IonCol> :
                                                            <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                                <IonText className="order_detail_popup">{awb}</IonText>
                                                            </IonCol>
                                                        }
                                                    </IonRow>
                                                    <IonItemDivider className="divider_order_details" />
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    )
                                }
                                )}
                            </div>
                        </>
                    </Modal.Body>
                </Modal>

                <HeadFoot User={"login"} Role={'Role'} Name='Home,My Materials' />
                <div className="backgroundorders page_content_padding">
                    {showLoading === false && data.length == 0 ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonText className="no_data">No Data Available</IonText>
                    </div> : <div>
                        <IonGrid>
                            {Object.entries(groupedOrders).map(([key, value], i) => {
                                var mainData: any = value;
                                return (
                                    <div key={key} className="ordercard" style={{ border: "1px solid red" }}>
                                        <div style={{ display: "flex", margin: "10px 10px 0px 10px", alignItems: "center" }}>
                                            <div style={{ width: "90%" }}>
                                                <IonRow>
                                                    {mainData[0].poojaMaterialOrdDto.dakshinaAmountForPriest === null ? <></> :
                                                        <IonText className="order_head_amt">Dakshina Amount : ₹ {formatter.format(mainData[0].poojaMaterialOrdDto.dakshinaAmountForPriest)}</IonText>}
                                                </IonRow>
                                                <IonRow>
                                                    {mainData[0].poojaMaterialOrdDto.dakshinaAmountToTemple === null ? <></> :
                                                        <IonText className="order_head_amt">Donation Amount : ₹ {formatter.format(mainData[0].poojaMaterialOrdDto.dakshinaAmountToTemple)}</IonText>
                                                    }
                                                </IonRow>
                                            </div>
                                            <div style={{ justifyContent: "flex-end" }}>
                                                <IonButton size="small" className="order_invoice_button" onClick={()=>downInvoice(key)}>
                                                    <IonIcon src={downloadOutline} style={{ fontSize: "20px" }} />
                                                    <span className="order_button_padding" style={{ marginLeft: "10px" }}>
                                                        Invoice
                                                    </span>
                                                </IonButton>
                                            </div>
                                        </div>
                                        {(mainData).map((item: any, i: any) => {
                                            var date = item.poojaMaterialOrdDto.orderDate.split("T");
                                            const od = moment(date[0]).format("DD-MM-YYYY");
                                            // var ld = item.poojaMaterialOrdDto.listOfdates;
                                            var ld2 = item.poojaMaterialOrdDto.listOfdates.split("||")
                                            var ld4 = ld2.splice(-1)
                                            var ld3 = ld2.join(", ")
                                            return (
                                                <IonCard>
                                                    <IonRow className="order_header">
                                                        <IonCol size="6">
                                                            <IonText className="order_id">{item.poojaMaterialOrdDto.orderNumber}</IonText>
                                                        </IonCol>
                                                        <IonCol size="6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <IonText className="order_id">₹ {formatter.format(parseInt(item.poojaMaterialOrdDto.totalPaidAmount))}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="mat_pad">
                                                        <IonCol sizeMd="12" sizeXl="3" sizeLg="3" sizeSm="12" sizeXs="12" style={{ display: 'flex', justifyContent: "center" }}>
                                                            <IonImg className="material_img" onClick={() => itemimage(item)} src={item.poojaMaterialOrdDto.poojaMaterialDto.image} />
                                                        </IonCol>
                                                        <IonCol sizeMd="12" sizeXl="9" sizeLg="9" sizeSm="12" sizeXs="12" >

                                                            <IonRow style={{ paddingTop: '5px' }}>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                                    <IonText className="order_detail">Order Date</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="3.5" size-md="3.5" size-sm={changebtnposition === true ? "7.5" : "3.5"} size-xs={changebtnposition === true ? "7.5" : "3.5"}>
                                                                    <IonText className="order_detail">{od}</IonText>
                                                                </IonCol>
                                                                {changebtnposition === true ? <></> : <IonCol size-lg="4" size-md="4" size-sm="4" size-xs="4">
                                                                    <div style={{ textAlign: "right" }}>
                                                                        <IonButton size="small" className="order_button" onClick={() => ViewStatus(item)}>
                                                                            <span className="order_button_padding">
                                                                                Order Status
                                                                            </span>
                                                                        </IonButton>

                                                                    </div>

                                                                </IonCol>}
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                                    <IonText className="order_detail">Temple Details </IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                                    <IonText className="order_detail">
                                                                        {item.poojaMaterialOrdDto.deity.deityName}, {item.poojaMaterialOrdDto.temples.name}
                                                                    </IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                                    <IonText className="order_detail">Product Name</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                                    <IonText className="order_detail">{item.poojaMaterialOrdDto.poojaMaterialDto.productName}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {/* <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} /> 
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="4" size-sm="4" size-xs="4">
                                                        <IonText className="order_detail">Brand Name</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                        <IonText className="order_detail">{item.poojaMaterialOrdDto.poojaMaterialDto.brandName}</IonText>
                                                    </IonCol>
                                                </IonRow> */}
                                                            <IonRow>
                                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                </IonCol>
                                                                <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="3">
                                                                    <IonText className="order_detail">Material Price</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                    <IonText className="order_detail">:</IonText>
                                                                </IonCol>
                                                                <IonCol size-lg="7.5" size-md="7.5" size-sm="7.5" size-xs="7.5">
                                                                    <IonText className="order_detail">₹ {formatter.format(item.poojaMaterialOrdDto.totalAmount)}</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                                                                {item.orderDetailShow === true ?
                                                                    <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 2)}>
                                                                        see Less
                                                                    </IonText> : <IonText className="order_details_txt" onClick={() => orderdetailsopen(item, i, 1)}>
                                                                        order details
                                                                    </IonText>}
                                                            </IonRow>
                                                        </IonCol>
                                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">

                                                        </IonCol>
                                                    </IonRow>
                                                    {item.orderDetailShow === true ?
                                                        <div className="mat_pad">
                                                            <div style={{ marginRight: "25px" }}>
                                                                <IonItemDivider className="divider_order_details" />
                                                            </div>
                                                            <IonRow style={{ display: 'flex', justifyContent: "center" }}>
                                                                <IonText className="order_details_head">
                                                                    Order Details
                                                                </IonText>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">
                                                                    <IonRow style={{ paddingTop: '5px' }}>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >
                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Name</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            {item.poojaMaterialOrdDto.name === "" || item.poojaMaterialOrdDto.name === null ?
                                                                                <IonText className="order_detail">N/A</IonText> :
                                                                                <IonText className="order_detail">{item.poojaMaterialOrdDto.name}</IonText>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Star</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            {item.poojaMaterialOrdDto.star === "" || item.poojaMaterialOrdDto.star === null ?
                                                                                <IonText className="order_detail">N/A</IonText> :
                                                                                <IonText className="order_detail">{item.poojaMaterialOrdDto.star}</IonText>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >
                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Delivery Address</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">{item.poojaMaterialOrdDto.temples.shippingAddress}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {/* {item.poojaMaterialOrdDto.dakshinaAmountForPriest == null ? <></> : <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Dakshina Amount</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">₹ {formatter.format(item.poojaMaterialOrdDto.dakshinaAmountForPriest)}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>} */}
                                                                </IonCol>
                                                                <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">
                                                                    <IonRow style={{ paddingTop: '5px' }}>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Quantity</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">{item.poojaMaterialOrdDto.quantity}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">List of Date</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">{ld3}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Delivery Charge</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">₹ {formatter.format(item.poojaMaterialOrdDto.pdAmt)}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Total Amount</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">₹ {formatter.format(parseInt(item.poojaMaterialOrdDto.totalPaidAmount))}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    {/* {item.poojaMaterialOrdDto.dakshinaAmountToTemple == null ? <></> : <IonRow>
                                                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container'>
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} />
                                                                        </IonCol>
                                                                        <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                                            <IonText className="order_detail">Donation Amount</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                                            <IonText className="order_detail">:</IonText>
                                                                        </IonCol>
                                                                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                                            <IonText className="order_detail">₹ {formatter.format(item.poojaMaterialOrdDto.dakshinaAmountToTemple)}</IonText>
                                                                        </IonCol>
                                                                    </IonRow>} */}
                                                                </IonCol>
                                                            </IonRow>
                                                        </div> : <></>}
                                                    {changebtnposition === false ? <></> :
                                                        <IonRow style={{ justifyContent: "center" }}>
                                                            <IonCol size-lg="4" size-md="4" size-sm="4" size-xs="4">
                                                                <div style={{ textAlign: "right" }}>
                                                                    <IonButton size="small" className="order_button" onClick={() => ViewStatus(item)}>
                                                                        <span className="order_button_padding">
                                                                            Order Status
                                                                        </span>
                                                                    </IonButton>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>}
                                                    {/* <IonRow className="ion-justify-content-center">
                                            <IonCol sizeLg="4" sizeMd="8" sizeXl="8" sizeSm="8" >
                                                <div className="center_col">
                                                    <IonButton size="small" onClick={() => ViewStatus(item)}>
                                                        <span className="button_padding">
                                                            Order Status
                                                        </span>
                                                    </IonButton></div>
                                            </IonCol>
                                        </IonRow> */}
                                                </IonCard>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </IonGrid>
                    </div>}

                </div>

                <Foot />
            </IonContent >
        </IonPage >
    )
}
export default Mymaterial;