/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonIcon, IonImg } from "@ionic/react"
import { logo } from "../../theme/imagePath"
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/AppContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { chevronForwardOutline } from "ionicons/icons";

const Sidebar: React.FC = () => {

      const { userdata } = useContext(AppContext)!;
      const { uFlag } = useContext(AppContext)!;

      const local: any = useLocation();

      const [isSideMenu, setSideMenu] = useState("");
      const history = useHistory()

      const toggleSidebar = (value: any) => {
            setSideMenu(value);
      }
      const [imageLoaded, setImageLoaded] = useState(false)

      useEffect(() => {
            if (local.pathname == "/grammerceadm" || local.pathname == "/grammerceorderadm") {
                  setSideMenu("grammerce")
            } else if (local.pathname == "/materialadm" || local.pathname == "/admmaterialorders") {
                  setSideMenu("materials")
            }
            else if (local.pathname == "/projectmanage" || local.pathname == "/donationadm") {
                  setSideMenu("projects")
            }
            const delay = 1000; // 3 seconds delay before rendering the image
            const timer = setTimeout(() => {
                  setImageLoaded(true);
            }, delay);

            return () => clearTimeout(timer); // Clear the timer if component unmounts
      }, []);

      return (
            <div className="sidebar" id="sidebar">
                  <div className="sidebar-inner slimscroll">

                        <div className="sidebar_logo" onClick={() => history.push("/")} >


                        </div>

                        <div id="sidebar-menu" className="sidebar-menu">
                              {userdata === "ROLE_ADMIN" ?
                                    <ul>
                                          <li className={local.pathname === "/admin_page" ? "active" : ""}>
                                                <Link to="/admin_page" > <span>User Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/deitymanagement" ? "active" : ""}>
                                                <Link to="/deitymanagement" > <span>Deity Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/districtmanagement" ? "active" : ""}>
                                                <Link to="/districtmanagement" > <span>District Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/admtemple" || local.pathname === "/addTemple"
                                                || local.pathname === "/editTemple" ? "active" : ""}>
                                                <Link to="/admtemple" > <span>Temple Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/volunteer" || local.pathname === "/volunteer"
                                                || local.pathname === "/volunteer" ? "active" : ""}>
                                                <Link to="/volunteer" > <span>Temple Booking Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/offeringsmanagement" || local.pathname === "/offeringsmanagement"
                                                || local.pathname === "/offeringsmanagement" ? "active" : ""}>
                                                <Link to="/offeringsmanagement" > <span>Pooja master</span></Link>
                                          </li>
                                          <li className={local.pathname === "/marketManagement" ? "active" : ""}>
                                                <Link to="/marketManagement" > <span>Market Management</span></Link>
                                          </li>
                                          <li className={local.pathname === "/userBookingOrders" ? "active" : ""}>
                                                <Link to="/userBookingOrders" > <span>User Booking Orders</span></Link>
                                          </li>
                                          {/* <li className={local.pathname === "/orderAdmin" ? "active" : ""}>
                                                <Link to="/orderAdmin" > <span>Offering Order Management</span></Link>
                                          </li> */}
                                          {/* <li className="submenu">
                                                <a className={isSideMenu == "materials" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "materials" ? "" : "materials")}><span> Pooja Materials</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                {isSideMenu === "materials" ?
                                                      <ul >
                                                            <li><Link className={local.pathname === "/materialadm" ? "active" : ""} to="/materialadm">Material Management</Link></li>
                                                            <li><Link className={local.pathname === "/admmaterialorders" ? "active" : ""}
                                                                  to="/admmaterialorders">Material Order Management</Link></li>
                                                      </ul>
                                                      : ""
                                                }
                                          </li> */}
                                          {/* <li className="submenu">
                                                <a className={isSideMenu == "grammerce" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "grammerce" ? "" : "grammerce")}><span> Grammerce</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                {isSideMenu === "grammerce" ?
                                                      <ul >
                                                            <li><Link className={local.pathname === "/grammerceadm" ? "active" : ""} to="/grammerceadm">Product Management</Link></li>
                                                            <li><Link className={local.pathname === "/grammerceorderadm" ? "active" : ""}
                                                                  to="/grammerceorderadm"> Order Management</Link></li>
                                                      </ul>
                                                      : ""
                                                }
                                          </li> */}
                                          <li className={local.pathname === "/donationmanage" ? "active" : ""}>
                                                <Link to="/donationmanage" > <span>Donation Management</span></Link>
                                          </li>
                                          {/* <li className="submenu">
                                                <a className={((local.pathname === "/projectmanage") || (local.pathname === "/donationadm")) ? "active" : ""} onClick={() => toggleSidebar(isSideMenu == "projects" ? "" : "projects")}><span> Projects</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                {isSideMenu === "projects" ?
                                                      <ul >
                                                            <li><Link className={local.pathname === "/projectmanage" ? "active" : ""} to="/projectmanage">Project Management</Link></li>
                                                            <li><Link className={local.pathname === "/donationadm" ? "active" : ""}
                                                                  to="/donationadm"> Project Donation</Link></li>
                                                      </ul>
                                                      : ""
                                                }
                                          </li> */}
                                          <li className="submenu">
                                                <a className={local.pathname === "/carousel" ? "active" : ""}
                                                      onClick={() =>
                                                            toggleSidebar(isSideMenu === "configure" ? "" : "configure")
                                                      }>
                                                      <span> Configure</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                {isSideMenu === "configure" ?
                                                      <ul >
                                                            <li><Link className={local.pathname === "/carousel" ? "active" : ""} to="/carousel">Carousel</Link></li>
                                                      </ul>
                                                      : ""
                                                }
                                          </li>
                                          <li className="submenu">
                                                <a className={local.pathname === "/carousel" ? "active" : ""}
                                                      onClick={() =>
                                                            toggleSidebar(isSideMenu === "report" ? "" : "report")
                                                      }>
                                                      <span> Report</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                {isSideMenu === "report" ?
                                                      <ul >
                                                            <li><Link className={local.pathname === "/fundtobedisbursed" ? "active" : ""} to="/fundtobedisbursed">Fund to be Disbursed</Link></li>
                                                            <li><Link className={local.pathname === "/funddisbursed" ? "active" : ""} to="/funddisbursed">Fund Disbursed</Link></li>   {/* funddisbursed */}
                                                            <li><Link className={local.pathname === "/fundrefunds" ? "active" : ""} to="/fundrefunds">Fund for Refund</Link></li>
                                                            <li><Link className={local.pathname === "/prasadhamDelivery" ? "active" : ""} to="/prasadhamDelivery">Prasadam to be delivered</Link></li> {/* prasadhamDelivery*/}
                                                            <li><Link className={local.pathname === "/reviewList" ? "active" : ""} to="/reviewList">Review</Link></li> {/* prasadhamDelivery*/}

                                                      </ul>
                                                      : ""
                                                }
                                          </li>
                                          {/* <li className={local.pathname === "/reports" ? "active" : ""}>
                                                <Link to="/reports" > <span>Report</span></Link>
                                          </li> */}
                                    </ul>
                                    : userdata === "ROLE_TEMPADMIN" ?
                                          <ul>
                                                <li className={local.pathname === "/tempadmin" || local.pathname === "/addTemple"
                                                      || local.pathname === "/editTemple" ? "active" : ""}>
                                                      <Link to="/tempadmin" > <span>Temple Management</span></Link>
                                                </li>
                                                {/* <li className={local.pathname === "/order" ? "active" : ""}>
                                                      <Link to="/order" > <span>Offering Order Management</span></Link>
                                                </li> */}
                                                <li className={local.pathname === "/reports" ? "active" : ""}>
                                                      <Link to="/reports" > <span>Report</span></Link>
                                                </li>
                                          </ul>
                                          : userdata === "ROLE_VENDOR" ?
                                                <ul>
                                                      <li className={local.pathname === "/vendormaterials" ? "active" : ""}>
                                                            <Link to="/vendormaterials" > <span>Material Management</span></Link>
                                                      </li>
                                                      <li className={local.pathname === "/vendormateriaorders" ? "active" : ""}>
                                                            <Link to="/vendormateriaorders" > <span>Material Order Management</span></Link>
                                                      </li>
                                                      <li className={local.pathname === "/reports" ? "active" : ""}>
                                                            <Link to="/reports" > <span>Report</span></Link>
                                                      </li>
                                                </ul>
                                                : userdata === "ROLE_PROJECTADMIN" ?
                                                      <ul>
                                                            <li className={local.pathname === "/projectadmin" ? "active" : ""}>
                                                                  <Link to="/projectadmin" > <span>Project Management</span></Link>
                                                            </li>
                                                            <li className={local.pathname === "/projectdonate" ? "active" : ""}>
                                                                  <Link to="/projectdonate" > <span>Project Donation</span></Link>
                                                            </li>
                                                            <li className={local.pathname === "/reports" ? "active" : ""}>
                                                                  <Link to="/reports" > <span>Report</span></Link>
                                                            </li>
                                                      </ul>
                                                      : <></>
                              }
                        </div>
                  </div>
            </div>
      )
}

export default Sidebar