import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonModal,
  IonTextarea,
  IonLoading,
  IonSearchbar,
  useIonAlert,
  IonText,
  IonGrid,
  IonCheckbox,
  IonAlert
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, addOutline, list, close, arrowBackOutline, chevronBackOutline, chevronDownOutline, closeCircle, searchOutline } from "ionicons/icons";
import DataTable from "react-data-table-component"
import "./Deitymanage.css";
import { useHistory, useLocation } from "react-router";
import { Capacitor } from "@capacitor/core";
import Authservice from "../../Services/user.service";
import Lightbox from "react-image-lightbox";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import { AppContext } from "../../components/AppContext";
import CommonDataTable from "../../components/datatable";
import moment from "moment";
import HeadFoot from "../Head";
import CommonModal from "../../components/popipInfo";
import Breadcrumb from "../../components/Breadcrumb";

const Deity: React.FC = () => {
  const [comment, setComment] = useState("");
  const [presentAlert] = useIonAlert();
  const pageNames = ['Deities', 'Deity Details'];


  const columns: any = [
    {
      name: "S.No",
      sortable: true,
      width: "90px",
      selector: (item: any) => item.serial,
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => clickRow(item)}>
          {item.serial}
        </div>
      ),
    },
    {
      name: "Deity Image",
      sortable: false,
      width: "150px",
      selector: (item: any) => item.image,
      cell: (item: any, i: any) => {
        return (
          <div className="center_col_tab" onClick={() => clickRow(item)}>
            <IonImg style={{ width: "50px", height: "50px", cursor: "pointer" }} alt="img" src={item.carouselDtos?.[0]?.imageUrls?.[0] ? item.carouselDtos[0].imageUrls[0] + "?v=" + new Date() : ""}></IonImg>
          </div>
        )
      },
    },
    {
      name: "Deity Name",
      selector: (item: any) => item.deityName,
      sortable: true,
      width: "150px",
      style: {
        textAlign: 'left'
      },
      cell: (item: any) => (
        <div className="left_col" onClick={() => clickRow(item)}>
          <div style={{ cursor: "pointer" }} >{item.deityName}</div>
        </div>
      )
    },
    {
      name: "Description",
      selector: (item: any) => item.significance,
      sortable: false,
      //width: "50%",
      cell: (item: any) => (
        <div onClick={() => clickRow(item)} style={{ textAlign: "justify" }}>
          <div className="textDes threelineCls" >{item.significance}</div>
        </div>
      )
    },
  ]


  const {
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const [usertoken] = useState(sessionStorage.getItem("token"));
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);
  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const uFlag: any = useState(sessionStorage.getItem("uFlag"));
  const [deitylist, setDeitylist] = useState([]);
  const [modalopen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [remark, setRemark] = useState("");
  const [description, setDescription] = useState("");
  const [activeComment, setActiveComment] = useState("");
  const [inActiveComment, setInActivecomment] = useState("");
  const [image, setImage]: any = useState([]);
  const [deityImg, setDeityImg] = useState("");
  const [imageFiles, setImageFiles]: any = useState([]);
  const [isedit, setIsedit] = useState(false);
  const [deityid, setDeityid] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [userId] = useState(sessionStorage.getItem("UserId"));
  const [tempCode, setTempCode] = useState("");
  const [index, setIndex] = useState(0)
  const { exportCSV } = useContext(AppContext)!
  const [isUserSide, setIsuserSide] = useState(true)
  const [isEdit, setIsEdit] = useState(false)

  const [Commonmodal, setCommonmodal] = useState(false)
  const [CommonModalMesg, setCommonModalMesg] = useState("")
  const [Commmonmodaltitle, setCommonmodaltitle] = useState("")


  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  if (deitylist != undefined && deitylist.length > 0) {
    deitylist.forEach((item: any, index: any) => { item.serial = index + 1; });
  }
  const [searchTerm, setSearchTerm] = useState("");
  const [showReviewInput, setShowReviewInput] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [modalData, setModaldata] = useState()


  const modalColumn = [
    {
      name: "Review",
      selector: (item: any, i: any) => item.review,
      sortable: true,
      width: "85%",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.review}</div>
        </div>
      )
    },
    {
      name: "Review By",
      selector: (item: any, i: any) => item.reviewBy,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
        </div>
      )
    },

  ]

  const modalColumnAdmin = [
    {
      name: "Active",
      selector: (item: any, i: any) => item.isActive,
      sortable: true,
      width: "100px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.isActive === null ? "N/A" : item.isActive ? "Yes" : "No"}</div>
        </div>
      )
    },
    {
      name: "Delete",
      width: "100px",
      selector: (item: any, i: any) => item.isDeleted,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.isDeleted === null ? "N/A" : item.isDeleted ? "Yes" : "No"}</div>
        </div>
      )
    },
    {
      name: "Vetoed By",
      width: "100px",
      selector: (item: any, i: any) => item.vetoedBy,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.vetoedBy === null ? "N/A" : item.vetoedBy}</div>
        </div>
      )
    },
    {
      name: "Vetoed On",
      width: "100px",
      selector: (item: any, i: any) => item.vetoedon,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.vetoedon === null ? "N/A" : item.vetoedon}</div>
        </div>
      )
    },
    {
      name: "Review",
      selector: (item: any, i: any) => item.review,
      sortable: true,
      width: "50%",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.review}</div>
        </div>
      )
    },
    {
      name: "Review By",
      selector: (item: any, i: any) => item.reviewBy,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
        </div>
      )
    },

  ]
  const location: any = useLocation()


  useEffect(() => {
    setIndex(0)
    if (location.pathname === "/deitymanagement") {
      setIsuserSide(false)
    }
    else {
      setIsuserSide(true)
    }
    showalldeity();
    getMessagesByCategory()
  }, []);

  const getMessagesByCategory = () => {
    UserService.getMessagesByCategory(userId, usertoken, usertoken, "Deity").then((res: any) => {
      if (res.data !== "" && res.data !== null && isUserSide) {
        setCommonModalMesg(res.data)
        setCommonmodaltitle("Information")
        setCommonmodal(true)
        setShowLoading(false)
      }
    })
      .catch((error: any) => {
        console.log(error, "error")
        setShowLoading(false)
      })
  }

  const showalldeity = () => {
    setShowLoading(true);
    back()
    UserService.Showdeity(usertoken, userId, usertoken).then((response) => {
      console.log(response.data, "saas");
      if (response.data) {
        setDeitylist(response.data);
        setIndex(0)
      }
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const handleStatus = (status: any, id: any, comm: any) => {
    setShowLoading(true)
    Authservice.deityActIn(usertoken, id, status, comm).then((response) => {
      console.log(response)
      // alert("User Activated")
      window.location.reload()
      setShowLoading(false)
    })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
        setShowLoading(false)
      });

  }

  const tabMenu: any = [
    "Admin Controller",
    "About",
    "Introduction",
    "DivineForm",
    "TribalTraditions",
    "Cultural",
    "NaturalConnection",
    "Healing",
    "SacredRituals",
    "CommunityLife",
    "ModernRelevance",
    "Sacred Knowledge",
    "Notes",
    "Review",
    "Gallery"
  ]

  const [menuItems, setMenuItems] = useState(
    tabMenu.map((data: any) => ({ name: data, isOpen: false }))
  );
  const [isActive, setIsActive] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [modifiedBy, setModifiedBy] = useState("")
  const [modifiedOn, setModifiedOn] = useState("")
  const [deityName, setDeityName] = useState("")
  const [deityCode, setDeityCode] = useState("")
  const [significance, setSignificance] = useState("")
  const [introduction, setIntroduction] = useState("")
  const [divineForm, setDivineForm] = useState("")
  const [tribalTraditions, setTribalTraditions] = useState("")
  const [cultural, setCultural] = useState("")
  const [naturalConnection, setNaturalConnection] = useState("")
  const [healing, setHealing] = useState("")
  const [sacredRituals, setSacredRituals] = useState("")
  const [communityLife, setCommunityLife] = useState("")
  const [modernRelevance, setModernRelevance] = useState("")
  const [sacredKnowledge, setSacredKnowledge] = useState("")
  const [notes, setNotes] = useState("")
  const imageinputref = useRef<HTMLInputElement>(null);
  const [iserror, setIserror] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [successAlert, setSuccessAlert] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  let debounceTimeout: any;

  const clickRow: any = (item: any) => {
    setIsEdit(true)
    setShowLoading(true);
    UserService.getDeityDetails(usertoken, userId, usertoken, item.deityCode).then((response) => {
      console.log(response.data, "saas");
      if (response.data) {
        setDeityName(response.data.deityName)
        setDeityCode(response.data.deityCode)
        setSignificance(response.data.significance)
        setIsActive(response.data.isActive || false)
        setIsDeleted(response.data.isDeleted || false)
        setModifiedBy(response.data.modifiedBy || "")
        setModifiedBy(response.data.modifiedBy || "")
        if (response.data.modifiedOn !== null && response.data.modifiedOn !== "") {
          var date: any = new Date(response.data.modifiedOn)
          var format = moment(date).format("DD-MM-YYYY")
          setModifiedOn(format)
        }
        setNaturalConnection(response.data.naturalConnection || "")
        setNotes(response.data.notes || "")
        setSacredKnowledge(response.data.sacredKnoledge || "")
        setSacredRituals(response.data.sacredRituals || "")
        setModernRelevance(response.data.modernRelevance || "")
        setCommunityLife(response.data.communityLife || "")
        setCultural(response.data.cultural || "")
        setTribalTraditions(response.data.tribalTraditions || "")
        setIntroduction(response.data.introduction || "")
        setDivineForm(response.data.divineForm || "")
        setHealing(response.data.healing || "")
        setModaldata(response.data.reviewsDtos || [])
        if (response.data.carouselDtos.length > 0) {
          response.data.carouselDtos.map((item: any) => {
            setImage((e: any) => [
              ...e, { image: item.imageUrls[0], name: item.image, id: item.id }
            ])
          })
        }
        else {
          setImage([])
          setImageFiles([])
        }
        setMenuItems((prev: any) =>
          prev.map((item: any) => ({
            ...item,
            isOpen: false,
          }))
        );
        setIndex(1)
      }
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  };

  const openContent = (index: number, param: any) => {
    const modifiedParam = param.replace(/\s+/g, ""); // Remove all spaces
    Authservice.getDeityInfoByObjects(usertoken, userId, deityCode, modifiedParam).then((response) => {
      console.log(response)
      setShowLoading(false)
    })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
        setShowLoading(false)
      });
    if (index === 13) {
      setModalOpen(true)
      return
    }
    const updatedMenuItems = menuItems.map((item: any, i: number) => ({
      ...item,
      isOpen: i === index ? !item.isOpen : false, // Toggle selected item, close others
    }));
    setMenuItems(updatedMenuItems);
  };

  const adminSelectionChange = (e: any, label: any) => {
    if (label === "active") {
      if (e.detail.checked) {
        setIsActive(true)
      }
      else {
        setIsActive(false)
      }
    }
    else if (label === "delete") {
      if (e.detail.checked) {
        setIsDeleted(true)
      }
      else {
        setIsDeleted(false)
      }
    }
  }

  const openmodal = () => {

  };

  const imageref: any = useRef(null)
  const onchangeImage = (e: any) => {
    const files = e.target.files;
    if (files) {
      const newImages: any = [...image];
      Array.from(files).forEach((file: any) => {
        if (newImages.length < 10 && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")) {
          const reader = new FileReader();
          reader.onload = () => {
            newImages.push({ image: reader.result, name: file.name, id: "" });
            console.log({ image: reader.result, name: file.name })
            setImage([...newImages]);
            setImageFiles([...imageFiles, ...files])
          };
          reader.readAsDataURL(file);
        }
      });
    }
  };

  const [formerrors, setFormerrors]: any = useState({})
  const namecheck = /^[a-zA-Z\s]*$/

  const validation = (num: any) => {
    if (num === 1) {
      if (name === "") {
        setFormerrors({ ...formerrors, name: "Enter the Name" })
      }
      else if (name.charAt(0) === " ") {
        setFormerrors({ ...formerrors, name: "Empty space at Name" })
      }
      else if (namecheck.test(name) == false) {
        setFormerrors({ ...formerrors, name: 'Alphabets only Allowed in First Name' })
      }
      else {
        setFormerrors({ ...formerrors, name: "" })
      }
    }
    else if (num === 2) {
      if (description === "") {
        setFormerrors({ ...formerrors, description: "Enter the Description" })
      }
      else if (description.charAt(0) === " ") {
        setFormerrors({ ...formerrors, description: "Empty space at Description" })
      }
      else {
        setFormerrors({ ...formerrors, description: "" })
      }
    }
  }

  const onChangevalidation = (num: any, e: any) => {
    if (num === 1) {
      var namevalue = e.detail.value!;
      if (namevalue === "") {
        setFormerrors({ ...formerrors, name: "Enter the Name" })
      }
      else if (namevalue.charAt(0) === " ") {
        setFormerrors({ ...formerrors, name: "Empty space at Name" })
      }
      else if (namecheck.test(namevalue) == false) {
        setFormerrors({ ...formerrors, name: 'Alphabets only Allowed in First Name' })
      }
      else {
        setFormerrors({ ...formerrors, name: "" })
      }
      setName(e.detail.value!)
    }
    else if (num === 2) {
      var desvalue = e.detail.value!;
      if (desvalue === "") {
        setFormerrors({ ...formerrors, description: "Enter the Description" })
      }
      else if (desvalue.charAt(0) === " ") {
        setFormerrors({ ...formerrors, description: "Empty space at Description" })
      }
      else {
        setFormerrors({ ...formerrors, description: "" })
      }
      setDescription(e.detail.value!)
    }
    else if (num === 3) {
      var desvalue = e.detail.value!;
      // if (desvalue === "") {
      //   setFormerrors({ ...formerrors, remark: "Enter the Description" })
      // }
      // else if (desvalue.charAt(0) === " ") {
      //   setFormerrors({ ...formerrors, description: "Empty space at Description" })
      // }
      // else {
      //   setFormerrors({ ...formerrors, description: "" })
      // }
      setRemark(e.detail.value!)
    }
  }

  const removeImage = (item: any, i: any) => {
    if (item.id === "" || item.id === null) {
      setImage(image.filter((_: any, index: any) => index !== i))
      setImageFiles(imageFiles.filter((_: any, index: any) => index !== i))
    }
    else {
      setShowLoading(true)
      UserService.deleteDeityImg(usertoken, userId, "", item.name, item.id).then((response: any) => {
        if (response.data === "success") {
          setImage(image.filter((_: any, index: any) => index !== i))
          setImageFiles(imageFiles.filter((_: any, index: any) => index !== i))
          setSuccessMsg("Image removed successfully")
          setSuccessAlert(true)
        }
        else {
          setIserror(true)
          setErrorMsg("Something wen`t wrong.Please try again later")
        }
        setShowLoading(false)
      })
        .catch((error: any) => {
          console.log(error, "error")
          setShowLoading(false)
          setIserror(true)
          setErrorMsg("Something wen`t wrong.Please try again later")
        })
    }
  }

  const addUpdateDeity = () => {
    if (deityName.trim() === "") {
      setIserror(true)
      setErrorMsg("Enter the deity name")
    }
    else {
      setShowLoading(true)
      var data = {
        "deityCode": deityCode,
        "deityName": deityName,
        "significance": significance,
        "remarks": "",
        "introduction": introduction,
        "divineForm": divineForm,
        "tribalTraditions": tribalTraditions,
        "cultural": cultural,
        "naturalConnection": naturalConnection,
        "healing": healing,
        "sacredRituals": sacredRituals,
        "communityLife": communityLife,
        "modernRelevance": modernRelevance,
        "sacredKnoledge": sacredKnowledge,
        "notes": notes,
        "isActive": isActive,
        "isDeleted": isDeleted
      }
      console.log(data, "data")
      UserService.addOrUpdateDeity(usertoken, userId, "", data, imageFiles).then((response: any) => {
        if (response.data.responseText === "SUCCESS") {
          console.log(response.data, "resData")
          if (deityCode === "") {
            setSuccessMsg("Deity Added Successfully")
          }
          else {
            setSuccessMsg("Deity Updated Successfully")
          }
          setSuccessAlert(true)
        }
        else {
          setIserror(true)
          setErrorMsg("Something wen`t wrong.Please try again later")
        }
        setShowLoading(false)

      })
        .catch((error: any) => {
          console.log(error, "error")
          setShowLoading(false)
          setIserror(true)
          setErrorMsg("Something wen`t wrong.Please try again later")
        })
    }
  }

  const handleSubmitReview = async () => {
    if (!reviewText.trim()) {
      alert("Please enter a review before submitting.");
      return;
    }
    const data = {
      "code": deityCode,
      "review": reviewText,
      "category": "Deity",
      "isActive": false,
      "isDeleted": false,
      "isVetoed": false
    }
    Authservice.createOrUpdateTempleReviews(userId, usertoken, usertoken, data).then((response: any) => {
      console.log(response);
      setReviewText(""); // Clear textarea
      setShowReviewInput(false); // Hide input after submission
      setModalOpen(false)
      if (response.status === 200) {
        setCommonmodal(true)
        setCommonmodaltitle("Information")
        setCommonModalMesg("Review submitted successfully!");
      }

    })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        setShowLoading(false);
      });

  };

  const back = () => {
    setDeityCode("")
    setDeityName("")
    setCommunityLife("")
    setDivineForm("")
    setIsActive(false)
    setIsDeleted(false)
    setModifiedBy("")
    setModifiedOn("")
    setSignificance("")
    setIntroduction("")
    setTribalTraditions("")
    setCultural("")
    setNaturalConnection("")
    setHealing("")
    setSacredRituals("")
    setModernRelevance("")
    setSacredKnowledge("")
    setNotes("")
    setImage([])
    setImageFiles([])
    setIsEdit(false)
  }

  var tableData: any = [];
  if (deitylist != undefined && deitylist.length > 0) {
    tableData = deitylist.filter(
      (item: any) =>
        JSON.stringify(item.deityName)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
  }


  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const Getdatausingsearch = (value: any) => {
    UserService.getDeityListBySearch(userId, "", usertoken, value)
      .then((response: any) => {
        setDeitylist(response.data);
        setShowLoading(false);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        setShowLoading(false);
      });
  }

  const handleSearch = (e: any) => {
    clearTimeout(debounceTimeout);
    const value = e.target.value;
    setSearchTerm(value)
    if (value === "") {
      showalldeity()
    }
    else {
      debounceTimeout = setTimeout(() => {
        Getdatausingsearch(value)
      }, 1000);
    }
  };

  const modalClose = () => {
    setCommonmodal(false)
  }


  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <IonRow className={isUserSide ? "table_btn_container ion-justify-content-end" : "table_btn_container"}>
        {!isUserSide &&
          <div>
            <IonButton onClick={() => {
              back(); setIndex(1);
              setMenuItems((prev: any) =>
                prev.map((item: any) => ({
                  ...item,
                  isOpen: false,
                }))
              );
            }} className="admin_add_btn">
              <IonIcon src={addOutline} />
              <span>Add Deity</span>
            </IonButton>
          </div>
        }
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {!isUserSide &&
            <IonButton className="admin_add_btn" onClick={() => exportCSV(columns, deitylist, "Deity Details")}>
              Export
            </IonButton>
          }
          <div className="select-search-container">
            <div className="radio-search-container">
              {userId && <>
                <IonInput placeholder="Search..." style={{ textAlign: "left" }} value={searchTerm} onIonChange={handleSearch} className="search-box"></IonInput>
              </>
              }
            </div>
          </div>
        </div>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle, deitylist]);

  const [showModal, setShowModal] = useState(false)
  const imagenew = () => {
    setShowModal(true)
  }

  const handleButtonClick = () => {
    if (imageref.current) {
      imageref.current.click();
    }
  };

  const [fileName, setFileName] = useState("");
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")
  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }


  const handleBreadcrumbClick = (index: any) => {
    console.log(index, "index")
    setIndex(index);
  };

  return (
    <IonPage>
      {!isUserSide ? <AdminHead User={"user"} Role={"Role"} Name={"Deity Management"} /> :
        <HeadFoot User={"login"} Role="Role" Name='Home,Deities' />}
      {!isUserSide && <Sidebar />}
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error!"}
          message={errorMsg}
          buttons={["Ok"]}
        />
        <IonAlert
          isOpen={successAlert}
          onDidDismiss={() => setSuccessAlert(false)}
          cssClass="my-custom-class"
          header={"Success"}
          message={successMsg}
          onWillDismiss={() => {
            if (successMsg.startsWith("Image")) {
              setSuccessAlert(false)
            }
            else {
              showalldeity()
            }
          }}
          buttons={["Ok"]}
        />
        <div className={isUserSide ? "page_content_padding" : "container_middle_table page-wrapper admin_background_color"}>
          {isUserSide && <Breadcrumb pageNames={pageNames}
            currentPage={index}
            onBreadcrumbClick={handleBreadcrumbClick} />}
          {index === 0 ?
            <IonRow className={isUserSide ? "deity_container " : "data_table"} style={{ paddingTop: '10px' }}>
              <div style={{ width: "100%" }}>
                <CommonDataTable
                  columns={columns}
                  data={tableData}
                  loading={showLoading}
                  noDataMessage="Sorry, No data available"
                  onRowClick={clickRow}
                  isSubHeader={true}
                  subHeaderComponent={subHeaderComponent}
                />
              </div>
            </IonRow>
            : index === 1 ?
              <IonGrid className='tab_parent_div deity_container'>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: "space-between" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div onClick={() => { back(); setIndex(0) }} style={{ marginRight: '10px' }}>
                      <IonIcon icon={arrowBackOutline} size="large" style={{ cursor: "pointer" }} />
                    </div>
                    {isEdit &&
                      <IonText style={{ fontSize: '20px', fontWeight: '900' }}>
                        {deityName} [{deityCode}]
                      </IonText>
                    }
                  </div>
                  {isUserSide &&
                    <IonButton className="admin_add_btn" onClick={() => history.push({ pathname: "/offerings", state: { deityName: deityName } })} color="primary">
                      <span className="button_padding_v2">
                        Offer Pooja
                      </span>
                    </IonButton>
                  }
                </div>
                {menuItems.map((section: any, index: any) => {
                  var flag = true;
                  if (isUserSide && index === 0) {
                    flag = false;
                  }
                  if (flag) {
                    return (
                      <div key={index} className={section.isOpen === true ? 'temple_tab_container temple_active_tab_container' : 'temple_tab_container'}>

                        <div onClick={() => openContent(index, section.name)} className={section.isOpen === true ? 'temple_tab label_icon active_tab_container' : 'label_icon temple_tab'}>
                          <IonIcon src={chevronDownOutline} />
                          <IonLabel>{section.name}</IonLabel>
                        </div>
                        {section.isOpen &&
                          <div className='tab_content_container'>
                            {index === 0 ?
                              <IonGrid>
                                <IonRow className="admin_controller_menu_container">
                                  <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                    <IonCheckbox style={{ zIndex: 0 }} className="multiple_checkbox_style" checked={isActive} onIonChange={(e) => adminSelectionChange(e, "active")} />
                                    <IonLabel>isActive</IonLabel>
                                  </IonCol>
                                  <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                    <IonCheckbox className="multiple_checkbox_style" checked={isDeleted} onIonChange={(e) => adminSelectionChange(e, "delete")} />
                                    <IonLabel>isDelete</IonLabel>
                                  </IonCol>
                                  {modifiedBy !== "" &&
                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                      <IonLabel>Modified By :</IonLabel>
                                      <IonText>{modifiedBy}</IonText>
                                    </IonCol>
                                  }
                                  {modifiedOn !== "" &&
                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                      <IonLabel>Modified On :</IonLabel>
                                      <IonText>{modifiedOn}</IonText>
                                    </IonCol>
                                  }
                                </IonRow>
                              </IonGrid>
                              : index === 2 ?
                                <IonGrid>
                                  <IonRow>
                                    <textarea
                                      className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                      placeholder="Enter Introduction"
                                      disabled={isUserSide}
                                      value={introduction}
                                      onChange={(e) => setIntroduction(e.target.value)}
                                    />
                                  </IonRow>
                                </IonGrid>
                                : index === 1 ?
                                  <IonGrid>
                                    {!isHovered && < ><IonRow>
                                      <IonLabel className="input-label-editTem">Deity Name</IonLabel>
                                    </IonRow>
                                      <IonRow>
                                        <IonInput disabled={isUserSide} placeholder="Enter Deity Name" style={{ width: "100%" }} className="temple_inputs"
                                          value={deityName} onIonChange={(e) => setDeityName(e.detail.value!)} />
                                      </IonRow>
                                    </>}
                                    <IonRow style={{ marginTop: "5px" }}>
                                      <IonLabel className="input-label-editTem">Significance</IonLabel>
                                      <textarea
                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                        placeholder="Enter Introduction"
                                        disabled={isUserSide}
                                        value={significance}
                                        onChange={(e) => setSignificance(e.target.value)}
                                      />
                                    </IonRow>
                                  </IonGrid>
                                  : index === 3 ?
                                    <IonRow>
                                      <textarea
                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                        placeholder="Enter Divine Form"
                                        disabled={isUserSide}
                                        value={divineForm}
                                        onChange={(e) => setDivineForm(e.target.value)}
                                      />
                                    </IonRow>
                                    : index === 4 ?
                                      <IonRow>
                                        <textarea
                                          className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                          placeholder="Enter Tribal Traditions"
                                          disabled={isUserSide}
                                          value={tribalTraditions}
                                          onChange={(e) => setTribalTraditions(e.target.value)}
                                        />
                                      </IonRow>
                                      : index === 5 ?
                                        <IonRow>
                                          <textarea
                                            className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                            placeholder="Enter Cultural"
                                            disabled={isUserSide}
                                            value={cultural}
                                            onChange={(e) => setCultural(e.target.value)}
                                          />
                                        </IonRow>
                                        : index === 6 ?
                                          <IonRow>
                                            <textarea
                                              className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                              placeholder="Enter natural Connection"
                                              disabled={isUserSide}
                                              value={naturalConnection}
                                              onChange={(e) => setNaturalConnection(e.target.value)}
                                            />
                                          </IonRow>
                                          : index === 7 ?
                                            <IonRow>
                                              <textarea
                                                className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                placeholder="Enter Healing"
                                                disabled={isUserSide}
                                                value={healing}
                                                onChange={(e) => setHealing(e.target.value)}
                                              />
                                            </IonRow>
                                            : index === 8 ?
                                              <IonRow>
                                                <textarea
                                                  className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                  placeholder="Enter Sacred Rituals"
                                                  disabled={isUserSide}
                                                  value={sacredRituals}
                                                  onChange={(e) => setSacredRituals(e.target.value)}
                                                />
                                              </IonRow>
                                              : index === 9 ?
                                                <IonRow>
                                                  <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    placeholder="Enter Community Life"
                                                    disabled={isUserSide}
                                                    value={communityLife}
                                                    onChange={(e) => setCommunityLife(e.target.value)}
                                                  />
                                                </IonRow>
                                                : index === 10 ?
                                                  <IonRow>
                                                    <textarea
                                                      className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                      placeholder="Enter Modern Relevance"
                                                      disabled={isUserSide}
                                                      value={modernRelevance}
                                                      onChange={(e) => setModernRelevance(e.target.value)}
                                                    />
                                                  </IonRow>
                                                  : index === 11 ?
                                                    <IonRow>
                                                      <textarea
                                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                        placeholder="Enter Sacred Knowledge"
                                                        disabled={isUserSide}
                                                        value={sacredKnowledge}
                                                        onChange={(e) => setSacredKnowledge(e.target.value)}
                                                      />
                                                    </IonRow>
                                                    : index === 12 ?
                                                      <IonRow>
                                                        <textarea
                                                          className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                          placeholder="Enter Notes"
                                                          disabled={isUserSide}
                                                          value={notes}
                                                          onChange={(e) => setNotes(e.target.value)}
                                                        />
                                                      </IonRow>
                                                      : index === 14 ?
                                                        <IonGrid>
                                                          {!isUserSide && < IonRow >
                                                            <input
                                                              style={{ display: "none" }}
                                                              ref={imageinputref}
                                                              type="file"
                                                              name="photo"
                                                              onChange={(e) => onchangeImage(e)}
                                                              multiple={true}
                                                            />
                                                            <IonCol size="2">
                                                              <IonButton onClick={() => imageinputref.current?.click()} className="admin_img_btn image_content">
                                                                <span>Choose File</span>
                                                              </IonButton>
                                                            </IonCol>
                                                          </IonRow>}
                                                          {image ? image.map((item: any, i: number) => (
                                                            <IonCol sizeLg="4" size="4" key={i}>
                                                              <div style={{ display: "flex", justifyContent: "right" }}>
                                                                {!isUserSide &&
                                                                  <IonIcon
                                                                    src={closeCircle}
                                                                    tabIndex={0}
                                                                    size="small"
                                                                    style={{ cursor: "pointer", color: '#576F9F' }}
                                                                    onClick={() => removeImage(item, i)}
                                                                  />
                                                                }
                                                              </div>
                                                              <IonImg
                                                                src={item.image}
                                                                tabIndex={1}
                                                                className="img"
                                                                onClick={() => { /* Open modal to view larger image */ }}
                                                              />
                                                            </IonCol>
                                                          )) : "No Data Available"}
                                                        </IonGrid>
                                                        : <></>
                            }
                          </div>
                        }
                      </div>
                    )
                  }
                }
                )
                }
                {!isUserSide &&
                  <IonRow style={{ justifyContent: "flex-end", marginTop: "10px" }}>
                    <IonButton onClick={addUpdateDeity} className="admin_add_btn">
                      <span className="button_padding_v2">
                        Submit
                      </span>
                    </IonButton>
                    <IonButton onClick={() => { back(); setIndex(0) }} className="admin_cencel_btn" fill="outline">
                      <span className="button_padding_v2">
                        Cancel
                      </span>
                    </IonButton>
                  </IonRow>
                }
              </IonGrid> : <></>}
          {isUserSide && <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
          }
        </div>
        <Modal show={modalopen} className="off_info_modal" centered size={"xl"}>
          <Modal.Header>
            <IonGrid>
              <IonRow className="off_info_modal_header">
                <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
                  <IonText className="info_modal_offname">Information</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

              {/* Hide Table when Adding Review */}
              {!showReviewInput ? (
                <>
                  <CommonDataTable
                    columns={isUserSide ? modalColumn : modalColumnAdmin}
                    data={modalData}
                    loading={showLoading}
                    type="reviewTable"
                    noDataMessage="Sorry, No data available"
                  />

                  {/* Add Review & Cancel Buttons in Same Line */}
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", gap: "10px" }}>
                    <IonButton className="btn_v2 admin_add_btn" onClick={() => setShowReviewInput(true)}>
                      <span className="button_padding_v2">
                        Add Review
                      </span>
                    </IonButton>
                    <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setModalOpen(false)}>
                      <span className="button_padding_v2">
                        Cancel
                      </span>
                    </IonButton>
                  </div>
                </>
              ) : (
                /* Review Input Section */
                <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                  <textarea
                    placeholder="Write your review here..."
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    style={{
                      width: "80%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      fontSize: "14px",
                    }}
                  ></textarea>

                  {/* Submit & Back Buttons in Same Line */}
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
                    <IonButton className="btn_v2 admin_add_btn" onClick={handleSubmitReview}>
                      <span className="button_padding">  Submit Review </span>
                    </IonButton>
                    <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setShowReviewInput(false)}>
                      <span className="button_padding">  Back</span>
                    </IonButton>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </IonContent>
      {isUserSide && <Foot />}
    </IonPage >
  );
};
export default Deity;

function the_char() {
  throw new Error("Function not implemented.");
}
