// CommonDataTable.jsx
import React, { useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IonItem, IonLabel, IonInput, IonSelectOption, IonSelect, IonSearchbar } from '@ionic/react';
import customTableStyle from './CustomTableStyle';

const CommonDataTable = ({ columns, data, loading, noDataMessage, onRowClick,
    conditionalRowStyles, onSelectedRowsChange, selectableRows, selectableRowDisabled, selectableRowSelected,
    subHeaderComponent, isSubHeader, expandableRowsComponent, expandableRows, paginationComponent,
    pagination, keyfield, type, noDataComponent, expandedRows }: any) => {

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const customTableStyle1 = {
        ...customTableStyle,
        table: {
            style: {
                border: "0.5px solid #E5E3E3",
                minWidth: '1000px',
                maxHeight: "400px"
            },
        },
        headRow: {
            style: {
                borderWidth: "0px",
                backgroundColor: "#576F9F",
                minHeight: "50px"
            },
        },
        tableWrapper: {
            style: {
                overflowX: 'auto', // Enable horizontal scrolling
            },
        },

        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "15px",
                paddingLeft: '10px',
                paddingRight: '10px',
                border: "none",
                justifyContent: "left",
                textalign: "left",
                // backgroundColor: "#dbdbd"
                backgroundColor: "#576F9F",
                color: "#fff"

            },
        },
        rows: {
            style: {
                borderWidth: "0.5px #E5E3E3",
                padding: "5px",
                minHeight: "20px"
            }
        },
        cells: {
            style: {
                fontSize: "16px",
                justifyContent: "left",
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingTop: '0px',
                paddingBottom: '0px',
                border: "none",
                textAlign: "left"
            }
        },
    }


    return (
        <div style={{ width: "100%", }}>
            <div style={{ width: "100%" }} className={type === "reviewTable" ? "" : 'common_dataTable'}>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination={pagination}
                    paginationComponent={paginationComponent}
                    highlightOnHover
                    striped
                    noDataComponent={noDataComponent}
                    onRowClicked={(item: any, i: any) => { onRowClick(item, i); console.log(onRowClick, "onRowClick") }}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 20, 50, 75, 100]}
                    // onChangeRowsPerPage={(newRowsPerPage, currentPage) => {
                    //     fetchData(newRowsPerPage);
                    // }}
                    selectableRows={selectableRows}
                    conditionalRowStyles={conditionalRowStyles}
                    customStyles={customTableStyle1}
                    onSelectedRowsChange={onSelectedRowsChange}
                    selectableRowDisabled={selectableRowDisabled}
                    selectableRowSelected={selectableRowSelected}
                    subHeader={isSubHeader}
                    subHeaderComponent={subHeaderComponent}
                    expandableRowsComponent={expandableRowsComponent}
                    expandableRows={expandableRows}
                    keyField={keyfield}
                    expandableRowExpanded={expandedRows} // Control expanded state
                />
            </div>
        </div>
    );
};

export default CommonDataTable;
