import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import "./PrivacyPolicy.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const PrivacyPolicy: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Privacy Notice"} />
        <IonRow className="page_content_padding">
          <IonCol>
            <div style={{ margin: "2% 4%" }}>
              <div>
                <p style={{ textAlign: "justify" }}>
                  Users need to know how their information is being used and your trust in us and our use is well appreciated. This Privacy Notice describes how Atmagram.com and its affiliate Sree Sankara Peetham Charitable Trust (SSP) collect and process your personal information through our websites.
                </p>
                <p style={{ textAlign: "justify" }}>
                  By using our websites, you agree to our use of your personal information in accordance with this Privacy Notice, as may be amended from time to time by us at our discretion. You also agree to our collecting, processing, storing, transferring and sharing your personal information with third parties or service providers for the purposes set out in this Privacy Notice.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We collect your personal information in order to provide and continually improve our services.  We automatically collect and store certain information which include information about your interaction with content and services available. Like other websites, we use cookies and other unique identifiers, and we obtain certain types of information when your browser or device accesses our services and other content served. We might receive information about you from other sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We use your personal information to operate, provide, develop and improve the products and services that we offer our customers. We use your personal information to take and fulfil orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.  We use your personal information to provide functionality, analyse performance, fix errors, and improve the usability and effectiveness of our services. In certain cases, we collect and use your personal information to comply with laws. For instance, we collect from sellers’ information regarding place of establishment and bank account information for identity verification and other purposes. We use your personal information to communicate with you via different channels (e.g., by phone, e-mail, chat). We use personal information to prevent and detect fraud and abuse in order to protect the security of our customers and others.
                </p>
                <p style={{ textAlign: "justify" }}>
                  To enable our systems to recognize your browser or device and to provide and improve our services, we use cookies and other identifiers.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Information about our customers is an important part of our business and we are not in the business of selling our customers’ personal information to others. We share customers’ personal information only as described below and with Sree Sankara Peetham Charitable Trust and subsidiaries that Sree Sankara Peetham Charitable Trust controls that either are subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We make available to you services, products, applications, or skills provided by third parties for use on or through our services. For example, the products you order and the services offered through our marketplace are from third parties and we share customers’ personal information related to all those transactions with that third party.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We employ other companies and individuals to perform functions on our behalf including fulfilling orders for products or services, delivering packages, processing payments, transmitting content, scoring assessing and managing credit risk, and providing customer service. These third party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with applicable law.
                </p>
                <p style={{ textAlign: "justify" }}>
                  As we continue to develop our operations, we might sell or buy or other businesses or services. In such transactions, customer information generally is one of the transferred business assets but remain subject to the promises made in any pre-existing Privacy Notice (unless, of course, the customer consents otherwise). Also, in an unlikely event that Sree Sankara Peetham Charitable Trust or any of its affiliates, or substantially all of their assets is acquired, customer information will of course be one of the transferred assets.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; to protect the rights, property, of safety Atmagram, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Other than as set out above, you will receive notice when personal information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We design our systems with your security and privacy in mind.
                </p>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    We work to protect the security of your personal information during transmission by using encryption protocols and software.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling payment card data.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, processing, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Our devices offer security features to protect them against unauthorized access and loss of data. You can control these features and configure them based on your needs.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    It is important for you to protect against unauthorized access to your password and to your computers, devices and applications. Be sure to sign off when finished using a shared computer.
                  </li>
                </ul>
                <p style={{ textAlign: "justify" }}>
                  You can access your information, including your name, address, payment options, profile information, and purchase history in the "Your Account" section of the website or mobile application. You can always choose not to provide certain information, but then you might not be able to take advantage of many of our services.
                </p>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;