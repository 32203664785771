import React, { useContext, useEffect, useRef, useState } from 'react'
import { IonRow, IonCol, IonGrid, IonIcon, IonPage, IonItemDivider, IonContent, IonText, IonLoading, IonButton, IonTextarea, IonLabel, IonTitle, IonImg } from '@ionic/react'
import "./sam-temp.css";
import Slider from "react-slick";
import "./project.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { timeOutline, heart, informationCircleSharp, arrowBack, arrowForward, idCard, shareSocialOutline, arrowForwardCircleOutline, arrowBackCircleOutline, close, } from "ionicons/icons";
import Foot from './Foot';
import UserHead from './UserHead';
import Authservice from "../Services/user.service";
import { IonProgressBar } from '@ionic/react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { AppContext } from '../components/AppContext';
import UserService from '../Services/auth.service';
import HeadFoot from './Head';
import { clock1, closeicon, heart2, infoicon, leftArrow, rightArrow, shareIcon } from '../theme/imagePath';


const Project: React.FC = (props) => {


    const [index, setIndex] = useState(0)
    const params: any = useParams()
    const [data, setData]: any = useState([]);
    const [proLoad, setProLoad]: any = useState(false);
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const [usertoken]: any = useState(sessionStorage.getItem("token"));
    const [showLoading, setShowLoading] = useState(false);
    const [modalopen1, setModalopen1] = useState(false);
    const [purpose, setPurpose] = useState("");
    const [proTitle, setTitle] = useState("");
    const history = useHistory();
    const location: any = useLocation()
    const date: any = new Date()
    const tdy: any = moment(date).format("DD-MM-YYYY")
    const widthSize = window.innerWidth
    const [slidesToShow, setSlidesToShow]: any = useState(widthSize > 1000 ? 2 : 1);
    const formatter = new Intl.NumberFormat('en-IN')
    const [shareLink, setShareLink]: any = useState('');
    const projId = params.id;
    const [load, setLoad]: any = useState(false);


    const NextArrow = (props: any) => {
        const { className, onClick } = props;
        const currentSlide = props.currentSlide
        const slideCount = props.slideCount
        return (
            <div className="slick_nxt_project" onClick={onClick} style={{ display: currentSlide === slideCount - slidesToShow ? 'none' : 'block' }}>
                <IonIcon className='arrow-forward-back' color='secondary' src={arrowForwardCircleOutline} />
            </div>
        );
    };

    const PrevArrow = (props: any) => {
        const { className, onClick } = props;
        const currentSlide = props.currentSlide
        return (
            <div className="slick_prev_project" onClick={onClick} style={{ display: currentSlide === 0 ? 'none' : 'block' }}>
                <IonIcon className='arrow-forward-back' color='secondary' src={arrowBackCircleOutline} />
            </div>
        );
    };

    const uFlag: any = useState(sessionStorage.getItem("uFlag"))

    useEffect(() => {
        if (userdata[0] !== null && userdata[0] !== "ROLE_USER") {
            history.push("/home")
        }
        else if (userdata[0] !== null && uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            if (!load) {
                showProject();
                setLoad(true)
            }
        }
    }, []);

    const showProject = () => {
        setShowLoading(true);
        setData([])
        Authservice.ShowAllProjectUser()
            .then((response) => {
                console.log(response.data);
                if (response.data !== undefined) {
                    if (projId) {
                        setData([])
                        for (let i = 0; i < response.data.length; i++) {
                            const st: any = response.data[i].startDate.split("-")
                            const start = st[2] + "-" + st[1] + "-" + st[0]
                            const startDate: any = new Date(start)
                            if (startDate <= date && projId == response.data[i].id) {
                                setData((e: any) => [
                                    ...e, response.data[i]
                                ])
                            }
                        }

                    } else {
                        setData([])
                        for (let i = 0; i < response.data.length; i++) {
                            const st: any = response.data[i].startDate.split("-")
                            const start = st[2] + "-" + st[1] + "-" + st[0]
                            const startDate: any = new Date(start)
                            if (startDate <= date) {
                                setData((e: any) => [
                                    ...e, response.data[i]
                                ])
                            }
                        }
                    }
                } else {
                    setData([])
                }
                setProLoad(true)
                setShowLoading(false);
            })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const ProjectdonationView = (item: any) => {
        history.push({
            pathname: "/projectdonationuser",
            state: { item }
        })
    }
    var Settings = {}
    if (data.length >= 2) {
        Settings = {
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        }
    }
    else if (data.length < 2) {
        Settings = {
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        }
    }

    const View = (item: any) => {
        setPurpose(item.purpose);
        setTitle(item.projectTitle)
        setModalopen1(true);
    }

    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen1(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const userprojectmodal = useRef(null);

    useOutsideAlerter(userprojectmodal);
    const [sharemodal, setSharemodal] = useState(false)
    const { shareurl }: any = useContext(AppContext)!;

    const share = async (item: any) => {
        setShareLink(shareurl + "/" + item.id);

        if (Capacitor.getPlatform() === "web") {
            setSharemodal(true)
        }
        else {
            await Share.share({
                title: 'Atmagram',
                url: shareLink,
                dialogTitle: 'Share with buddies',
            });
        }
    }

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"projects"} Role={'Role'} Name={"Home,Projects"} />
                <div className="page_content_padding project_container">
                    <Modal show={sharemodal} className="donation-modal" centered>
                        <Modal.Body>
                            <div className="donation-modal-header">
                                <h5>Share</h5>
                                <div>
                                    <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setSharemodal(false)} />
                                </div>
                            </div>
                            <div className='donation-modal-content'>
                                <IonGrid>
                                    <IonRow style={{ textAlign: "center" }}>
                                        <IonCol size-lg='2.4' size-md='2.4' size-xs='4'>
                                            <FacebookShareButton url={shareLink} >
                                                <FacebookIcon round={true} size={40} />
                                            </FacebookShareButton>
                                        </IonCol>
                                        <IonCol size-lg='2.4' size-md='2.4' size-xs='4'>
                                            <EmailShareButton url={shareLink}>
                                                <EmailIcon round={true} size={40} />
                                            </EmailShareButton>
                                        </IonCol>
                                        <IonCol size-lg='2.4' size-md='2.4' size-xs='4'>
                                            <WhatsappShareButton url={shareLink}>
                                                <WhatsappIcon round={true} size={40} />
                                            </WhatsappShareButton>
                                        </IonCol>
                                        <IonCol size-lg='2.4' size-md='2.4' size-xs='4'>
                                            <LinkedinShareButton url={shareLink}>
                                                <LinkedinIcon round={true} size={40} />
                                            </LinkedinShareButton>
                                        </IonCol>
                                        <IonCol size-lg='2.4' size-md='2.4' size-xs='4'>
                                            <TwitterShareButton url={shareLink}>
                                                <TwitterIcon round={true} size={40} />
                                            </TwitterShareButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div>
                        <Modal show={modalopen1} className="donation-modal" centered>
                            <Modal.Body ref={userprojectmodal}>
                                <div className="donation-modal-header project-modal-header">
                                    <h5>{proTitle}</h5>
                                    <div>
                                        <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                                    </div>
                                </div>
                                <div className='donation-modal-content'>
                                    <IonText>{purpose}</IonText>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <IonGrid>
                            <IonRow>
                                {data.length == 0 && proLoad ? <IonCol className='no-materials'>
                                    <IonText>Sorry, No Projects available</IonText>
                                </IonCol> :
                                    <IonCol size='12'>
                                        <div className='project-slider-container'>
                                            <h5 className='page-title'>Projects</h5>
                                            <div className='slider-element'>
                                                <Slider {...Settings}>
                                                    {
                                                        data.map((item: any, i: any) => (
                                                            <div className='project_cards'>
                                                                <div className='card-header'>
                                                                    <p className='card-title'>{item.projectTitle}</p>
                                                                    <IonIcon src={informationCircleSharp} className='info_icon' onClick={() => View(item)} />
                                                                </div>
                                                                <div className='card-content'>
                                                                    <p>{item.purpose}</p>
                                                                </div>
                                                                <div className='progressNcost'>
                                                                    <div>
                                                                        <IonProgressBar type="determinate" value={0.2} className="card-progressBar"></IonProgressBar>
                                                                    </div>
                                                                    <div>
                                                                        {item.totalAmountCollected === null ?
                                                                            <p className='project-rupees'>Yet To Be Donated</p>
                                                                            :
                                                                            <p className='project-rupees'>Rs.{formatter.format(item.totalAmountCollected)} Collected</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <IonRow>
                                                                        <IonCol sizeLg='6' sizeMd='6' sizeSm='6' sizeXs='12'>
                                                                            <IonRow>
                                                                                <IonCol size='6' className='card-icons-parent'>
                                                                                    <IonImg src={clock1} className='card-icons' />
                                                                                    <p className='days-likes'>{item.noOfDaysLeft} days Left</p>
                                                                                </IonCol>
                                                                                <IonCol size='6' className='card-icons-parent'>
                                                                                    <IonImg src={heart2} className='card-icons' />
                                                                                    <p className='days-likes'>{item.totalSupporters} supporters</p>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonCol>
                                                                        <IonCol sizeLg='6' sizeMd='6' sizeSm='6' sizeXs='12' className='donate-share'>
                                                                            <IonRow >
                                                                                <IonCol sizeLg='9' sizeMd='9' sizeSm='9' sizeXs='6' className='card-icons-parent'>
                                                                                    <IonButton className='donate-btn' onClick={() => ProjectdonationView(item)}><span className='dontion-btn-txt'>Donate</span></IonButton>
                                                                                </IonCol>
                                                                                <IonCol sizeLg='3' sizeMd='3' sizeSm='3' sizeXs='6' className='card-icons-parent shareIcon-mobile'>
                                                                                    <div onClick={() => share(item)} className='shareIcon-element'>
                                                                                        {/* <IonImg src={shareIcon} className='share-icon-project' /> */}
                                                                                        <IonIcon src={shareSocialOutline} className='share-icon-project' />
                                                                                    </div>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonCol>

                                                                    </IonRow>
                                                                </div>
                                                                {/* <div>
                                                            
                                                                <h5>{item.projectTitle}</h5>
                                                                <div>
                                                                    <p>{item.purpose}</p>
                                                                    <IonIcon src={informationCircleSharp} onClick={() => View(item)} />
                                                                </div>

                                                                <div style={{ display: "flex", justifyContent: "center", padding: "4% 0% 0% 0%" }}>
                                                                    <IonProgressBar type="determinate" value={0.2} style={{ height: "12px", width: "150px", border: "1px solid #89B4C2", borderRadius: "5px" }} className="progress"></IonProgressBar>
                                                                </div>
                                                                <div style={{ height: "30px" }}>
                                                                    {item.totalAmountCollected === null ?
                                                                        <p style={{ fontWeight: "bold", padding: "10px" }}>Yet To Be Donated</p>
                                                                        :
                                                                        <p style={{ fontWeight: "bold", padding: "10px" }}>Rs.{formatter.format(item.totalAmountCollected)} Collected</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <IonRow>
                                                                <IonCol size-lg='6' size-md="6" size-sm="6" size-xs="12" className='status-ship' style={{ display: "flex", padding: "3% 4%", alignItems: "center", justifyContent: "center" }}>
                                                                    <IonIcon src={timeOutline} size="large" />
                                                                    <IonText style={{ paddingLeft: "5%" }}>{item.noOfDaysLeft} days Left</IonText>
                                                                </IonCol>
                                                                <IonCol size='6' size-lg='6' size-md="6" size-sm="6" size-xs="12" className='status-ship' style={{ display: "flex", padding: "3% 4%", alignItems: "center", justifyContent: "center" }}>
                                                                    <IonIcon src={heart} size="large" style={{ color: "red" }} />
                                                                    <IonText style={{ paddingLeft: "5%" }} >{item.totalSupporters} supporters</IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow style={{ borderTop: "1px solid", marginTop: "2%" }}>
                                                                <IonCol onClick={() => share(item)} size='6' style={{ borderRight: "1px solid", padding: "3% 0%", cursor: "pointer" }}>
                                                                    <IonButton expand='block' fill='clear'>
                                                                        <IonText style={{ fontWeight: "bold" }}>Share</IonText>
                                                                    </IonButton>
                                                                </IonCol>
                                                                <IonCol onClick={() => ProjectdonationView(item)} size='6' style={{ textAlign: "center", padding: "3% 0%", cursor: "pointer" }}>
                                                                    <IonButton expand='block' fill='clear'>
                                                                        <IonText style={{ fontWeight: "bold" }}>DONATE</IonText>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow> */}
                                                            </div>
                                                        ))}
                                                </Slider>

                                            </div>
                                        </div>
                                    </IonCol>
                                }
                            </IonRow>
                        </IonGrid>
                    </div>
                    <IonLoading message="Loading..." spinner={null}
                        isOpen={showLoading}
                    // onDidDismiss={() => setShowLoading(false)}

                    // duration={5000}
                    />
                </div>


                <Foot />
            </IonContent>
        </IonPage>

    )
}
export default Project;