import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const Peetam: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Replacement Policy"} />
        <IonRow className="page_content_padding">
          <IonCol className="">
        <div style={{ margin: '2% 4%' }}>
          <div>
            <p > Fulfilled by Atmagram items, and few seller items in Grammerce can be replaced at no extra cost through our portal provided the following conditions are met.</p>
          </div>
             
              <div style={{paddingTop:"10px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>Which items are eligible for a Free Replacement?</h6>
              </div>
              <div style={{paddingTop:"5px"}}>
                <p className="policy_text">Fulfilled by Atmagram items and few seller items in Grammerce are eligible for free replacements. If an eligible item is out of stock from the same seller, it cannot be replaced. Only a refund against the returned item will be issued.</p>
                
              
              </div>
              <div style={{paddingTop:"10px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>What are the conditions for Free Replacement?</h6>
              </div>
              <div style={{paddingTop:"5px"}}>
                <p className="policy_text">Items within return window and in stock (exact same item) with same seller are eligible for free replacement. The free replacement order will be shipped through standard shipping once the original order is returned. Free replacements can be requested if the following conditions apply:</p>
                <p className="policy_text" style={{paddingTop:"10px"}}>{"\u2022"} &emsp;  Item received is physically damaged;</p>
                <p className="policy_text">{"\u2022"} &emsp;   Item received has missing parts or accessories;</p>
                <p className="policy_text">{"\u2022"}  &emsp; Item received is different from their description on the product detail page on Atmagram.com; or</p>
                <p className="policy_text">{"\u2022"} &emsp;  Item received is defective/does not work properly.</p>
              
              </div>
              <div style={{paddingTop:"10px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>Note:</h6>
              </div>
              <div style={{paddingTop:"5px"}}>
                <p className="policy_text" style={{paddingTop:"10px"}}>{"\u2022"} &emsp;  A free replacement cannot be created for an item which was returned and replaced once earlier.</p>
                <p className="policy_text">{"\u2022"} &emsp;  If your item is not eligible for free replacement due to any reason, you can always return it for a refund.</p>
               
              
              </div>
        </div>
        </IonCol></IonRow>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Peetam;