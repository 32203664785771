import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonText, useIonAlert } from "@ionic/react";
import HeadFoot from "../Head";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import "./report.css"
import UserService from "../../Services/auth.service";
import Authservice from "../../Services/user.service";
import { saveAs } from 'file-saver';
import { addOutline, text } from "ionicons/icons";
import { AppContext } from "../../components/AppContext";
import CommonDataTable from "../../components/datatable";
import DataTable from "react-data-table-component";
import TawkToWidget from "./Donationmanage";
import { Modal } from "react-bootstrap";
import CommonModal from "../../components/popipInfo";
import moment from "moment";

const RefundReport: React.FC = () => {


    const userRole = sessionStorage.getItem("UserData")
    const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
    const [userId]: any = useState(sessionStorage.getItem("UserId"))


    const [Alert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [filterText, setFilterText] = useState("");
    const { exportCSV } = useContext(AppContext)!
    const [selectedRowIds, setSelectedRowIds]: any = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ref1, setRef1] = useState("")
    const [ref2, setRef2] = useState("")
    const [refDate, setRefDate] = useState("")
    const [remarks, setRemarks] = useState("")
    const [rowData, setRowData]: any = useState({})
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")


    useEffect(() => {
        setShowLoading(true);
        const getCurrentDateTime = () => {
            const now = new Date();
            return now.getFullYear() +
                '-' + String(now.getMonth() + 1).padStart(2, '0') +
                '-' + String(now.getDate()).padStart(2, '0') +
                ' ' + String(now.getHours()).padStart(2, '0') +
                ':' + String(now.getMinutes()).padStart(2, '0') +
                ':' + String(now.getSeconds()).padStart(2, '0');
        };
        const date = getCurrentDateTime();
        console.log("date", date)
        UserService.getFundForRefund(usertoken, userId, date).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                setFilteredData(response.data)
                setShowLoading(false);
            }
        }).catch((error: any) => {
            setShowLoading(false);
            console.log("error", error)
        })
    }, []
    )


    const tableData = filteredData.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );







    // Header columns
    const headerColumns = [
        {
            name: 'Temple Code',
            selector: (row: any) => row.templeCode,
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.templeCode}</div>
                    </div>
                )
            }
        },
        {
            name: 'Booking Order Number',
            selector: (row: any) => row.bookingOrderNumber,
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.bookingOrderNumber}</div>
                    </div>
                )
            }
        },

        {
            name: 'Name',
            selector: (row: any) => row.name || 'N/A',
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.name}</div>
                    </div>
                )
            }
        },
        {
            name: 'Remarks',
            selector: (row: any) => row.refundRemarks || 'N/A',
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.refundRemarks}</div>
                    </div>
                )
            },
            width: "300px"
        },
        {
            name: 'Total Amount',
            selector: (row: any) => row.fundAmount,
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.fundAmount}</div>
                    </div>
                )
            }
        },
        {
            name: 'Refund Amount',
            selector: (row: any) => row.refundAmount,
            sortable: true,
            cell: (item: any, i: any) => {
                return (
                    <div >
                        <div style={{ cursor: "pointer" }} >{item.refundAmount}</div>
                    </div>
                )
            }
        },
    ];

    // Detail columns
    // Expandable row component

    const subHeaderComponent = useMemo(() => {
        return (
            <IonRow className="table_btn_container">
                <div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <IonButton className="admin_add_btn" onClick={() => exportCSV(headerColumns, tableData, "Fund Report")}>
                        Export
                    </IonButton>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, filteredData]);

    const handleRowSelect = (item: any) => {
        setRowData(item)
        setShowModal(true)
    };

    const handleInputChange = (e: any, from: any) => {
        const value = e.target.value
        if (from === 0) {
            setRefDate(value)
        }
        else if (from === 1) {
            setRef1(value)
        }
        else if (from === 2) {
            setRef2(value)
        } else {
            setRemarks(value)
        }
    }

    const confirmRefund = () => {
        if (!refDate) {
            alert('Please select a Date.');
            return;
        }

        const format = moment(refDate).format("YYYY-MM-DD HH:mm:ss"); // Adjust locale as needed
        const obj = {
            orderNo: rowData.bookingOrderNumber,
            bookingNo: rowData.bookingDetailsNumber,
            refundRemark: remarks,
            refundOn: format,
            refundRef1: ref1,
            refundRef2: ref2,
        }
        console.log(format)
        Authservice.SubmitRefund(usertoken, userId, usertoken, obj).then((response: any) => {
            console.log(response.data);
            if (response.data === "success") {
                setShowModal(false);
                setCommonmodaltitle("Information")
                setCommonModalMesg("Updated Successfully")
                setCommonmodal(true)
            }
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });

    }
    const modalClose = () => {
        window.location.reload()
    }
    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Fund for Refund"} />
                <Sidebar /></>}
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Fund Disbursed' />}
                <IonGrid className={userRole === "ROLE_ADMIN" ? "page-wrapper report_container_middle" : "page_content_padding tableContainev2"}>
                    <CommonDataTable
                        columns={headerColumns}
                        data={tableData}
                        defaultSortFieldId="name"
                        striped
                        noDataComponent="Sorry ! No result found"
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                        onRowClick={handleRowSelect}
                        selectableRows={false} />
                </IonGrid>
            </IonContent>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="image-upload-modal"
                className="expandable-row-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="image-upload-modal">Refund</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <label className="form-label">Refund Date  <span style={{ color: "red" }}>*</span></label>
                                <IonInput type="date" value={refDate} onIonChange={(e: any) => handleInputChange(e, 0)} className="form-input" placeholder="Select refund date" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeMd="6" size="12">
                                <label className="form-label">Refund Reference 1</label>
                                <IonInput type="text" value={ref1} className="form-input" onIonChange={(e: any) => handleInputChange(e, 1)} placeholder="Enter reference 1" />
                            </IonCol>
                            <IonCol sizeMd="6" size="12">
                                <label className="form-label">Refund Reference 2</label>
                                <IonInput type="text" value={ref2} className="form-input" onIonChange={(e: any) => handleInputChange(e, 2)} placeholder="Enter reference 2" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <label className="form-label">Remarks</label>
                                <IonInput type="text" value={remarks} className="form-input" onIonChange={(e: any) => handleInputChange(e, 3)} placeholder="Enter Remarks" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" style={{
                                textAlign: "center"
                            }}>
                                < IonButton className="btn_v2" onClick={confirmRefund}> <span className="button_padding_v2">Confirm</span></IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </Modal.Body>
            </Modal >
            <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
        </IonPage >
    )

}
export default RefundReport;