import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonImg,
    IonCheckbox,
    IonModal,
    IonTextarea,
    IonGrid,
    IonText,
    useIonAlert,
    IonLoading,
    IonSearchbar,
    IonAlert,
    IonProgressBar,
    IonBadge,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import React, { useCallback, useContext, useEffect, useRef, useState, } from "react";
import Slider from "react-slick";
import useRazorpay from "react-razorpay";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./Grammerce.css"
import "./project.css"
import "./sam-temp.css";
import { arrowBack, arrowForward, informationCircleOutline, caretBack, caretForward, add, remove, close, closeOutline, removeOutline, addOutline, informationCircle, gift, chevronForward, chevronBack, mapOutline, locate, locationOutline, checkmarkCircleOutline, checkmarkCircleSharp, cart, cartOutline, heartOutline, notificationsOutline } from "ionicons/icons";
import UserHead from "./UserHead";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import { useHistory, useLocation } from "react-router";
import { Capacitor } from "@capacitor/core";
import { useForm } from "react-hook-form";
import UserService from "../Services/auth.service";
import validator from 'validator'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from "moment";
import HeadFoot from "./Head";
import { closeicon, location1, temple } from "../theme/imagePath";
import { tick } from "../theme/imagePath";
import { Link } from "react-router-dom";
import { PhoneAuthProvider, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import Lightbox from "react-image-lightbox";
import CommonModal from "../components/popipInfo";
import Breadcrumb from "../components/Breadcrumb";


const Localmarket: React.FC = () => {

    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uflag: any = useState(sessionStorage.getItem("uFlag"))
    const history = useHistory();
    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [data, setData]: any = useState([]);
    const [searchData, setSearchData]: any = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [index, setIndex] = useState(0);
    const [searchQuery, setSearchQuery]: any = useState("");
    const [hoverItem, setHoverItem]: any = useState("")
    const [userId, setUserId]: any = useState(sessionStorage.getItem("UserId"));
    const [selectedProduct, setSelectedProduct] = useState<any>(null); // To store selected product details
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [mailVerifyFlag, setMailVerifyFlag] = useState(true)


    useEffect(() => {
        if (!userId) {
            getMessagesByCategory()
        } else {
            Authservice.getAppConfigs(usertoken, userId, "").then((res: any) => {
                if (res.data !== "" && res.data !== null) {
                    const isEmailVerified = res.data.some((item: any) => item.name === "EmailVerification" && item.value !== "False");
                    if (isEmailVerified) {
                        checkEmailVerify();
                        return
                    } else {
                        fetchDetails();
                        getMessagesByCategory();
                    }
                    console.log(res.data, "data")
                    setShowLoading(false)
                }
            })
                .catch((error: any) => {
                    console.log(error, "error")
                    setShowLoading(false)
                })
        }
    }, [])

    const checkEmailVerify = () => {
        Authservice.isEmailVerified(userId, null)
            .then((response) => {
                if (response.data === true) {
                    fetchDetails()
                    getMessagesByCategory()
                } else {
                    setCommonModalMesg("Your email has not been verified. Please verify it to proceed further.")
                    setCommonmodal(true)
                    setCommonmodaltitle("Information")
                    setMailVerifyFlag(false)
                }
            })
            .catch((error) => {
                setShowLoading(false)
            });
    }


    const getMessagesByCategory = () => {
        Authservice.getMessagesByCategory(userId, usertoken, usertoken, !userId ? "Market" : "LocalMarket").then((res: any) => {
            if (res.data !== "" && res.data !== null) {
                setCommonModalMesg(res.data)
                setCommonmodaltitle("Information")
                setCommonmodal(true)
                setShowLoading(false)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }



    const fetchDetails = async () => {
        Authservice.getAllProductsListAdm(userId, usertoken, usertoken).then((response) => {
            console.log(response.data)
            setData(response.data)
        })
            .catch((error: any) => {
                console.log(error + "mobErr")
            });

    };

    const handleViewProduct = (productId: string) => {
        Authservice.getProductByProductCode(userId, usertoken, usertoken, productId).then((response) => {
            console.log(response.data)
            setSelectedProduct(response.data)
        })
            .catch((error: any) => {
                console.log(error + "mobErr")
            });
    };


    const modalClose = () => {
        if (!userId) {
            history.push("/login")
        }
        else if (!mailVerifyFlag) {
            history.push("/profile")
        } else {
            setCommonmodal(false)
        }
    }
    const pageNames = ["Local Market"];


    return (
        <IonPage>
            <HeadFoot User={"login"} Role="Role" Name={"Home,Local Market"} />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Breadcrumb pageNames={pageNames}
                    currentPage={index}
                />
                <div className="product-grid flex flex-wrap justify-center">

                    {data.length === 0 ? (
                        <div className="no-data-message">
                            <p>No Data Available</p>
                        </div>
                    ) : (
                        data.map((product: any) => (
                            <IonCard key={product.id} className="product-card">
                                <div className="product-image-container">
                                    <img
                                        src={product.productGalleryDtos[0]?.imageUrl}
                                        alt={product.productDesc}
                                        className="product-image"
                                    />
                                </div>

                                <div className="product-content">
                                    <h3 className="product-title">
                                        {product.productDesc}
                                    </h3>

                                    <p className="product-details">
                                        {product.productDetail}
                                    </p>

                                    <div className="product-footer">
                                        <span className="product-price">
                                            ₹{product.price.toFixed(2)}
                                        </span>
                                        <IonButton className="btn_v2" onClick={() => handleViewProduct(product.productCode)}>
                                            <span className="button_padding">View</span>
                                        </IonButton>
                                    </div>
                                </div>
                                <div style={{ padding: "0px 10px" }}>
                                    <IonText style={{ fontSize: "15px" }}>No of visitors  :  {product.clickCount}</IonText>
                                </div>
                            </IonCard>
                        ))
                    )}
                </div>

            </IonContent>
            <Modal show={selectedProduct !== null} onHide={() => setSelectedProduct(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="product-modal-container">
                        <div className="product-modal-image">
                            <img
                                src={selectedProduct?.productGalleryDtos[0]?.imageUrl || temple} //selectedProduct?.productGalleryDtos[0]?.imageUrl || 
                                alt={selectedProduct?.productDesc}
                                className="product-modal-img"
                            />
                        </div>
                        <div className="product-modal-details">
                            <h4>{selectedProduct?.productDesc}</h4>
                            <p><strong>Product Code:</strong> {selectedProduct?.productCode}</p>
                            <p><strong>Price:</strong> ₹{selectedProduct?.price.toFixed(2)}</p>
                            <p><strong>Valid Till:</strong> {new Date(selectedProduct?.validTill).toLocaleDateString()}</p>
                            {/* Vendor Details */}
                            <p><strong>Vendor Name:</strong> {selectedProduct?.vendorDetails?.vendorName}</p>
                            <p><strong>Vendor Email:</strong> {selectedProduct?.vendorDetails?.email}</p>
                            <p><strong>Vendor Mobile:</strong> {selectedProduct?.vendorDetails?.mobileNumber}</p>
                            <p><strong>Vendor Address:</strong> {selectedProduct?.vendorDetails?.address1}, {selectedProduct?.vendorDetails?.city}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IonButton className="btn_v2" onClick={() => setSelectedProduct(null)}>
                        <span className="button_padding">Close</span>
                    </IonButton>
                </Modal.Footer>
            </Modal>
            <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            <Foot />

        </IonPage>
    );
};

export default Localmarket;