import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonText,
    IonLabel,
    IonInput,
    IonLoading,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    useIonAlert,
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, close } from "ionicons/icons";
import DataTable from "react-data-table-component"

import "./Deitymanage.css";
import { useHistory } from "react-router";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import Authservice from "../../Services/user.service";
import Select, { components } from 'react-select';
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";

const Projectdonationadmin: React.FC = () => {
    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [amt, setAmt]: any = useState();
    const [date, setDate]: any = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState("");
    const [addr, setAddr] = useState();
    const [title, setTitle] = useState();
    const [purpose, setPurpose] = useState();
    const [remarks, setRemarks] = useState("")
    const [taramt, setTaramt]: any = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [projAdm, setProjAdm] = useState("");
    const [projAdmList, setProjAdmList]: any = useState([]);
    const dataFetchedRef = useRef(false);

    const [presentAlert] = useIonAlert();

    const formatter = new Intl.NumberFormat('en-IN')

    const [isSearch, setIsSearch] = useState(false);

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            width: "90px",
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Donation Number",
            sortable: true,
            selector: (item: any) => item.donationNumber,
            // width: "200px",
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.donationNumber}</div>
                </div>
            )
        },
        {
            name: "Donor Name",
            selector: (item: any) => item.donorName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.donorName}</div>
                </div>
            )
        },

        {
            name: "Donation Date",
            selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.localOrderDate}</div>
                </div>
            )
        },

        {
            name: "Contributed Amount (₹)",
            selector: (item: any) => parseInt(item.contributionAmount, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{formatter.format(item.contributionAmount)}</div>
                </div>
            )
        },
        {
            name: "Purpose",
            selector: (item: any) => item.projectModel.purpose,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} className="two-line-text" >{item.projectModel.purpose}</div>
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,

        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={() => viewdetails(item)} style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // }
    ]
    const {
        handleSubmit,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const history = useHistory();
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const [donationlist, setDonationlist] = useState([]);
    const [modalopen, setModalopen] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    if (donationlist != undefined && donationlist.length > 0) {
        donationlist.forEach((item: any, index: any) => { item.serial = index + 1; });
        donationlist.forEach((item: any, index: any) => {
            var date = item.donationDate.split("T");
            const od = moment(date[0]).format("DD-MM-YYYY");
            item.localOrderDate = od;
        });
    }


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            getStates()
            // showProjAdmList();
        }

    }, []);

    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                console.log(response)
                setStates(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const showProjAdmList = () => {
        setShowLoading(true);
        setProjAdmList([]);
        Authservice.projAdmList(usertoken)
            .then((response) => {
                console.log(response.data);
                var list: any = response.data;
                for (let i = 0; i < list.length; i++) {
                    setProjAdmList((projAdmList: any) => [...projAdmList, list[i]])
                    //projAdmList.push(list[i]);
                }
                setShowLoading(false);
            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }
    const chooseVenAdms = (e: any) => {
        setProjAdm(e);
        setShowLoading(true);
        showalldonation(e);

    }
    const showalldonation = (e: any) => {
        setShowLoading(true);
        setDonationlist([])
        UserService.Projectdonation(usertoken, e).then((response) => {
            console.log(response.data);
            setDonationlist(response.data);
            setShowLoading(false);
            setIsSearch(true)
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }

    const viewdetails = (item: any) => {
        var date = item.donationDate.split("T");
        const od = moment(date[0]).format("DD-MM-YYYY");
        setModalopen(true);
        setName(item.donorName);
        setNumber(item.donationNumber);
        setAmt(item.contributionAmount);
        setAddr(item.address)
        setDate(od);
        setMail(item.emailId);
        setPhone(item.contactNumber);
        setTitle(item.projectModel.projectTitle);
        setTaramt(item.projectModel.targetAmount);
        setPurpose(item.projectModel.purpose);
        setRemarks(item.remarks)
    };


    const Modalclose = () => {
        showalldonation(projAdm);
        setModalopen(false)
    }

    var tableData: any = [];
    if (donationlist != undefined && donationlist.length > 0) {
        tableData = donationlist.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <IonRow className="table_btn_container">
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);
    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const projectdonationmodal = useRef(null);
    useOutsideAlerter(projectdonationmodal);

    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

    const handleChange = (selectedOption: any) => {
        if (selectedOption !== null) {
            setFormErrors({ ...formErrors, admin: "" })
            setProjAdm(selectedOption.value);
        }
        else {
            setProjAdm("");
            setFormErrors({ ...formErrors, admin: "Select a Project admin" })
        }
        setSelectedOption(selectedOption);
        setProjAdm(selectedOption.value);
        setShowLoading(true);
        showalldonation(selectedOption.value);
        console.log(selectedOption, "kmkkk")
    };

    const projectAdmListOptions = projAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [formErrors, setFormErrors]: any = useState({});
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }


    const handleStateChange = (e: any) => {
        if (e !== null) {
            setFormErrors({ ...formErrors, state: '' })
            setState(e.value);
        }
        else {
            setState("")
            setFormErrors({ ...formErrors, state: 'Please Select a State' })
        }
        setStateOption(e);
    }

    const validation = (num: any) => {
        if (num === 1) {
            if (state.length === 0) {
                setFormErrors({ ...formErrors, state: 'Please Select a State' })
            }
            else {
                setFormErrors({ ...formErrors, state: '' })
            }
        }
        else if (num === 2) {
            if (projAdm.length === 0) {
                setFormErrors({ ...formErrors, admin: 'Please Select a Project admin' })
            }
            else {
                setFormErrors({ ...formErrors, admin: '' })
            }
        }
    }

    const handleSearch = () => {
        const errors: any = {};
        if (state.length === 0) {
            errors.state = 'Please Select a State';
        }

        // if (district.trim() === '') {
        //     errors.district = 'Enter the District';
        // }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            // setNameView(true)
            setShowLoading(true)
            setSelectedOption(null)
            setDonationlist([])
            setProjAdmList([])
            Authservice.getProjectAdminList(usertoken, state, city)
                .then((response) => {
                    console.log(response, "jnjj")
                    if (response.data.length != 0) {
                        var list: any = response.data;
                        setProjAdmList(list)
                    }
                    else {
                        presentAlert("No Results Found !")
                    }
                    setShowLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false);
                })
        }
    }

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Project Donations"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />

                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body ref={projectdonationmodal}>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Donation Details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <div className="create-modal-project-container donation-admin-container">
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{number}</IonText>
                                </IonCol>
                            </IonRow>
                            {name === "" || name === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Donor Name
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{name}</IonText>
                                    </IonCol>
                                </IonRow>}
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Contributed Amount(₹)
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(amt)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Date
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{date}</IonText>
                                </IonCol>
                            </IonRow>
                            {mail === "" || mail === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Mail Id
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{mail}</IonText>
                                    </IonCol>
                                </IonRow>}
                            {addr === "" || addr === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Address
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{addr}</IonText>
                                    </IonCol>
                                </IonRow>}
                            {phone === "" || phone === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Contact Number
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{Authservice.formatPhone(phone)}</IonText>
                                    </IonCol>
                                </IonRow>}
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Project Title
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{title}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Purpose
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{purpose}</IonText>
                                </IonCol>
                            </IonRow>
                            {remarks === "" || remarks === null ? <></> : <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Remarks
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{remarks}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Target Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(taramt)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12" style={{ display: "flex", justifyContent: "center" }}>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={Modalclose}
                                    >
                                        <span className="button_padding">Close</span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>

                <div>
                    <div className="container_middle_table page-wrapper admin_background_color">
                        <div className="project-management-search-container">
                            <IonRow>
                                <IonCol sizeXs="12" sizeLg="3" sizeSm="4.5">
                                    <IonLabel className="admin_search_label">
                                        State <span style={{ color: "red" }}>*</span>
                                    </IonLabel>
                                    <Select
                                        options={stateLiistOptions}
                                        value={stateOption}
                                        onChange={handleStateChange}
                                        onBlur={() => validation(8)}
                                        placeholder="Select the State"
                                        isClearable
                                        styles={customStyles}
                                        className="custom-select"
                                    />
                                    <div style={{ height: "10px" }}>
                                        {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                                    </div>
                                </IonCol>
                                <IonCol sizeXs="12" sizeLg="3" sizeSm="4.5" style={{ zIndex: "999", position: "relative" }}>
                                    <IonLabel className="admin_search_label">
                                        City
                                    </IonLabel>
                                    <IonInput className="admin_search_input" value={city} placeholder="Enter city name" onIonChange={(e: any) => setCity(e.detail.value)} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeLg="6" sizeSm="3" class="search-colum-project-admin">
                                    <IonLabel className="admin_search_label">
                                    </IonLabel>
                                    <IonButton
                                        className="admin_add_btn"
                                        type="button"
                                        onClick={handleSearch}
                                    >
                                        <span className="button_padding">Search</span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            {projAdmList.length != 0 ?
                                <IonRow style={{ zIndex: "999", position: "relative" }}>
                                    <IonCol size-xs="12" size-lg="3" size-md="3">
                                        <IonLabel className="admin_search_label">
                                            Project Admin
                                        </IonLabel>
                                        <Select
                                            options={projectAdmListOptions}
                                            value={selectedOption}
                                            onChange={handleChange}
                                            onBlur={() => validation(2)}
                                            placeholder="Select a Project Admin"
                                            isClearable
                                            styles={customStyles}
                                            className="custom-select"
                                        />
                                        {formErrors.admin && <span className="alert_input_bottom ">{formErrors.admin}</span>}
                                    </IonCol>
                                </IonRow>
                                : <></>}
                        </div>
                        {selectedOption != null ?
                            <DataTable
                                columns={columns}
                                data={tableData}
                                striped
                                noDataComponent="Sorry ! No result found"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                                subHeader
                                subHeaderComponent={isSearch === true || selectedOption != null ? subHeaderComponent : ""}
                                style={{ width: "fit-content" }}
                                onRowClicked={(item: any) => viewdetails(item)}
                                customStyles={customTableStyle}
                            /> :
                            <></>}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default Projectdonationadmin;


