import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import {
  IonContent,
  IonItem,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  IonModal,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonText,
  useIonAlert,
  IonSearchbar
} from "@ionic/react";
import { addCircleSharp, addOutline, arrowBack, close, reorderFour, trashOutline } from "ionicons/icons";
import "./Userpage.css";
import "./Home.css";
import Head from "./Head";
import Foot from "./Foot";
import UserService from "../Services/auth.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component"
import { Modal } from "react-bootstrap";
import Authservice from "../Services/user.service";
import { Capacitor } from "@capacitor/core";
import validator from "validator";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { count, error } from "console";
import Sidebar from "./admin/sidebar";
import AdminHead from "./admin/adminHead";
import customTableStyle from "../components/CustomTableStyle";
import { AppContext } from "../components/AppContext";
import CommonDataTable from "../components/datatable";
import Select, { components } from 'react-select';
import CommonModal from "../components/popipInfo";

interface State {
  id: number;
  name: string;
}

const Adminpage: React.FC = () => {
  const [allusers, setAllusers] = useState([]);
  const [index, setIndex] = useState(0);
  const [user, setUser]: any = useState("ROLE_USER");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password, setPassword] = useState("");
  const [state1, setState1]: any = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [aadhar1, setAadhar1] = useState("");
  const [addr, setAddr] = useState("");
  const [area1, setArea1] = useState("")
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [city1, setCity1] = useState("");
  const [country1, setCountry1] = useState("");
  const [email1, setEmail1] = useState("");
  const [comment, setComment] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const [pincode1, setPincode1] = useState("");
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const namecheck = /^[a-zA-Z\s]*$/
  const phoneInputRef = useRef(null)
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const [formErrors, setFormErrors]: any = useState({});

  const { exportCSV } = useContext(AppContext)!

  const [countryLiistOptions, setCountryListOptions] = useState([{ label: "India", value: "IN" }])
  const [countryOption, setCountryOption] = useState({ label: "India", value: "IN" })
  const [stateListOptions, setStateListOptions] = useState([{ label: "Kerala", value: "KL" }])
  const [stateOption, setStateOption] = useState({ label: "Kerala", value: "KL" })
  const [districtListOptions, setDistrictListOptions]: any = useState([])
  const [districtOption, setDistrictOption]: any = useState()
  const [panchayatListOptions, setPanchayatListOptions]: any = useState([])
  const [panchayatOption, setPanchayatOption]: any = useState([])
  const [templelistoptions, setTempleListOptions] = useState([])
  const [templeOption, setTempleOption]: any = useState([])
  const [districtlimitoptionist, setDistrictlimitoptionist] = useState([])  //district limit options
  const [districtlimitoption, setDistrictlimitoption]: any = useState([])  //district limit options
  const [panchayatlimitoptionist, setPanchayatlimitoptionist] = useState([])  //panchayat limit options
  const [panchayatlimitoption, setPanchayatlimitoption]: any = useState([])  //panchayat limit options

  const [bankName, setBankName] = useState("");
  const [bankUserName, setBankUserName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankIFSC, setBankIFSC] = useState("");
  const [upiCode, setUpiCode] = useState("");

  const [editUserId, setEditUserId] = useState("");
  const [itemId, setItemId] = useState("");

  const moveCursorToEnd = (value: any) => {
    console.log(value)
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
      if (value === '' || value === undefined) {
        setFormErrors({ ...formErrors, phone1: "Mobile Number is required" })
      }
      else if (!(phoneNumberObject ? phoneNumberObject.isValid() : false)) {
        setFormErrors({ ...formErrors, phone1: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, phone1: "" })
      }
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const validation = (num: any) => {
    if (num === 1) {
      if (user === "") {
        setFormErrors({ ...formErrors, user: "Choose a Role" })
      }
      else {
        setFormErrors({ ...formErrors, user: "" })
      }
    }
    else if (num === 2) {
      if (firstname1 === "") {
        setFormErrors({ ...formErrors, firstName1: "Enter First Name" })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors({ ...formErrors, firstName1: 'Alphabets only Allowed in First Name' })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors({ ...formErrors, firstName1: 'Empty Space at First Name' })
      }
      else {
        setFormErrors({ ...formErrors, firstName1: "" })
      }
    }
    else if (num === 3) {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors({ ...formErrors, phone1: "Mobile Number is required" })
      }
      else if (!isValidPhoneNumberSign) {
        setFormErrors({ ...formErrors, phone1: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, phone1: "" })
      }
    }
    else if (num === 4) {
      if (email1.trim() === '') {
        setFormErrors({ ...formErrors, email1: "Email is required" })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors({ ...formErrors, email1: "Enter Valid Email Address" })
      }
      else {
        setFormErrors({ ...formErrors, email1: "" })
      }
    }
    if (num === 5) {
      if (password.trim() === '') {
        setFormErrors({ ...formErrors, password1: "Password is required" })
      }
      else {
        setFormErrors({ ...formErrors, password1: "" })
      }
    }
    if (num === 6) {
      if (confirmpassword.trim() === '') {
        setFormErrors({ ...formErrors, confirmpassword: "Confirm Password is required" })
      }
    }
    if (num === 7) {
      if (user === "ROLE_TEMPADMIN" && TName === "") {
        setFormErrors({ ...formErrors, templeName: "Enter Temple Name" })
      }
      else if (user === "ROLE_TEMPADMIN" && namecheck.test(TName) === false) {
        setFormErrors({ ...formErrors, templeName: "Alphabets only Allowed in Temple Name" })
      }
    }
    if (num === 8) {
      if (user === "ROLE_TEMPADMIN" && TAdd === "") {
        setFormErrors({ ...formErrors, address: "Enter Temple Address" })
      }
    }
    if (num === 9) {
      if (!districtOption) {
        setFormErrors({ ...formErrors, district: "Select a district" })
      }
    }
    if (num === 10) {
      if (!panchayatOption) {
        setFormErrors({ ...formErrors, panchayat: "Select a district" })
      }
    }
    if (num === 11) {
      if (!address) {
        setFormErrors({ ...formErrors, address: "Enter a address" })
      }
    }
    if (num === 12) {
      if (!pincode1) {
        setFormErrors({ ...formErrors, pincode: "Select a pincode " })
      }
    }
    if (num === 13) {
      if (!templeOption) {
        setFormErrors({ ...formErrors, templelimit: "Select a Temple " })
      }
    }
  }

  const onchangevalidation = (e: any, num: any) => {
    if (num === 1) {
      console.log(e.target.value)
      var cuser = e.target.value;
      if (cuser !== "") {
        setFormErrors({ ...formErrors, user: "" })
      }
      setUser(e.target.value)
    }
    if (num === 2) {
      var name = e.detail.value!;
      if (namecheck.test(name) == false) {
        setFormErrors({ ...formErrors, firstName1: 'Alphabets only Allowed in First Name' })
      }
      else if (name.charAt(0) == " ") {
        setFormErrors({ ...formErrors, firstName1: 'Empty Space at First Name' })
      }
      else {
        setFormErrors({ ...formErrors, firstName1: "" })
      }
      setFirstname1(e.detail.value!)
    }
    if (num === 3) {
      var mail = e.detail.value!;
      if (mail.trim() === '') {
        setFormErrors({ ...formErrors, email1: "Email is required" })
      }
      else if (validator.isEmail(mail) === false) {
        setFormErrors({ ...formErrors, email1: "Enter Valid Email Address" })
      }
      else {
        setFormErrors({ ...formErrors, email1: "" })
      }
      setEmail1(e.detail.value!)
    }
    if (num === 4) {
      var pass = e.detail.value!;
      if (confirmpassword !== "") {
        if (pass === confirmpassword) {
          setFormErrors({ ...formErrors, confirmpassword: "" })
        }
      }
      else if (pass.trim() === '') {
        setFormErrors({ ...formErrors, password1: "Password is required" })
      }
      else {
        setFormErrors({ ...formErrors, password1: "" })
      }
      setPassword(e.detail.value!)
    }
    if (num === 5) {
      var cpass = e.detail.value!;
      if (cpass.trim() === '') {
        setFormErrors({ ...formErrors, confirmpassword: "Confirm Password is required" })
      }
      else if (password != cpass) {
        setFormErrors({ ...formErrors, confirmpassword: "Password and confirm password are not same" })
      }
      else {
        setFormErrors({ ...formErrors, confirmpassword: "" })
      }
      setConfirmpassword(e.detail.value!)
    }
    if (num === 6) {
      var temName = e.detail.value!;
      if (user === "ROLE_TEMPADMIN" && temName === "") {
        setFormErrors({ ...formErrors, templeName: "Enter Temple Name" })
      }
      else if (user === "ROLE_TEMPADMIN" && temName.charAt(0) == " ") {
        setFormErrors({ ...formErrors, templeName: 'Empty Space at First Name' })
      }
      else if (user === "ROLE_TEMPADMIN" && namecheck.test(temName) === false) {
        setFormErrors({ ...formErrors, templeName: "Alphabets only Allowed in Temple Name" })
      }
      else {
        setFormErrors({ ...formErrors, templeName: "" })
      }
      setTName(e.detail.value!)
    }
    if (num === 7) {
      var add = e.detail.value!;
      if (user === "ROLE_TEMPADMIN" && add === "") {
        setFormErrors({ ...formErrors, address: "Enter Temple Address" })
      }
      else {
        setFormErrors({ ...formErrors, address: "" })
      }
      setTAdd(e.detail.value!)
    }
    if (num === 10) {
      const district = e.value;
      if (!district) {
        setFormErrors({ ...formErrors, district: "Select District" })
      } else {
        setFormErrors({ ...formErrors, district: "" })
        getAllPanchayats(district);
      }
      setDistrictOption(e)
    }
    if (num === 11) {
      const district = e.value;
      if (!district) {
        setFormErrors({ ...formErrors, panchayat: "Select Panchayat" })
      } else {
        setFormErrors({ ...formErrors, panchayat: "" })
      }
      setPanchayatOption(e)
    }
    if (num === 12) {
      const val = e.detail.value!;
      if (!val) {
        setFormErrors({ ...formErrors, address: "Address is required" })
      } else {
        setFormErrors({ ...formErrors, address: "" })
      }
      setAddress(val)
    }
    if (num === 13) {
      const val = e.detail.value!;
      if (!val) {
        setFormErrors({ ...formErrors, pincode: "Pincode is required" })
      } else {
        setFormErrors({ ...formErrors, pincode: "" })
      }
      setPincode1(val)
    }
    if (num === 14) {
      const val = e;
      if (!val) {
        setFormErrors({ ...formErrors, templelimit: "Temple is required" })
      } else {
        setFormErrors({ ...formErrors, templelimit: "" })
      }
      console.log(val, "val")
      setTempleOption(e)
    }
  }




  const bankingChange = (e: any, num: any) => {
    const val = e.detail.value; // Get the input value
    let fieldName = "";
    switch (num) {
      case 14:
        fieldName = "bankName";
        setBankName(val);
        break;
      case 15:
        fieldName = "bankUserName";
        setBankUserName(val);
        break;
      case 16:
        fieldName = "bankAccountNumber";
        setBankAccountNumber(val);
        break;
      case 17:
        fieldName = "bankBranch";
        setBankBranch(val);
        break;
      case 18:
        fieldName = "bankIFSC";
        setBankIFSC(val);
        break;
      case 19:
        fieldName = "upiCode";
        setUpiCode(val);
        break;
      default:
        break;
    }
    if (!val) {
      setFormErrors({ ...formErrors, [fieldName]: `${fieldName} is required` });
    } else {
      setFormErrors({ ...formErrors, [fieldName]: "" });
    }
    console.log(val, `${fieldName} value`);
  }

  const modalClose = () => {
    setCommonmodal(false)
    setCommonModalMesg("")
    setCommonmodaltitle("")
    showallusers()
  }


  const submit = () => {
    const errors: any = {};
    if (user === "") {
      errors.user = "Choose a Role"
    }
    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    if (!isedit && password.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (!isedit && confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (!isedit && password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    // if (user === "ROLE_TEMPADMIN" && TName === "") {
    //   errors.templeName = "Enter Temple Name"
    // }
    // else if (user === "ROLE_TEMPADMIN" && namecheck.test(TName) === false) {
    //   errors.templeName = "Alphabets only Allowed in Temple Name"
    // }
    // if (user === "ROLE_TEMPADMIN" && TAdd === "") {
    //   errors.address = "Enter Temple Address"
    // }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.Registeradm(email1, firstname1, phone1, password, user, TName, TAdd, user,
        countryOption.value,
        stateOption.value, districtOption.value, panchayatOption.value,
        address, pincode1, panchayatlimitoption, templeOption, districtlimitoption,
        bankAccountNumber, bankBranch, bankIFSC, bankName, bankUserName, upiCode, userid, usertoken, editUserId, isedit, lastname1, itemId).then((response) => {
          console.log("res", response);
          setShowLoading(false);
          const userRoles: any = {
            ROLE_ADMIN: "Admin",
            ROLE_USER: "User",
            ROLE_VOLUNTEER: "Volunteer",
            ROLE_FINANCE: "Finance",
            ROLE_VENDOR: "Vendor",
            // Add more roles as needed
          };
          if (response == "SUCCESS") {
            setCommonModalMesg(`${userRoles[user] || user} ${isedit ? "Updated" : "Created"} Successfully`);
            setCommonmodal(true)
            setCommonmodaltitle("Information")
            setIndex(0);
          } else if (response.responseText == "ERROR:Email Id already exist") {
            setCommonModalMesg("Email Id Exits, Try Any Other Email Id");
            setCommonmodal(true)
            setCommonmodaltitle("Information")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            setCommonModalMesg("Mobile Number Exits, Try Any Other Mobile Number");
            setCommonmodal(true)
            setCommonmodaltitle("Information")
          } else {
            setCommonModalMesg("User Not Register")
            setCommonmodal(true)
            setCommonmodaltitle("Information")
          }

        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          setCommonModalMesg("Something went wrong");
          setCommonmodal(true)
          setCommonmodaltitle("Information")
        });
      // } else {
      //   // Authservice.Profileupdate(aadhar, address, pincode1, area, city, countryOption.value, email1, firstname1, lastname, phone1, stateOption.value, usertoken, userid, districtOption.value, panchayatOption.value, id)
      //   //   .then((response) => {
      //   //     setShowLoading(false)
      //   //     if (response.data.startsWith("SUCCESS")) {

      //   //     } else {

      //   //     }
      //   //   })
      //   //   .catch((error: any) => {
      //   //     setShowLoading(false)
      //   //     alert(error)
      //   //   });
      // }
    }

  };
  const [presentAlert] = useIonAlert();
  const columns: any = [
    {
      name: "S.No",
      selector: (item: any, i: any) => item.serial,
      sortable: true,
      width: "90px",
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => Onviewdetails(item)}>
          <div style={{ cursor: "pointer" }}>{item.serial}</div>
        </div>
      )
    },
    {
      name: "Name",
      selector: (item: any) => item.firstName,
      sortable: true,
      cell: (item: any) => (
        <div onClick={() => Onviewdetails(item)} className="left_col">
          <div style={{ cursor: "pointer" }} >{item.firstName}</div>
        </div>
      )
    },
    {
      name: "Email",
      selector: (item: any) => item.email,
      sortable: true,
      cell: (item: any) => (
        <div onClick={() => Onviewdetails(item)} className="left_col">
          <div style={{ cursor: "pointer" }} >{item.email}</div>
        </div>
      )
    },
    {
      name: "Role",
      selector: (item: any) => item.preRole,
      sortable: true,
      width: "120px",
      cell: (item: any) => {
        return (
          <div className="center_col_tab" onClick={() => Onviewdetails(item)}>
            <div style={{ cursor: "pointer" }} >{item.preRole}</div>
          </div>
        )
      }
    },
    {
      name: "Last modified On",
      selector: (item: any) => item.modifiedOn,
      sortable: true,
      width: "120px",
      cell: (item: any) => {
        return (
          <div className="center_col_tab" onClick={() => Onviewdetails(item)}>
            <div style={{ cursor: "pointer" }} >{formatDate(item.createdOn)}</div>
          </div>
        )
      }
    },
    {
      name: "Status",
      sortable: true,
      selector: (item: any) => item.uflag,
      width: "150px",
      cell: (item: any) => (
        <div className="center_col_tab">
          <IonButton
            color={item.uflag === "true" ? "danger" : "success"}
            className="datatable-activate-btn"
            onClick={() =>
              presentAlert({
                header: "Atmagram",
                message: `Do you want to ${item.uflag === "true" ? "Deactivate" : "Activate"} this User?`,
                inputs: [{ name: "comment", type: "text", value: comment, placeholder: "Enter your comment" }],
                buttons: [
                  { text: "No", role: "cancel" },
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: (alertData) => handleStatus(item.uflag !== "true", item.userId, alertData.comment),
                  },
                ],
              })
            }
          >
            <span className="button_padding">{item.uflag === "true" ? "Deactivate" : "Activate"}</span>
          </IonButton>
        </div>
      )
    },
    {
      name: "Action",
      sortable: false,
      width: "100px",
      cell: (item: any) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }} onClick={() =>
          presentAlert({
            header: "Atmagram",
            message: "Are you sure you want to delete this item?",
            buttons: [
              { text: "No", role: "cancel" },
              {
                text: "Yes",
                role: "confirm",
                handler: () => deleteUser(item),
              },
            ],
          })
        }>
          <IonIcon icon={trashOutline} />
        </div>

      ),
    }

  ]

  const formatDate = (timestamp: any) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });
  };
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);
  const [iserror, setIserror] = useState(false);
  const [modals, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [usertoken]: any = useState(sessionStorage.getItem("token"));
  const [uFlag]: any = useState(sessionStorage.getItem("uFlag"))
  const [userid]: any = useState(sessionStorage.getItem("UserId"))
  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadharNumber] = useState("");
  const [activeComment, setActiveComment] = useState("");
  const [inactiveComment, setInactiveComment]: any = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [isedit, setisedit] = useState(false);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);

  const [alertmsg, setAlertMsg]: any = useState("")
  const [alertModal, setAlertModal]: any = useState(false)
  const alertRef = useRef(null);
  const [selecteOption, setSelectedOption]: any = useState([])
  const [emailverified, setEmailverified]: any = useState(false);
  const [Commonmodal, setCommonmodal] = useState(false)
  const [CommonModalMesg, setCommonModalMesg] = useState("")
  const [Commmonmodaltitle, setCommonmodaltitle] = useState("")




  if (allusers != undefined && allusers.length > 0) {
    allusers.forEach((item: any, index: any) => { item.serial = index + 1; });
    allusers.forEach((item: any, index: any) => {
      if (item.role === "ROLE_PROJECTADMIN") {
        item.preRole = "Project Admin"
      }
      else if (item.role === "ROLE_USER") {
        item.preRole = "User"
      }
      else if (item.role === "ROLE_TEMPADMIN") {
        item.preRole = "Temple Admin"
      }
      else if (item.role === "ROLE_VENDOR") {
        item.preRole = "Vendor"
      }
      else {
        item.preRole = "Admin"
      }
    });
  }


  const deleteUser = (e: any) => {
    console.log(e, "delete")
    Authservice.deleteUserAcc(usertoken, true, e.userId).then((response) => {
      console.log(response)
      // alert("User Activated")
      window.location.reload()
      setShowLoading(true)
    })
      .catch((error) => {
        presentAlert("Something went wrong, please try again later.");
        setShowLoading(true)
      });
  }


  useEffect(() => {
    console.log(userdata, "jvjh")
    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_ADMIN") {
      history.push("/home")
    }
    else if (uFlag[0] === "false") {
      history.push("/home")
    }
    else {
      showallusers();
      Getdatausingsearch('')
      getAllDistricts();
      Authservice.getAllUserRoles(usertoken, userid).then((response) => {
        console.log(response)
        setSelectedOption(response.data.filter((e: any) => e.roleId !== "ROLE_ADMIN" && e.roleId !== "ROLE_PROJECTADMIN" && e.roleId !== "ROLE_TEMPADMIN"))
        setShowLoading(false)
      })
        .catch((error) => {
          alert("Something went wrong, please try again later.");
          setShowLoading(false)
        });
    }

  }, []);

  const Getdatausingsearch = (value: any) => {
    Authservice.getAllTemplesForAdmin(usertoken, value, userid, userdata)
      .then((response: any) => {
        console.log(response);
        setShowLoading(false);
        const data = response.data.map((e: any, i: any) => {
          return { label: e.templeName, value: e.templeCode }
        })
        setTempleListOptions(data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        setShowLoading(false);
      });
  }


  const getAllDistricts = () => {
    setShowLoading(true);
    Authservice.getAdmAllDistricts(userid, usertoken, usertoken).then((response) => {
      console.log(response.data);
      setDistrictListOptions(response.data.map((e: any) => ({ label: e.districtName, value: e.districtCode })));
      setDistrictlimitoptionist(response.data.map((e: any) => ({ label: e.districtName, value: e.districtCode })));
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }
  const getAllPanchayats = (e: any) => {
    setShowLoading(true);
    Authservice.getAllPanchayats(userid, usertoken, usertoken, e).then((response) => {
      console.log(response.data);
      setPanchayatListOptions(response.data.map((e: any) => ({ label: e.panchayatName, value: e.panchayatCode })));
      setPanchayatlimitoptionist(response.data.map((e: any) => ({ label: e.panchayatName, value: e.panchayatCode })));
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }


  const showallusers = () => {
    setShowLoading(true);

    Authservice.Showallusers(usertoken).then((response) => {
      console.log(response.data);
      setAllusers(response.data);
      setShowLoading(false);
    })
      .catch((error) => {
        presentAlert("Something went wrong, please try again later.");
        console.log(error + "net")
        setShowLoading(false);

      });
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const states: State[] = [
    {
      id: 1,
      name: "Andhra Pradesh",
    },
    {
      id: 2,
      name: "Arunachal Pradesh",
    },
    {
      id: 3,
      name: "Assam",
    },
    {
      id: 4,
      name: "Bihar",
    },
    {
      id: 5,
      name: "Chhattisgarh",
    },
    {
      id: 6,
      name: "Goa",
    },
    {
      id: 7,
      name: "Gujarat",
    },
    {
      id: 8,
      name: "Haryana",
    },
    {
      id: 9,
      name: "Himachal Pradesh",
    },
    {
      id: 10,
      name: "Jharkhand",
    },
    {
      id: 11,
      name: "Karnataka",
    },

    {
      id: 12,
      name: "Kerala",
    },

    {
      id: 13,
      name: "Madhya Pradesh",
    },
    {
      id: 14,
      name: "Maharashtra",
    },

    {
      id: 14,
      name: "Manipur",
    },

    {
      id: 15,
      name: "Meghalaya",
    },

    {
      id: 16,
      name: "Mizoram",
    },

    {
      id: 17,
      name: "Nagaland",
    },

    {
      id: 18,
      name: "Odisha",
    },
    {
      id: 19,
      name: "Punjab",
    },
    {
      id: 20,
      name: "Rajasthan",
    },
    {
      id: 21,
      name: "Sikkim",
    },
    {
      id: 22,
      name: "Tamil Nadu",
    },
    {
      id: 23,
      name: "Telengana",
    },
    {
      id: 24,
      name: "Tripura",
    },
    {
      id: 23,
      name: "Uttarkhand",
    },
    {
      id: 24,
      name: "Uttar Pradesh",
    },
    {
      id: 25,
      name: "West Bengal",
    },
    {
      id: 26,
      name: "Andaman and Nicobar Islands",
    },
    {
      id: 27,
      name: "Chandigarh",
    },
    {
      id: 28,
      name: "The Government of NCT of Delhi",
    },
    {
      id: 29,
      name: "Dadra and Nagar Haveli and Daman & Diu",
    },
    {
      id: 30,
      name: "Ladakh",
    },
    {
      id: 31,
      name: "Jammu & Kashmir",
    },
    {
      id: 32,
      name: "Lakshadweep",
    },

    {
      id: 33,
      name: "Puducherry",
    },
  ];

  const [flag, setFlag] = useState("")

  const Onviewdetails = (item: any) => {
    var number: any
    if (item.mobileNumber !== null) {
      if (!item.mobileNumber.startsWith("+")) {
        number = Authservice.formatPhone("+91" + item.mobileNumber)
      } else {
        number = Authservice.formatPhone(item.mobileNumber)
      }
    }

    console.log(number, item, "jnjn");
    setFlag(item.uflag)
    setFirstname1(item.firstName)
    setLastname1(item.lastName)
    setUser(item.role)
    setPhone1(number)
    setEditUserId(item.userId)
    setItemId(item.id)
    moveCursorToEnd(number)
    setEmail1(item.email)
    console.log(districtListOptions.filter((e: any) => e.value === item.district)[0], "district")
    setDistrictOption({ label: item.district ? districtListOptions.filter((e: any) => e.value === item.district)[0]?.label : null, value: item.district })
    if (item.district) {
      getAllPanchayats(item.district)
      const arr = []
      const dis = item.districtLimit?.split(",")
      for (let i = 0; i < districtListOptions.length; i++) {
        if (dis?.includes(districtListOptions[i].value)) {
          arr.push({ label: districtListOptions[i].label, value: districtListOptions[i].value })
        }
      }
      setDistrictlimitoption(arr)
    }
    setPanchayatOption({ label: item.panchayat ? panchayatListOptions.filter((e: any) => e.value === item.panchayat)[0]?.label : null, value: item.panchayat })
    if (item.panchayathLimit) {
      const arr = []
      const pan = item.panchayathLimit?.split(",")
      for (let i = 0; i < panchayatListOptions.length; i++) {
        if (pan.includes(panchayatListOptions[i].value)) {
          arr.push({ label: panchayatListOptions[i].label, value: panchayatListOptions[i].value })
        }
      }
      console.log(arr, "sakbxj")
      setPanchayatlimitoption(arr)
    }
    setAddress(item.address1 ? item.address1.split(",")[0] : "")
    setPincode1(item.address1 ? item.address1.split(",")[1] : "")
    setAadhar1(item.aadharNumber)
    setActiveComment(item.activeComment);
    setInactiveComment(item.inactiveComment);
    setCreateUserModal(true)
    setUpiCode(item.upiCode)
    setBankName(item.bankName)
    setBankUserName(item.bankUserName)
    setBankAccountNumber(item.bankAccountNumber)
    setBankBranch(item.bankBranch)
    setBankIFSC(item.bankIFSC)
    setEmailverified(item.emailVerified)
    setisedit(true)

  };
  const addmodal = () => {
    setCreateUserModal(true)
    setAddress("")
    setUser("ROLE_USER")
    setFormErrors({})
    setisedit(false)
  }

  const handleStatus = (status: any, id: any, comm: any) => {
    setShowLoading(true)
    Authservice.statusAdmUser(usertoken, status, id, comm).then((response) => {
      console.log(response)
      // alert("User Activated")
      window.location.reload()
      setShowLoading(true)
    })
      .catch((error) => {
        presentAlert("Something went wrong, please try again later.");
        setShowLoading(true)
      });

  }
  var tableData: any = [];
  if (allusers != undefined && allusers.length > 0) {
    tableData = allusers.filter((item: any) => {
      const serializedItem = JSON.stringify(item).toLowerCase();
      const searchTerm = filterText.toLowerCase();

      var regex: any;

      if (/\+/.test(searchTerm)) {
        const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        regex = new RegExp(escapedSearchTerm);
      } else {
        regex = new RegExp(`\\b${searchTerm}\\b`);
      }

      return regex.test(serializedItem);
    });
  }


  // const csvContent = useMemo(() => {
  //   if (!allusers || allusers.length === 0) return '';
  //   const headers = columns.map((col: any) => col.name).join(',') + '\n';
  //   const csvRows = allusers.map((row: any) =>
  //     columns.map((col: any) => col.selector(row)).join(',')
  //   ).join('\n');

  //   return headers + csvRows;
  // }, [allusers, columns]);



  // const exportCSV = () => {
  //   if (!csvContent) {
  //     console.warn("No data available to export.");
  //     return;
  //   }
  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.setAttribute('download', 'exported_data.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };



  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <IonRow className="table_btn_container">
        <div>
          <IonButton onClick={addmodal} className="admin_add_btn">
            <IonIcon src={addOutline} />
            <span className="button_padding">Add User</span>
          </IonButton>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IonButton className="admin_add_btn" onClick={() => exportCSV(columns, allusers, "User Details")}>
            Export
          </IonButton>
          <IonSearchbar
            value={filterText}
            onIonChange={(e: any) => setFilterText(e.detail.value)}
            placeholder="Search"
            className="table_search"
          />
        </div>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle, allusers]);



  const useOutsideAlerter = (refer: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setModal(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const usermodal = useRef(null);
  useOutsideAlerter(usermodal);




  const CloseCreateModal = () => {
    setCreateUserModal(false)
    setFormErrors({})
    setUser("")
    setFirstname1("")
    setPhone1("")
    setEmail1("")
    setPassword("")
    setConfirmpassword("")
    setTName("")
    setTAdd("")
  }

  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"User Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <IonRow style={{ position: "absolute" }}>
          <IonCol>
            <IonAlert
              isOpen={iserror}
              onDidDismiss={() => setIserror(false)}
              cssClass="my-custom-class"
              header={"Error!"}
              message={message}
              buttons={["Dismiss"]}
            />
          </IonCol>
        </IonRow>
        <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
          <Modal.Body ref={alertRef}>
            <div style={{ textAlign: "center" }}><div>
              <IonText> {alertmsg}</IonText>
            </div><br></br>
              <div>
                <IonButton className="admin_add_btn">
                  <span onClick={() => setAlertModal(false)
                  } className="button_padding">ok</span>
                </IonButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modals} className="admin_modal">
          <Modal.Body ref={usermodal}>
            <div className="donation-modal-header admin-user-modal-header">
              <h5 className="donation-modal-title">User Information</h5>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModal(false)} />
              </div>
            </div>
            <div className="admin_signup_modal">
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    First Name
                  </IonLabel>
                  <IonInput
                    value={firstname}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Last Name
                  </IonLabel>
                  <IonInput
                    value={lastname}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Role
                  </IonLabel>
                  <IonInput
                    value={role}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Mobile Number
                  </IonLabel>
                  <IonInput
                    value={phone}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Area
                  </IonLabel>
                  <IonInput
                    value={area}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    City
                  </IonLabel>
                  <IonInput
                    value={city}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    State
                  </IonLabel>
                  <IonInput
                    value={state}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Country
                  </IonLabel>
                  <IonInput
                    value={country}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Email
                  </IonLabel>
                  <IonInput
                    value={email}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Address
                  </IonLabel>
                  <IonInput
                    value={address}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                {aadhar === null ? <div></div> :
                  <IonCol size-xs="12" size-lg="6" size-md="6">
                    <IonLabel className="label_align">
                      Aadhaar Number
                    </IonLabel>
                    <IonInput
                      value={aadhar}
                      readonly
                    ></IonInput>
                  </IonCol>
                }
                {flag === "true" ? <>
                </> : <>
                  {inactiveComment === null ? <div></div> :
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          Comment
                        </IonLabel>
                        <IonInput
                          value={inactiveComment}
                          readonly
                        ></IonInput>
                      </div>
                    </IonCol>
                  }
                </>
                }
              </IonRow>
              <IonRow style={{ marginTop: '10px' }}>
                <IonCol size="12" style={{ textAlign: "center" }}>
                  <IonButton onClick={(e) => setModal(false)} className="admin_add_btn"><span className="button_padding">Close</span></IonButton>
                </IonCol>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <div >
          {index == 0 ? <div className="container_middle_table page-wrapper admin_background_color">
            <CommonDataTable
              columns={columns}
              data={tableData}
              defaultSortFieldId="name"
              striped
              noDataComponent="Sorry ! No result found"
              isSubHeader={true}
              subHeaderComponent={subHeaderComponent}
              onRowClicked={Onviewdetails}
              customStyles={customTableStyle}
            />
          </div> : index == 1 ? <></>
            : <></>}
          <Modal show={createUserModal} className="admin_modal">
            <Modal.Body>
              <div className="donation-modal-header admin-user-modal-header">
                <h5 className="donation-modal-title">{!isedit ? "Create User" : "View user"}</h5>
                <div>
                  <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => CloseCreateModal()} />
                </div>
              </div>
              <div>
                <div className="admin_signup_modal">
                  <IonRow>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          Choose a Role
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <select
                          value={user}
                          onBlur={() => validation(1)}
                          style={{ width: "100%", height: "100%", outline: "0px" }}
                          onChange={(e: any) => onchangevalidation(e, 1)}
                          className="select_user_admin"
                        // disabled={isedit}
                        >
                          {selecteOption.map((e: any) =>
                            <option value={e.roleId}>{e.rolename}</option>
                          )}
                          {/* <option value="5">
                            Project Admin
                          </option> */}
                        </select>
                      </div>
                      {formErrors.user && <span className="alert_input_bottom ">{formErrors.user}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          First Name
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          value={firstname1}
                          onBlur={() => validation(2)}
                          onIonChange={(e) => onchangevalidation(e, 2)}
                        // readonly={isedit}
                        ></IonInput>
                      </div>
                      {formErrors.firstName1 && <span className="alert_input_bottom ">{formErrors.firstName1}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          Last Name
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          value={lastname1}
                          // readonly={isedit}
                          onBlur={() => validation(2)}
                          onIonChange={(e: any) => setLastname1(e.detail.value)}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div >
                        <IonLabel className="label_align">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          onBlur={() => validation(3)}
                          value={phone1}
                          // readOnly={isedit}
                          className="signUpNum"
                          ref={phoneInputRef}
                          onChange={(value: any) => moveCursorToEnd(value)}
                        />
                      </div>
                      {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Email <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="email"
                        value={email1}
                        // readonly={isedit}
                        required
                        onBlur={() => validation(4)}
                        onIonChange={(e) => onchangevalidation(e, 3)}
                      ></IonInput>
                      {formErrors.email1 && !isedit && < span className="alert_input_bottom ">{formErrors.email1}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="3" size-md="6">
                      <IonLabel className="label_align">
                        Country <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <Select
                        options={countryLiistOptions}
                        value={countryOption}
                        className="select_user_admin"
                        placeholder="Select a Country"
                        isClearable
                        isDisabled
                        onBlur={() => validation(6)}
                        onChange={(e: any) => onchangevalidation(e, 7)}
                      />
                      {formErrors.Country && <span className="alert_input_bottom">{formErrors.Country}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="3" size-md="6">
                      <IonLabel className="label_align">
                        State <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <Select
                        options={stateListOptions}
                        value={stateOption}
                        className="select_user_admin"
                        placeholder="Select a State"
                        isClearable
                        isDisabled
                        onChange={(e: any) => onchangevalidation(e, 8)}
                      />
                      {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        District
                      </IonLabel>
                      <Select
                        options={districtListOptions}
                        value={districtOption}
                        className="select_user_admin"
                        placeholder="Select a District"
                        isClearable
                        // isDisabled={isedit}
                        onBlur={() => validation(9)}
                        onChange={(e: any) => onchangevalidation(e, 10)}
                      />
                      {formErrors.district && <span className="alert_input_bottom">{formErrors.district}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Panchayat
                      </IonLabel>
                      <Select
                        options={panchayatListOptions}
                        value={panchayatOption}
                        className="select_user_admin"
                        placeholder="Select a Panchayat"
                        isClearable
                        // isDisabled={isedit}
                        onBlur={() => validation(10)}
                        onChange={(e: any) => onchangevalidation(e, 11)}
                      />
                      {formErrors.panchayat && <span className="alert_input_bottom ">{formErrors.panchayat}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        District Limit
                      </IonLabel>
                      <Select
                        options={districtlimitoptionist}
                        value={districtlimitoption}
                        className="select_user_admin limit-select"
                        placeholder="Select a District"
                        isClearable
                        // isDisabled={isedit}
                        isMulti
                        onChange={(e: any) => setDistrictlimitoption(e)}
                      />
                      {formErrors.districtlimit && <span className="alert_input_bottom">{formErrors.districtlimit}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Panchayat Limit
                      </IonLabel>
                      <Select
                        options={panchayatlimitoptionist}
                        value={panchayatlimitoption}
                        className="select_user_admin limit-select"
                        placeholder="Select a Panchayat"
                        isClearable
                        // isDisabled={isedit}
                        isMulti
                        onChange={(e: any) => setPanchayatlimitoption(e)}
                      />
                      {formErrors.panchayatlimit && <span className="alert_input_bottom ">{formErrors.panchayatlimit}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Temple Limit
                      </IonLabel>
                      <Select
                        options={templelistoptions}
                        value={templeOption}
                        className="select_user_admin"
                        placeholder="Select a Temple"
                        isClearable
                        isMulti
                        // isDisabled={isedit}
                        onBlur={() => validation(10)}
                        onChange={(e: any) => onchangevalidation(e, 14)}
                      />
                      {formErrors.templelimint && <span className="alert_input_bottom ">{formErrors.templelimint}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Address
                      </IonLabel>
                      <IonInput
                        type="text"
                        value={address}
                        required
                        clearOnEdit={false}
                        // readonly={isedit}
                        onBlur={() => validation(11)}
                        onIonChange={(e) => onchangevalidation(e, 12)}
                      ></IonInput>
                      {formErrors.address && <span className="alert_input_bottom">{formErrors.address}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Pincode
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={pincode1}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(12)}
                        onIonChange={(e) => onchangevalidation(e, 13)}
                      ></IonInput>
                      {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Bank Name
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={bankName}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(14)}
                        onIonChange={(e) => bankingChange(e, 14)}
                      ></IonInput>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Bank User Name
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={bankUserName}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(16)}
                        onIonChange={(e) => bankingChange(e, 15)}
                      ></IonInput>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Bank Account Number
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={bankAccountNumber}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(18)}
                        onIonChange={(e) => bankingChange(e, 16)}
                      ></IonInput>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Bank Branch
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={bankBranch}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(20)}
                        onIonChange={(e) => bankingChange(e, 17)}
                      ></IonInput>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Bank IFSC
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={bankIFSC}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(22)}
                        onIonChange={(e) => bankingChange(e, 18)}
                      ></IonInput>
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        UPI Code
                      </IonLabel>
                      <IonInput
                        type="text"
                        clearOnEdit={false}
                        value={upiCode}
                        required
                        // readonly={isedit}
                        onBlur={() => validation(24)}
                        onIonChange={(e) => bankingChange(e, 19)}
                      ></IonInput>
                    </IonCol>
                    {isedit && <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Email verified
                      </IonLabel>
                      <IonInput
                        type="text"
                        required
                        // readonly={isedit}
                        value={emailverified ? "Yes" : "No"}
                      ></IonInput>
                    </IonCol>}

                    {!isedit && <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        value={password}
                        required
                        clearOnEdit={false}
                        onBlur={() => validation(5)}
                        onIonChange={(e) => onchangevalidation(e, 4)}
                      ></IonInput>
                      {formErrors.password1 && <span className="alert_input_bottom">{formErrors.password1}</span>}
                    </IonCol>}
                    {!isedit && < IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        clearOnEdit={false}
                        value={confirmpassword}
                        required
                        onBlur={() => validation(6)}
                        onIonChange={(e) => onchangevalidation(e, 5)}
                      ></IonInput>
                      {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                    </IonCol>}
                    {/* {user == "ROLE_TEMPADMIN" ?
                      <IonRow>
                        <IonCol size-xs="12" size-lg="6" size-md="6">
                          <IonLabel className="label_align">
                            Temple Name <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                          <IonInput
                            value={TName}
                            required
                            onBlur={() => validation(7)}
                            onIonChange={(e) => onchangevalidation(e, 6)}
                          ></IonInput>
                          {formErrors.templeName && <span className="alert_input_bottom ">{formErrors.templeName}</span>}
                        </IonCol>
                        <IonCol size-xs="12" size-lg="6" size-md="6">
                          <IonLabel className="label_align">
                            Temple Address <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                          <IonInput
                            value={TAdd}
                            required
                            onBlur={() => validation(8)}
                            onIonChange={(e) => onchangevalidation(e, 7)}
                          ></IonInput>
                          {formErrors.address && <span className="alert_input_bottom ">{formErrors.address}</span>}
                        </IonCol>
                      </IonRow> : <></>} */}
                    <IonCol size="12" size-lg="12" style={{ display: "flex", justifyContent: "center" }}>
                      <div className={email1.length === 0 ||
                        user == "" || (isedit ? false : (password.length === 0 || confirmpassword.length === 0)) || !phone1 ? "disableCursor" : ""}>
                        <IonButton
                          className="admin_add_btn"
                          type="submit"
                          onClick={submit}
                          disabled={
                            email1.length === 0 ||
                            user == "" || (isedit ? false : (password.length === 0 || confirmpassword.length === 0)) || !phone1
                          }
                        >
                          <span className="button_padding">{!isedit ? "Create User" : "Edit user"}</span>
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
        </div>
        {/* <Foot /> */}
      </IonContent>
    </IonPage >
  );
};
export default Adminpage;
