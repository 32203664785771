import React, { useState } from 'react';
import axios from 'axios';
import {
    IonPage,
    IonContent,
    IonInput,
    IonButton,
    IonLoading,
    IonAlert
} from '@ionic/react';
import Anthropic from '@anthropic-ai/sdk';

const Transportation: React.FC = () => {
    const [startCity, setStartCity] = useState('');
    const [templeName, setTempleName] = useState('');
    const [transportDetails, setTransportDetails]: any = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const API_KEY = "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"
    const anthropic = new Anthropic({
        apiKey: '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',
        dangerouslyAllowBrowser: true
    });

    const fetchRouteDetails = async () => {
        setTransportDetails("")
        setLoading(true)
        try {
            const result: any = await anthropic.messages.create({
                model: "claude-3-5-sonnet-20241022",
                max_tokens: 1024,
                messages: [{
                    role: "user",
                    content: `Provide transportation details to reach ${templeName}  from ${startCity}. Include available modes of transport, approximate duration, and rough cost estimates  and the which area is that also.
    
              Respond ONLY with a JSON object with these exact keys:
              {
                "transportMode": "string",
                "duration": "string",
                "estimatedCost": number,
                "routes": ["string"]
              }`
                }],
            });
            const responseContent = result.content[0].text;
            const parsedResponse = JSON.parse(responseContent);
            console.log(parsedResponse, "parsedResponse")
            setTransportDetails(parsedResponse);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching route details:", error);
            setLoading(false)
        }
    };

    return (
        <IonPage>
            <IonContent>
                <div style={{ padding: "30px" }}>
                    <IonInput
                        value={startCity}
                        placeholder="Enter Starting City"
                        onIonChange={e => setStartCity(e.detail.value!)}
                    />
                    <IonInput
                        style={{ marginTop: "10px" }}
                        value={templeName}
                        placeholder="Enter Temple Name"
                        onIonChange={e => setTempleName(e.detail.value!)}
                    />
                    <div style={{ marginTop: "20px" }}>
                        <IonButton
                            className='btn_v2'
                            disabled={templeName === "" || startCity === ""}
                            onClick={() => fetchRouteDetails()}>
                            <span className='button_padding'>Get Transport Details</span>
                        </IonButton>
                    </div>

                    <IonLoading
                        isOpen={loading}
                        message={'Fetching transport details...'}
                    />

                    {error && (
                        <IonAlert
                            isOpen={!!error}
                            onDidDismiss={() => setError(null)}
                            header={'Error'}
                            message={error}
                            buttons={['OK']}
                        />
                    )}

                    {transportDetails && (
                        <div style={{ marginTop: "20px" }}>
                            <h3>Transport Modes: {transportDetails.transportMode}</h3>
                            <p>Duration: {transportDetails.duration}</p>
                            <div>
                                <h4>Estimated Costs:</h4>
                                {typeof transportDetails.estimatedCost === "object" ? (
                                    <ul>
                                        {Object.entries(transportDetails.estimatedCost).map(([mode, cost]) => (
                                            <li key={mode}>
                                                {`${mode}: ₹${cost}`}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>Cost: ₹{transportDetails.estimatedCost}</p>
                                )}
                            </div>
                            <h4>Routes:</h4>
                            <ul>
                                {transportDetails.routes.map((route: string, index: number) => (
                                    <li key={index}>{route}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Transportation;