import { IonButton, IonCol, IonGrid, IonRow, IonText, IonIcon } from "@ionic/react";
import { Modal } from "react-bootstrap";
import { informationCircleOutline, alertCircleOutline, warningOutline } from "ionicons/icons";
import { useEffect } from "react";

const CommonModal = ({ showModal, AlertModalMesg, closeModal, modaltitle }: any) => {
    // Define styles and icons based on the message type
    const getModalStyle = () => {
        switch (modaltitle) {
            case "Information":
                return { backgroundColor: "#e7f3ff", color: "#007bff", icon: informationCircleOutline };
            case "Error":
                return { backgroundColor: "#fdecea", color: "#d93025", icon: alertCircleOutline };
            case "Warning":
                return { backgroundColor: "#fff4e5", color: "#ff9800", icon: warningOutline };
            default:
                return { backgroundColor: "#ffffff", color: "black", icon: informationCircleOutline };
        }
    };

    const { backgroundColor, color, icon } = getModalStyle();

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter" && showModal) {
                closeModal();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [showModal, closeModal]);

    return (
        <Modal show={showModal} className="common_info_modal" size="sm" centered>
            <Modal.Header style={{ backgroundColor }}>
                <IonGrid>
                    <IonRow className="off_info_modal_header">
                        <IonCol size="12">
                            <IonText className="info_modal_offname" style={{ color, fontWeight: "bold" }}>
                                {modaltitle}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor }}>
                <div style={{ textAlign: "center", color: "#333" }}>
                    <IonText>{AlertModalMesg}</IonText>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <IonButton className="btn_v2" onClick={closeModal}>
                        <span className="button_padding">Ok</span>
                    </IonButton>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CommonModal;
