import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonCheckbox,
  IonModal,
  IonTextarea,
  IonGrid,
  IonText,
  useIonAlert,
  IonLoading,
  IonSearchbar,
  IonAlert,
  IonProgressBar,
  IonBadge
} from "@ionic/react";
import React, { useCallback, useContext, useEffect, useRef, useState, } from "react";
import Slider from "react-slick";
import useRazorpay from "react-razorpay";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./Grammerce.css"
import "./project.css"
import "./sam-temp.css";
import { arrowBack, arrowForward, informationCircleOutline, caretBack, caretForward, add, remove, close, closeOutline, removeOutline, addOutline, informationCircle, gift, chevronForward, chevronBack, mapOutline, locate, locationOutline, checkmarkCircleOutline, checkmarkCircleSharp, cart } from "ionicons/icons";
import UserHead from "./UserHead";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import { useHistory, useLocation } from "react-router";
import { Capacitor } from "@capacitor/core";
import { useForm } from "react-hook-form";
import UserService from "../Services/auth.service";
import validator from 'validator'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from "moment";
import HeadFoot from "./Head";
import { closeicon, location1 } from "../theme/imagePath";
import { tick } from "../theme/imagePath";
import { Link } from "react-router-dom";
import { PhoneAuthProvider, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import Lightbox from "react-image-lightbox";


const Grammerce: React.FC = () => {

  // const [presentAlert] = useIonAlert();

  const [token, setUsertoken]: any = useState(sessionStorage.getItem("token"));
  // const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [data, setData]: any = useState([]);
  const [searchData, setSearchData]: any = useState([]);
  const [grammerce, setGrammerce]: any = useState([]);

  const [index, setIndex] = useState(0);
  const [tot, setTot]: any = useState("");
  const [name, setName]: any = useState("");
  const [cname, setCname]: any = useState("");
  const [email, setEmail]: any = useState("");
  const [loginemail, setLoginEmail]: any = useState("");
  const [pincode, setPin]: any = useState("");
  const [searchQuery, setSearchQuery]: any = useState("");
  const [searchpincode, setSearchpincode] = useState("")
  const [deliAdd, setDeliAdd]: any = useState("");
  const [gramModalopen, setGramModalopen]: any = useState(false);
  const [che, setChe] = useState(false);
  const [gif, setGif] = useState(false)
  const [giftopen, setGiftopen] = useState(false)
  const [img, setImg]: any = useState([]);
  const [significance, setSignificance] = useState("");
  const [activeIND, setActiveind]: any = useState(false)
  const [add, setAdd]: any = useState(sessionStorage.getItem("Address"));
  const [isError, setError] = useState(false)
  const [stock, setStock]: any = useState("")
  const [Alert] = useIonAlert();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [payProceed, setPayProceed] = useState(false);
  const location: any = useLocation()
  const [giftby, setGiftby] = useState("");
  const [giftnote, setGiftnote] = useState("");
  const [enable, setEnable]: any = useState()
  const [getAddress, setGetAddress]: any = useState()
  const [password, setPassword] = useState("");

  const [signupmodal, setSignupmodal] = useState(false)
  const [loginmodal, setLoginmodal] = useState(false)
  const [iserror, setIserror] = useState(false);
  // const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
  const [userdata, setUserdata]: any = useState(sessionStorage.getItem("UserData"));
  const [userAddress, setUserAddress]: any = useState(sessionStorage.getItem("Address"));
  const [dmail, setDmail]: any = useState(sessionStorage.getItem("Email"))
  const [dphone, setDphone]: any = useState(sessionStorage.getItem("Phone"));
  const [dname, setDName]: any = useState(sessionStorage.getItem("Name"));
  const [deleteModal, setDeleteModal] = useState(false);
  const [delItem, setDelItem]: any = useState()
  const sliderRef: any = useRef()
  const [actionFlag, setActionFlag] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const widthSize = window.innerWidth
  const [slidesToShow, setSlidesToShow]: any = useState(widthSize > 700 ? 3 : widthSize > 500 ? 2 : widthSize < 500 ? 1 : 0);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const [isValidPhoneNumberLogin, setIsValidPhoneNumberLogin] = useState(false);



  var DelTot: any = 0;

  const Razorpay = useRazorpay();
  const formatter = new Intl.NumberFormat('en-IN')

  const mailPat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const namepat = /^[a-zA-Z\s]*$/
  const [delChargeData, setDelChargeData]: any = useState("")
  const buttonClickedRef = useRef(index);
  const actionClickedRef = useRef(actionFlag);
  const [formErrors, setFormErrors]: any = useState({});
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [showAlert, setShowAlert] = useState(false);
  const [returnPage, setRtnPage] = useState("");
  const [rerenderKey, setRerenderKey] = useState(0);

  const { amountLimit }: any = useContext(AppContext)!;

  const goToLastSlide = () => {
    console.log(sliderRef.current)
    if (sliderRef.current) {
      const lastSlideIndex = sliderRef.current.props.children.length - 1;
      sliderRef.current.slickGoTo(lastSlideIndex);
    }
  };

  const goTofirstSlide = () => {
    console.log(sliderRef.current)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    const slideCount = props.slideCount
    console.log(slideCount, currentSlide)
    return (
      <div className={currentSlide === slideCount - slidesToShow ? "slick_nxt_disable" : "slick_nxt"} style={{ display: "flex" }}>
        <div onClick={onClick}  >
          <IonIcon icon={chevronForward} />
        </div>
        <div onClick={goToLastSlide}>
          <div>
            <IonIcon icon={chevronForward} />
          </div>
          <div className="chervonIcon">
            |
          </div>
        </div>
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    console.log(props)
    const currentSlide = props.currentSlide
    return (
      <div className={currentSlide === 0 ? "slick_prev_disable" : "slick_prev"} style={{ display: 'flex' }}>
        <div onClick={goTofirstSlide}>
          <div >
            <IonIcon icon={chevronBack} />
          </div>
          <div className="chervonBackIcon">
            |
          </div>
        </div>
        <div onClick={onClick}>
          <IonIcon icon={chevronBack} />
        </div>
      </div>
    );
  };
  const [Settings, setSettings] = useState({
    dots: false,
    horizontal: true,
    speed: 500,
    slidesToShow: 3,
    infinite: false,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })


  const uFlag: any = useState(sessionStorage.getItem("uFlag"))
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const mobile = screenWidth <= 1000;

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setHeadName("Home,Grammerce");
    // if (userdata[0] === null) {
    //   history.push({
    //     pathname: "/login",
    //     state: {
    //       local: location.pathname
    //     }
    //   })
    // } else {
    if (userdata !== null && userdata !== "ROLE_USER") {
      history.push("/home")
    }
    else if (userdata !== null && uFlag[0] === "false") {
      history.push("/home")
    }
    // else if (userdata === null) {
    //   setPincodemodal(true)
    // }
    // else {
    //   setPincodemodal(false)
    // }
    if (userAddress === null) {
      setEnable(false)
      setGetAddress(false)
     pincodemodalopen()
    
    } else {
      var pincode = userAddress.split(",")
     // setSearchpincode(pincode[pincode.length - 1])
     // showmaterial(pincode[pincode.length - 1])
      setGetAddress(true)
      setEnable(true)
    }
    showmaterial("");
    // }

    const unlisten = history.block((location, action) => {

      if (!actionClickedRef.current && (buttonClickedRef.current === 2 || buttonClickedRef.current === 3 || buttonClickedRef.current === 4)) {
        setRtnPage(location.pathname)
        setActionFlag(true)
        // return "Are you sure want to Leave? If you leave, your order details will be lost.";
        setShowAlert(true);
        return false;
        //alert("Are you sure want to Leave? If you leave, your order details will be lost.")
      }
      // Code to execute when the page is about to leave
      console.log('Leaving MyPage');
      // Additional actions can be performed here

    });

    window.addEventListener('resize', updateScreenWidth);

    return () => {
      unlisten();
      window.removeEventListener('resize', updateScreenWidth);

    };

    // Clean up the event listener when the component unmounts
  }, [history]);





  useEffect(() => {
    buttonClickedRef.current = index;
    actionClickedRef.current = actionFlag;
  }, [index, actionFlag]);
  const showmaterial = (pin: any) => {
    setShowLoading(true);
    Authservice.ShowAllGrammerce(pin)
      .then((response) => {
        console.log(response);
        setData(response.data.map((pro: any) => {
          return { ...pro, quantity: 0 }
        }));
        setSearchData(response.data.map((pro: any) => {
          return { ...pro, quantity: 0 }
        }));
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error)
        setShowLoading(false);
      });
  }
  const searchItems: any = Object.values(data)
  const [searchParam] = useState(["productName"]);
  const search = (datas: any) => {

    return datas.filter((item: any) => {
      if (item.productName) {
        return searchParam.some((newItem: any) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) > -1
          );
        });
      }
    });
  }
  const changeSearchQuery = (query: any) => {
    console.log(search(searchItems).length)
    setSearchQuery(query)
    setRerenderKey((prevKey) => prevKey + 1);
  }

  const quantityIncre = (items: any, i: any, num: any) => {
    const newGrammerceItem = grammerceItem.map((item: any, index: any) => {
      if (i === index) {
        const max = parseInt(items.stock, 10);
        const newQuantity = item.quantity + 1;

        if (newQuantity > 0) {
          setActiveind(true);
        }

        if (num === 1 && newQuantity > max) {
          Alert(max + ' Stock only  ');
          return { ...item, quantity: max };
        } else {
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    });

    setGrammerceItem(newGrammerceItem);

    // Update data array too
    const newData = data.map((item: any) => {
      if (items.id === item.id) {
        const max = parseInt(items.stock, 10);
        const newQuantity = item.quantity + 1;

        if (newQuantity > 0) {
          setActiveind(true);
        }

        if ((num === 2 || num === 1) && newQuantity > max) {
          if (num === 2) {
            Alert(max + ' Stock only  ');
          }
          return { ...item, quantity: max };
        } else {
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    });

    setData(newData);

    let t: any = 0;
    newGrammerceItem.forEach((p: any) => {
      if (p.quantity > 0) {
        t = t + p.quantity * p.price;
        setTot(t);
      }
    });

    setTot(calculateTotal([...newData]));
  };

  const handleChange = (event: any, i: any, items: any, num: any) => {
    const min = 0;
    const max = items.stock + 1;
    setStock(items.stock);

    const value = Math.round(Math.max(min, Math.min(max, Number(event.target.value))));

    const updatedGrammerceItem = grammerceItem.map((item: any, index: any) => {
      if (i === index) {
        const newQuantity = value;
        if (newQuantity > 0) {
          setActiveind(true);
        }

        if (num === 1 && newQuantity > item.stock) {
          Alert(item.stock + ' Stock only');
          return { ...item, quantity: item.stock };
        }
        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    setGrammerceItem(updatedGrammerceItem);

    const updatedData = data.map((item: any) => {
      if (item.id === items.id) {
        const newQuantity = value;

        if (num === 2 && newQuantity > item.stock) {
          Alert(item.stock + ' Stock only');
          return { ...item, quantity: item.stock };
        }
        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    setData(updatedData);

    let t = 0;
    updatedGrammerceItem.forEach((p: any) => {
      if (p.quantity > 0) {
        t += p.quantity * p.price;
      }
    });

    setTot(t);

    let tUpdatedData = 0;
    updatedData.forEach((p: any) => {
      if (p.quantity > 0) {
        tUpdatedData += p.quantity * p.price;
      }
    });

    setTot(tUpdatedData);

    if (updatedGrammerceItem.every((e: any) => e.quantity === 0)) {
      setActiveind(false);
      setTot(0);
    }
  };

  const calculateTotal = (items: any) => {
    let total = 0;
    items.forEach((item: any) => {
      if (item.quantity > 0) {
        total += item.quantity * item.price;
      }
    });
    return total;
  };

  const removeItem = () => {
    var arr = [];
    arr = data;
    const newarr = arr.map((item: any, i: any) => {
      if (item.productName === delItem.productName) {
        var val = 0;
        return { ...item, quantity: val }
      }
      return item
    })
    setData(newarr)
    setGrammerce(newarr)
    setTot(calculateTotal([...newarr]));
    if (newarr.every((e: any) => (e.quantity == 0))) {
      setActiveind(false)
      setTot(0)
    }
    setDeleteModal(false)
    setDelItem('')
  }

  const openDeleteModal = (item: any) => {
    setDelItem(item)
    setDeleteModal(true)
  }

  const quantityDecre = (items: any, i: any) => {
    const newGrammerceItem = grammerceItem.map((item: any, index: any) => {
      if (i === index) {
        if (item.quantity > 0) {
          const newQuantity = item.quantity - 1;
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    });

    setGrammerceItem(newGrammerceItem);

    const newData = data.map((item: any) => {
      if (items.id === item.id) {
        if (item.quantity > 0) {
          const newQuantity = item.quantity - 1;
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    });

    setData(newData);

    const newTot = tot - items.price;
    setTot(newTot);

    if (newGrammerceItem.every((e: any) => e.quantity === 0)) {
      setActiveind(false);
      setTot(0);
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const noimage: any = "assets/icon/image.png";
  const openModal = (item: any) => {

    if (item.grammerceImgsUrl === null) {
      setImg(noimage);
    }
    else {
      setImg(item.grammerceImgsUrl[0]);
    }
    setSignificance(item.significance);
    setGramModalopen(true)


  }

  const show = () => {
    const errors: any = {};

    if (name == "") {
      errors.name = 'Please Enter name';
      // alert({
      //   header: 'Alert',
      //   message: 'Enter name',
      //   buttons: ['OK'],
      // })
    }
    else if (namepat.test(name) == false) {
      errors.name = 'Alphabets only Allowed in Name';
      // alert({
      //   header: 'Alert',
      //   message: 'Alphabets only Allowed in Name',
      //   buttons: ['OK'],
      // })
    }
    else if (name.charAt(0) == " ") {
      errors.name = 'Empty Space at Name';
      // alert({
      //   header: 'Alert',
      //   message: 'Empty Space at Name',
      //   buttons: ['OK'],
      // })
    }
    if (cname == "" || cname === undefined) {
      errors.cname = 'Please Enter Contact Number';

      // alert({
      //   header: 'Alert',
      //   message: 'Enter Contact Number',
      //   buttons: ['OK'],
      // })
    } else if (!isValidPhoneNumber) {
      errors.cname = "Please Enter valid Mobile Number"
    }
    // else if (!(cname.match('[0-9]{10}'))) {
    //   alert({
    //     header: 'Alert',
    //     message: 'Enter Valid Mobile Number',
    //     buttons: ['OK'],
    //   })
    // }
    if (email == "") {
      errors.email = 'Please enter email id';
      // alert({
      //   header: 'Alert',
      //   message: 'Enter Email ID',
      //   buttons: ['OK'],
      // })
    }
    else if (validator.isEmail(email) == false) {
      errors.email = 'Please enter valid email id';
      // alert({
      //   header: 'Alert',
      //   message: 'Enter Valid Email Address',
      //   buttons: ['OK'],
      // })
    }
    if (deliAdd == "") {
      errors.deliAdd = 'Please Enter Delivery Address';
      // alert({
      //   header: 'Alert',
      //   message: 'Enter Delivery Address',
      //   buttons: ['OK'],
      // })
    }
    else if (deliAdd.charAt(0) == " ") {
      errors.deliAdd = 'Empty Space at Delivery Address';
      // alert({
      //   header: 'Alert',
      //   message: 'Empty Space at Delivery Address',
      //   buttons: ['OK'],
      // })
    }
    if (pincode == "") {
      errors.pincode = 'Please Enter Pincode';
      // alert({
      //   header: 'Alert',
      //   message: 'Enter Pincode',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.charAt(0) == "0") {
      errors.pincode = 'Pincode is invalid';
      // alert({
      //   header: 'Alert',
      //   message: 'Pincode is invalid',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.length != 6) {
      errors.pincode = 'Please Enter Valid Pincode';
      
    }
    if (giftopen == true && giftby == "") {
      errors.giftby = 'Please Enter Gifted By';
      // alert({
      //   header: "Atmagram",
      //   message: "Enter Gifted By",
      //   buttons: ["OK"]
      // })
    }
    else if (giftopen == true && giftby.charAt(0) == " ") {
      errors.giftby = 'Empty Space in  Gifted By';

      // alert({
      //   header: "Atmagram",
      //   message: "Empty Space in  Gifted By",
      //   buttons: ["OK"]
      // })
    }
    else if (giftopen == true && namepat.test(giftby) == false) {
      errors.giftnote = 'Alphabets only Allowed in Gifted By';

      // alert({
      //   header: 'Alert',
      //   message: 'Alphabets only Allowed in Gifted By',
      //   buttons: ['OK'],
      // })
    }

    if (giftopen == true && giftnote == "") {
      errors.giftnote = 'Please Enter Gift Note';

      // alert({
      //   header: "Atmagram",
      //   message: "Enter Gift Note",
      //   buttons: ["OK"]
      // })
    }
    else if (giftopen == true && giftnote.charAt(0) == " ") {
      errors.giftnote = 'Empty Space in Gift Note';
      // alert({
      //   header: "Atmagram",
      //   message: "Empty Space in Gift Note",
      //   buttons: ["OK"]
      // })
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      // if (token == null) {
      //   setLoginmodal(true)
      // }
      // else {
      //   bookpayment(token);
      // }
      pinvalid()
    }
  }
  const pinvalid = () => {
    var data: any = []
    searchItems.map((item: any, i: any) => {
      if (item.quantity > 0) {
        data.push(item.id)
      }
    })
    console.log(data)

    Authservice.pinValidGramm(pincode, data).then((response: any) => {
      console.log(response.data, "gcf");
      if (response.data.MESSAGE == "SUCCESS") {
        // if (token == null) {
        //   setLoginmodal(true)
        // } else {
        // setIndex(4)
        setActionFlag(true)
        bookpayment(token)
        // }
      } else if (response.data.MESSAGE == "FAILURE") {
        const products = searchItems.filter((e: any) => {
          const matchingids = response.data.DATA.find((item: any) => item === e.id);
          return matchingids != null;
        });
        const arr = products.map((item: any) => `<b>${item.productName}</b>`);
        if (arr.length > 2) {
          Alert(`${arr.slice(0, -1).join(', ')}, and ${arr.slice(-1)}` + " are not deliverable to this Pincode")
        } else if (arr.length == 1) {
          Alert(arr[0] + " is not deliverable for this Pincode")
        }
        else if (arr.length == 2) {
          Alert(arr[0] + " and " + arr[1] + " are not deliverable for this Pincode")
        }
      } else {
        Alert("item(s) are not deliverable for this Pincode")
      }
    })
      .catch((error: any) => {
        console.log(error);
        setShowLoading(false);
      });

  }
 
  const bookpayment = (e: any) => {
    var list: any = [];
    searchItems.map((item: any, i: any) => {
      if (item.quantity > 0) {
        list.push(item);
      }

    })
    var delAdd = deliAdd + "," + pincode;
    var dataDel = {

      "name": name,

      "contactNumber": cname,

      "emailId": email,

      "deliveryAddress": delAdd,

      "listOfGrammerceDto": list,

      "totalPrice": tot,


    };

    var delJsonObj = JSON.stringify(dataDel);
    setShowLoading(true)
    Authservice.calculateDelGrammerce(e, dataDel)
      .then((response) => {
        console.log("delChat" + response.data);
        setDelChargeData(response.data)
        console.log(DelTot)
        setActionFlag(false)
        setIndex(4)
        setHeadName("Home,Booking Details")
        var list = response.data.listOfGrammerceDto;
        // for(var a=0;a<=list.length-1;a++ ){
        //   if(list[a].activeFlag&&list[a].stock>0){
        //     setPayProceed(true)
        //   }else{

        //     setPayProceed(false)
        //     alert(list[a].productName+" not available now")
        //   }
        // }
        setShowLoading(false)

      })
      .catch((error) => {
        setShowLoading(false)
        console.log(error)
      });

  }

  const [errormodal, setErrormodal] = useState(false)
  const [errormsg, setErrormsg] = useState([])
  const [pincodemodal, setPincodemodal] = useState(false)

  const OrderBtn = () => {
    if (tot > amountLimit) {
      Alert({
        header: "Atmagram",
        message: "Maximum Transaction limit is Rs. " + formatter.format(amountLimit),
        buttons: [
          {
            text: "ok",
          }
        ]
      })
    }
    else if (activeIND === true) {
      setIndex(3)
      setHeadName("Home,Booking Details")
    }
    else {
      Alert('Select one Product')
    }
  }
  const paymentFailedResponse = (refId:any,e:any) => {
    var resData: any = {

      
      "cartPayRefId":refId,
      

    }
    console.log("resData", resData);
    Authservice.grammercePaymentMultiRes(resData, e)
      .then((response) => {
        console.log("payresponsefailed" + response);
       
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const payment = (e: any) => {
    setActionFlag(true)
    setShowLoading(true);
    var list: any = [];
    searchItems.map((item: any, i: any) => {
      if (item.quantity > 0) {
        list.push(item);
      }

    })
    console.log(list)
    var delAdd = deliAdd + "," + pincode;
    var dataPay = {

      "name": name,

      "contactNumber": cname,

      "emailId": email,

      "deliveryAddress": delAdd,

      "listOfGrammerceDto": delChargeData.listOfGrammerceDto,

      "totalPrice": tot,

      "giftFlag": giftopen,

      "giftNote": giftnote,

      "giftedBY": giftby

    }
    console.log('datapay', dataPay);

    Authservice.grammercePayment(dataPay, e)
      .then((response) => {
        console.log(response, "checking")

        console.log("payresponse" + response.data.orderId);

        if (response.data.errors === null) {
          var options: any = {
            key: response.data.pgOption.key,
            key_secret: response.data.secretKey,
            amount: response.data.pgOption.amount,
            currency: response.data.pgOption.currency,
            name: response.data.pgOption.name,
            order_id: response.data.pgOption.order_id,
            description: response.data.pgOption.description,
            modal: {
              escape: false, ondismiss: function () {
                // code here 
                Alert("Payment Failed")
                paymentFailedResponse (response.data.cartPayRefId,e)
              }
            },
            handler: function (res: any) {
              // alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
              var resData: any = {

                "amount": response.data.pgOption.amount,
                "orderId": res.razorpay_order_id,
                "cartPayRefId": response.data.cartPayRefId,
                "signature": res.razorpay_signature,
                "paymentId": res.razorpay_payment_id

              }
              console.log("resData", resData);
              Authservice.grammercePaymentMultiRes(resData, e)
                .then((response) => {
                  console.log("payresponse" + response);
                  Alert({
                    header: "Atmagram",
                    message: "your order placed successfully, please visit your  My Orders",
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: "ok",
                        handler: () => {
                          window.location.reload()
                        }
                      }
                    ]
                  })
                })
                .catch((error) => {
                  console.log(error)
                });

            },
            prefill: {
              name: name,
              email: email,
              contact: cname
            },
            notes: {
              address: deliAdd
            },
            theme: {
              color: response.data.theme
            }
          };
          var pay: any = new Razorpay(options)
          pay.on("payment.failed", function (data: any) {
            // alert(data.error.code);
            // alert(data.error.description);
            // alert(data.error.source);
            // alert(data.error.step);
            Alert(data.error.reason);
         //   paymentFailedResponse (response.data.cartPayRefId,e)

            // alert(data.error.metadata.order_id);
            // alert(data.error.metadata.payment_id);
          });

          pay.on("payment.cancelled", function (data: any) {
            Alert(data.error.reason);
          //  paymentFailedResponse (response.data.cartPayRefId,e)
            // Additional handling or redirection logic can be added here if needed.
          });
          setShowLoading(false);
          pay.open()
        }
        else {
          setShowLoading(false);
          setErrormodal(true)
          console.log(response.data.errors)
          setErrormsg(response.data.errors)
        }
      })
      .catch((error) => {
        console.log(error)
      });

  }
  const sameas = (e: any) => {
    console.log(e)
    if (e.target.checked) {
      if (!enable) {
        Alert("Enter Address Details at My Profile")
        setChe(false)
      } else {
        console.log("aa")
        setChe(true)
        var pin = add.split(',');
        var arrLlen = pin.length - 1;
        var address: any = "";
        for (var a = 0; a < arrLlen; a++) {
          if (a == 0) {
            address = address + pin[a];

          } else {
            address = address + "," + pin[a];
          }

        }
        setDeliAdd(address)
        setPin(pin[arrLlen]);
      }
      if (dname != null && dname != '') {
        setName(dname)

      } else {
        Alert("Enter Name at My Profile")
        setChe(false)
      }
      if (dphone != null && dphone != '') {
        if (!dphone.startsWith("+")) {
          setCname("+91" + dphone)
          try {
            const phoneNumberObject: any = parsePhoneNumberFromString("+91" + dphone);
            setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
            console.log(phoneNumberObject.isValid())
          } catch (error) {
            setIsValidPhoneNumber(false);
            console.log(error)
          }
        } else {
          try {
            const phoneNumberObject: any = parsePhoneNumberFromString(dphone);
            setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
            console.log(phoneNumberObject.isValid())
          } catch (error) {
            setIsValidPhoneNumber(false);
            console.log(error)
          }
          setCname(dphone)
        }
        setFormErrors((prev: any) => {
          return { ...prev, cname: "" }
        })
      } else {
        Alert("Enter mobile number at My Profile")
        setChe(false)
      }
      if (dmail != null && dmail != '') {
        setEmail(dmail)

      } else {
        Alert("Enter Email at My Profile")
        setChe(false)
      }
    } else {
      setChe(false)
      setDeliAdd("")
      setPin("")
      setCname('')
      setName('')
      setEmail('')
    }
  }
  const gift = (e: any) => {
    if (e.target.checked) {
      setGif(true)
      setGiftopen(true)
    }
    else {
      setGif(false)
      setGiftopen(false)
      setGiftby("");
      setGiftnote("");
      setFormErrors((prev: any) => {
        return { ...prev, giftby: "", giftnote: "" }
      })
    }
  }

  const useOutsideAlerter = (refer: any, logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setGramModalopen(false)
        }
        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(true)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const grammercemodal = useRef(null);
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)

  useOutsideAlerter(grammercemodal, loginmodalref, signupmodalref);

  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const namecheck = /^[a-zA-Z\s]*$/

  const [user, setUser]: any = useState("2");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [aadhar1, setAadhar1] = useState("");
  const [firstname1, setFirstname1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [email1, setEmail1] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const signupphoneInputRef = useRef(null)
  const moveCursorToEnd = (value: any) => {
    const input: any = signupphoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const moveCursorToEndonLogin = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone(value)
  };

  const contactnoref = useRef(null)

  const cursorend = (value: any) => {
    const input: any = contactnoref.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      console.log(phoneNumberObject.isValid())
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setCname(value)
  };

  const restrictE = (e: any) => {
    if (e.key == "e") {
      e.preventDefault()
    }
  }

  const Validation = (label: any) => {
    if (label === "GName") {
      if (name == "") {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Please Enter name" }
        })
      }
      else if (namepat.test(name) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Alphabets only Allowed in Name" }
        })
      }
      else if (name.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Empty Space at Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, name: "" }
        })
      }
    }
    if (label === "GMobile") {
      if (cname == "" || cname === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "Please Enter Contact Number" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "Please Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "" }
        })
      }
    }
    if (label === "GEmail") {
      if (email == "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please enter email id" }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please Enter Valid Email id" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "GAddress") {
      if (deliAdd == "") {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "Please Enter Delivery Address" }
        })
      }
      else if (deliAdd.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "Empty Space at Delivery Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "" }
        })
      }
    }

    if (label === "GPincode") {
      if (pincode == "") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter Pincode" }
        })
      }
      else if (pincode.charAt(0) == "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter Valid Pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "Ggiftby") {
      if (giftopen == true && giftby == "") {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Please Enter Gifted By" }
        })
      }
      else if (giftopen == true && giftby.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Empty Space in  Gifted By" }
        })
      }
      else if (giftopen == true && namepat.test(giftby) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Alphabets only Allowed in Gifted By" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "" }
        })
      }
    }
    if (label === "GgiftNote") {
      if (giftopen == true && giftnote == "") {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "Please Enter Gift Note" }
        })
      }
      else if (giftopen == true && giftnote.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "Empty Space in Gift Note" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "" }
        })
      }
    }

    if (label === "firstname") {
      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })

      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })

      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumberSign) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }


    if (label === "email") {
      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }

    if (label === "password") {
      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "login email") {
      if (loginemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "Email is required" }
        })
      }
      else if (validator.isEmail(loginemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "" }
        })
      }
    }

    if (label === "login password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "phone") {
      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(phone);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }
      if (phone == "" || phone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please Enter Contact Number" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }

    if (label === "otp") {
      if (otp === undefined || otp === "" || otp == null) {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "Enter OTP" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "" }
        })
      }
    }

    if (label === "Delivery Pincode") {
      if (pinchange === "") {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: 'Please Enter the Pincode' }
        })
      }
      else if (pinchange.charAt(0) === "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "Pincode not start with 0" }
        })
      }
      else if (pinchange.length !== 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "Please enter a valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "" }
        })
      }
    }
  }

  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }
  const ChangeValidation = (label: any, e: any) => {
    if (label === "GName") {
      const name = e.target.value;
      if (name == "") {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Please Enter name" }
        })
      }
      else if (namepat.test(name) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Alphabets only Allowed in Name" }
        })
      }
      else if (name.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, name: "Empty Space at Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, name: "" }
        })
      }
    }
    if (label === "GMobile") {
      const cname = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (cname == "" || cname === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "Please Enter Contact Number" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "Please Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, cname: "" }
        })
      }
    }
    if (label === "GEmail") {
      const email = e.target.value;

      if (email.trim() == "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please enter email id" }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please enter valid email id" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "GAddress") {
      const deliAdd = e.target.value;

      if (deliAdd == "") {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "Please Enter Delivery Address" }
        })
      }
      else if (deliAdd.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "Empty Space at Delivery Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, deliAdd: "" }
        })
      }
    }

    if (label === "GPincode") {
      const pincode = e.target.value;

      if (pincode == "") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter Pincode" }
        })
      }
      else if (pincode.charAt(0) == "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter Valid Pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "Ggiftby") {
      const giftby = e.target.value;

      if (giftopen == true && giftby == "") {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Please Enter Gifted By" }
        })
      }
      else if (giftopen == true && giftby.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Empty Space in  Gifted By" }
        })
      }
      else if (giftopen == true && namepat.test(giftby) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "Alphabets only Allowed in Gifted By" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, giftby: "" }
        })
      }
    }
    if (label === "GgiftNote") {
      const giftnote = e.target.value;

      if (giftopen == true && giftnote == "") {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "Please Enter Gift Note" }
        })
      }
      else if (giftopen == true && giftnote.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "Empty Space in Gift Note" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, giftnote: "" }
        })
      }
    }

    if (label === "firstname") {
      const firstname1 = e.target.value;

      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })

      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })

      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {

      const phone1 = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }


    if (label === "email") {
      const email1 = e.target.value;

      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }

    if (label === "password") {
      const password1 = e.target.value;

      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "login email") {
      const loginemail = e.target.value;

      if (loginemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "Email is required" }
        })
      }
      else if (validator.isEmail(loginemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginemail: "" }
        })
      }
    }

    if (label === "phone") {
      const cname = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
        setIsValidPhoneNumberLogin(valid)
      } catch (error) {
        valid = false;
        console.log(error)
        setIsValidPhoneNumberLogin(valid)
      }

      if (cname == "" || cname === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please Enter Contact Number" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }

    if (label === "otp") {
      const otp = e.target.value;
      if (otp === undefined || otp === "" || otp == null) {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "Enter OTP" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "" }
        })
      }
    }

    if (label === "login password") {
      const password = e.target.value;

      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "Delivery Pincode") {
      const pinchange = e.target.value;

      if (pinchange === "") {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: 'Please Enter the Pincode' }
        })
      }
      else if (pinchange.charAt(0) === "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "Pincode not start with 0" }
        })
      }
      else if (pinchange.length !== 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "Please enter a valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pinerror: "" }
        })
      }
    }
  }

  const submit = () => {
    const errors: any = {};

    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
   
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password1.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password1 != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      console.log(aadhar1,
        email1,
        phone1,
        password1,
        user,
        user,
        firstname1)
      UserService.Registermaterials(
        email1,
        firstname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            setIssuccess(true);
            setConfirmpassword("");
            setEmail1("");
            setFirstname1("");
            setPassword("");
            setPhone1("");
            setUser("");
            loginalertmodalaction(true, "Register Successfully")
            setSignupmodal(false)
            // Alert("Register Successfully");
            console.log(response, "resss =>")
          }
          else if (response.responseText == "ERROR:Email Id already exist") {
            loginalertmodalaction(true, "Email Id Already Exists, Try Any Other Email Id")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            loginalertmodalaction(true, "Mobile Number Already Exists, Try Any Other Mobile Number")
          } else {
            loginalertmodalaction(true, "User Not Register Successfully")
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          loginalertmodalaction(true, "Something went wrong")
        });
    }
  }
  const Searchlog = (value: any) => {
    if (value == "Enter") {
      Loginsubmit()
    }
  }
  const handleLeave = () => {
    setShowAlert(false);
    history.push(returnPage); // Example: Navigate to the new page
  };


  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")
  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const alertmodalclose = () => {
    if (loginalertdata === "Register Successfully") {
      setLoginmodal(true)
      setLoginalertmodal(false)
    }
    else {
      setLoginalertmodal(false)
    }
    setLoginalertdata("")
  }

  const Loginsubmit = () => {
    const errors: any = {};

    if (loginemail.trim() === '') {
      errors.loginemail = 'Email is required';
    }
    else if (validator.isEmail(loginemail) === false) {
      errors.loginemail = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

      setShowLoading(true);
      UserService.Login(loginemail, password,"")
        .then((response) => {
          setShowLoading(false);

          if (response.data.responseText.startsWith("SUCCESS")) {
            // if (Capacitor.getPlatform() === "web") {
            //   console.log(response, "ressss")
            if (response.data.userModel.role !== "ROLE_USER") {
              // Alert("This Account is not a user")
              loginalertmodalaction(true, "This Account is not a user")
            }
            else
              if (response.data.userModel.uflag === "false") {
                console.log(response.data.userModel.uflag, "uflag")
                // Alert("Account Not Approved Yet")
                loginalertmodalaction(true, "Account Not Approved Yet")
              }
              else {
                sessionStorage.setItem("token", response.data.userToken);
                sessionStorage.setItem("UserId", response.data.userModel.id);
                sessionStorage.setItem("UserData", response.data.userModel.role);
                sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                sessionStorage.setItem("Email", response.data.userModel.email);
                sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.firstName !== null) {
                  sessionStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.address !== null) {
                  sessionStorage.setItem("Address", response.data.userModel.address);
                }
                setLoginmodal(false)
                setDName(response.data.userModel.firstName);
                setDmail(response.data.userModel.email);
                setDphone(response.data.userModel.mobileNumber);
                setAdd(response.data.userModel.address);
                setUserAddress(response.data.userModel.address);
                setUsertoken(response.data.userToken)
                setEnable(true)
                setUserdata(response.data.userModel.role)
                console.log(response.data.userToken)
                Alert({
                  header: "Atmagram",
                  message: "LoggedIn Successfully",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        payment(response.data.userToken)
                      }
                    }
                  ]
                })
                // bookpayment(response.data.userToken);
              }
          }
          // }
          else {
            // Alert(response.data.responseText.split("ERROR:")[1]);
            loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])

          }
        })
        .catch((error) => {
          console.log(error);
          setLoginmodal(true)
          setShowLoading(false);
        });
    }
  };

  const [pinchange, setPinchange] = useState("")

  // const pinmodalclose = () => {
  //   const error: any = {};
  //   if (userdata === null && pinchange === "") {
  //     error.pinerror = 'Please Enter the Pincode';
  //   }
  //   setFormErrors(error)
  //   if (Object.keys(error).length === 0) {
  //     setPincodemodal(false)
  //   }
  // }
  const pincodemodalopen = () => {
    setPinchange(searchpincode)
    setPincodemodal(true)
    setTimeout(() => {
      pinCodeInputRef.current?.setFocus();
    }, 1000);
  }

  const pincodesubmit = () => {
    const error: any = {};
    if (pinchange === "") {
      error.pinerror = 'Please Enter the Pincode';
    }
    else if (pinchange.charAt(0) === "0") {
      error.pinerror = "Pincode not start with 0"
    }
    else if (pinchange.length !== 6) {
      error.pinerror = "Please enter a valid pincode"
    }
    setFormErrors(error);
    if (Object.keys(error).length === 0) {
      setSearchpincode(pinchange)
      showmaterial(pinchange)
      setPincodemodal(false)
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      pincodesubmit();
    }
  };


  const [hoverItem, setHoverItem]: any = useState("")
  const [grammerceItem, setGrammerceItem]: any = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [headName, setHeadName] = useState('');
  const [loginIndex, setLoginIndex] = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const [phone, setPhone] = useState("");
  const phoneInputRef = useRef(null);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [addshowModal, setAddshowModal] = useState(false);
  const pinCodeInputRef = useRef<any>(null);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const ViewItem = (item: any) => {
    console.log(item, "mkk")
    setIndex(1)
    grammerceItem.push(item)
    setHeadName("Home,Product View")
  }

  const isAnyQuantityPositive = grammerceItem.some((item: any) => item.quantity > 0);

  const hasQuantityGreaterThanZeroinData = data.some((item: any) => item.quantity > 0);

  const handleAddToCart = (items: any, i: any, num: any, e: any) => {
    if (num === 1) {
      // setHeadName("Home,Product View")

      const max = parseInt(items.stock, 10);
      const newQuantity = items.quantity + 1;

      // if (newQuantity > max) {
      //   Alert(max + ' Stock only !');
      //   return;
      // }

      // const newGrammerceItem = [
      //   ...grammerceItem,
      //   { ...items, quantity: newQuantity }
      // ];

      // setGrammerceItem(newGrammerceItem);

      const newData = data.map((item: any) => {
        if (items.id === item.id) {
          if (newQuantity > 0) {
            setActiveind(true);
          }

          if (newQuantity > max) {
            Alert(max + ' Stock only !');
            return { ...item, quantity: max };
          } else {
            return { ...item, quantity: newQuantity };
          }
        }
        return item;
      });

      setData(newData);

      // let t: any = 0;
      // newGrammerceItem.forEach((p: any) => {
      //   if (p.quantity > 0) {
      //     t += p.quantity * p.price;
      //   }
      // });

      // setTot(t);

      let tUpdatedData = 0;
      newData.forEach((p: any) => {
        if (p.quantity > 0) {
          tUpdatedData += p.quantity * p.price;
        }
      });

      setTot(tUpdatedData);

      e.stopPropagation();

      Alert({
        header: 'Atmagram',
        message: 'Item added to cart',
        buttons: ['OK'],
      })
    }
    else if (num === 2) {
      // setIndex(2);
      // setHeadName("Home,Shopping cart")

      const max = parseInt(items.stock, 10);
      const existingItem = grammerceItem.find((item: any) => item.id === items.id);

      if (existingItem) {
        if (existingItem.quantity >= max) {
          Alert(`${max} Stock only`);
          return;
        }

        const updatedItems = grammerceItem.map((item: any) =>
          item.id === existingItem.id ? { ...item, quantity: item.quantity + 1 } : item
        );

        setGrammerceItem(updatedItems);
      } else if (max > 0) {
        setGrammerceItem([...grammerceItem, { ...items, quantity: 1 }]);
      } else {
        Alert(`Item is out of stock`);
      }
    }
  };

  const ViewCart = () => {
    setIndex(2);
    setHeadName("Home,Shopping Cart");
    setTot(calculateTotal([...data]))
    setGrammerceItem([...data]);
  }

  const phoneLogin = () => {
    setFormErrors({})
    setLoginIndex(1)

  }
  const emailLogin = () => {
    setLoginIndex(0)
  }

  const checkMobile = () => {
    let valid: any = false
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(phone);
      valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
    } catch (error) {
      valid = false;
      console.log(error)
    }
    const errors: any = {};
    if (phone === undefined || phone === "") {
      errors.phone = "Mobile Number is required"
    } else if (!valid) {
      errors.phone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.validMob(phone).then((res) => {
        setShowLoading(false);
        if (res.data) {
          getOTP();
        } else {
          // alert("Please complete registration. Then only you can login with Atmagram")
          loginalertmodalaction(true, "Please complete registration. Then only you can login with Atmagram")
          // Alert({ header: 'Atmagram', message: "Please complete registration. Then only you can login with Atmagram",buttons: ['OK'], cssClass: 'custom-alert' })
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }

  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        // alert("Response expired. Please solve reCAPTCHA again")
        loginalertmodalaction(true, "Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, phone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        loginalertmodalaction(true, "Something went wrong, please try again later.")
        // alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        UserService.mobileLogin(phone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.responseText.startsWith("SUCCESS")) {
                // if (Capacitor.getPlatform() === "web") {
                //   console.log(response, "ressss")
                if (response.data.userModel.role !== "ROLE_USER") {
                  // Alert("This Account is not a user")
                  loginalertmodalaction(true, "This Account is not a user")
                }
                else
                  if (response.data.userModel.uflag === "false") {
                    console.log(response.data.userModel.uflag, "uflag")
                    // Alert("Account Not Approved Yet")
                    loginalertmodalaction(true, "Account Not Approved Yet")
                  }
                  else {
                    sessionStorage.setItem("token", response.data.userToken);
                    sessionStorage.setItem("UserId", response.data.userModel.id);
                    sessionStorage.setItem("UserData", response.data.userModel.role);
                    sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                    sessionStorage.setItem("Email", response.data.userModel.email);
                    sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                    setUFlag(response.data.userModel.uflag)
                    setToken(response.data.userToken)
                    setUserData(response.data.userModel.role)
                    if (response.data.userModel.firstName !== null) {
                      sessionStorage.setItem("Name", response.data.userModel.firstName);
                    }
                    if (response.data.userModel.address !== null) {
                      sessionStorage.setItem("Address", response.data.userModel.address);
                    }
                    setLoginmodal(false)
                    setDName(response.data.userModel.firstName);
                    setDmail(response.data.userModel.email);
                    setDphone(response.data.userModel.mobileNumber);
                    setAdd(response.data.userModel.address);
                    setUserAddress(response.data.userModel.address);
                    setUsertoken(response.data.userToken)
                    setEnable(true)
                    setUserdata(response.data.userModel.role)
                    console.log(response.data.userToken)
                    Alert({
                      header: "Atmagram",
                      message: "LoggedIn Successfully",
                      backdropDismiss: false,
                      buttons: [
                        {
                          text: "ok",
                          handler: () => {
                            payment(response.data.userToken)
                          }
                        }
                      ]
                    })
                    // bookpayment(response.data.userToken);
                  }
              }
              // }
              else {
                // Alert(response.data.responseText.split("ERROR:")[1]);
                loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
              }
            } else {
              // alert(response.data.responseText.split("ERROR:")[1]);
              loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        // alert("Not a valid verification code or Code expired, please try again")
        loginalertmodalaction(true, "Not a valid verification code or Code expired, please try again")
        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }
  }


  const goBack = () => {
    setShowOtp(false)
  }

  return (
    <IonPage>
      <IonAlert
        isOpen={showAlert}
        backdropDismiss={false}
        onDidDismiss={() => setShowAlert(false)}
        header="Confirmation"

        message="Are you sure you want to leave? If you proceed, your order details will be lost. Please click Cancel to continue with your order."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',

            handler: () => {
              setActionFlag(false)
              setShowAlert(false); // Update the flag to indicate that the action was clicked
            },
          },
          {
            text: 'Leave',
            handler: handleLeave,
          },
        ]}

      />
      <IonContent>
        <HeadFoot User={"login"} Role="Role" Name={headName} />
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        <Modal show={loginalertmodal} className="login_alert_modal" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <h5 className="login_alert_title">Atmagram</h5>
            </div>
            <div style={{ textAlign: "center" }}>
              <IonText>{loginalertdata}</IonText>
            </div>
            <div style={{ textAlign: "right", paddingTop: "15px" }}>
              <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={gramModalopen} className="custom-modal-wrapper" >
          <Modal.Body ref={grammercemodal} >
            <div className="modal-header" style={{ padding: "15px 0px" }}>
              <h5 className="modal-title">Atmagram</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => setGramModalopen(false)} />
              </div>

            </div>
            <div className="custom-modal-deity">
              <IonRow style={{ display: "flex", alignItem: "center", justifyContent: "center", margin: "5px 0px" }}>
                <IonImg src={img + "?=v1"} style={{ height: "150px", width: "125px" }} alt="Atmagram"
                />
              </IonRow>
              <IonRow style={{ display: "flex", alignItem: "center", justifyContent: "center", marginTop: "2%" }}>
                <p style={{ padding: "0px 17px", textAlign: "justify" }}>{significance}</p>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={signupmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h2 className="profileModal-title">Sign Up</h2>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => { setSignupmodal(false); setLoginmodal(true) }} />
            </div>
          </ModalHeader>
          <Modal.Body ref={signupmodalref}>

            <form onSubmit={handleSubmit(submit)}>
              <div>
                <IonRow>
                  <IonCol size="12" size-lg="7" className="email_container">
                    <div>
                      <IonLabel className="label_align">
                        First Name
                        <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text" className="select_user"
                        value={firstname1}
                        // required
                        onBlur={() => Validation("firstname")}
                        onIonChange={(e) => { setFirstname1(e.detail.value!); ChangeValidation("firstname", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.firstname1 && <span className="alert_input_bottom ">{formErrors.firstname1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <div className="select_user">
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          value={phone1}
                          ref={signupphoneInputRef}
                          onBlur={() => Validation("mobile")}
                          onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("mobile", value) }}
                        /></div>
                    </div>
                    {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align" >
                        E-mail <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text"
                        value={email1}
                        className="select_user"
                        onBlur={() => Validation("email")}
                        onIonChange={(e) => { setEmail1(e.detail.value!); ChangeValidation("email", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.email1 && <span className="alert_input_bottom ">{formErrors.email1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Password
                        <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        clearOnEdit={false}
                        value={password1}
                        className="select_user"
                        onBlur={() => Validation("password")}
                        onIonChange={(e) => { setPassword1(e.detail.value!); ChangeValidation("password", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.password1 && <span className="alert_input_bottom ">{formErrors.password1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Confirm Password
                        <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        clearOnEdit={false}
                        className="select_user"
                        value={confirmpassword}
                        onBlur={() => Validation("confirmpassword")}
                        onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                  </IonCol>
                </IonRow>
                <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                  <div>
                    <Link onClick={(event: any) => { setLoginmodal(true); event.preventDefault(); setSignupmodal(false) }} className="forgot_pass_css" to={"#"}>Already have an account?</Link>
                  </div>
                </div>
                <div>
                  <IonButton
                    type="submit"
                    disabled={
                      firstname1.length === 0 ||
                      email1.length === 0 ||
                      password1.length === 0 ||
                      confirmpassword.length === 0
                    }                  >
                    <span className="button_padding"> Sign Up</span>
                  </IonButton>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={loginmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h2 className="profileModal-title">Login</h2>
            <div>
              <IonIcon className="close_icon" src={close} onClick={() => { setLoginmodal(false); setLoginIndex(0); setFormErrors({}) }} />
            </div>
          </ModalHeader>
          <Modal.Body ref={loginmodalref}>

            {loginIndex === 0 ?
              <form onSubmit={handleSubmit(Loginsubmit)} onKeyPress={e => Searchlog(e.key)}>
                <div>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <div>
                        <IonLabel className="label_align">
                          E-mail
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          type="text"
                          placeholder="Enter mail-id"
                          value={loginemail}
                          onBlur={() => Validation("login email")}
                          onIonChange={(e) => { setLoginEmail(e.detail.value!); ChangeValidation("login email", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.loginemail && <span className="alert_input_bottom ">{formErrors.loginemail}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow >
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div>
                        <IonLabel className="label_align">
                          Password
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password" className="select_user"
                          clearOnEdit={false}
                          placeholder="Enter password"
                          onBlur={() => Validation("login password")}
                          onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("login password", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                    </IonCol>
                  </IonRow>
                  <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                    <div>
                      <Link onClick={(event: any) => { setSignupmodal(true); event.preventDefault(); setLoginmodal(false) }} className="forgot_pass_css" to={"#"}>Don't have account?</Link>
                    </div>
                  </div>
                  <div className="login_modal_btn_container">
                    <div className={loginemail.length === 0 || password.length === 0 ? "cursor_block" : ""}>
                      <IonButton
                        type="submit"
                        disabled={loginemail.length === 0 || password.length === 0}
                      >
                        <span className="button_padding"> Login</span>
                      </IonButton>
                    </div>
                    <div className="center or_text_container">
                      <p> or</p>
                    </div>
                    <IonButton onClick={phoneLogin}>
                      <span className="button_padding">  Login With Phone</span>
                    </IonButton>
                  </div>
                </div>
              </form> : <div>

                {!showOtp ? <>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <div>
                        <IonLabel className="label_align">
                          Mobile Number  <span style={{ color: "red" }}>*</span>
                        </IonLabel>

                        <PhoneInput
                          international
                          defaultCountry="IN"
                          value={phone}
                          className="select_user"
                          ref={phoneInputRef}
                          // flags=false
                          onBlur={(e) => Validation("phone")}
                          onChange={(value: any) => { moveCursorToEndonLogin(value); ChangeValidation("phone", value) }}
                        />
                      </div>
                      {formErrors.phone && <span className="alert_input_bottom ">{formErrors.phone}</span>}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div id="recaptcha-container"></div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" size-lg="12" className="textalign-center login_modal_btn_container">
                      <div className={phone === undefined || phone === "" || !isValidPhoneNumberLogin ? "cursor_block" : ""}>
                        <IonButton
                          onClick={checkMobile}
                          disabled={phone === undefined || phone === "" || !isValidPhoneNumberLogin}
                        >
                          <span className="button_padding"> Get OTP</span>

                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </> : <></>}

                {showOtp ?
                  <>
                    <IonRow>
                      <IonCol size="11" size-lg="7" className="email_container">

                        <IonLabel className="label_align">
                          Enter OTP  <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          onBlur={(e) => Validation("otp")}
                          onIonChange={(e) => { setOtp(e.detail.value!); ChangeValidation("otp", e) }}
                          required
                        ></IonInput>

                        {formErrors.otp && <span className="alert_input_bottom ">{formErrors.otp}</span>}
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="11" size-lg="7" className="pass_container" >
                        <span className="go_back_style"
                          onClick={goBack}
                        >go back
                        </span>
                      </IonCol>
                    </IonRow>

                    <div className="login_modal_btn_container">
                      <IonButton

                        onClick={verifyOTP}


                      >
                        <span className="button_padding">Verify OTP</span>

                      </IonButton>
                    </div>
                  </> : <></>
                }
                <div className="login_modal_btn_container">
                  <div className="center or_text_container">
                    <p>or</p>
                  </div>
                  <IonButton onClick={emailLogin}>
                    <span className="button_padding"> Login With Email</span>
                  </IonButton>
                </div>
              </div>}
          </Modal.Body>
        </Modal>
        <Modal show={errormodal} className="gramerce-modal" centered>
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title">Atmagram</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => setErrormodal(false)} />
              </div>

            </div>
            {errormsg.map((item: any, i: any) => (
              <div style={{ display: "flex", marginTop: "10px" }}>
                <IonText style={{ fontWeight: "bold" }}>{i + 1}.</IonText>
                <IonText style={{ paddingLeft: "5px" }}>{item}</IonText>
              </div>
            ))}
          </Modal.Body>
        </Modal>

        <Modal show={pincodemodal} className="profile-modal">
          <ModalHeader className="profilenodal-Header">
            <h2 className="profileModal-title">Enter the Delivery Pincode <span style={{ color: "red" }}>*</span></h2>

            <div>
              <IonIcon src={close} className="close_icon" onClick={() => setPincodemodal(false)} />
            </div>
          </ModalHeader>
          <Modal.Body>

            <IonRow>
              <IonCol size="12">
                <IonInput type="number" ref={(ref) => pinCodeInputRef.current = ref} placeholder="Enter the Delivery Location Pincode" onFocus={preventdata} className="profile-inputs" onKeyDown={(e: any) => restrictE(e)} value={pinchange} onBlur={() => Validation("Delivery Pincode")} onIonChange={(e: any) => { setPinchange(e.detail.value); ChangeValidation("Delivery Pincode", e) }} onKeyPress={handleKeyPress} />
                {formErrors.pinerror && <span className="profile-errors ">{formErrors.pinerror}</span>}
              </IonCol>
            </IonRow>
            <IonButton onClick={() => pincodesubmit()}>
              <span className="button_padding">
                Apply

              </span>

            </IonButton>
          </Modal.Body>
        </Modal>

        <div >
          {index == 0 ? <div className="page_content_padding grammerce_main_container">
            <div className="grammerce_head_container">
              <IonText className="grammerce_head">Village Market</IonText>
              {hasQuantityGreaterThanZeroinData &&
                <div className='cart-badge-parent' onClick={() => ViewCart()}>
                  <IonIcon src={cart} className="grammerce_main_cart_btn_mobile" />
                  <IonBadge className='cart-badge'>{data.filter((item: any) => item.quantity > 0).length}</IonBadge>
                </div>
              }
            </div>
            <div className="grammerce_top_row">
              <div onClick={() => pincodemodalopen()} className="grammerce-location_div">
                <div>
                  <IonIcon src={locationOutline} className="grammerce_location_icon" />
                </div>
                <div className="grammerce_location_text_wrapper">
                  <IonText className="grammerce_location_text">
                    Delivery to
                    {" " + searchpincode}
                  </IonText>
                </div>
              </div>
              <div className="grammerce_search_div ">
                {/* {searchData.length === 0 || searchpincode === "" ? <></> : */}
                 <IonSearchbar
                  value={searchQuery}
                  onIonChange={(e: any) => changeSearchQuery(e.detail.value)}
                  placeholder="Search"
                  className='orders-searchBar'
                />
                {/*  } */}
                {/* {searchData.length === 0 || searchpincode === "" ? <></> : <IonSearchbar
                  value={searchQuery} onIonChange={(e) => changeSearchQuery(e.target.value)}
                  placeholder="Search"
                />} */}
              </div>
            </div>
            <div>
              {showLoading === false && searchData.length === 0 ?
                <div className="no_grammerce_container">
                  <IonText style={{ fontWeight: "bold", fontSize: "22px", color: "#222222" }}>No Grammerce Available</IonText>
                </div>
                : showLoading === false && search(searchItems).length === 0 ? <div className="no_grammerce_container">
                  <IonText style={{ fontWeight: "bold", fontSize: "22px", color: "#222222" }}>No Grammerce Available</IonText>
                </div> : <div className="gremmerce_list_div">
                  <IonGrid>
                    <IonRow>
                      {search(searchItems).map((item: any, i: any) => (
                        <IonCol size-lg='3' siz-md='4' size-sm='6' size-xs='6' className="grammerce_list_items">
                          <div className="grammerce_list_img_container" onClick={() => ViewItem(item)} onMouseEnter={() => setHoverItem(item.id)} onMouseLeave={() => setHoverItem("")}>
                            <div className="grammerce_img">
                              {item.grammerceImgsUrl === null ?
                                <IonImg src="assets/icon/image.png" alt="Atmagram" className="grammerce_list_items_img" /> :
                                <IonImg src={item.grammerceImgsUrl[0] + "?=v1"} alt="Atmagram" onLoad={handleImageLoad} className="grammerce_list_items_img" />
                              }
                            </div>
                            <div className={`${hoverItem === item.id && !mobile ? "grammerce_cart_show" : "grammerce_cart"} ${item.stock <= 0 ? 'disableCursor' : ''}`}>
                              <IonButton className="grammerce_cart_div" onClick={(e: any) => handleAddToCart(item, i, 1, e)} disabled={item.stock <= 0 ? true : false}>
                                <span className="grammerce_cart_btn">Add to Cart</span>
                              </IonButton>
                            </div>
                          </div>
                          <div>
                            <div className="grammerce_height">
                              <IonText className="grammerce_product_name">{item.productName}</IonText>
                            </div>
                            <div className="grammerce_height">
                              <IonText className="grammerce_price">₹ {formatter.format(item.price)}</IonText>
                            </div>
                            {item.stock > 0 && <div className="grammerce_height">
                              <IonText className="grammerce_stock">Available Stock : <span>{item.stock}</span></IonText>
                            </div>}
                            {item.stock <= 0 && <div className="grammerce_height">
                              <IonText className="grammerce_outofStock">out of stock</IonText>
                            </div>}
                            {item.stock <= 0 ? <></> : <div className="grammerce_height">{
                              item.deliveryLeadTime == 0 ? <IonText className="grammerce_days">Same Day Delivery</IonText>
                                : <div className="grammerce_height">
                                  <IonText className="grammerce_days">Delivery Within {item.deliveryLeadTime} Day(s)</IonText>
                                </div>
                            }</div>}
                          </div>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>}
            </div>
          </div> : index === 1 ? <div className="page_content_padding grammerce_main_container">
            <div style={{ display: "flex", gap: "40px", alignItems: "center", margin: "10px 10px 10px 0px" }}>
              <IonIcon style={{ textAlign: "left", cursor: "pointer" }} onClick={() => {
                setIndex(0)
                setGrammerceItem([])
                setHeadName("Home,Grammerce")
                setHoverItem("")
                setExpanded(false)
              }
              } className="grammerce_head" src={arrowBack} size="large"></IonIcon>
            </div>
            {grammerceItem.map((item: any, i: any) => {
              var Img: any;
              if (item.grammerceImgsUrl != null) {
                Img = item.grammerceImgsUrl[0]
              }
              else {
                Img = "";
              }
              return (
                <IonRow>
                  <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                    <div className="grammerce_item_view">
                      <IonImg onClick={() => setAddshowModal(true)} src={Img} className="grammerce_item_Img " alt="" />
                    </div>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                    <div className="grammerce_item_details_container">
                      <div>
                        <IonText className="grammerce_head">Village Market</IonText>
                      </div>
                      <div>
                        <IonText className="grammerce_item_name">{item.productName}</IonText>
                      </div>
                      <div>
                        <IonText className="grammerce_item_price">₹ {formatter.format(item.price)}</IonText>
                      </div>
                      <div >
                        <p  className={`grammerce_item_significance ${expanded ? 'expanded' : ''}`}>
                        <div style={{whiteSpace: "pre-wrap"}}> 
        {item.significance} 
     </div>
                        </p>
                       
                        {item.significance.length > 3 * 43 && (
                          <span className="see_more" onClick={toggleExpansion}>
                            {expanded ? 'See less' : 'See more'}
                          </span>
                        )}
                      </div>
                      {item.stock <= 0 ?
                        <div>
                          <IonText className="grammerce_item_stock" style={{ color: "red" }}>Out of stock !</IonText>
                        </div>
                        : <>
                          <div>
                            <IonText className="grammerce_item_stock">Hurry! Only <span style={{ fontWeight: "700", color: "#4F4B4B" }}>{item.stock}</span> units left in stock !</IonText>
                          </div>
                          {/* <div>
                            <IonProgressBar type="determinate" value={0.5} className="grammerce_progress_bar" />
                          </div> */}
                        </>}

                      <div style={{ marginTop: "15px" }}>
                        <IonText className="grammerce_head">Quantity</IonText>
                      </div>
                      <IonRow>
                        <IonCol size="6" className="grammerce_item_col">
                          <div className="grammerce_item_btn">
                            <IonIcon src={removeOutline} style={{ cursor: item.quantity > 0 ? "pointer" : "not-allowed" }} onClick={(e: any) => { quantityDecre(item, i) }} className={item.stock === 0 ? "disableCursor" : ''} />
                            <span>|</span>
                            <IonInput className="grammerce_item_cart_input" value={item.quantity} onFocus={preventdata} type="number" max={item.stock} min="0" onIonChange={(e: any) => handleChange(e, i, item, 1)} readonly={item.stock <= 0} />
                            <span>|</span>
                            <IonIcon src={addOutline} onClick={() => {
                              if (item.stock !== 0 && item.quantity < item.stock) {
                                quantityIncre(item, i, 1);
                              }
                            }} style={{ cursor: item.quantity < item.stock ? "pointer" : "not-allowed" }} className={item.stock === 0 ? "disableCursor" : ''} />
                          </div>
                        </IonCol>
                        <IonCol size="6" className="grammerce_item_col">
                          <div>
                            {isAnyQuantityPositive ? <div style={{ justifyContent: "flex-end", display: "flex" }}>
                              <IonButton className="grammerce_item_cart_btn" onClick={() => ViewCart()}><span className="button_padding">View Cart</span></IonButton>
                            </div> :
                              <div style={{ justifyContent: "flex-end", display: "flex" }} className={`${item.stock === 0 ? 'cursor_block' : ''}`}>
                                <IonButton className="grammerce_item_cart_btn" disabled={item.stock === 0} onClick={(e: any) => handleAddToCart(item, i, 2, e)}>
                                  <span className="button_padding">Add to Cart</span></IonButton>
                              </div>
                            }
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>
                    {addshowModal && (
                      <Lightbox
                        mainSrc={Img}
                        onCloseRequest={() => setAddshowModal(false)}
                        onImageLoad={() => {
                          window.dispatchEvent(new Event('resize'));
                        }}
                      />
                    )}
                  </IonCol>
                </IonRow>
              )
            })}
          </div> : index === 2 ? <div className="page_content_padding grammerce_main_container2">
            <div className="grammerce_cart_back">
              <IonIcon style={{ textAlign: "left", cursor: "pointer" }} onClick={() => {
                setIndex(0)
                setGrammerceItem([])
                setHeadName("Home,Grammerce")
                setHoverItem("")
              }
              } className="grammerce_head" src={arrowBack} size="large"></IonIcon>
              <IonText className="grammerce_head">Shopping Cart</IonText>
            </div>
            <IonRow >
              <IonCol sizeLg='9' sizeMd='12' sizeSm='12' sizeXs='12'>
                <IonRow className="grammerce_cart_list_head">
                  <IonCol size='5' style={{ padding: "0px", textAlign: "left" }}>
                    <IonLabel className="grammerce_cart_list_head_text">Product</IonLabel>
                  </IonCol>
                  <IonCol size='2' style={{ padding: "0px", textAlign: "center" }}>
                    <IonLabel className="grammerce_cart_list_head_text">Price</IonLabel>
                  </IonCol>
                  <IonCol size='3' style={{ padding: "0px", textAlign: "center" }}>
                    <IonLabel className="grammerce_cart_list_head_text">Quantity</IonLabel>
                  </IonCol>
                  <IonCol size='2' style={{ padding: "0px", textAlign: "center" }}>
                    <IonLabel></IonLabel>
                  </IonCol>
                </IonRow>
                {searchItems.every((item: any) => item.quantity === 0) ? (
                  <div className="no-materials">
                    <IonText>No Items in Your Shopping Cart</IonText>
                  </div>
                ) : (
                  searchItems.map((item: any, i: any) => {
                    var Img: any;
                    if (item.grammerceImgsUrl != null) {
                      Img = item.grammerceImgsUrl[0]
                    }
                    else {
                      Img = "";
                    }
                    return (
                      item.quantity > 0 &&
                      <>
                        <IonRow className="grammerce_cart_list_item cart-hide-for-mobile-grammerce">
                          <IonCol size='5' className="grammerce_cart_item_img_col">
                            <div className="grammerce_cart_item_img_div">
                              <IonImg src={Img} className="grammerce_cart_item_img" alt="" />
                            </div>
                            <div>
                              <IonLabel className="grammerce_cart_item_text">{item.productName}</IonLabel>
                              <IonLabel className="grammerce_cart_item_price">₹ {formatter.format(item.price)}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size='2' style={{ textAlign: "center" }}>
                            <IonText className="grammerce_cart_item_text">₹ {formatter.format(item.price * item.quantity)}</IonText>
                          </IonCol>
                          <IonCol size='3' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="grammerce_cart_item_btn">
                              <IonIcon src={removeOutline} onClick={() => quantityDecre(item, i)} /><span>|</span><IonInput className="grammerce_item_cart_input" value={item.quantity} onFocus={preventdata} type="number" max={item.stock} min="0" onIonChange={(e: any) => handleChange(e, i, item, 2)} readonly={item.stock <= 0} /><span>|</span><IonIcon src={addOutline} onClick={() => quantityIncre(item, i, 2)} />
                            </div>
                          </IonCol>
                          <IonCol size='2' className="grammerce_cart_item_remove_div" onClick={() => openDeleteModal(item)}>
                            <IonIcon src={closeOutline} className="grammerce_cart_item_remove" size="large" />
                            <IonText className="grammerce_cart_item_remove">Remove</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className='cart-show-for-mobile'>
                          <IonCol size='6'>
                            <div className='cartImg-view'>
                              <IonImg src={Img} className='material-cartImg' />
                            </div>
                          </IonCol>
                          <IonCol size='6'>
                            <div className='garmmerce-close-Icon'>
                              <p>{item.productName}</p>
                              <IonIcon onClick={() => openDeleteModal(item)} src={closeOutline} className='cart-remove-icon' size='large' />
                            </div>

                            <p><span className="grammerce_cart_item_price">Price - ₹ {formatter.format(item.price)}</span></p><p>Total - ₹ {formatter.format(item.price * item.quantity)}</p>
                            <div className='increment-decrement-element cart-inc-dec'>
                              <IonIcon src={removeOutline} onClick={() => quantityDecre(item, i)} /><span>|</span><IonInput className='details-input' onFocus={preventdata} type="number" min="0" onKeyUp={(e: any) => handleChange(e, i, item, 2)} readonly={item.stock <= 0} value={item.quantity} max={item.stock} /><span>|</span><IonIcon src={addOutline} onClick={() => quantityIncre(item, i, 2)} />
                            </div>
                          </IonCol>
                        </IonRow>
                      </>
                    )
                  })
                )}
              </IonCol>
              <IonCol sizeLg='3' sizeMd='12' sizeSm='12' sizeXs='12' className="grammerce_total_col_container">
                <div className="cart-total-view-grammerce">
                  <div className="cart-totalAmt">
                    <IonText>Total</IonText>
                    <IonText>₹ {formatter.format(tot)}</IonText>
                  </div>
                  <div className={`grammerce_cart_amt_btn ${(activeIND === false || tot === 0) ? 'disableCursor' : ''}`}>
                    <IonButton className="grammerce_cart_order_btn" disabled={activeIND === false || tot === 0} onClick={OrderBtn}><span className="button_padding">Order Now</span></IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </div>
            : index == 3 ? <div className="page_content_padding grammerce_main_container">
              <div className="grammerce_cart_back">
                <IonIcon style={{ textAlign: "left", cursor: "pointer" }} onClick={() => {
                  setIndex(2)
                  setHeadName("Home,Product Cart")
                }
                } className="grammerce_head" src={arrowBack} size="large"></IonIcon>
                <IonText className="grammerce_head">Booking Details</IonText>
              </div>
              <div style={{ marginTop: "4%" }}>
                <IonRow className="grammerce_confirm_list_head">
                  <IonText className="grammerce_confirm_list_head_text">Delivery Details</IonText>
                </IonRow>
                <IonRow className="grammerce_confirm_details_container">
                  <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammerce_confirm_sub_container">
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="4.5" sizeXs="11">
                        <IonText className="order_detail">Name <span style={{ color: "red" }}>*</span></IonText>
                      </IonCol>
                      <IonCol sizeMd="0.5" sizeXs="1">
                        <IonText className="order_detail">:</IonText>
                      </IonCol>
                      <IonCol sizeMd="5.5" sizeXs="11">
                        <IonInput onBlur={() => Validation("GName")} className="grammerce_confirm_input" type="text" value={name} onIonChange={(e: any) => { setName(e.detail.value); ChangeValidation("GName", e) }} />
                        {formErrors.name && <span className="alert_input_bottom ">{formErrors.name}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="4.5" sizeXs="11">
                        <IonText className="order_detail">Contact Number <span style={{ color: "red" }}>*</span></IonText>
                      </IonCol>
                      <IonCol sizeMd="0.5" sizeXs="1" >
                        <IonText className="order_detail">:</IonText>
                      </IonCol>
                      <IonCol sizeMd="5.5" sizeXs="11" >
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          value={cname}
                          ref={contactnoref}
                          onBlur={() => Validation("GMobile")}
                          className="grammerce_confirm_input"
                          onChange={(value: any) => { cursorend(value); ChangeValidation("GMobile", value) }}
                        />
                        {formErrors.cname && <span className="alert_input_bottom ">{formErrors.cname}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="4.5" sizeXs="11" >
                        <IonText className="order_detail">E-mail Id <span style={{ color: "red" }}>*</span></IonText>
                      </IonCol>
                      <IonCol sizeMd="0.5" sizeXs="1">
                        <IonText className="order_detail">:</IonText>
                      </IonCol>
                      <IonCol sizeMd="5.5" sizeXs="11">
                        <IonInput onBlur={() => Validation("GEmail")} value={email} className="grammerce_confirm_input" onIonChange={(e: any) => { setEmail(e.detail.value); ChangeValidation("GEmail", e) }} />
                        {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="10.5" sizeXs="11" style={{ display: "flex", alignItems: "center" }} >
                        <IonCheckbox className="custom-tabIndex" onIonChange={(e: any) => gift(e)} checked={gif} />
                        <IonLabel style={{ margin: "0% 2%" }} className="order_detail">Gift</IonLabel>
                      </IonCol>
                    </IonRow>
                    {giftopen == false ? <></> : <div>
                      <IonRow>
                        <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol sizeMd="4.5" sizeXs="11" >
                          <IonText className="order_detail">Gifted By</IonText>
                        </IonCol>
                        <IonCol sizeMd="0.5" sizeXs="1">
                          <IonText className="order_detail">:</IonText>
                        </IonCol>
                        <IonCol sizeMd="5.5" sizeXs="11">
                          <IonInput type="text" onBlur={() => Validation("Ggiftby")} value={giftby} className="grammerce_confirm_input" onIonChange={(e: any) => { setGiftby(e.detail.value); ChangeValidation("Ggiftby", e) }} />
                          {formErrors.giftby && <span className="alert_input_bottom ">{formErrors.giftby}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol sizeMd="4.5" sizeXs="11" >
                          <IonText className="order_detail">Gift Note</IonText>
                        </IonCol>
                        <IonCol sizeMd="0.5" sizeXs="1" >
                          <IonText className="order_detail">:</IonText>
                        </IonCol>
                        <IonCol sizeMd="5.5" sizeXs="11">
                          <IonInput type="text" className="grammerce_confirm_input" onBlur={() => Validation("GgiftNote")} value={giftnote} onIonChange={(e: any) => { setGiftnote(e.detail.value); ChangeValidation("GgiftNote", e) }} />
                          {formErrors.giftnote && <span className="alert_input_bottom ">{formErrors.giftnote}</span>}
                        </IonCol>
                      </IonRow>
                    </div>}
                  </IonCol>
                  <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammerce_book_col2">
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="4.5" sizeXs="11">
                        <IonText className="order_detail">Address <span style={{ color: "red" }}>*</span></IonText>
                      </IonCol>
                      <IonCol sizeMd="0.5" sizeXs="1">
                        <IonText className="order_detail">:</IonText>
                      </IonCol>
                      <IonCol sizeMd="5.5" sizeXs="11">
                        <IonTextarea autoGrow onBlur={() => Validation("GAddress")} value={deliAdd} className="grammerce_confirm_textarea" onIonChange={(e: any) => { setDeliAdd(e.detail.value); ChangeValidation("GAddress", e) }} />
                        {formErrors.deliAdd && <span className="alert_input_bottom ">{formErrors.deliAdd}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol sizeMd="4.5" sizeXs="11">
                        <IonText className="order_detail">Pincode <span style={{ color: "red" }}>*</span></IonText>
                      </IonCol>
                      <IonCol sizeMd="0.5" sizeXs="1" >
                        <IonText className="order_detail">:</IonText>
                      </IonCol>
                      <IonCol sizeMd="5.5" sizeXs="11">
                        <IonInput type="number" onFocus={preventdata} value={pincode} onBlur={() => Validation("GPincode")} maxlength={6} className="grammerce_confirm_input" onIonChange={(e: any) => { setPin(e.detail.value); ChangeValidation("GPincode", e) }} />
                        {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                      </IonCol>
                    </IonRow>
                    {userdata ?
                      <IonRow>
                        <IonCol sizeMd="1.5" sizeXs="1" className='tick_container'>
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol sizeMd="10.5" sizeXs="11" style={{ display: "flex", alignItems: "center" }} >
                          <IonCheckbox className="custom-tabIndex" onIonChange={(e: any) => sameas(e)} checked={che} />
                          <IonLabel style={{ margin: "0% 2%" }} className="order_detail">Same as Profile</IonLabel>
                        </IonCol>
                      </IonRow> : <></>}
                    <IonRow className="grammerce_confirm_btn_container">
                      <IonButton onClick={() => show()}><span className="button_padding">Confirm Order</span></IonButton>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </div>
            </div> : index === 4 ? <div className="page_content_padding grammerce_main_container">
              <div className="grammerce_cart_back">
                <IonIcon style={{ textAlign: "left", cursor: "pointer" }} onClick={() => {
                  setIndex(3)
                  setActionFlag(false)
                  setHeadName("Home,Booking Details")
                }
                } className="grammerce_head" src={arrowBack} size="large"></IonIcon>
                <IonText className="grammerce_head">Confirm Booking Details</IonText>
              </div>
              <IonGrid style={{ marginTop: "4%" }}>
                <IonRow style={{ columnGap: "10px" }}>
                  <IonCol size-lg="3" size-md="4" size-sm="12" size-xs="12" className="grammerce_confirm2_container">
                    <IonRow>
                      <IonCol size="2" className="grammerce_confirm2_col">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="grammerce_confirm2_heaed_text">Name</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="order_detail">{name}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2" className="grammerce_confirm2_col">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="grammerce_confirm2_heaed_text">Contact Number</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="order_detail">{cname}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2" className="grammerce_confirm2_col">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="grammerce_confirm2_heaed_text">E-mail id</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="order_detail">{email}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2" className="grammerce_confirm2_col">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="grammerce_confirm2_heaed_text">Address</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="order_detail">{deliAdd}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2" className="grammerce_confirm2_col">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="grammerce_confirm2_heaed_text">Pincode</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="2">
                      </IonCol>
                      <IonCol size="10">
                        <IonText className="order_detail">{pincode}</IonText>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size-lg="8.8" size-md="7.8" size-sm="12" size-xs="12" className="grammerce_confirm2_container2">
                    <IonRow className="grammerce_confirm2_list_head">
                      <IonCol size='3' style={{ padding: "0px", textAlign: "left" }}>
                        <IonLabel className="grammerce_confirm2_list_head_text">Grammerce Name</IonLabel>
                      </IonCol>
                      <IonCol size='1.4' style={{ padding: "0px", textAlign: "center" }}>
                        <IonLabel className="grammerce_confirm2_list_head_text">Quantity</IonLabel>
                      </IonCol>
                      <IonCol size='2' style={{ padding: "0px", textAlign: "center" }}>
                        <IonLabel className="grammerce_confirm2_list_head_text">Price</IonLabel>
                      </IonCol>
                      <IonCol size='3' style={{ padding: "0px", textAlign: "center" }}>
                        <IonLabel className="grammerce_confirm2_list_head_text">Delivery Charge</IonLabel>
                      </IonCol>
                      <IonCol size='2.6' style={{ padding: "0px", textAlign: "center" }}>
                        <IonLabel className="grammerce_confirm2_list_head_text">Delivery Date</IonLabel>
                      </IonCol>
                    </IonRow>
                    <div className="grammerce_confirm2_list_container">
                      {delChargeData.listOfGrammerceDto
                        .map((item: any, i: any) => {
                          DelTot = (item.price * item.quantity) + item.pdDelCharge + DelTot;
                          var expDate: any;
                          if (item.expDeliveryDate != null && item.expDeliveryDate != "" && item.expDeliveryDate != undefined) {
                            expDate = moment(item.expDeliveryDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MM-YYYY");
                          }
                          else {
                            expDate = ""
                          }
                          return (
                            <>
                              <IonRow className="grammerce_confirm_row">
                                <IonCol size='3' style={{ padding: "0px", textAlign: "left" }}>
                                  <IonLabel className="grammerce_confirm2_list_text">{item.productName}</IonLabel>
                                </IonCol>
                                <IonCol size='1.4' style={{ padding: "0px", textAlign: "center" }}>
                                  <IonLabel className="grammerce_confirm2_list_text">{item.quantity}</IonLabel>
                                </IonCol>
                                <IonCol size='2' style={{ padding: "0px", textAlign: "center" }}>
                                  <IonLabel className="grammerce_confirm2_list_text">₹ {formatter.format(item.price * item.quantity)}</IonLabel>
                                </IonCol>
                                <IonCol size='3' style={{ padding: "0px", textAlign: "center" }}>
                                  <IonLabel className="grammerce_confirm2_list_text">₹ {item.pdDelCharge}</IonLabel>
                                </IonCol>
                                <IonCol size='2.6' style={{ padding: "0px", textAlign: "center" }}>
                                  <IonLabel className="grammerce_confirm2_list_text">{expDate}</IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow className="grammerce_confirm_mobile_row">
                                <IonCol size='12'>
                                  <IonRow>
                                    <IonCol size="1.5"><IonLabel className="grammerce_confirm2_list_text">{i + 1} .</IonLabel></IonCol>
                                    <IonCol size='4'><IonLabel className="grammerce_confirm2_list_text">Grammerce Name</IonLabel></IonCol>
                                    <IonCol size='1.5'>:</IonCol>
                                    <IonCol size='5'><IonText className="grammerce_confirm2_list_text">{item.productName}</IonText></IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol size="1.5" />
                                    <IonCol size='4'><IonLabel className="grammerce_confirm2_list_text">Quantity</IonLabel></IonCol>
                                    <IonCol size='1.5'>:</IonCol>
                                    <IonCol size='5'><IonText className="grammerce_confirm2_list_text">{item.quantity}</IonText></IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol size="1.5" />
                                    <IonCol size='4'><IonLabel className="grammerce_confirm2_list_text">Price</IonLabel></IonCol>
                                    <IonCol size='1.5'>:</IonCol>
                                    <IonCol size='5'><IonText className="grammerce_confirm2_list_text">₹ {formatter.format(item.price * item.quantity)}</IonText></IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol size="1.5" />
                                    <IonCol size='4'><IonLabel className="grammerce_confirm2_list_text">Delivery Charge</IonLabel></IonCol>
                                    <IonCol size='1.5'>:</IonCol>
                                    <IonCol size='5'><IonText className="grammerce_confirm2_list_text">₹ {formatter.format(item.pdDelCharge)}</IonText></IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol size="1.5" />
                                    <IonCol size='4'><IonLabel className="grammerce_confirm2_list_text">Delivery Date</IonLabel></IonCol>
                                    <IonCol size='1.5'>:</IonCol>
                                    <IonCol size='5'><IonText className="grammerce_confirm2_list_text"> {expDate}</IonText></IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            </>
                          )
                        })}
                      <div className="grammerce_border_div" />
                      <IonRow className="grammerce_confirm2_amt_div">
                        <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" className="grammerce_confirm2_items_div">
                          <IonText className="grammerce_confirm2_total_text">No of Items :</IonText>
                          <IonText className="grammerce_confirm2_total_text">{delChargeData.listOfGrammerceDto.length} </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" className="grammerce_confirm2_total_amt_div">
                          <IonText className="grammerce_confirm2_total_text">Total Price :</IonText>
                          <IonText className="grammerce_confirm2_total_text">₹ {formatter.format(DelTot)}</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow className="grammerce_confirm2_btn_container">
                        <IonButton onClick={() => {
                          if (token == null) {
                            setLoginmodal(true)
                          }
                          else {
                            payment(token)
                          }
                        }} ><span className="button_padding">Proceed to Payment</span></IonButton>
                      </IonRow>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div> : <></>}
        </div>
        <Modal show={deleteModal} className="gramerce-modal" centered >
          <Modal.Body>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <h5 className="mt-2">Are You Sure Want To Delete?</h5>
              </IonCol>
            </IonRow>
            <IonRow className="mt-12">
              <IonCol size="6" style={{ textAlign: "end" }}>
                <IonButton className="alert_btn" onClick={() => removeItem()}>
                  <span className="button_padding">Yes</span>
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton className="alert_btn" onClick={(e) => setDeleteModal(false)}>
                  <span className="button_padding">No</span>
                </IonButton>
              </IonCol>
            </IonRow>
          </Modal.Body>
        </Modal>

        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Grammerce;