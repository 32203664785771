export const fetchUserDetails = async () => {
    const getBrowserDetails = () => navigator.userAgent || "Unknown";
  
    const getOperatingSystem = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("windows")) return "Windows";
      if (userAgent.includes("mac")) return "MacOS";
      if (userAgent.includes("linux")) return "Linux";
      if (userAgent.includes("android")) return "Android";
      if (userAgent.includes("iphone")) return "iOS";
      return "Unknown";
    };
  
    const getScreenResolution = () => `${window.screen.width}x${window.screen.height}`;
  
    const getLanguage = () => navigator.language || "Unknown";
  
    const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || "Unknown";
  
    const getDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|ipad/.test(userAgent)) return "Mobile";
      if (/tablet/.test(userAgent)) return "Tablet";
      return "Desktop";
    };
  
    const checkIfBot = () => /bot|crawl|spider|crawler|slurp|archiver/.test(navigator.userAgent.toLowerCase());
  
    const getIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip || null;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
      }
    };
  
    const getLocation = async () => {
      return new Promise<{ latitude: number, longitude: number } | null>((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            error => {
              console.error("Error fetching location:", error);
              resolve(null); // If location is denied, return null
            }
          );
        } else {
          resolve(null); // Geolocation not supported
        }
      });
    };
  
    try {
      const location = await getLocation();
      const userDetails = {
        ipAddress: await getIpAddress(),
        browserDetails: getBrowserDetails(),
        operatingSystem: getOperatingSystem(),
        screenResolution: getScreenResolution(),
        language: getLanguage(),
        timezone: getTimezone(),
        deviceType: getDeviceType(),
        isBot: checkIfBot(),
        location: location, // Will be null if location fetching fails
      };
  
      return userDetails;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null; // In case of a broader error, return null or a default object
    }
  };
  