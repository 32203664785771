import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonLabel,
  IonInput,
  IonTextarea,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import "./Enquiry.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const Enquiry: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Enquiry"} />
        <div className="page_content_padding">
          <div style={{ marginBottom: "20px" }} className="container">
            <IonLabel>First Name</IonLabel>
            <IonInput className="text" type="text" name="firstname" placeholder="Your name.." />
            <IonLabel>Last Name</IonLabel>
            <IonInput className="text" type="text" name="lastname" placeholder="Your last name.." />
            <IonLabel>Subject</IonLabel>
            <IonTextarea className="textarea" name="subject" placeholder="Write something.." style={{ height: "200px" }}></IonTextarea>
            <IonButton>Submit</IonButton>
          </div>
        </div>
        {/* {Capacitor.getPlatform() === "web"? */}
        <Foot />
        {/* :<></>} */}
      </IonContent>
    </IonPage >
  );
};

export default Enquiry;