import {
  IonContent,
  IonPage,
  IonItemDivider,
  useIonRouter,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonTab,
  IonText,
  IonButton,
  IonIcon
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import "./Userpage.css"
import "./Home.css";
import Foot from "./Foot";
import HeadFoot from "./Head";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./Home.css"
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";
import { BudhaImg, home_image, logo, grammercelogo, materialslogo, offeringlogo, projectlogo, temple, donation, temple_home_card, donation_home_card, pooja_home_card, leftArrow } from "../theme/imagePath";
import { Link, NavLink } from "react-router-dom";
import { arrowForwardOutline, arrowBackCircleOutline, arrowForwardCircleOutline, arrowBack, arrowForward, arrowDown, arrowUp } from "ionicons/icons";
import { fetchUserDetails } from "../components/userInformation";
import { set } from "react-hook-form";

const Userhome: React.FC = () => {
  const params: any = useParams()
  const renderAfterCalled = useRef(false);


  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const uflag: any = useState(sessionStorage.getItem("uFlag"))
  const history = useHistory();
  const [usertoken] = useState(sessionStorage.getItem("token"));
  var token: any = params.token;
  const local: any = useLocation()
  const [images, setImages]: any = useState([
    // "images/grammercelogo.png",
    // "images/temple.jpeg",
    // "images/joinhands.png"
  ]);

  const [speedOfSlider, setSpeedOfSlider]: any = useState();
  const [appVersion, setAppVersion]: any = useState();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const carouselType = "DEF"
  const [userId, setUserId]: any = useState(sessionStorage.getItem("UserId"));
  const [logInfo, setLogInfo]: any = useState("")

  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoplaySpeed, setAutoplaySpeed] = useState(images[0]?.timeInSeconds * 1000 || 3000);
  const [dashdata, setdashdata]: any = useState({})


  const dashboardItems = [
    { type: "Temples Clicked", count: dashdata.temples, icon: temple_home_card },
    { type: "Poojas Offered", count: dashdata.poojas, icon: pooja_home_card },
    { type: "Donations", count: dashdata.donations, icon: donation_home_card },
  ];

  // Show these only for ROLE_ADMIN and ROLE_VOLUNTEER
  if (userdata.includes("ROLE_ADMIN") || userdata.includes("ROLE_VOLUNTEER")) {
    dashboardItems.unshift(
      { type: "Pending Temple Booking", count: dashdata.pendingTempleBooking, icon: temple_home_card },
      { type: "Pending Prasadam Delivery", count: dashdata.pendingPrasadamDelivery, icon: pooja_home_card }
    );
  }


  useEffect(() => {
    // Update autoplay speed based on current slide's timeInSeconds
    setAutoplaySpeed(images[currentSlide]?.timeInSeconds * 1000 || 3000);
  }, [currentSlide, images]);

  const handleAfterChange = (current: any) => {
    videoRefs.current.forEach((video: any, index: any) => {
      if (video && index !== current) {
        video.pause();
      }
    });
  };


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const getDashboardData = () => {
    if (userId) {
      Authservice.getdashboardDetails(userId, usertoken, usertoken,).then((response) => {
        console.log(response.data)
        setdashdata(response.data)
      })
        .catch((error: any) => {
          console.log(error + "mobErr")
        });
    }
    Authservice.getAppConfigs(usertoken, carouselType, userId,).then((response) => {
      console.log(response.data)
      setSpeedOfSlider(parseInt(response.data.filter((e: any) => e.name === "CarouselTimer")[0].value) * 1000)
      setAppVersion(response.data.filter((e: any) => e.name === "AppVersion")[0].value)
    })
      .catch((error: any) => {
        console.log(error + "mobErr")
      });
  }


  const fetchDetails = async () => {
    const details = await fetchUserDetails();
    setLogInfo(JSON.stringify(details));
    Authservice.getAllUserCarousels(usertoken, carouselType, userId, JSON.stringify(details)).then((response) => {
      console.log(response.data)
      const data = response.data
      setImages(data)
    })
      .catch((error: any) => {
        console.log(error + "mobErr")
      });

  };


  useEffect(() => {
    // Fetch user details when the app first loads
    getDashboardData()
    fetchDetails();
  }, []);

  useEffect(() => {
    if (userdata[0] === null) {
      // history.push("/login")
    } else {
    }
    if (!renderAfterCalled.current) {
      if (token != null && token != undefined && token != "") {
        Authservice.VerifyMobileChange(token, usertoken).then((response) => {
          if (response.data.startsWith("SUCCESS")) {
            alert(response.data.split('SUCCESS:')[1]);
            token = null;
            history.push("/profile")
            return;
          } else {
            alert(response.data.split('ERROR:')[1]);
            token = null;
            history.push("/home")
            return;
          }
        })
          .catch((error) => {
            console.log(error + "mobErr")
          });
      }
    }
    renderAfterCalled.current = true;
    // window.addEventListener('beforeunload',(e) => {
    //   e.preventDefault();
    // console.log(sessionStorage.getItem("token"))
    // sessionStorage.removeItem("token");
    // sessionStorage.removeItem("UserData");
    //})
    window.addEventListener('resize', updateScreenWidth);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  /************** Scroll to div ******************/

  const targetRef: any = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      console.log("Scrolling to target...");
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isHome = local.pathname === '/home' || local.pathname === '/';
  const displayHeadBackgroundImg = isHome && screenWidth <= 500;


  const PrevArrow = ({ onClick }: any) => (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <IonIcon src={arrowBackCircleOutline} />
    </div>
  );

  const NextArrow = ({ onClick }: any) => (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <IonIcon src={arrowForwardCircleOutline} />
    </div>
  );

  const sliderRef = useRef(null); // Ref to access the slider
  const videoRefs: any = useRef([]);   // Refs for the videos
  const scrollRef: any = useRef(null);
  const [scrollPos, setScrollPos] = useState({ top: false, bottom: true });

  const handleScroll = () => {
    if (scrollRef.current) {
      setScrollPos({
        top: scrollRef.current.scrollTop > 0, // Check if scrolled down
        bottom: scrollRef.current.scrollTop < scrollRef.current.scrollHeight - scrollRef.current.clientHeight, // Check if not at the bottom
      });
    }
  };

  const scroll = (direction: any) => {
    if (scrollRef.current) {
      const scrollAmount = 250; // Adjust as needed
      scrollRef.current.scrollBy({
        left: direction === "up" ? -scrollAmount :
          scrollAmount, behavior: "smooth"
      });
    }
  };

  const goto = (type: any) => {
    if (type === "Pending Temple Booking") {
      history.push("/volunteer")
    } else if (type === "Pending Prasadam Delivery") {
      history.push("/prasadhamDelivery")
    }
  }






  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // afterChange: handleAfterChange,
    afterChange: handleAfterChange,
    beforeChange: (oldIndex: any, newIndex: any) => setCurrentSlide(newIndex),


  };


  return (
    <IonPage>
      <IonContent fullscreen>
        <HeadFoot User={""} Role={"Role"} Name="" />
        <div className={`${displayHeadBackgroundImg ? 'home_head_sub_container_mobile' : 'home_head_background_img'}`}>
          <IonText style={{ position: "absolute", right: "10px", fontSize: "10px", color: "white", top: "55px" }}>
            {appVersion}
          </IonText>
          <div className="home_head_sub_container1">
            <div className="widgets_container">
              {userId && (
                <div className="scroll-wrapper">
                  {displayHeadBackgroundImg ?
                    <></>
                    : <IonIcon icon={arrowUp} className="scroll-arrow top" onClick={() => scroll("up")} style={{ display: dashboardItems?.length == 3 && "none" }} />}
                  <div className="scroll-container" ref={scrollRef} onScroll={handleScroll}>
                    {dashboardItems.map((item, index) => (
                      <div
                        key={index}
                        className="scroll-item"
                        onClick={() => goto(item.type)}
                        style={{ cursor: item.type === "Pending Temple Booking" || item.type === "Pending Prasadam Delivery" ? "pointer" : "default" }}
                      >
                        <IonCard className="info-card">
                          <IonCardHeader>
                            {/* <img src={item.icon} alt={item.type} className="icon" /> */}
                            <IonCardTitle>
                              {item.type}
                              <br />
                              <br />
                              {item.count}
                            </IonCardTitle>
                          </IonCardHeader>
                        </IonCard>
                      </div>
                    ))}
                  </div>
                  {/* {scrollPos.bottom && (userdata.includes("ROLE_ADMIN") || userdata.includes("ROLE_VOLUNTEER")) && ( */}
                  {displayHeadBackgroundImg ?
                    // <IonIcon icon={arrowForward} className="scroll-arrow rightArrow" onClick={() => scroll("down")} />
                    <></>
                    : <IonIcon icon={arrowDown} className="scroll-arrow bottom" onClick={() => scroll("up")} style={{ display: dashboardItems?.length == 3 && "none" }} />}
                  {/* )} */}
                </div>

              )}
            </div>
            <div className="home_text_container_parent">
              <div className="home_text_container">
                <div className="slider-container" style={{ marginTop: !userId ? "20px" : "0" }}>
                  {images.length > 0 ? (
                    <Slider {...settings} ref={sliderRef}>
                      {images.map((image: any, index: any) => {
                        const isVideo: any = image.carouselType == "TXT" ? "TXT" : image.imageUrls[0]?.endsWith('.mp4') ? "video" : "image";
                        console.log("isVideo", isVideo, image);
                        return (
                          <div key={index} >
                            {isVideo === "video" ? (
                              <video
                                className="carousel-image carousel-video"
                                style={{ width: '100%' }}
                                loop
                                autoPlay
                                muted
                              >
                                <source src={image.imageUrls[0]} type="video/mp4" />
                              </video>
                            ) : isVideo === "image" ? (
                              <img
                                src={image.imageUrls[0]}
                                alt={`Slide ${index + 1}`}
                                className="carousel-image"
                              />
                            ) : (
                              <div className="carousel-text-container" style={{ border: isVideo === "TXT" ? "1px solid #fff" : "", width: "100%", textAlign: "center" }}>
                                {image.description}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <p>Loading images...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Userhome;
