import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    IonIcon,
    IonRow,
    IonCol,
    IonText,
    IonGrid,
    IonButton,
    IonPage,
    IonContent,
    IonItemDivider,
    IonLoading,
    IonCard,
    IonImg,
    useIonAlert,
    IonLabel,
    IonInput,
    IonCardHeader,
    IonCardContent,
    IonList,
    IonItem,
    IonCardTitle,
    IonBadge,
    IonChip,
    IonNote,
    IonAvatar,
    IonListHeader,
    IonSearchbar
} from "@ionic/react";
import Authservice from "../Services/user.service";
import { calendar, checkmarkCircleSharp, close, createOutline, location, logoWhatsapp, person, shareOutline, star, trashBin, trashOutline, warning } from "ionicons/icons";
import UserHead from "./UserHead";
import Foot from "./Foot";
import { Modal, ModalHeader } from "react-bootstrap";
import "./Bookdetails.css";
import moment from "moment";
import useRazorpay from "react-razorpay";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import SessionTimeout from "../Services/SessionTimeOut";
import "./Myorder.css"
import HeadFoot from "./Head";
import { edit_icon, imagePathForRecipt, tick } from "../theme/imagePath";
import UserService from "../Services/auth.service";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import validator from 'validator'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { time } from "console";
import CommonDataTable from "../components/datatable";
import CommonModal from "../components/popipInfo";
import Lightbox from "react-image-lightbox";

const UserBookingOrders: React.FC = () => {
    const [token, settoken]: any = useState(sessionStorage.getItem("token"));
    const [payData, setPayData]: any = useState([])
    const [cartList, setcart]: any = useState([]);
    const [paymentdata, setPaymentdata]: any = useState([]);
    const Razorpay = useRazorpay();
    const [showLoading, setShowLoading] = useState(false);
    const [Alert] = useIonAlert()
    const [userAddress, setUserAddress]: any = useState(sessionStorage.getItem("Address"));
    const [userId, setUserId]: any = useState(sessionStorage.getItem("userId"));
    const isMobile = window.innerWidth <= 768;
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
    const [showlightBox, setShowLightBox] = useState(false);
    const [image, setImage] = useState("");

    const [bookingStatusMap, setBookingStatusMap] = useState<{ [key: string]: string }>({});
    const [filterTextDate, setFilterTextDate] = useState("")



    const headerColumns = [
        {
            name: 'Order',
            selector: (row: any) => row.orderNo,
            sortable: true,
            width: "130px"
        },
        {
            name: 'Date',
            selector: (row: any) => row.orderDate,
            sortable: true,
            format: (row: any) => new Date(row.orderDate).toLocaleDateString(),
            width: "120px"
        },
        {
            name: "Temple Name",
            selector: (row: any) => row.templeName,
            sortable: true,
        },
        {
            name: "Ordered By",
            selector: (row: any) => row.orderedBy,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: (row: any) => row.totalAmount,
            sortable: true,
            format: (row: any) => `₹${row.totalAmount}`,
        },
        {
            name: 'Status',
            selector: (row: any) => row.bookingStatus,
            sortable: true,
            format: (row: any) => bookingStatusMap[row.bookingStatus],
        }
    ];


    const dateTableData = cartList.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterTextDate.toLowerCase()) !== -1
    );

    const proceedPayment = (tkn: any) => {
        if (cartList.length == 0) {
            setShowLoading(false);
            Alert('your cart is empty')
            return;
        }
        console.log(payData, "jksdbfdjkb")
        var obj = { "userCartDetailDtoLst": payData }
        console.log(paymentdata)
        Authservice.payInit(tkn, obj).then((response: any) => {
            console.log(response.data)
            // alert('Order placed successfully, Please visit MyOrder')
            // window.location.replace("/orders")
            if (response.data.errors !== null) {
                Alert(response.data.errors)
            }
            else if (response.data.initResMsg !== null) {
                Alert(response.data.initResMsg)
            }
            else {
                var options: any = {
                    key: response.data.pgOption.key,
                    key_secret: response.data.secretKey,
                    amount: response.data.pgOption.amount,
                    currency: response.data.pgOption.currency,
                    name: response.data.pgOption.name,
                    order_id: response.data.pgOption.order_id,
                    description: response.data.pgOption.description,
                    modal: {
                        escape: false, ondismiss: function () {
                            Alert("Payment Failed")
                        }
                    },
                    handler: function (res: any) {
                        var resData: any = {

                            "amount": response.data.pgOption.amount,
                            "orderId": res.razorpay_order_id,
                            "cartPayRefId": response.data.cartPayRefId,
                            "signature": res.razorpay_signature,
                            "paymentId": res.razorpay_payment_id

                        }
                        console.log("resData", resData);
                        Authservice.payOfferingRes(token, resData)
                            .then((response) => {
                                console.log("payresponse" + response);
                                Alert({
                                    header: "Atmagram",
                                    message: "Order placed successfully, Please visit My Offerings",
                                    backdropDismiss: false,
                                    buttons: [
                                        {
                                            text: "ok",
                                            handler: () => {
                                                window.location.reload()
                                            }
                                        }
                                    ]
                                })
                            })
                            .catch((error) => {
                                Alert("Something went wrong, please try again later.");
                            });

                    },
                    prefill: {
                        name: response.data.pgOption.name,
                        email: response.data.email,
                        contact: response.data.contact

                    },

                    theme: {
                        color: response.data.theme
                    }
                };
                var pay: any = new Razorpay(options)
                pay.on("payment.failed", function (data: any) {
                    Alert(data.error.reason);
                });
                setShowLoading(false);
                pay.open()
            }
        })

    }
    const setPaymentData = (list: any, tkn: any) => {
        //  setPayData([]);

        for (let order of list) {
            let user: any;
            if (order.userModel == null) {
                user = userAddress
            } else {
                user = order.userModel.id;
            }
            var data = {
                "id": order.id,
                "userModel": {
                    "id": user
                },
                "poojaOfferings": {

                    "id": order.poojaOfferings.id,
                    "price": order.poojaOfferings.price
                },
                "firstName": order.firstName,
                "mailId": order.mailId,
                "mobileNumber": order.mobileNumber,
                "deliveryAddress": order.deliveryAddress,
                "prasadhamDelFlag": order.prasadhamDelFlag,
                "fromDate": order.fromDate,
                "toDate": order.toDate,
                "payDakshinaToPriestFlag": order.payDakshinaToPriestFlag,
                "payDakshinaToTempleFlag": order.payDakshinaToTempleFlag,
                "dakshinaAmountForPriest": order.dakshinaAmountForPriest,
                "dakshinaAmountToTemple": order.dakshinaAmountToTemple,
                "delFlag": order.delFlag,
                "noOfMonths": order.noOfMonths
            }
            console.log(data)
            payData.push(data)
        }
        setTimeout(() => {
            proceedPayment(tkn);
        }, 3000);
    }

    const Cart = () => {
        setShowLoading(true);
        var guestTkn: any = sessionStorage.getItem("guestTkn");
        Authservice.getuserbookingOrders(token, userId).then((response: any) => {
            console.log(response.data)
            setcart(response.data)
            setShowLoading(false);
        }).catch((error) => {
            setcart([])
            setShowLoading(false);
        });
        Authservice.getBookingStatus(token, userId).then((response: any) => {
            console.log(response.data)
            const statusMap = response.data.reduce((acc: any, item: any) => {
                acc[item.StatusId] = item.Status;
                return acc;
            }, {} as { [key: string]: string });
            setBookingStatusMap(statusMap);
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        setShowLoading(true)
        Cart();

    }, [])


    const handleRowClicks = (row: any) => {
        console.log(row)
        setExpandedRows((prev: any) => ({
            ...prev,
            [row.id]: !prev[row.id], // Toggle expand state for clicked row
        }));
    };



    const formatDate = (timestamp: any, num: any) => {
        if (num == "1") {
            return new Date(timestamp).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            });
        } else {
            return new Date(timestamp).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    };

    const OrderDetailsCard = ({ data: order }: any) => {
        console.log(order, "order")
        const formatDate = (timestamp: any) => {
            return new Date(timestamp).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        };

        const tantriCharges = order.bookingDetailsDtos
            .filter((booking: any) => booking.poojaCode === "TANTRI")
            .reduce((sum: number, booking: any) => sum + booking.poojaAmount, 0);
        const poojari = order.bookingDetailsDtos
            .filter((booking: any) => booking.poojaCode === "SWAMI")
            .reduce((sum: number, booking: any) => sum + booking.poojaAmount, 0);

        const templeDonation = order.bookingDetailsDtos
            .filter((booking: any) => booking.poojaCode === "TEMPLE")
            .reduce((sum: number, booking: any) => sum + booking.poojaAmount, 0);

        return (
            <div className="p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Pooja Details Card */}
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Pooja Details</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="space-y-4">
                                {order.bookingDetailsDtos
                                    .filter((booking: any) => !['VDC', 'DLVR', 'TANTRI', 'SWAMI', 'TEMPLE'].includes(booking.poojaCode))
                                    .map((booking: any) => (
                                        <div key={booking.id} className="border-b pb-3">
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div className="flex justify-between items-start">
                                                    <div className="flex gap-3">
                                                        <div>
                                                            <div>
                                                                <p className="font-medium">{booking.name || `Pooja ${booking.poojaCode}`}</p>
                                                                <p className="text-sm text-gray-500">{formatDate(booking.poojaFrom)}</p>
                                                                {booking.star && (
                                                                    <p className="text-sm text-gray-600">Star: {booking.star}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="font-medium">₹{booking.poojaAmount}</span>
                                                </div>
                                                <div>
                                                    {booking.imageUrl && (
                                                        <IonImg src={booking.imageUrl}
                                                            onClick={() => {
                                                                setImage(booking.imageUrl)
                                                                setShowLightBox(true)
                                                            }} alt={booking.name || `Pooja ${booking.poojaCode}`} className="w-12 h-12 rounded-md object-cover" style={{ width: "150px", height: "150px" }} />
                                                    )}
                                                </div>
                                            </div>

                                            {booking.bookingSuccess === "7" && (
                                                <p className="text-red-500 text-sm mt-1">
                                                    Cancelled: {booking.templeBookingRemark}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </IonCardContent>
                    </IonCard>

                    {/* Delivery and Payment Card */}
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Delivery & Payment</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="space-y-4">
                                {order.bookingDetailsDtos.some((b: any) => b.prasadamRequired === "1") && (
                                    <div className="border-b pb-3">
                                        <p className="font-medium">Delivery Address</p>
                                        <p className="text-sm text-gray-600 whitespace-pre-line">
                                            {order.bookingDetailsDtos.find((b: any) => b.prasadamDeliveryDetails)
                                                ?.prasadamDeliveryDetails}
                                        </p>
                                        <p className="text-sm mt-2">
                                            Delivery Charge: <span className="font-medium">₹{order.prasadamDeliveryCharge}</span>
                                        </p>
                                    </div>
                                )}

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span>Pooja Amount : </span>
                                        <span>₹{order.poojaAmount}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span>Service Charges : </span>
                                        <span>₹{order.charges}</span>
                                    </div>
                                    {order.templeDonation > 0 && (
                                        <div className="flex justify-between">
                                            <span>Temple Donation : </span>
                                            <span>₹{order.templeDonation}</span>
                                        </div>
                                    )}
                                    {tantriCharges > 0 && (
                                        <div className="flex justify-between">
                                            <span>Tantri Charges : </span>
                                            <span>₹{tantriCharges}</span>
                                        </div>
                                    )}
                                    {poojari > 0 && (
                                        <div className="flex justify-between">
                                            <span>Poojari Dakshina : </span>
                                            <span>₹{poojari}</span>
                                        </div>
                                    )}
                                    {templeDonation > 0 && (
                                        <div className="flex justify-between">
                                            <span>Temple Donation : </span>
                                            <span>₹{templeDonation}</span>
                                        </div>
                                    )}
                                    <div className="flex justify-between font-bold pt-2 border-t">
                                        <span>Total Amount : </span>
                                        <span>₹{order.totalAmount}</span>
                                    </div>
                                </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            </div>
        );
    };


    const modalClose = () => {
        window.location.reload();
    }

    const handleShare = (order: any) => {
        let shareText = ` Order Details:\n`;
        shareText += `Order No: ${order.orderNo}\n Date: ${formatDate(order.orderDate, "0")}\n\n`;

        shareText += `🔹 Pooja Details:\n`;
        order.bookingDetailsDtos.forEach((booking: any) => {
            shareText += ` ${booking.name || `Pooja ${booking.poojaCode}`}\n`;
            shareText += ` Date: ${formatDate(booking.poojaFrom, "1")}\n`;
            shareText += `Amount: ₹${booking.poojaAmount}\n\n`;
        });

        // Add Delivery Address if available
        const deliveryDetails = order.bookingDetailsDtos.find((b: any) => b.prasadamDeliveryDetails)?.prasadamDeliveryDetails;
        if (deliveryDetails) {
            shareText += ` Delivery Address:\n${deliveryDetails}\n\n`;
        }

        shareText += `💳 Total Amount: ₹${order.totalAmount}`;

        // Encode message properly
        const encodedMessage = encodeURIComponent(shareText);

        // Share via WhatsApp or Email
        const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
        const emailUrl = `mailto:?subject=Order Details&body=${encodedMessage}`;

        window.open(whatsappUrl, '_blank'); // Opens WhatsApp
        // window.open(emailUrl, '_blank'); // Uncomment for Email
    };


    const subHeaderComponentdate = useMemo(() => {

        return (
            <IonRow className="table_btn_container">
                <div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <IonSearchbar
                        value={filterTextDate}
                        onIonChange={(e: any) => setFilterTextDate(e.target.value)}
                        placeholder="Search"
                        className="table_search"
                    />

                </div>
            </IonRow>
        );
    }, [filterTextDate]);

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"login"} Role={'Role'} Name='Home,User Booking Orders' />
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <div className="backgroundorders page_content_padding">

                    {showLoading === false && cartList.length == 0 ?
                        <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                            <IonText className="no_data">Your Order is Empty</IonText>
                        </div> : <div>
                            <IonGrid>
                                {!isMobile ?
                                    <CommonDataTable
                                        columns={headerColumns}
                                        expandableRows={true}
                                        data={dateTableData}
                                        defaultSortFieldId="name"
                                        striped
                                        noDataComponent="Sorry ! No result found"
                                        isSubHeader={true}
                                        expandableRowsComponent={OrderDetailsCard}
                                        onRowClick={handleRowClicks}
                                        expandedRows={(row: any) => !!expandedRows[row.id]}
                                        subHeaderComponent={subHeaderComponentdate}
                                        selectableRows={false} /> :
                                    <div className="cart-list-parent">
                                        {cartList.map((order: any) => {

                                            return (
                                                <IonList key={order.orderNo} className="cart-list-item">
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "10px" }}>
                                                        <IonIcon onClick={() => handleShare(order)} style={{ color: "#333" }} icon={logoWhatsapp} />
                                                    </div>
                                                    <IonListHeader>
                                                        <IonLabel>
                                                            Order #{order.orderNo}
                                                            <IonText color="medium" className="ion-padding-start">
                                                                {formatDate(order.orderDate, "0")}
                                                            </IonText>
                                                        </IonLabel>
                                                    </IonListHeader>

                                                    {order.bookingDetailsDtos
                                                        .filter((booking: any) => !['VDC', 'DLVR', 'TANTRI', 'SWAMI', 'TEMPLE'].includes(booking.poojaCode))
                                                        .map((booking: any) => {
                                                            return (
                                                                <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                                        {booking.imageUrl && (
                                                                            <IonImg src={imagePathForRecipt + booking.imageUrl}
                                                                                onClick={() => {
                                                                                    setImage(imagePathForRecipt + booking.imageUrl)
                                                                                    setShowLightBox(true)
                                                                                }} alt={booking.name || `Pooja ${booking.poojaCode}`} className="w-12 h-12 rounded-md object-cover" style={{ width: "150px", height: "150px" }} />
                                                                        )}
                                                                    </div>
                                                                    <IonItem key={booking.id}>
                                                                        <IonLabel>
                                                                            <h2>{booking.name || `Pooja ${booking.poojaCode}`}</h2>
                                                                            <IonText color="medium">
                                                                                {formatDate(booking.poojaFrom, "1")}
                                                                            </IonText>
                                                                            {booking.star && (
                                                                                <div>
                                                                                    <IonNote>{booking.star}</IonNote>
                                                                                </div>
                                                                            )}
                                                                        </IonLabel>
                                                                        <IonText slot="end" color="dark">₹{booking.poojaAmount}</IonText>
                                                                    </IonItem>
                                                                </>
                                                            );
                                                        })}

                                                    {order.bookingDetailsDtos.some((b: any) => b.prasadamRequired === "1") && (
                                                        <IonItem>
                                                            <IonLabel className="ion-text-wrap">
                                                                <h2>Delivery Address</h2>
                                                                <p>
                                                                    {order.bookingDetailsDtos.find((b: any) => b.prasadamDeliveryDetails)
                                                                        ?.prasadamDeliveryDetails}
                                                                </p>
                                                            </IonLabel>
                                                            <IonText slot="end">₹{order.prasadamDeliveryCharge}</IonText>
                                                        </IonItem>
                                                    )}

                                                    <IonItem>
                                                        <IonLabel>Service Charge</IonLabel>
                                                        <IonText slot="end">₹{order.charges}</IonText>
                                                    </IonItem>
                                                    {order.bookingDetailsDtos
                                                        .filter((b: any) => ['TANTRI', 'TEMPLE', 'SWAMI'].includes(b.poojaCode))
                                                        .map((item: any) => (
                                                            <IonItem key={item.id}>
                                                                <IonLabel>{item.name || `${item.poojaCode}`}</IonLabel>
                                                                <IonText slot="end">₹{item.poojaAmount}</IonText>
                                                            </IonItem>
                                                        ))}
                                                    <IonItem lines="none">
                                                        <IonLabel>
                                                            <h2><strong>Total</strong></h2>
                                                        </IonLabel>
                                                        <IonBadge slot="end" color="primary">₹{order.totalAmount}</IonBadge>
                                                    </IonItem>
                                                </IonList>
                                            )
                                        }
                                        )}
                                    </div>
                                }
                            </IonGrid>
                        </div>}
                </div>
                {showlightBox && (
                    <Lightbox
                        mainSrc={image}
                        onCloseRequest={() => setShowLightBox(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
                <Foot />
            </IonContent>
        </IonPage>
    )
}


export default UserBookingOrders;
