import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import "./Notification.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const Notifications: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Return Policy"} />
        <IonRow className="page_content_padding">
          <IonCol className="">
            <div style={{ margin: '2% 4%' }}>
             
              <div className="">
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>What can I return?</h6>
              </div>
              <div className="">
                <p className="policy_text">You may request returns for most items listed on Amazon.in that are explicitly identified as "returnable" on the product detail page and/or our policy and within the 'return window' period. To know about the "non-returnable" categories and the specific return windows for categories eligible for return.</p>
                
                <p className="policy_text" style={{paddingTop:"20px"}}>Products that are physically damaged, defective, has missing parts, or are different from their description in the product details page are eligible for return.</p>
              
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>How to return items?</h6>
              </div>
              <div className="">
                <p className="policy_text">1. Go to <a href="#/mygrammerce" style={{textDecoration:"none"}}>My Orders</a>. (ensure you have signed in before)</p>
                <p className="policy_text">2. Choose an item that you want to return or replace.</p>
                <p className="policy_text">3. Select a reason from menu and continue.</p>
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>What are the return guidelines?</h6>
              </div>
              <div className="">
                <p className="policy_text">1. Your address and the item that you wish to return must be eligible for return.</p>
                <p className="policy_text">2. If the return is not eligible for Pickup, a Self-Return option will be provided.</p>
                <p className="policy_text">3. Once the return is processed, you will, in accordance with <a href="#/RefundPolicy">Atmagram.com Refund Policy</a>, be issued a refund to your original payment method.
For Pay on Delivery orders, refunds will be processed either to your bank account (via National Electronic Funds Transfer (NEFT)).</p>

              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>Where can I view status of return?</h6>
              </div>
              <div className="">
                <p className="policy_text">1. Locate the item from <a href="#/mygrammerce" style={{textDecoration:"none"}}>My Orders</a></p>
                <p className="policy_text">2. Select Return/Refund status.</p>
                
              </div>

              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>How can I return a Gift?</h6>
              </div>
              <div className="">
                <p className="policy_text">For items eligible for refund as per return policy, you can also return the gift item in exchange for an Atmagram Gift Card, if the gift giver has sent a Gift Receipt to you.</p>
                <p className="policy_text" style={{paddingTop:"20px"}}>Certain items are eligible only for replacement as per our return policy and will be replaced after the issue is verified.</p>
                
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>How can I return a Gift Card?</h6>
              </div>
              <div className="">
                <p className="policy_text">Gift cards once purchased cannot be cancelled or returned due to regulatory restrictions.</p>
                
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>Can my order be replaced?</h6>
              </div>
              <div className="">
                <p className="policy_text">Only the items ordered from Grammerce can be replaced.</p>
                <p className="policy_text" style={{paddingTop:"20px"}}>If the item delivered is physically damaged/ defective or is different from their description on the product detail page, or has missing parts or accessories, it will be eligible for a free replacement as long as the exact item is available with the same seller.</p>
                
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>I want the replacement to a different address.</h6>
              </div>
              <div className="">
                <p className="policy_text">Replacement cannot be delivered to a different address. We recommend to return the item for a refund and place a new order with the new address.</p>
                
              </div>
              </div>
              
          </IonCol>
        </IonRow>


        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Notifications;