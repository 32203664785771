import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonIcon, IonImg, IonInput, IonLoading, IonPage, IonRow, IonSearchbar } from "@ionic/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import HeadFoot from "../Head";
import DataTable from "react-data-table-component";
import CommonDataTable from "../../components/datatable";
import { data } from "jquery";
import Foot from "../Foot";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import { Button, Form, Modal } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { set } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'; // Example icons
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import CommonModal from "../../components/popipInfo";
import { AppContext } from "../../components/AppContext";


const ReviewList: React.FC = () => {

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const [showLoading, setShowLoading] = useState(false);
    const [tabledata, settabledata]: any = useState([])
    const [showlightBox, setShowLightBox] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedImage, setIsCheckedImage] = useState(false);
    const [selectedImage, setSelectedImage]: any = useState(null);
    const imageref: any = useRef(null)
    const [image, setImage] = useState("");
    const [images, setImages]: any = useState("");
    const [radioValue, setRadioValue]: any = useState("Yes");
    const [userRole] = useState(sessionStorage.getItem("UserData"));
    const { exportCSV } = useContext(AppContext)!
    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [rowItem, setRowItem]: any = useState({})
    const [iserror, setiserror]: any = useState()
    const [title, setTitle]: any = useState()
    const [message, setMessage]: any = useState("")
    const [remarks, setremarks] = useState()
    const [details, setDetails] = useState("")
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [filterText, setFilterText] = useState("");
    const [reportData, setReportData] = useState([]);
    const [headerColumns, setHeaderColumns] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate]: any = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState(null);
    const [updatedRow, setUpdatedRow] = useState(selectedRow);




    const tableData1 = tabledata.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    useEffect(() => {
        getAllReviewsList()
    }, [toDate, fromDate])


    const handleRowClick = (row: any) => {
        console.log(row)
        setSelectedRow(row);
        setShowModal(true);
    };




    const getAllReviewsList = () => {
        if (!fromDate || !toDate) return; // Ensure both dates are selected
        if (new Date(toDate) < new Date(fromDate)) {
            setCommonModalMesg("To Date cannot be earlier than From Date.");
            setCommonmodaltitle("Error");
            setCommonmodal(true);
            return;
        }
        setShowLoading(true);

        const formatDateTime = (dateString: string) => {
            const selectedDate = new Date(dateString);
            const today = new Date();
            if (
                selectedDate.getFullYear() === today.getFullYear() &&
                selectedDate.getMonth() === today.getMonth() &&
                selectedDate.getDate() === today.getDate()
            ) {
                selectedDate.setHours(today.getHours(), today.getMinutes(), today.getSeconds());
            }
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
            const day = String(selectedDate.getDate()).padStart(2, "0");
            const hours = String(selectedDate.getHours()).padStart(2, "0");
            const minutes = String(selectedDate.getMinutes()).padStart(2, "0");
            const seconds = String(selectedDate.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const fromdateFormat = formatDateTime(fromDate);
        const todateFormat = formatDateTime(toDate);

        console.log(fromdateFormat, todateFormat);
        Authservice.getAllReviewsList(usertoken, userId, usertoken, fromdateFormat, todateFormat)
            .then((response: any) => {
                console.log(response.data);
                settabledata(response.data);
                setShowLoading(false);
            })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }


    const modalClose = () => {
        if (Commmonmodaltitle === "Information") {
            getAllReviewsList()
            setCommonmodal(false)
            setShowModal(false)
        } else {
            setCommonmodal(false)
        }
    }


    const columns = [
        {
            name: "Code",
            selector: (row: any) => row.code,
            sortable: true,
            width: "110px"
        },
        {
            name: "Name",
            selector: (row: any) => row.category == "Temple" ? row.templeName : row.category == "Deity" ? row.deityName : row.category == "District" ? row.districtName : "N/A",
            wrap: true,
            width: "300px"
        },
        {
            name: "Category",
            selector: (row: any) => row.category,
            sortable: true,
            width: "100px"

        },
        {
            name: "Review",
            selector: (row: any) => row.review,
            wrap: true,
        },
        {
            name: "Reviewed By",
            selector: (row: any) => row.reviewBy,
            sortable: true,
            width: "150px"

        },
        {
            name: "Reviewed On",
            selector: (row: any) => new Date(row.reviewOn).toLocaleDateString(),
            sortable: true,
            width: "150px"

        },


    ];

    const handleSubmit = () => {
        console.log("Submitting:", selectedRow, updatedRow);
        const data = {
            "code": selectedRow.code,
            "review": selectedRow.review,
            "category": selectedRow.category,
            "isActive": selectedRow.isActive || false,
            "isDeleted": selectedRow.isDelet || false,
            "isVetoed": selectedRow.isVetoed || false,
            "id":selectedRow.id
        }
        Authservice.createOrUpdateTempleReviews(userId, usertoken, usertoken, data).then((response: any) => {
            console.log(response);
            if (response.status === 200) {
                setCommonmodal(true)
                setCommonmodaltitle("Information")
                setCommonModalMesg("Review submitted successfully!");
            }
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    };

    const formatDate = (timestamp: any) => {
        console.log(timestamp)
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };




    const conditionalRowStyles = [
        {
            when: (row: any) => row.isUserHeader, // Highlight user rows
            style: {
                backgroundColor: "#e3eeff",
                fontWeight: "bold",
                borderBottom: "1px solid #d3d3d3",
            },
        },
    ];



    const handledateChange = (e: any, par: any) => {
        console.log(e.target.value)
        if (par === "from") {
            setFromDate(e.target.value)
            setToDate(null)
        } else {
            setToDate(e.target.value)
            getAllReviewsList()
        }
    }

    const handleCheckboxChange = (field: any, value: any) => {
        setSelectedRow((prev: any) => ({
            ...prev,
            [field]: value
        }));
    };



    const subHeaderComponent = useMemo(() => {
        return (
            <IonRow className="table_btn_container">
                <div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <IonInput type="date" value={fromDate} onIonChange={(e) => handledateChange(e, "from")} placeholder="From Date" />
                    <IonInput type="date" value={toDate} onIonChange={(e) => handledateChange(e, "to")} placeholder="To Date" />
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                    <IonButton className="admin_add_btn" onClick={() => exportCSV(columns, tabledata, "Reviews")} disabled={tabledata.length === 0}>
                        Export
                    </IonButton>
                </div>
            </IonRow>
        );
    }, [filterText, tabledata]);




    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Reviews"} />
                <Sidebar /></>}
            <IonContent>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={"Please wait..."}
                />
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Reviews' />}
                <div className={userRole === "ROLE_ADMIN" ? "container_middle_table page-wrapper admin_background_color" : "page_content_padding tableContainev2"}>
                    {/* {isDesktop ? */}
                    <CommonDataTable
                        columns={columns}
                        data={tableData1}
                        //  loading={showLoading}
                        noDataComponent={"Select Date Range to view data"}
                        onRowClick={handleRowClick}
                        conditionalRowStyles={conditionalRowStyles}
                        selectableRows={false}
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                    />

                </div>
                {userRole !== "ROLE_ADMIN" && <Foot />}
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Review Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow ? (
                            <div>
                                <p><strong>Code:</strong> {selectedRow.code}</p>
                                <p><strong>Name:</strong> {selectedRow.category === "Temple" ?
                                    selectedRow.templeName :
                                    selectedRow.category === "District" ?
                                        selectedRow.districtName :
                                        selectedRow.category === "Deity" ?
                                            selectedRow.deityName : "N/A"}</p>
                                <p><strong>Review:</strong> {selectedRow.review}</p>
                                <p><strong>Reviewed By:</strong> {selectedRow.reviewBy}</p>
                                <p><strong>Reviewed On:</strong> {new Date(selectedRow.reviewOn).toLocaleDateString()}</p>
                                <p><strong>Category:</strong> {selectedRow.category}</p>

                                {/* Checkboxes */}
                                <div className="checkbox-container">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedRow.isActive}
                                            onChange={(e) => handleCheckboxChange('isActive', e.target.checked)}

                                        /> Is Active
                                    </label>
                                    <br />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedRow.vetoedBy !== null || selectedRow.isVetoed !== null}
                                            onChange={(e) => handleCheckboxChange('isVetoed', e.target.checked)}

                                        /> Is Vetoed
                                    </label>
                                    <br />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedRow.isDelete}
                                            onChange={(e) => handleCheckboxChange('isDelete', e.target.checked)}

                                        /> Is Delete
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <p>No details available</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <IonButton className="btn_v2" onClick={() => setShowModal(false)}>
                            <span className="button_padding_v2">Close</span>
                        </IonButton>
                        <IonButton className="btn_v2 submit-btn" onClick={handleSubmit}>
                            <span className="button_padding_v2">Submit</span>
                        </IonButton>
                    </Modal.Footer>
                </Modal>

                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </IonContent>
        </IonPage>
    )
}

export default ReviewList;