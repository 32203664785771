import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonLoading, IonPage, IonRow, IonSearchbar, IonText, IonTextarea } from "@ionic/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AdminHead from "./adminHead";
import HeadFoot from "../Head";
import Sidebar from "./sidebar";
import Foot from "../Foot";
import Select from 'react-select';
import { AppContext } from "../../components/AppContext";
import Authservice from "../../Services/user.service";
import { chevronDownCircleOutline } from "ionicons/icons";
import CommonModal from "../../components/popipInfo";
import Breadcrumb from "../../components/Breadcrumb";
import CommonDataTable from "../../components/datatable";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router";

const DistrictComponenet: React.FC = () => {

    const [isUserSide, setIsuserSide] = useState(false)
    const [userdata]: any = useState(sessionStorage.getItem("UserData"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [index, setIndex] = useState(0);
    const [districtOptionList, setDistrictOptionList] = useState([])
    const [districtValue, setdistrictValue]: any = useState("")
    const [showLoading, setShowLoading] = useState(false);
    const [iserror, setIserror] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [successAlert, setSuccessAlert] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    const oneTimeRender = useRef(false)
    const [id, setId] = useState("")
    const [districtCOde, setDistrictCode] = useState("")
    const [generalBackground, setGeneralBackground] = useState("")
    const [geographicalSetting, setGeographicalSetting] = useState("")
    const [history, setHistory] = useState("")
    const [freedomStruggles, setFreedomStruggles] = useState("")
    const [peopleAndReligion, setPeopleAndReligion] = useState("")
    const [modeOfWorship, setModeOfWorship] = useState("")
    const [antiquity, setAntiquity] = useState("")
    const [architecture, setArchitecture] = useState("")
    const [carvingsAndPaintings, setCarvingsAndPaintings] = useState("")
    const [administration, setAdministration] = useState("")
    const [classification, setClassification] = useState("")
    const [timeOfWorship, setTimeOfWorship] = useState("")
    const [festivals, setFestivals] = useState("")
    const [templeStaff, setTempleStaff] = useState("")
    const [roleOfGovt, setRoleOfGovt] = useState("")
    const [coverage, setCoverage] = useState("")

    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [mesuIsOpen, setMesuIsOpen] = useState(true)

    const tab_items = [
        // "General Background",
        "Geographical Setting",
        "History",
        "Freedom Struggles",
        "People And Religion",
        "Mode Of Worship",
        "Administration",
        "Classification",
        "Time Of Worship",
        "Festivals",
        "Temple Staff",
        "Role Of Govt",
        // "Coverage",
        "Review"
    ]
    const [menuItem, setMenuItems] = useState(tab_items.map((data: any) => ({ name: data, isOpen: false })))
    const pageNames = ['District', 'District Details'];

    const [modalopen, setModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [showReviewInput, setShowReviewInput] = useState(false);
    const [reviewText, setReviewText] = useState("");
    const [modalData, setModaldata] = useState()


    const modalColumn = [
        {
            name: "Review",
            selector: (item: any, i: any) => item.review,
            sortable: true,
            width: "85%",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.review}</div>
                </div>
            )
        },
        {
            name: "Review By",
            selector: (item: any, i: any) => item.reviewBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
                </div>
            )
        },

    ]

    const modalColumnAdmin = [
        {
            name: "Active",
            selector: (item: any, i: any) => item.isActive,
            sortable: true,
            width: "100px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.isActive === null ? "N/A" : item.isActive ? "Yes" : "No"}</div>
                </div>
            )
        },
        {
            name: "Delete",
            width: "100px",
            selector: (item: any, i: any) => item.isDeleted,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.isDeleted === null ? "N/A" : item.isDeleted ? "Yes" : "No"}</div>
                </div>
            )
        },
        {
            name: "Vetoed By",
            width: "100px",
            selector: (item: any, i: any) => item.vetoedBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.vetoedBy === null ? "N/A" : item.vetoedBy}</div>
                </div>
            )
        },
        {
            name: "Vetoed On",
            width: "100px",
            selector: (item: any, i: any) => item.vetoedon,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.vetoedon === null ? "N/A" : item.vetoedon}</div>
                </div>
            )
        },
        {
            name: "Review",
            selector: (item: any, i: any) => item.review,
            sortable: true,
            width: "50%",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.review}</div>
                </div>
            )
        },
        {
            name: "Review By",
            selector: (item: any, i: any) => item.reviewBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
                </div>
            )
        },

    ]



    const showAllDistrict = () => {
        setShowLoading(true)
        Authservice.getAdmAllDistricts(userId, "", usertoken).then((response: any) => {
            if (response.data.length > 0) {
                const data = response.data.map((e: any) => ({ label: e.districtName, value: e.districtCode }));
                setDistrictOptionList(data)
            }
            setShowLoading(false)
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }

    const getMessagesByCategory = (e: any) => {
        Authservice.getMessagesByCategory(userId, usertoken, usertoken, e).then((res: any) => {
            console.log(isUserSide)
            if (res.data !== "" && res.data !== null) {
                setCommonModalMesg(res.data)
                setCommonmodaltitle("Information")
                setCommonmodal(true)
                setShowLoading(false)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }
    const local: any = useLocation();


    useEffect(() => {
        if (oneTimeRender.current === false) {
            oneTimeRender.current = true;
            if (local.pathname === "/districtmanagement") {
                setIsuserSide(false)
            }
            else {
                setIsuserSide(true)
            }
            showAllDistrict()
            getMessagesByCategory("District")
        }
    }, [])

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "none",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const getDistrictsDetails = (e: any) => {
        setShowLoading(true)
        Authservice.getDistrictsDetails(userId, "", usertoken, e).then((response: any) => {
            if (response.data !== "") {
                setId(response.data.id)
                setDistrictCode(response.data.districtCode)
                setGeneralBackground(response.data.generalBackground || "")
                setGeographicalSetting(response.data.geographicalSetting || "")
                setHistory(response.data.history || "")
                setFreedomStruggles(response.data.freedomStruggles || "")
                setPeopleAndReligion(response.data.peopleAndReligion || "")
                setModeOfWorship(response.data.modeOfWorship || "")
                setAntiquity(response.data.antiquity || "")
                setArchitecture(response.data.architecture || "")
                setCarvingsAndPaintings(response.data.carvingsAndPaintings || "")
                setAdministration(response.data.administration || "")
                setClassification(response.data.classification || "")
                setTimeOfWorship(response.data.timeOfWorship || "")
                setFestivals(response.data.festivals || "")
                setTempleStaff(response.data.templeStaff || "")
                setRoleOfGovt(response.data.roleOfGovt || "")
                setCoverage(response.data.coverage || "")
                setModaldata(response.data.reviewsDtos || [])
            }
            else {
                clearAll()
            }
            setIndex(1)
            setMenuItems((prev: any) =>
                prev.map((item: any) => ({
                    ...item,
                    isOpen: false,
                }))
            );
            setShowLoading(false)
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }

    const clearAll = () => {
        setId("")
        setDistrictCode("")
        setGeneralBackground("")
        setGeographicalSetting("")
        setHistory("")
        setFreedomStruggles("")
        setPeopleAndReligion("")
        setModeOfWorship("")
        setAntiquity("")
        setArchitecture("")
        setCarvingsAndPaintings("")
        setAdministration("")
        setClassification("")
        setTimeOfWorship("")
        setFestivals("")
        setTempleStaff("")
        setRoleOfGovt("")
        setCoverage("")
    }

    const openContent = (index: number) => {
        if (index === 11) {
            if (userId) {
                setModalOpen(true)
                return
            }
            else {
                getMessagesByCategory("LoginMessage")
                return
            }
        }
        const updatedMenuItems = menuItem.map((item: any, i: number) => ({
            ...item,
            isOpen: i === index ? !item.isOpen : false, // Toggle selected item, close others
        }));
        setMenuItems(updatedMenuItems);
    };

    const handleDistrictChange = (e: any) => {
        console.log(e)
        if (e) {
            setdistrictValue(e);
            getDistrictsDetails(e.value)
        } else {
            setdistrictValue({})
            clearAll()
        }
        setMesuIsOpen(false)
    }

    const addUpdateDistrict = () => {
        setShowLoading(true)
        const districtDetailsDto = {
            "id": id,
            "districtCode": districtValue.value,
            "generalBackground": generalBackground,
            "geographicalSetting": geographicalSetting,
            "history": history,
            "freedomStruggles": freedomStruggles,
            "peopleAndReligion": peopleAndReligion,
            "modeOfWorship": modeOfWorship,
            "administration": administration,
            "classification": classification,
            "timeOfWorship": timeOfWorship,
            "festivals": festivals,
            "templeStaff": templeStaff,
            "roleOfGovt": roleOfGovt,
            "coverage": coverage
        }
        Authservice.addUpdateDistrict(userId, "", usertoken, districtDetailsDto).then((response: any) => {
            if (response.data === "success") {
                if (id === "" || id === null) {
                    setSuccessMsg("District Details Added Successfully")
                }
                else {
                    setSuccessMsg("District Details Updated Successfully")
                }
                setSuccessAlert(true)
            }
            else {
                setErrorMsg("Something wen`t wrong, Please try again later")
                setIserror(true)
            }
            console.log(response.data, "data")
            setShowLoading(false)
        })
            .catch((error: any) => {
                setShowLoading(false)
                setErrorMsg("Something wen`t wrong, Please try again later")
                setIserror(true)
                console.log(error, "error")
            })
    }


    const modalClose = () => {
        setCommonmodal(false)
    }

    const handleBreadcrumbClick = (index: any) => {
        console.log(index, "index")
        setIndex(index);
    };

    const handleSubmitReview = async () => {
        if (!reviewText.trim()) {
            alert("Please enter a review before submitting.");
            return;
        }
        const data = {
            "code": districtCOde,
            "review": reviewText,
            "category": "District",
            "isActive": false,
            "isDeleted": false,
            "isVetoed": false
        }
        Authservice.createOrUpdateTempleReviews(userId, usertoken, usertoken, data).then((response: any) => {
            console.log(response);
            setReviewText(""); // Clear textarea
            setShowReviewInput(false); // Hide input after submission
            setModalOpen(false)
            if (response.status === 200) {
                setCommonmodal(true)
                setCommonmodaltitle("Information")
                setCommonModalMesg("Review submitted successfully!");
            }

        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });

    };




    return (
        <IonPage>
            {!isUserSide ? <AdminHead User={"user"} Role={"Role"} Name={"District Management"} /> : <HeadFoot User={"login"} Role="Role" Name='Home,District' />}
            {!isUserSide && <Sidebar />}
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    cssClass="my-custom-class"
                    header={"Error!"}
                    message={errorMsg}
                    buttons={["Ok"]}
                />
                <IonAlert
                    isOpen={successAlert}
                    onDidDismiss={() => setSuccessAlert(false)}
                    cssClass="my-custom-class"
                    header={"Success"}
                    message={successMsg}
                    onWillDismiss={() =>
                        // clearAll();
                        // setIndex(0);
                        // setdistrictValue("")
                        setSuccessAlert(false)
                    }
                    buttons={["Ok"]}
                />
                <div className={isUserSide ? "page_content_padding deity_container district_container" : "container_middle_table page-wrapper admin_background_color"}>
                    {isUserSide && <Breadcrumb pageNames={pageNames}
                        currentPage={index}
                        onBreadcrumbClick={handleBreadcrumbClick} />}
                    <IonRow className={isUserSide ? "" : "data_table"} style={{ paddingTop: '10px', textAlign: "left" }}>
                        <div style={{ width: "100%" }}>
                            <IonLabel className="form-labels">District <span style={{ color: 'red' }}>*</span></IonLabel>
                            <Select
                                options={districtOptionList}
                                value={districtValue}
                                className="profile-autoSelect"
                                placeholder="Select a District"
                                isClearable={false}
                                styles={customStyles}
                                defaultMenuIsOpen={true}
                                onChange={(e) => { handleDistrictChange(e) }}
                            />
                        </div>
                    </IonRow>
                    {index === 1 &&
                        <IonGrid className='tab_parent_div'>
                            {menuItem.map((section: any, index: any) => {
                                return (
                                    <div key={index} className={section.isOpen === true ? 'temple_tab_container temple_active_tab_container' : 'temple_tab_container'}>
                                        <div onClick={() => openContent(index)} className={section.isOpen === true ? 'temple_tab label_icon active_tab_container' : 'label_icon temple_tab'}>
                                            <IonIcon src={chevronDownCircleOutline} />
                                            <IonLabel>{section.name}</IonLabel>
                                        </div>
                                        {section.isOpen &&
                                            <div className='tab_content_container'>
                                                {
                                                    // index === 0 ?
                                                    //     <IonGrid>
                                                    //         <IonRow>
                                                    //             <IonTextarea autoGrow
                                                    //                 className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    //                 placeholder="Enter General Background"
                                                    //                 disabled={isUserSide}
                                                    //                 value={generalBackground}
                                                    //                 onChange={(e) => setGeneralBackground(e.target.value)}
                                                    //             />
                                                    //         </IonRow>
                                                    //     </IonGrid>
                                                    index === 0 ?
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonTextarea autoGrow
                                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                    placeholder="Enter Geographical Setting"
                                                                    readonly={isUserSide}
                                                                    value={geographicalSetting}
                                                                    onIonChange={(e: any) => setGeographicalSetting(e.target.value)}
                                                                />
                                                            </IonRow>
                                                        </IonGrid>
                                                        : index === 1 ?
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonTextarea autoGrow
                                                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                        placeholder="Enter History"
                                                                        readonly={isUserSide}
                                                                        value={history}
                                                                        onIonChange={(e: any) => setHistory(e.target.value)}
                                                                    />
                                                                </IonRow>
                                                            </IonGrid>
                                                            : index === 2 ?
                                                                <IonRow>
                                                                    <IonTextarea autoGrow
                                                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                        placeholder="Enter Freedom Struggles"
                                                                        readonly={isUserSide}
                                                                        value={freedomStruggles}
                                                                        onIonChange={(e: any) => setFreedomStruggles(e.target.value)}
                                                                    />
                                                                </IonRow>
                                                                : index === 3 ?
                                                                    <IonRow>
                                                                        <IonTextarea autoGrow
                                                                            className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                            placeholder="Enter People And Religion"
                                                                            readonly={isUserSide}
                                                                            value={peopleAndReligion}
                                                                            onIonChange={(e: any) => setPeopleAndReligion(e.target.value)}
                                                                        />
                                                                    </IonRow>
                                                                    : index === 4 ?
                                                                        <IonRow>
                                                                            <IonTextarea autoGrow
                                                                                className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                placeholder="Enter Mode Of Worship"
                                                                                readonly={isUserSide}
                                                                                value={modeOfWorship}
                                                                                onIonChange={(e: any) => setModeOfWorship(e.target.value)}
                                                                            />
                                                                        </IonRow>
                                                                        : index === 5 ?
                                                                            <IonRow>
                                                                                <IonTextarea autoGrow
                                                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                    placeholder="Enter the Administration"
                                                                                    readonly={isUserSide}
                                                                                    value={administration}
                                                                                    onIonChange={(e: any) => setAdministration(e.target.value)}
                                                                                />
                                                                            </IonRow>
                                                                            : index === 6 ?
                                                                                <IonRow>
                                                                                    <IonTextarea autoGrow
                                                                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                        placeholder="Enter Healing"
                                                                                        readonly={isUserSide}
                                                                                        value={classification}
                                                                                        onIonChange={(e: any) => setClassification(e.target.value)}
                                                                                    />
                                                                                </IonRow>
                                                                                : index === 7 ?
                                                                                    <IonRow>
                                                                                        <IonTextarea autoGrow
                                                                                            className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                            placeholder="Enter Sacred Rituals"
                                                                                            readonly={isUserSide}
                                                                                            value={timeOfWorship}
                                                                                            onIonChange={(e: any) => setTimeOfWorship(e.target.value)}
                                                                                        />
                                                                                    </IonRow>
                                                                                    : index === 8 ?
                                                                                        <IonRow>
                                                                                            <IonTextarea autoGrow
                                                                                                className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                                placeholder="Enter Community Life"
                                                                                                readonly={isUserSide}
                                                                                                value={festivals}
                                                                                                onIonChange={(e: any) => setFestivals(e.target.value)}
                                                                                            />
                                                                                        </IonRow>
                                                                                        : index === 9 ?
                                                                                            <IonRow>
                                                                                                <IonTextarea autoGrow
                                                                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                                    placeholder="Enter Modern Relevance"
                                                                                                    readonly={isUserSide}
                                                                                                    value={templeStaff}
                                                                                                    onIonChange={(e: any) => setTempleStaff(e.target.value)}
                                                                                                />
                                                                                            </IonRow>
                                                                                            : index === 10 ?
                                                                                                <IonRow>
                                                                                                    <IonTextarea autoGrow
                                                                                                        className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                                        placeholder="Enter Role Of Govt"
                                                                                                        readonly={isUserSide}
                                                                                                        value={roleOfGovt}
                                                                                                        onIonChange={(e: any) => setRoleOfGovt(e.target.value)}
                                                                                                    />
                                                                                                </IonRow>
                                                                                                // : index === 11 ?
                                                                                                //     <IonRow>
                                                                                                //         <IonTextarea autoGrow
                                                                                                //             className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                                                //             placeholder="Enter the Coverage"
                                                                                                //             readonly={isUserSide}
                                                                                                //             value={coverage}
                                                                                                //             onIonChange={(e:any) => setCoverage(e.target.value)}
                                                                                                //         />
                                                                                                //     </IonRow>
                                                                                                //    
                                                                                                :
                                                                                                <></>
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            {!isUserSide &&
                                <IonRow style={{ justifyContent: "flex-end", marginTop: "10px" }}>
                                    <IonButton onClick={addUpdateDistrict} className="admin_add_btn">
                                        <span className="button_padding_v2">
                                            Submit
                                        </span>
                                    </IonButton>
                                    <IonButton onClick={() => { clearAll(); setIndex(0) }} className="admin_cencel_btn" fill="outline">
                                        <span className="button_padding_v2">
                                            Cancel
                                        </span>
                                    </IonButton>
                                </IonRow>
                            }
                        </IonGrid>
                    }
                    {isUserSide && <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
                    }
                </div>
                <Modal show={modalopen} className="off_info_modal" centered size={"xl"}>
                    <Modal.Header>
                        <IonGrid>
                            <IonRow className="off_info_modal_header">
                                <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
                                    <IonText className="info_modal_offname">Information</IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                            {/* Hide Table when Adding Review */}
                            {!showReviewInput ? (
                                <>
                                    <CommonDataTable
                                        columns={isUserSide ? modalColumn : modalColumnAdmin}
                                        data={modalData}
                                        loading={showLoading}
                                        type="reviewTable"
                                        noDataMessage="Sorry, No data available"
                                    />

                                    {/* Add Review & Cancel Buttons in Same Line */}
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", gap: "10px" }}>
                                        <IonButton className="btn_v2 admin_add_btn" onClick={() => setShowReviewInput(true)}>
                                            <span className="button_padding_v2">
                                                Add Review
                                            </span>
                                        </IonButton>
                                        <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setModalOpen(false)}>
                                            <span className="button_padding_v2">
                                                Cancel
                                            </span>
                                        </IonButton>
                                    </div>
                                </>
                            ) : (
                                /* Review Input Section */
                                <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                                    <IonTextarea autoGrow
                                        placeholder="Write your review here..."
                                        value={reviewText}
                                        onIonChange={(e: any) => setReviewText(e.target.value)}
                                        style={{
                                            width: "80%",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            border: "1px solid #ccc",
                                            fontSize: "14px",
                                        }}
                                    />

                                    {/* Submit & Back Buttons in Same Line */}
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
                                        <IonButton className="btn_v2 admin_add_btn" onClick={handleSubmitReview}>
                                            <span className="button_padding">  Submit Review </span>
                                        </IonButton>
                                        <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setShowReviewInput(false)}>
                                            <span className="button_padding">  Back</span>
                                        </IonButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </IonContent>
            {isUserSide && <Foot />}
        </IonPage>
    )
}

export default DistrictComponenet;