import React, { useEffect, useState } from "react";
import {
  IonToolbar,
  IonRow,
  IonCol,
  IonIcon,
  useIonRouter,
  IonItemDivider,
  IonImg,
  IonText,
  IonButton,
} from "@ionic/react";
import "./Userpage.css";
import "./Home.css";
import "./Login.css"


import {
  logoFacebook,
  call,
  logoYoutube,
  logoTwitter,
  logoInstagram,
  mailOutline,
  callOutline,
  logoWhatsapp,
  home,
} from "ionicons/icons";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { logo } from "../theme/imagePath";
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";
import { Modal } from "react-bootstrap";
import CommonModal from "../components/popipInfo";
const Foot: React.FC = () => {

  const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
  const [userId]: any = useState(sessionStorage.getItem("UserId"))
  const router = useIonRouter();
  const recipient = 'srisankarapeetam@gmail.com';
  const subject = 'Atmagram';
  const [showModal, setShowModal] = useState(false);
  const [addressData, setAddressData] = useState("");
  const [Commonmodal, setCommonmodal] = useState(false)
  const [CommonModalMesg, setCommonModalMesg] = useState("")
  const [Commmonmodaltitle, setCommonmodaltitle] = useState("")

  const shareViaWhatsapp = () => {
    const phoneNumber = '+918281641474'; // Replace with the phone number you want to share with
    const message = encodeURIComponent('Hi Atmagram');

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };
  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}`;
  const phone = "+918281641474";

  const mailtogo = (e: any) => {
    console.log(e)
    if (userId || e == "ContactAddress") {
      UserService.getAppConfigByName(usertoken, userId, e).then((response) => {
        console.log(response.data);
        if (response.data) {
          if (e === "ContactNo") {
            window.location.href = `tel:${response.data.value}`;
          } else if (e === "ContactEmail") {
            window.location.href = `mailto:${response.data.value}`;
          }
          else {
            setAddressData(response.data.value);
            setShowModal(true);
          }
        }
      }
      )
    } else {
      getMessagesByCategory()
    }
  }

  const getMessagesByCategory = () => {
    Authservice.getMessagesByCategory(userId, usertoken, usertoken, "LoginMessage").then((res: any) => {
      if (res.data !== "" && res.data !== null) {
        setCommonModalMesg(res.data)
        setCommonmodaltitle("Information")
        setCommonmodal(true)
      }
    })
      .catch((error: any) => {
        console.log(error, "error")
      })
  }

  const handleOpenTerms = (e: any) => {
    if (e === 1) {
      const pdfUrl = `${window.location.origin}/termsandconditions.pdf`;
      window.open(pdfUrl, "_blank");
    }
    else {
      const pdfUrl = `${window.location.origin}/privacypolicy.pdf`;
      window.open(pdfUrl, "_blank");
    }
  };


  return (
    <div>
      <div>
        <IonToolbar className="high_align" color="primary">
          <div className="footer_parent">
            <div className="media_alignment_container padding_adjustment">
              <div style={{ display: "grid", gap: "5px", padding: "10px 0px" }}>
                <div>
                  <label className="terms_label">
                    <a style={{ cursor: "pointer" }}
                      onClick={() => handleOpenTerms(1)}
                    >Terms and Conditions</a></label>
                </div>
                <div>
                  <label className="terms_label">
                    <a style={{ cursor: "pointer" }}
                      onClick={() => handleOpenTerms(2)}
                    >Privacy Policy</a></label>
                </div>
              </div>
              <IonRow className="media_logo_container">
                <IonText style={{ fontSize: "20px" }}> Contact Us : </IonText>
                <IonIcon src={mailOutline} className="font_icon" onClick={() => mailtogo("ContactEmail")} />
                <IonIcon src={callOutline} className="font_icon" tabIndex={0} onClick={() => mailtogo("ContactNo")} />
                <IonIcon src={home} className="font_icon" onClick={() => mailtogo("ContactAddress")} tabIndex={0} />
              </IonRow>
            </div>
            <div >
              <div style={{ justifyContent: "end", alignItems: "end", display: "flex" }}>
                <IonText className="copy_font">
                  Copyrights @ 2025
                </IonText>
                <IonText className="copy_font_hignlight ">Atmagram</IonText>
                <IonText className="copy_font">
                  . All Rights Reserved.
                </IonText>
              </div>
            </div>
          </div>
        </IonToolbar>
        <Modal show={showModal} centered style={{ width: "40%", left: "30%" }}>
          <Modal.Body>
            <div style={{ textAlign: "center", whiteSpace: "pre-line" }}>
              <IonText>{addressData}</IonText>
              <br />
              <IonButton className="admin_add_btn" onClick={() => setShowModal(false)}>
                <span className="button_padding">OK</span>
              </IonButton>
            </div>
          </Modal.Body>
        </Modal>
        <CommonModal showModal={Commonmodal} closeModal={() => setCommonmodal(false)} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />

      </div>
    </div>
  )
}
export default Foot
