import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonInput, IonLabel, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRow, IonText, useIonAlert } from "@ionic/react";
import HeadFoot from "../Head";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useEffect, useState } from "react";
import Select from 'react-select';
import "./report.css"
import UserService from "../../Services/auth.service";
import Authservice from "../../Services/user.service";
import { saveAs } from 'file-saver';

const Report: React.FC = () => {

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [type, setType] = useState("")
    const [admin, setAdmin] = useState("")
    const [vendorname, setVendorName] = useState("")
    const [templeName, setTempleName] = useState("")
    const [region, setRegion] = useState("")
    const [status, setStatus] = useState()
    const [completedStatus, setCompletedStatus] = useState(false)
    const [inCompletedStatus, setInCompletedStatus] = useState(false)
    const [report, setReport] = useState("")
    const [filterStatus, setFilterStatus]:any=useState("")
    const [dateTypeFilter, setDateType]:any=useState("")
    const [reportFilter, setReportType]:any=useState("")
    const userRole = sessionStorage.getItem("UserData")
    const [option, setOption]:any = useState()
    const [regionList, setRegionList] = useState([]);
    const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
    const [showLoading, setShowLoading] = useState(false)
    const [regionChoose, setregionChoose]:any=useState("")
    const [statusChoose, setstatusChoose]:any=useState("")
    const [orderDate, setorderDate] = useState(false)
    const [deliveryDate, setdeliveryDate] = useState(false)
   
  
    const [Alert] = useIonAlert();
    const orderStatusList:any[] = [
       
       
    {value:1, label:"Temple"},
    {value:2, label:"Vendor"},
    {value:3, label:"Grammerce"},
    {value:4, label:"Donation"},
    {value:5, label:"Project Donation"},]
    const dateType:any[] = [
       
       
        {value:1, label:"Ordered Date"},
        {value:2, label:"Delivery Date"},
        ]
        const statusList:any[] = [
       
       
        {value:"All", label:"All"},
        {value:"Completed", label:"Completed"},
        {value:"InCompleted", label:"InCompleted"},
        ]
        const reportType:any[] = [
       
       
            {value:1, label:"Summary"},
            {value:2, label:"Detailed"},
            ]
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left",
            zIndex:9999
            
        }),
    };

    const handleregionChange = (e:any) => {
        setRegion(e)
        setregionChoose(e.value) ;

    }
    useEffect(() => {
        console.log(userRole)
        getAllRegion();
        if (userRole === "ROLE_TEMPADMIN") {
            setAdmin("2")
            setOption(1)
            return
        }
        else if (userRole === "ROLE_VENDOR") {
            setAdmin("3")
            setOption(2)
            return
        }
        else if (userRole === "ROLE_PROJECTADMIN") {
            setAdmin("4")
            setOption(5)
            return
        }
        else if (userRole === "ROLE_ADMIN") {
            setAdmin("1")
            return
        }
    }, [])
    const getAllRegion = () => {
        setShowLoading(true);
        console.log("token",usertoken)
        Authservice.getAllRegion(usertoken).then((response: any) => {
          console.log(response.data)
          if (response.data !== undefined || response.data.length !== 0) {
            var data = response.data;
            var dataArr:any = [];
            for(var i=0; i<data.length;i++){
              var d1 =  {value:data[i], label:data[i]}
              dataArr.push(d1)

            }
            setRegionList(dataArr);
          } else {
            setRegionList([]);
            
          }
          setShowLoading(false)
    
        })
          .catch((error: any) => {
            console.log(error);
            
            setShowLoading(false);
          });
      }
      const formatDate = (dateString:any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
   const  handleOrderChange = (e:any) =>{
    setFilterStatus(e)
    setOption(e.value)

   }
 
   const  handleDateTypeChange = (e:any) =>{
    setDateType(e)
    if(e.value == 1){
        setorderDate(true)
    }else if(e.value == 2){
        setdeliveryDate(true)
    }

    }
    const handlestatusChange = (e:any) =>{
        setStatus(e)
        setstatusChoose(e.value)

    }
    const handlereporttypeChange = (e:any) =>{
        setReportType(e)
    
        }

        const downLoadForGrammerce = (stDate:any,enDate:any) => {
            var reqData = {
                "startDate" : stDate,
                "endDate" : enDate,
                "region" : regionChoose,
                "status":statusChoose
                
            }
                Authservice.downLoadGrammerceReport(usertoken,reqData).then((response) => {
                    console.log(response);
                    
                    if(response.data == "No Data Found"){
                        Alert(response.data)
                    }else{
                          // Decode the base64 string
          const decodedData = atob(response.data);
          const filename = "grammerce"+new Date()+".xlsx";
        
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
        
          // Convert the Uint8Array to a Blob
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        
          // Use FileSaver.js to save the Blob as a file
          saveAs(blob, filename);
          window.location.reload();
                    }
        
        
                   setShowLoading(false);
                }) .catch((error) => {
                    setShowLoading(false);
                    Alert("Something went wrong, please try again later.")
                   
                });
        

        }
        const downLoadForDonation = (stDate:any,enDate:any) => {
            var reqData = {
                "startDate" : stDate,
                "endDate" : enDate,
                "region" : "",
               
                
            }
                Authservice.downLoadDonationReport(usertoken,reqData).then((response) => {
                    console.log(response);
                    
                    if(response.data == "No Data Found"){
                        Alert(response.data)
                    }else{
                          // Decode the base64 string
          const decodedData = atob(response.data);
          const filename = "Donation"+new Date()+".xlsx";
        
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
        
          // Convert the Uint8Array to a Blob
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        
          // Use FileSaver.js to save the Blob as a file
          saveAs(blob, filename);
          window.location.reload();
                    }
        
        
                   setShowLoading(false);
                }) .catch((error) => {
                    setShowLoading(false);
                    Alert("Something went wrong, please try again later.")
                   
                });
        

        }
        const downLoadForProjectDonation = (stDate:any,enDate:any) => {
            var reqData = {
                "startDate" : stDate,
                "endDate" : enDate,
                "region" : regionChoose,
               
                
            }
                Authservice.downLoadProjectDonationReport(usertoken,reqData).then((response) => {
                    console.log(response);
                    
                    if(response.data == "No Data Found"){
                        Alert(response.data)
                    }else{
                          // Decode the base64 string
          const decodedData = atob(response.data);
          const filename = "ProjectDonation"+new Date()+".xlsx";
        
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
        
          // Convert the Uint8Array to a Blob
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        
          // Use FileSaver.js to save the Blob as a file
          saveAs(blob, filename);
          window.location.reload();
                    }
        
        
                   setShowLoading(false);
                }) .catch((error) => {
                    setShowLoading(false);
                    Alert("Something went wrong, please try again later.")
                   
                });
        

        }
        const downLoadForVendor = (stDate:any,enDate:any) => {
            var reqData = {
                "startDate" : stDate,
                "endDate" : enDate,
                "region" : regionChoose,
                "status":statusChoose,
                "orderDate": orderDate,
                "deliveryDate": deliveryDate,
               
                
            }
                Authservice.downLoadVendorReport(usertoken,reqData).then((response) => {
                    console.log(response);
                    
                    if(response.data == "No Data Found"){
                        Alert(response.data)
                    }else{
                          // Decode the base64 string
          const decodedData = atob(response.data);
          const filename = "vendor_report"+new Date()+".xlsx";
        
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
        
          // Convert the Uint8Array to a Blob
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        
          // Use FileSaver.js to save the Blob as a file
          saveAs(blob, filename);
          window.location.reload();
                    }
        
        
                   setShowLoading(false);
                }) .catch((error) => {
                    setShowLoading(false);
                    Alert("Something went wrong, please try again later.")
                   
                });
        

        }
        const downLoadForTemple = (stDate:any,enDate:any) => {
            var reqData = {
                "startDate" : stDate,
                "endDate" : enDate,
                "region" : regionChoose,
                "status":statusChoose,
                "orderDate": orderDate,
                "deliveryDate": deliveryDate,
               
                
            }
                Authservice.downLoadTempleReport(usertoken,reqData).then((response) => {
                    console.log(response);
                    
                    if(response.data == "No Data Found"){
                        Alert(response.data)
                    }else{
                          // Decode the base64 string
          const decodedData = atob(response.data);
          const filename = "temple_report"+new Date()+".xlsx";
        
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }
        
          // Convert the Uint8Array to a Blob
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        
          // Use FileSaver.js to save the Blob as a file
          saveAs(blob, filename);
          window.location.reload();
                    }
        
        
                   setShowLoading(false);
                }) .catch((error) => {
                    setShowLoading(false);
                    Alert("Something went wrong, please try again later.")
                   
                });
        

        }
        const downloadReport = () => {
            if(startDate  == "" && endDate == ""){
                Alert("Please select start and end date")
                return
            } 
            if((option == "" || option == undefined || option == null) && admin == "1"){
                Alert("Please choose report for")
                return
            }
            var stDate= formatDate(startDate);
            var enDate =  formatDate(endDate);console.log("reqData", regionChoose,statusChoose)
             

            if(option == 3){
                downLoadForGrammerce(stDate,enDate);

            } else if(option == 4){
                downLoadForDonation(stDate,enDate);

            }
            else if(option == 5){
                downLoadForProjectDonation(stDate,enDate);

            }
            else if(option == 2){
                if(!orderDate && !deliveryDate){
                    Alert("Please choose filter by")
                    return
                }
                downLoadForVendor(stDate,enDate);

            }
            else if(option == 1){
                if(!orderDate && !deliveryDate){
                    Alert("Please choose filter by")
                    return
                }
                downLoadForTemple(stDate,enDate)
            }
            
        }

        

    return (
        <IonPage>
            <IonContent>
            <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
       
        />
                <AdminHead User={"user"} Role={"Role"} Name={"Report"} />
                <Sidebar />
                <IonGrid className="page-wrapper report_container_middle">
                    <IonRow className="report_date_container">
                        <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11" className="">
                            <IonLabel>
                                Date
                            </IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
:
                        </IonCol>
                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="12">
                            <IonLabel>
                                Start Date<span style={{ color: "red" }}>*</span>
                            </IonLabel>
                            <IonInput type="date" value={startDate} onIonChange={(e: any) => {
                                setStartDate(e.detail.value)}} className="report_dates" />
                        </IonCol>
                        <IonCol size-lg="3" size-md="3" size-sm="3" size-xs="12">
                            <IonLabel>
                                End Date<span style={{ color: "red" }}>*</span>
                            </IonLabel>
                            <IonInput type="date" value={endDate} onIonChange={(e: any) => setEndDate(e.detail.value)} className="report_dates" />
                        </IonCol>
                    </IonRow>

                    {userRole === "ROLE_ADMIN" && <IonRow className="report_type_container">
                        <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11" className="">
                            <IonLabel>
                                Report For<span style={{ color: "red" }}>*</span>
                            </IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
:
                        </IonCol>
                        <IonCol size="3">
                            <div >
                            <Select
        options={orderStatusList}
        value={filterStatus}
      
        
        onChange={handleOrderChange}
        placeholder="Select Report for"
        
        
        
        menuPortalTarget={document.body}
        menuPosition={'fixed'} 
        className="custom-select"
    />

                            </div>
                       
                        </IonCol>
                         
                    </IonRow>}

                    {/* <>
                        {
                            admin === "1" ?
                                <IonRow className="report_type_container">
                                    <IonCol size-lg="2" className="">
                                        <IonLabel>
                                            Temple Name :
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput value={templeName} onIonChange={(e: any) => setTempleName(e.detail.value)} />
                                    </IonCol>
                                </IonRow> : admin === "2" && userRole != "ROLE_VENDOR" ?
                                    <IonRow className="report_type_container">
                                        <IonCol size-lg="2" className="">
                                            <IonLabel>
                                                Vendor Name :
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonInput value={vendorname} onIonChange={(e: any) => setVendorName(e.detail.value)} />
                                        </IonCol>
                                    </IonRow> : admin === "3" ?
                                        <IonRow className="report_type_container">
                                            <IonCol size-lg="2" className="">
                                                <IonLabel>
                                                    Project Name :
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonInput value={vendorname} onIonChange={(e: any) => setVendorName(e.detail.value)} />
                                            </IonCol>
                                        </IonRow>
                                        : <></>
                        }
                    </> */}
                    {(admin != "" &&  admin != "4" && option !=4) &&
                     <IonRow className="report_type_container">
                     <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11">
                         <IonLabel>
                             Region 
                         </IonLabel>
                     </IonCol>
                     <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
:
             </IonCol>
                     <IonCol size="3">
                     <Select
options={regionList}
value={region}

onChange={handleregionChange}
placeholder="Select Region"

menuPortalTarget={document.body}
menuPosition={'fixed'} 

className="custom-select"
/>
                     </IonCol>
                 </IonRow>
}
                    {(admin != "" &&  admin != "4" && option !=4 && option !=5 && option !=3) &&
                        <>
                            <IonRow className="report_type_container">
                                <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11" className="">
                                    <IonLabel>
                                        Filter By<span style={{ color: "red" }}>*</span>
                                    </IonLabel>
                                </IonCol>
                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
:
                        </IonCol>
                                <IonCol size-lg="3">
                                <Select
        options={dateType}
        value={dateTypeFilter}
        
        onChange={handleDateTypeChange}
        placeholder="Select Date Type"
        
        
        menuPortalTarget={document.body}
        menuPosition={'fixed'} 
       
        className="custom-select"
    />
                                </IonCol>
                            </IonRow></>
                    }
                    {(admin != "" &&  admin != "4" && option !=4 && option !=5) &&<>
                           
                            <IonRow className="report_type_container">
                                <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11">
                                    <IonLabel>
                                        Status 
                                    </IonLabel>
                                </IonCol>
                                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
: </IonCol>
<IonCol size="3">
<Select
        options={statusList}
        value={status}
        
        onChange={handlestatusChange}
        placeholder="Select Staus"
    
        
        menuPortalTarget={document.body}
        menuPosition={'fixed'} 
       
        className="custom-select"
    />
                                </IonCol>
                            </IonRow>
                        </>
                    }

                    {/* <IonRow className="report_type_container">
                        <IonCol size-lg="2" size-md="2" size-sm="2" size-xs="11" className="">
                            <IonLabel>
                                Report 
                            </IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
:
                        </IonCol>
                        <IonCol size-lg="3">
                        <Select
        options={reportType}
        value={reportFilter}
        
        onChange={handlereporttypeChange}
        placeholder="Select Report Type"
        isClearable
        menuPortalTarget={document.body}
        menuPosition={'fixed'} 
      
        className="custom-select"
    />
                        </IonCol>
                    </IonRow> */}
                    <IonRow className="report_download_btn">
                        <IonButton
                            className="admin_add_btn"
                            type="submit" onClick={downloadReport}
                        >
                            <span className="button_padding">
                                Download
                            </span>
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )

}
export default Report;