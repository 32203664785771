import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonInput,
    IonImg,
    IonLoading,
    IonSlide,
    IonSlides,
    useIonAlert,
    IonPopover,
    IonList,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonTextarea,
    IonAlert,
} from "@ionic/react";
import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import Foot from "../Foot";
import Head from "../Head";
import { Modal } from 'react-bootstrap';
import { addCircleSharp, addOutline, arrowBack, close, ellipsisVerticalCircleOutline, filter } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Capacitor } from "@capacitor/core";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Select, { components } from 'react-select';
import { stat } from "fs";
import { error } from "console";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import CommonDataTable from "../../components/datatable";

const OfferManagement: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const [showAlert, setShowAlert] = useState(false);
    const [alertmsg, setalertmsg]: any = useState("")

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            sortable: true,
            width: "100px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => viewOffer(item)}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Pooja Code",
            selector: (item: any) => item.poojaCode,
            sortable: true,
            // width: "400px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => viewOffer(item)}>{item.poojaCode}</div>
                </div>
            )
        },
        {
            name: "Pooja Name",
            selector: (item: any) => item.name,
            sortable: true,
            // width: "400px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => viewOffer(item)}>{item.name}</div>
                </div>
            )
        },
        {
            name: "Pooja Description",
            selector: (item: any) => item.description,
            sortable: true,
            // width: "400px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} onClick={() => viewOffer(item)}>{item.description}</div>
                </div>
            )
        },
    ]

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const location = useLocation()
    const [offerData, setOfferData]: any = useState([])
    const [bookDetailsModal, setBookDetailsModal] = useState(false)
    const [editPoojaItem, seteditPoojaItem]: any = useState({})
    const [formErrors, setFormErrors] = useState<any>({});


    var tableData: any = [];
    if (offerData != undefined && offerData.length > 0) {
        tableData = offerData.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }


    useEffect(() => {
        getPoojaOfferingDetailsForAdmin()
    }, [])


    const getPoojaOfferingDetailsForAdmin = () => {
        Authservice.getPoojaOfferingDetailsForAdmin(usertoken, userId, usertoken)
            .then((response: any) => {
                console.log(response);
                setShowLoading(false);
                const data = response.data.map((e: any, i: any) => {
                    return { ...e, serial: i + 1 }
                })
                setOfferData(data)
            })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }

    const onclick = (id: any) => {
        history.push({
            pathname: "/addeditTemple",
            state: { templeCode: id, isedit: true }
        })
    }

    const addoffer = () => {
        seteditPoojaItem({})
        setFormErrors({})
        setBookDetailsModal(true)
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={addoffer} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span className="button_padding">Add Pooja</span>
                    </IonButton>
                </div>
                <div>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);

    const viewOffer = (item: any) => {
        seteditPoojaItem(item)
        setFormErrors({})
        setBookDetailsModal(true)
    }
    const handlePoojaChange = (e: any, field: any) => {
        const updatedPoojaList = editPoojaItem;
        updatedPoojaList[field] = e.detail.value;
        seteditPoojaItem(updatedPoojaList); // Update pooja offering based on field
        console.log(updatedPoojaList)
    };

    const savepoojadetails = () => {
        const errors: any = {};
        console.log(errors, "errorserrors")
        if (!editPoojaItem.name) {
            errors.name = "Please enter the Name"
        }
        if (!editPoojaItem.description) {
            errors.des = "Please enter the description"
        }
        if (!editPoojaItem.poojaSignificance) {
            errors.poojaSignificance = "Please enter the Pooja Significance"
        }

        setFormErrors(errors)
        if (Object.keys(errors).length === 0) {
            const obj = {
                "name": editPoojaItem.name, "description": editPoojaItem.description, "poojaSignificance": editPoojaItem.poojaSignificance,
                "poojaCode": editPoojaItem.poojaCode || ""
            }
            Authservice.createOrUpdatePoojaOffering(usertoken, userId, usertoken, obj)
                .then((response: any) => {
                    console.log(response);
                    if (response.data == true) {
                        setBookDetailsModal(false)
                        setShowAlert(true)
                        if (editPoojaItem.poojaCode) {
                            setalertmsg("Pooja Updated Successfully")
                        } else {
                            setalertmsg("Pooja Created Successfully")
                        }
                    }
                })
                .catch((error: any) => {
                    console.error("Error fetching data:", error);
                    setShowLoading(false);
                });
        }
    }
    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Pooja master"} />
            <Sidebar />
            <IonAlert
                isOpen={showAlert}
                header="Success"
                message={alertmsg}
                backdropDismiss={false}
                buttons={["Ok"]}
                onDidDismiss={() => setBookDetailsModal(false)}
            />
            <IonContent style={{ width: "100%" }}>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}
                // duration={5000}
                />
                <div className="container_middle_table page-wrapper admin_background_color">
                    <CommonDataTable
                        columns={columns}
                        data={tableData}
                        loading={showLoading}
                        noDataMessage="Sorry, No data available"
                        onRowClick={viewOffer}
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                    />
                </div>
                < Modal show={bookDetailsModal} className="admin_modal" >
                    <Modal.Body className='modal_container' style={{ width: "100%" }}>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Temple Pooja</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setBookDetailsModal(false)} />
                            </div>
                        </div>
                        <IonRow >
                            <IonCol size="6">
                                <IonLabel className="temple_details_label">Pooja Name</IonLabel>
                                <IonTextarea
                                    className="textArea_v2 "
                                    value={editPoojaItem.name}
                                    onIonChange={(e) => handlePoojaChange(e, "name")}
                                    placeholder="Enter Pooja Name"
                                />
                                {formErrors.name && <span className="alert_input_bottom ">{formErrors.name}</span>}

                            </IonCol>
                            {/* <IonCol size="6">
                                <IonLabel className="temple_details_label">Pooja Description</IonLabel>
                                <IonTextarea
                                    className="textArea_v2 "
                                    value={editPoojaItem.description}
                                    onIonChange={(e) => handlePoojaChange(e, "dailyPerformCount")}
                                    placeholder="Enter daily perform count"
                                />
                                {formErrors.count && <span className="alert_input_bottom ">{formErrors.description}</span>}
                            </IonCol> */}
                            <IonCol size="6">
                                <IonLabel className="temple_details_label">Pooja Significance</IonLabel>
                                <IonTextarea
                                    className="textArea_v2 "
                                    value={editPoojaItem.poojaSignificance}
                                    onIonChange={(e) => handlePoojaChange(e, "poojaSignificance")}
                                    placeholder="Enter Pooja Significance"
                                />
                                {formErrors.poojaSignificance && <span className="alert_input_bottom ">{formErrors.poojaSignificance}</span>}
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel className="temple_details_label">Pooja Description</IonLabel>
                                <IonTextarea
                                    className="textArea_v2 "
                                    value={editPoojaItem.description}
                                    onIonChange={(e) => handlePoojaChange(e, "description")}
                                    placeholder="Enter pooja description"
                                />
                                {formErrors.des && <span className="alert_input_bottom ">{formErrors.des}</span>}
                            </IonCol>
                            <IonCol size="12">
                                <div style={{
                                    display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", marginTop: "20px"
                                }}>
                                    < IonButton className="admin_add_btn" color="primary" onClick={() => setBookDetailsModal(false)}>
                                        <span className="button_padding_v2">
                                            Cancel
                                        </span>
                                    </IonButton>
                                    < IonButton className="admin_add_btn" onClick={() => savepoojadetails()} color="primary">
                                        <span className="button_padding_v2">
                                            Save
                                        </span>
                                    </IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                    </Modal.Body>
                </Modal >
            </IonContent>
        </IonPage >
    )

}

export default OfferManagement;