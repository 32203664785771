import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonInput,
    IonImg,
    IonLoading,
    IonSlide,
    IonSlides,
    useIonAlert,
    IonPopover,
    IonList,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
} from "@ionic/react";
import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import Foot from "../Foot";
import Head from "../Head";
import { Modal } from 'react-bootstrap';
import { addCircleSharp, addOutline, arrowBack, ellipsisVerticalCircleOutline, filter } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Capacitor } from "@capacitor/core";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Select, { components } from 'react-select';
import { stat } from "fs";
import { error } from "console";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import CommonDataTable from "../../components/datatable";
import TempleComponent from "./templedetailscomponent";
import HeadFoot from "../Head";

const AdmtempleList: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("");
    const [tempAdm, setTempAdm]: any = useState();
    const [name, setName]: any = useState()
    const [tempAdmList, setTempAdmList]: any = useState([]);
    const dataFetchedRef = useRef(false);
    const [headName, setHeadName] = useState("")

    const [isSearch, setIsSearch] = useState(false)

    const [districtOption, setDistrictOption]: any = useState({ value: "ALL", label: "ALL" });
    const [districtListOptions, setDistrictlistoptions] = useState([])
    const [panchayatListOptions, setPanchayatListoptions] = useState([])
    const [panchayatoption, setPanchayatOption]: any = useState({ value: "ALL", label: "ALL" })

    const columns: any = [
        {
            name: "Temple Code",
            selector: (item: any, i: any) => item.templeCode,
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => onclick(item.templeCode)}>
                    <div style={{ cursor: "pointer" }}>{item.templeCode}</div>
                </div>
            )
        },
        {
            name: "Temple Name",
            selector: (item: any) => item.templeName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => onclick(item.templeCode)}>
                    <div style={{ cursor: "pointer" }} >{item.templeName}</div>
                </div>
            )
        },
        {
            name: "Location",
            selector: (item: any) => item.templeLocation,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => onclick(item.templeCode)}>
                    <div style={{ cursor: "pointer" }} className="threelineCls">{item.templeLocation}</div>
                </div>
            )
        },
        {
            name: "Main Deity",
            selector: (item: any) => item.mainDeity,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => onclick(item.templeCode)}>
                    <div className="textDes threelineCls" style={{ cursor: "pointer" }} >{item.mainDeity || "N/A"}</div>
                </div>
            )
        },
        // {
        //     name: "Status",
        //     sortable: false,
        //     selector: (item: any) => item.isActive,
        //     cell: (item: any) => (
        //         <div className="center_col_tab">
        //             {item.isActive === "true" ? (
        //                 <IonButton color="danger" style={{ textTransform: "none" }}
        //                     className="datatable-activate-btn"
        //                     onClick={() =>
        //                         presentAlert({
        //                             header: 'Atmagram',
        //                             message: 'Do you want to deactivate this temple',
        //                             buttons: [
        //                                 {
        //                                     text: 'No',
        //                                     role: 'cancel',
        //                                     handler: () => {
        //                                         // setHandlerMessage('Alert canceled');
        //                                     },
        //                                 },
        //                                 {
        //                                     text: 'Yes',
        //                                     role: 'confirm',
        //                                     handler: (alertData) => {
        //                                         handleStatus(false, item.id, alertData.comment)
        //                                     },
        //                                 },
        //                             ],
        //                             inputs: [
        //                                 {
        //                                     name: 'comment',
        //                                     type: 'text',
        //                                     value: comment,
        //                                     placeholder: 'Enter your comment'
        //                                 }
        //                             ]
        //                         })

        //                     }>
        //                     <span className="button_padding">
        //                         Deactivate
        //                     </span>
        //                 </IonButton>
        //             ) : (
        //                 <IonButton color="success" className="datatable-activate-btn"
        //                     style={{ textTransform: "none" }} onClick={() => presentAlert({
        //                         header: 'Atmagram',
        //                         message: 'Do you want to activate this temple',
        //                         buttons: [
        //                             {
        //                                 text: 'No',
        //                                 role: 'cancel',
        //                                 handler: () => {
        //                                     // setHandlerMessage('Alert canceled');
        //                                 },
        //                             },
        //                             {
        //                                 text: 'Yes',
        //                                 role: 'confirm',
        //                                 handler: (alertData) => {
        //                                     handleStatus(true, item.id, alertData.comment)
        //                                 },
        //                             },
        //                         ],
        //                         inputs: [
        //                             {
        //                                 name: 'comment',
        //                                 type: 'text',
        //                                 value: comment,
        //                                 placeholder: 'Enter your comment'
        //                             }
        //                         ]
        //                     })}>
        //                     <span className="button_padding">
        //                         Activate
        //                     </span>
        //                 </IonButton>
        //             )}
        //         </div>
        //     )
        // },
        // {
        //     name: "View",
        //     sortable: false,
        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={
        //                 // () => show(item.id)
        //                 () => onclick(item.id)
        //             } style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // },
    ]

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const history = useHistory();
    const userdata: any = useState(sessionStorage.getItem("UserData"));
    const uFlag: any = useState(sessionStorage.getItem("uFlag"));
    const [templelist, setTemplelist]: any = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [index1, setIndex1] = useState(0);
    const [isedit, setIsedit] = useState(false);

    const [id, Setid] = useState("");


    const handleStatus = (flag: any, id: any, comm: any) => {
        Authservice.statusAdmtemp(usertoken, flag, id, comm).then((response) => {
            console.log(response)
            // window.location.reload()
            ShowAllTemple(tempAdm)
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
            });

    }

    const tempId: any = useRef("")
    const idBack: any = useRef("")
    const adminBack: any = useRef("")
    const nameBack: any = useRef("")
    const propState: any = useRef("")
    const propCity: any = useRef("")
    const propDistrict: any = useRef("")
    const tempName: any = useRef("")
    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);
    const [tempprops, setTempprops]: any = useState({})
    const location = useLocation()
    const [backProps]: any = useState(location.state)
    const [additionalOption, setAdditionalOption] = useState({ value: "ALL", label: "ALL" })
    const [additionalOptions, setadditionalOptions] = useState([])

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (userdata[0] === null) {
            history.push("/login")
        } else if (userdata[0] !== "ROLE_ADMIN" && userdata[0] !== "ROLE_VOLUNTEER") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            setHeadName("Temple Management")
            console.log(backProps, "getttt")
            // if (backProps !== undefined) {
            //     tempId.current = backProps.tempId;
            //     adminBack.current = backProps.admin;
            //     idBack.current = backProps.id
            //     nameBack.current = backProps.templeName;
            //     propState.current = backProps.state;
            //     propDistrict.current = backProps.district;
            //     if (!backProps.city) {
            //         propCity.current = "";
            //     }
            //     else {
            //         propCity.current = backProps.city
            //     }
            //     if (!backProps.templeName) {
            //         tempName.current = "";
            //     }
            //     else {
            //         tempName.current = backProps.templeName;
            //     }
            //     setTempprops(backProps)
            // }
            const value = `[${districtOption.label},${panchayatoption.label},ALL] `
            Getdatausingsearch(value)
            getAllDistricts()
            getAllTempleSearchOf()
            console.log(backProps, "getprops")
            // showTempleAdmList();
            // console.log(props, "propsss"
        }
    }, [location])

    const onclick = (id: any) => {
        setIsedit(true)
        Setid(id)
        setIndex(1)

    }

    const addtemple = () => {
        setIsedit(false)
        Setid(id)
        setIndex(1)
    }


    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                // console.log(response)
                // if (response.status === 200) {
                setStates(response.data)
                setStateOption({ value: "KERALA", label: "KERALA" });
                // setShowLoading(false)
                // }
                getDistricts()
                if (backProps === undefined) {
                    setTempAdm("")
                    // setSelectedOption({ value: "", label: "" });
                    setIsSearch(false)
                    ShowAllTemple("");
                }
                else {
                    setTempAdm(backProps.tempId)
                    console.log(tempId)
                    setSelectedOption({ value: backProps.tempId, label: backProps.templeName });
                    ShowAllTemple(backProps.tempId);
                    setIsSearch(true)
                    // var admin = {
                    //     value: props.tempId,
                    //     label: props.templeName
                    // }
                    // handleChange(admin)
                }
                if (backProps.state !== "" && backProps.district !== "") {
                    setStateOption({ value: backProps.state, label: backProps.state });
                    setState(backProps.propState)
                    setCity(backProps.city)
                    setName(backProps.templeName)
                    setDistrict(backProps.district)
                    setDistrictOption({ value: backProps.district, label: backProps.district });
                    setTempleName(backProps.templeName)
                    var data = {
                        state: backProps.state,
                        city: backProps.city,
                        district: backProps.district,
                        temname: backProps.templeName
                    }
                    handleSearch(data)
                }
            })
            .catch((error) => {
                console.log(error)
                // setShowLoading(false)
            })
            .finally(() => {
                setShowLoading(false);
            });
    }
    const Getdatausingsearch = (value: any) => {
        Authservice.getAllTemplesForAdmin(usertoken, value, userId, "user")
            .then((response: any) => {
                console.log(response);
                setShowLoading(false);
                const data = response.data.map((e: any, i: any) => {
                    return { ...e, serial: i + 1 }
                })
                setTemplelist(data);
            })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }


    const ShowAllTemple = (id: any) => {
        console.log(id, "ewdsd")
        var idCheck;
        if (id != "") {
            idCheck = id
            setIsSearch(true)
        }
        else {
            idCheck = tempAdm
            setIsSearch(false)
        }
        setTemplelist([])

        setShowLoading(true);
        Authservice.Viewtemple(usertoken, idCheck)
            .then((response) => {
                console.log(response.data);


                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].facilitiesDto !== null) {
                        // templelist.push(response.data[i]);
                        setTemplelist((templelist: any) => [...templelist, response.data[i]])
                    }
                }
                setShowLoading(false);

            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }


    const [index, setIndex]: any = useState(0);
    const [lat, setLat]: any = useState();
    const [long, setLong]: any = useState();
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const getAllDistricts = () => {
        Authservice.getAdmAllDistricts(userId, usertoken, usertoken).then((response) => {
            console.log(response);
            setShowLoading(true)
            const districtLiistOptions: any = [
                { value: "ALL", label: "ALL" },
                ...response.data.map((e: any) => ({
                    value: e.districtCode,
                    label: e.districtName
                }))]
            setDistrictlistoptions(districtLiistOptions)
            setShowLoading(false)
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }

    const getAllTempleSearchOf = () => {
        Authservice.getAllTempleSearchOf(usertoken, userId).then((response) => {
            console.log(response);
            setShowLoading(true)
            const options: any = [
                { value: "ALL", label: "ALL" },
                ...response.data.map((e: any) => ({
                    value: e.id,
                    label: e.name
                }))]
            setadditionalOptions(options)
            setShowLoading(false)
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }


    const getAllPanchayats = (e: any) => {
        Authservice.getAllPanchayats(userId, usertoken, usertoken, e).then((response) => {
            console.log(response.data);
            const districtLiistOptions: any = [
                { value: "ALL", label: "ALL" },
                ...response.data.map((e: any) => ({
                    value: e.panchayatCode,
                    label: e.panchayatName
                }))]
            setPanchayatListoptions(districtLiistOptions);
            setShowLoading(false);
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }



    const handleDistrictChange = (e: any) => {
        console.log(e)
        setDistrictOption(e);
        setDistrict(e.value);
        if (e) {
            getAllPanchayats(e.value)
            Getdatausingsearch(`[${e.label},ALL,${additionalOption.label}] ${filterText}`)
        } else {
            setPanchayatOption({ value: "ALL", label: "ALL" })
        }
        setPanchayatListoptions([])
    }

    const hanldePanchayatChange = (e: any) => {
        console.log(e)
        if (e) {
            setPanchayatOption(e);
            Getdatausingsearch(`[${districtOption.label},${e.label},${additionalOption.label}] ${filterText}`)
        } else {
            setPanchayatOption({ value: "ALL", label: "ALL" })
        }
    }

    const hanldeAdditionalChange = (e: any) => {
        console.log(e)
        if (e) {
            setAdditionalOption(e);
            Getdatausingsearch(`[${districtOption.label},${panchayatoption.label},${e.label}] ${filterText}`)
        } else {
            setAdditionalOption({ value: "ALL", label: "ALL" })
        }
    }



    if (templelist != undefined && templelist.length > 0) {
        templelist.forEach((item: any, index: any) => { item.serial = index + 1; });

    }
    var tableData: any = [];
    if (templelist != undefined && templelist.length > 0) {
        tableData = templelist.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }



    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    let debounceTimeout: any;


    const handleSearchtemple = (e: any) => {
        clearTimeout(debounceTimeout);
        const value = e;
        setFilterText(value);
        if (value.length > 3 || value.length == 0) {
            const searchValue = `[${districtOption.label},${panchayatoption.label},${additionalOption.label}] ${value}`;
            console.log(searchValue)
            debounceTimeout = setTimeout(() => {
                Getdatausingsearch(searchValue);
            }, 1000);
        }
    };



    const subHeaderComponent = useMemo(() => {



        return (
            <IonRow className="table_btn_container">
                {/* Add Temple Button */}
                <IonButton onClick={addtemple} className="admin_add_btn">
                    <IonIcon src={addOutline} />
                    <span className="button_padding">Add Temple</span>
                </IonButton>

                {/* Select Boxes and Search */}
                <div className="radio-search-container" style={{ alignItems: "end" }}>
                    {/* District Select with Label */}
                    <div className="select-group">
                        <label className="select-label">District</label>
                        <Select
                            options={districtListOptions}
                            value={districtOption}
                            onChange={handleDistrictChange}
                            placeholder="Select District"
                            className="search-box"
                        />
                    </div>

                    {/* Panchayat Select with Label */}
                    <div className="select-group">
                        <label className="select-label">Panchayat</label>
                        <Select
                            options={panchayatListOptions}
                            value={panchayatoption}
                            onChange={hanldePanchayatChange}
                            placeholder="Select Panchayat"
                            className="search-box"
                            isDisabled={!districtOption || districtOption.value === "ALL"}
                        />
                    </div>

                    <div className="select-group">
                        <label className="select-label">Search on</label>
                        <Select
                            options={additionalOptions}
                            value={additionalOption}
                            placeholder="Select Option"
                            className="search-box"
                            onChange={hanldeAdditionalChange}

                        />
                    </div>

                    {/* Search Input */}
                    <IonInput
                        placeholder="Search..."
                        value={filterText}
                        clearInput
                        onIonChange={(e: any) => handleSearchtemple(e.target.value)}
                        className="search-textbox"
                        style={{ width: "250px" }}
                    />
                </div>
            </IonRow>

        );
    }, [filterText, resetPaginationToggle, districtOption, panchayatoption, panchayatListOptions, districtListOptions, additionalOption]);

    const openMap = () => {
        window.open('https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',')
    }



    const handleChange = (selectedOption: any) => {
        console.log(tempId, id, nameBack, propState, propDistrict)
        setSelectedOption(selectedOption);
        setTempAdm(selectedOption.value);
        setName(selectedOption.label);
        tempId.current = selectedOption.value;
        nameBack.current = selectedOption.label;
        ShowAllTemple(selectedOption.value);
        console.log(tempId.current, id, nameBack, propState, propDistrict)


        console.log(selectedOption, "kmkkk")
    };

    const tempAdmListOptions = tempAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);
    const [stateMenu, setStateMenu] = useState(false)
    const [districtMenu, setDistrictMenu] = useState(false)

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [templeName, setTempleName] = useState("");
    const [formErrors, setFormErrors]: any = useState({});
    const [nameView, setNameView] = useState(false);

    const [districtList, setDistrictList] = useState([]);
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }



    const handleStateChange = (e: any) => {
        setStateOption(e);
        if (e == null) {
            setState("");
        } else {
            setState(e.value);
            setTempprops({
                ...tempprops, state: e.value
            })
            propState.current = e.value;
            setFormErrors({ ...formErrors, state: "" })

        }
        // getDistricts(e.value);
    }

    const getDistricts = () => {
        setDistrictOption(null)
        setDistrictList([])
        setShowLoading(true);
        Authservice.getAdmAllDistricts(userId, "", usertoken)
            .then((response) => {
                console.log(response)
                setDistrictList(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }
    var districtLiistOptions: any = [];
    if (districtList != undefined && districtList.length > 0) {
        districtLiistOptions = districtList.map((e: any, index: any) => ({
            value: e.districtCode,
            label: e.districtName
        }));
    }




    const handleSearch = (item: any) => {
        var searchstate;
        var searchdistrict;
        var searchcity;
        var searchname;
        console.log(item, "searchitem")
        if (item === "") {
            searchstate = state
            searchdistrict = district
            searchcity = city
            searchname = templeName
            setTempAdmList([])
            setSelectedOption(null)
        }
        else {
            searchstate = item.state
            searchdistrict = item.district
            searchcity = item.city
            searchname = item.temname
        }
        const errors: any = {};
        if (searchstate.length === 0) {
            errors.state = 'Please Select a State';
        }

        if (searchdistrict.length === 0) {
            errors.district = 'Please Select a District';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            console.log(usertoken, searchname, searchcity, searchstate, searchdistrict)
            setShowLoading(true)
            Authservice.tempadminsort(usertoken, searchname, searchcity, searchstate, searchdistrict)
                .then((response) => {
                    if (backProps === undefined) {
                        setSelectedOption(null)
                    }
                    setTemplelist([])
                    console.log(response)
                    if (response.data.length != 0) {
                        setTempAdmList(response.data);
                        setNameView(true)
                    }
                    else {
                        // setStateOption(null)
                        // setState("")
                        // setDistrictOption(null)
                        // setDistrict("")
                        // setCity("")
                        // setTempleName("")
                        // setDistrictList([])
                        presentAlert("No Results Found !")
                        setTempAdmList([]);
                    }
                    setShowLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false)
                })
        }
    }

    const validation = (e: any) => {
        if (e == 1) {
            if (state == "") {
                setFormErrors({ ...formErrors, state: "Please select a state" })
            }
        }
        if (e == 2) {
            if (district == "") {
                setFormErrors({ ...formErrors, district: "Please select a district" })
            }
        }
    }

    const goback = () => {
        setIndex(0)
    }

    return (
        <IonPage>
            {userdata[0] === "ROLE_ADMIN" && <><AdminHead User={"user"} Role={"Role"} Name={"Temple Management"} />
                <Sidebar /></>}
            <IonContent style={{ width: "100%" }}>
                {userdata[0] !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Volunteer' />}
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}
                // duration={5000}
                />
                <div className={userdata[0] === "ROLE_ADMIN" ? "container_middle_table page-wrapper admin_background_color" : "page_content_padding tableContainev2"}>
                    {index === 0 ?
                        <CommonDataTable
                            columns={columns}
                            data={templelist}
                            loading={showLoading}
                            noDataMessage="Sorry, No data available"
                            onRowClick={(item: any) => onclick(item.id)}
                            isSubHeader={true}
                            subHeaderComponent={subHeaderComponent}
                        />
                        :
                        <TempleComponent id={id} isUserSide={false} isedit={isedit} goBack={goback} />
                    }
                </div>

            </IonContent>
        </IonPage >
    )

}

export default AdmtempleList;