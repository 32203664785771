import React from "react";
import axios from "axios";
import { Alert } from "react-bootstrap";

// const API_URL = "https://groupnpay.com/vedagram/";
//const API_URL = "https://www.atmagram.com/";
//const API_URL = "http://uat.atmagram.com/";
//const API_URL = "http://4.224.33.212:8080/vedagram/";
// const API_URL = "http://172.20.10.2:8090/"
// const API_URL = "http://192.168.2.155:8090/"
// const API_URL = "http://192.168.169.126:8090/"
// const API_URL = "http://192.168.3.155:8090/"
// const API_URL = "https://www.atmagram.com/testing"
const API_URL = "https://www.atmagram.com"


// const[alert]=useIonAlert();
//const API_URL = "https://demo.infocareerindia.com/vedagram/";

const Login = (email: any, password: any, logInfo: any) => {

  // const loginData = {
  //   email: email,
  //   password: password,
  // };
  const loginData = new FormData()
  loginData.append("email", email)
  loginData.append("password", password)
  loginData.append("logInfo", logInfo)
  loginData.append("sessionId", "")
  loginData.append("userId", "")


  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    // .post("user/loginValidate", loginData)
    .post("user/loginValidate", loginData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {

      alert("Something went wrong, please try again later.");
      return error;

    });
};
const logout = (token: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .get("user/logout")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      alert("Something went wrong, please try again later.");
      return error;

    });
};

const Register = (
  aadhar: any,
  address: any,
  pincode: any,
  // area: any,
  // city: any,
  // state: any,
  // country: any,
  email: any,
  firstname: any,
  // lastname: any,
  phone: any,
  password: any,
  users: any,
  product: any,
  TName: any,
  TAdd: any,
  vendor: any,
  user: any,
  district: any,
  panchayat: any,
  otherOrganisations: any,
) => {
  console.log("userrrrr", user)
  var loginData = {}
  if (user == "ROLE_VENDOR") {
    loginData = {
      email: email,
      firstName: firstname,
      mobileNumber: phone,
      password: password,
      msRoleId: users,
      productType: product,
      vendorName: vendor
    }
  }
  else if (user === "ROLE_TEMPADMIN") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address + ',' + pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      // roleId: users,
      msRoleId: users,
      templeName: TName,
      templeAddress: TAdd
    }
  }
  else {
    loginData = {
      email: email,
      firstName: firstname,
      mobileNumber: phone,
      password: password,
      msRoleId: users,
      district: district,
      panchayat: panchayat,
      country: "IN",
      state: "KL",
      otherOrganisations: otherOrganisations,
      aadharNumber: aadhar,
      address: address + ',' + pincode,
    }
  }
  const formdata: any = new FormData()
  formdata.append("registerModel", JSON.stringify(loginData))
  formdata.append("userId", "")
  formdata.append("sessionId", "")
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/register", formdata)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const Getallgods = (islogin: any, token: any) => {
  var api: any = "";
  if (islogin) {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });
  }

  return api
    .get("u/showAllDeity")
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const Forgotpassword = (email: any) => {
  const logindata = {
    email: email,
  };

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/forgotpassword", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const ChangePasswordByTkn = (password: any, email: any, token: any, confirmpassword: any) => {
  const logindata = {
    password: password,
    email: email,
    confirmPassword: confirmpassword,
    resetPwdToken: token
  };

  const api = axios.create({
    baseURL: API_URL,

  });
  return api
    .post("/user/changePasswordByTkn", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const IsvaildToken = (token: any) => {


  const api = axios.create({
    baseURL: API_URL,
  });

  return api
    .get("user/" + token + "/pwdtkn")
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const SearchTemple = (usertoken: any, country: any, state: any, district: any, town: any, deityId: any, tempName: any) => {
  var body: any;
  if (tempName != "" && tempName != null && tempName != undefined) {
    body = {

      deityId: deityId,
      templeName: tempName
    };
  } else {
    body = {
      country: country,
      state: state,
      district: district,
      villageOrTown: town,
      deityId: deityId
    };
  }



  console.log(usertoken)

  if (usertoken === null) {
    const api = axios.create({
      baseURL: API_URL,
    });
    return api
      .post("/u/searchTemple", body)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  } else {
    const api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
    return api
      .post("/u/searchTemple", body)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  }


}
const Templedetails = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/tempadm/viewTemple/?templeId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};
const ShowAllOrder = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Registeradm = (email: any, firstname: any, phone: any, password: any, users: any, TName: any, TAdd: any, user: any, country: any, state: any, district: any, panchayat: any, address: any, pincode: any, panchayatLimit: any, templelimit: any,
  districtlimit: any, bankAccountNumber: any, bankBranch: any, bankIFSC: any,
  bankName: any, bankUserName: any, upiCode: any, userId: any, token: any, editUserId: any, isedit: any, lastname: any, id: any) => {
  console.log("userrrrr", user, phone.replace(/\s+/g, ''))
  var loginData: any = {}
  const panchayatlim = panchayatLimit.length > 0 ? panchayatLimit.map((item: any) => item.value).join(',') : "";
  const districtLimit = districtlimit.length > 0 ? districtlimit.map((item: any) => item.value).join(',') : "";
  const templeLimit = templelimit.length > 0 ? templelimit.map((item: any) => item.value).join(',') : "";
  loginData = {
    address: address + ',' + pincode,
    state: state,
    country: country,
    email: email,
    firstName: firstname,
    lastName: lastname,
    mobileNumber: phone.replace(/\s+/g, '') || phone,
    password: password,
    msRoleId: users,
    district: district,
    panchayat: panchayat,
    panchayatLimit: panchayatlim,
    templeLimit: templeLimit,
    districtLimit: districtLimit,
    bankName: bankName,
    bankUserName: bankUserName,
    bankAccountNumber: bankAccountNumber,
    bankBranch: bankBranch,
    bankIFSC: bankIFSC,
    upiCode: upiCode,
  }
  if (isedit) {
    loginData.userId = editUserId
    loginData.id = id
  }

  const formdata: any = new FormData()
  formdata.append("registerModel", JSON.stringify(loginData))
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .post(isedit ? "user/update" : "user/register", formdata)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const Registermaterials = (
  // aadhar: any,
  // address: any,
  // pincode:any,
  // area: any,
  // city: any,
  // state: any,
  // country: any,
  email: any,
  firstname: any,
  // lastname: any,
  phone: any,
  password: any,
  users: any,
  user: any
) => {
  console.log("userrrrr", user)
  var loginData = {}
  if (user == "2") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address+','+pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      msRoleId: users,
    }
  }

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/register", loginData)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
const validMob = (mobile: any) => {
  mobile = encodeURIComponent(mobile)


  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/verifyMobileNumber?mobileNumber=" + mobile)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const mobileLogin = (mobile: any) => {
  mobile = encodeURIComponent(mobile)


  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/loginViaMoblieNumber?mobileNumber=" + mobile)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const getFundReport = (token: any, userId: any, date: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("date", date)

  return api
    .post("u/getFundToBeDisbursed", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const getFundForRefund = (token: any, userId: any, date: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("tillDate", date)

  return api
    .post("u/getFundsForRefund", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}


const procesFundDisb = (token: any, userId: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("fundDetails", JSON.stringify(data))

  return api
    .post("/pay/procesFundDisb", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const getFundDisbursed = (token: any, userId: any, date: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("date", date)

  return api
    .post("u/getFundDisbursed", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}

const getCommissionDisbursement = (token: any, userId: any, fdate: any, tdate: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("fromDate", fdate)
  formdata.append("tillDate", tdate)


  return api
    .post("u/getCommissionDisbursement", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}

const getAppConfigByName = (token: any, userId: any, name: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const formdata: any = new FormData()
  formdata.append("userId", userId)
  formdata.append("sessionId", token)
  formdata.append("name", name)


  return api
    .post("user/getAppConfigByName", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}

const UserService = {
  Login,
  Register,
  Getallgods,
  Forgotpassword,
  ChangePasswordByTkn,
  IsvaildToken,
  SearchTemple,
  Templedetails,
  ShowAllOrder,
  Registeradm,
  Registermaterials,
  validMob,
  mobileLogin,
  logout,
  getFundReport,
  procesFundDisb,
  getFundForRefund,
  getFundDisbursed,
  getCommissionDisbursement,
  getAppConfigByName
};
export default UserService;