import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Plugins } from '@capacitor/core';

const SessionTimeout: React.FC = () => {
  const sessionTimeoutDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [isSessionExpired, setSessionExpired] = useState(false);
  const { Storage } = Plugins;

  let sessionTimer: NodeJS.Timeout;

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(sessionTimer);
      sessionTimer = setTimeout(handleSessionTimeout, sessionTimeoutDuration);
      sessionStorage.setItem('lastActivityTime', Date.now().toString());
    };
    const checkLastActivityTime = () => {
      const lastActivityTime = sessionStorage.getItem('lastActivityTime');
      if (lastActivityTime) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - parseInt(lastActivityTime, 10);
        if (elapsedTime >= sessionTimeoutDuration) {
          handleSessionTimeout();
        } else {
          resetTimer();
        }
      }
    };

    
    const handleUserActivity = () => {
      resetTimer();
      // Additional logic to handle user activity, e.g., making API calls, updating session status, etc.
    };

    const handleSessionTimeout = () => {
      setSessionExpired(true);
      // Additional logic to handle session expiration, e.g., clearing user data, redirecting to login page, etc.
    };
    const handleWindowClose = () => {
      sessionStorage.clear()

    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(sessionTimer);
        // Additional logic to handle tab switch or application window minimize, e.g., triggering logout action
        // You can call your logout function here
        // Example: logout();
        sessionStorage.clear()

      } else {
        resetTimer();
      }
    };
    // Set up event listeners to detect user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    checkLastActivityTime()
    resetTimer(); // Start the session timer

    return () => {
      clearTimeout(sessionTimer);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);

    };
  }, []); // Empty dependency array to ensure the effect runs only once

  if (isSessionExpired) {
    // Redirect to the login page or any other relevant action
    //return <Redirect to="/login" />;
    try {
       Storage.clear();
      console.log('Cache cleared successfully.');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }

    sessionStorage.clear()
  

   window.location.hash = "/login";
   window.location.reload();
  
    
  }

  // Render your app content here
  return <></>;
}

export default SessionTimeout;
