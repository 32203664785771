import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonInput,
    IonImg,
    IonLoading,
    IonSlide,
    IonSlides,
    useIonAlert,
    IonPopover,
    IonList,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
} from "@ionic/react";
import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import Foot from "../Foot";
import Head from "../Head";
import { Modal } from 'react-bootstrap';
import { addCircle, addCircleSharp, addOutline, arrowBack, chevronDownOutline, closeCircle, ellipsisVerticalCircleOutline, filter, remove, removeCircle, shareSocialSharp } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Capacitor } from "@capacitor/core";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Select, { components } from 'react-select';
import { stat } from "fs";
import { error } from "console";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import CommonDataTable from "../../components/datatable";
import TempleComponent from "./templedetailscomponent";

const TempleAddEdit: React.FC = () => {

    const [showLoading, setShowLoading] = useState(false);
    const location: any = useLocation();
    const templeCode = location.state.templeCode;
    const isedit = location.state.isedit;

    useEffect(() => {
        console.log(templeCode, "templeCode")
    }, [])

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Temple Management"} />
            <Sidebar />
            <IonContent style={{ width: "100%" }}>
                <IonLoading message="Loading..." spinner={null} isOpen={showLoading} />
                <div className="container_middle_table page-wrapper admin_background_color" style={{ textAlign: "left" }}>
                    <TempleComponent id={templeCode} isUserSide={false} isedit={isedit} />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default TempleAddEdit;
