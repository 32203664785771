import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import { IonAlert } from '@ionic/react';
import UserService from '../Services/auth.service';
import Authservice from '../Services/user.service';

interface AppContextProps {
  token: any;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  uFlag: any;
  setUFlag: React.Dispatch<React.SetStateAction<any>>;
  userdata: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  shareurl: any;
  amountLimit: any;
  exportCSV: any;
  userid: any;
}

interface AppProviderProps {
  children: ReactNode;
}
const { Network } = Plugins;

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [uFlag, setUFlag] = useState(sessionStorage.getItem("uFlag"));
  const [userdata, setUserData] = useState(sessionStorage.getItem("UserData"));
  const [userid, setUserId] = useState(sessionStorage.getItem("UserId"));
  const [showAlert, setShowAlert] = useState(false);
  var url = Authservice.getApiUrl();
  const shareurl = url + "#/projId"
  const amountLimit = 500000;

  // Function to handle closing the alert
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    // const checkNetworkStatus = async () => {
    //   const status = await Network.getStatus();
    //   console.log('Initial network status:', status.connected ? 'online' : 'offline');
    //   if (status.connected) {
    //     setShowAlert(false)
    //     console.log('Network is connected');
    //   } else {
    //     //alert('Network is disconnected');
    //     if(!showAlert){
    //       setShowAlert(true)
    //     }

    //   }
    // };

    // checkNetworkStatus();


    // const networkStatusChangeListener = Network.addListener(
    //   'networkStatusChange',
    //   (status: any) => {
    //     if (status.connected) {
    //       setShowAlert(false)
    //       console.log('Network is connected');  
    //     } else {
    //       //alert('Network is disconnected');
    //       if(!showAlert){
    //         setShowAlert(true)
    //       }

    //     }

    //     console.log('Connection type:', status.connectionType);
    //   }
    // );

    // return () => {
    //   networkStatusChangeListener.remove();
    // };


  }, []);

  const formatCSVValue = (value: any) => {
    if (typeof value === 'string') {
      // Escape internal quotes and wrap the value in double quotes
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value; // Return non-string values as is (e.g., numbers)
  };

  const exportCSV = (columns: any, data: any, filename: any) => {
    if (!data || data.length === 0) {
      console.warn('No data available to export.');
      return;
    }

    // Create CSV headers
    const headers = columns.map((col: any) => formatCSVValue(col.name)).join(',') + '\n';

    // Create CSV rows
    const csvRows = data.map((row: any) =>
      columns.map((col: any) => {
        // Ensure col.selector is a function before calling it
        const value = typeof col.selector === 'function' ? col.selector(row) : row[col.name];
        return formatCSVValue(value);
      }).join(',')
    ).join('\n');

    // Combine headers and rows
    const csvContent = headers + csvRows;

    // Create and download CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <AppContext.Provider value={{ token, setToken, uFlag, setUFlag, userdata, setUserData, shareurl, amountLimit, exportCSV, userid }}>
      <div>
        <IonAlert
          isOpen={showAlert}
          header="Alert"
          message="Network is disconnected, Please connect with internet otherwise you can't proceed further."
          backdropDismiss={false}
        />
      </div>
      {children}
    </AppContext.Provider>
  );
};
