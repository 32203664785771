import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";

import Foot from "./Foot";


import "./Home.css";
import "./PrivacyPolicy.css";

import HeadFoot from "./Head";

const TermsConditions: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Terms and Conditions"} />
        <IonRow className="page_content_padding">
          <IonCol>
            <div style={{ margin: "2% 4%" }}>
              <div>
                <p style={{ textAlign: "justify" }}>
                  This notice applies to the users who use Atmagram’s online payment and shipping services. If you choose our services, your use and any dispute with privacy is subject to this notice and our Terms and Conditions, including limitations of damage, resolution of disputes, and application of the prevailing law of India. In case of any concern regarding your privacy, kindly Contact Us with a detailed description, and we will try to resolve it. Please check our website frequently to check any changes in the Privacy Notice to see the recent changes.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without consent of the affected customers.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You provide information to us when you:
                </p>

                <ul>
                  <li style={{ textAlign: "justify" }}>
                    search or shop for products or services in our marketplace
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    add or remove an item from your cart, or place an order
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    provide information in Your Account or Your Profile
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    configure your settings , provide data access permissions
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    provide information in your Vendor or Temple Admin or Convener Account
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    communicate with us via phone, email, or otherwise
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    complete a questionnaire, contact form or any entry form
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    upload images, videos, audios or files
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    compile Your Cart or Your WishList
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    participate in Projects or
                  </li>

                </ul>
                <p style={{ textAlign: "justify" }}>
                  Please read these conditions carefully before using the Atmagram website. By using the Atmagram website, you signify your agreement to be bound by these conditions.
                </p>
                <p style={{ textAlign: "justify" }}>
                  In addition, when you use any current or future Atmagram service (eg. Wish List or Grammerce) , you will also be subject to the terms, guidelines and conditions applicable.
                </p>
                <p style={{ textAlign: "justify" }}>
                  These "Terms and Conditions" constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                </p>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    Conditions Relating to Your Use of Atmagram
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Notice and Procedure for Making Claims of Infringement
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Notice and Procedure for Notifying Atmagram of Objectionable Content
                  </li>
                </ul>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>Conditions Relating to Your Use of Atmagram</p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>1. Your Account</p>
                <p style={{ textAlign: "justify" }}>
                  If you use the website, you are responsible for maintaining the confidentiality of your account and password and for
                  restricting access to your computer to prevent unauthorised access to your account. You agree to accept responsibility
                  for all activities that occur under your account or password. You should take all necessary steps to ensure that the password
                  is kept confidential and secure and should inform us immediately if you have any reason to believe
                  that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner. Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information
                  that you provided when registering. You can access and update much of the information you provided us with in the Your Account area of the website. You agree and acknowledge that you will use your account on the website to purchase products only for your personal use and not for business purposes. Should you wish to order products for business purposes, please Contact Us. Atmagram reserves the right to refuse access to the website, terminate accounts, remove or edit content at any time without notice to you.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>2. Privacy</p>
                <p style={{ textAlign: "justify" }}>
                  Please review our Privacy Notice, which also governs your visit to Atmagram, to understand our practices. The personal information / data provided to us by you during the course of usage of Atmagram will be treated as strictly confidential and in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the website.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>3. E-Platform for Communication</p>
                <p style={{ textAlign: "justify" }}>
                  You agree, understand and acknowledge that the website is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location. You further agree and acknowledge that Atmagram is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the website. Accordingly, the contract of sale of products on the website shall be a strictly bipartite contract between you and the sellers on Atmagram.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>4. Access to Atmagram</p>
                <p>
                  We will do our utmost to ensure that availability of the website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>5. Licence for website access</p>
                <p style={{ textAlign: "justify" }}>
                  Subject to your compliance with these Terms and Conditions and payment of applicable fees, if any, Sri Sankara Peetam Charitable Trust grants you a limited licence to access and make personal use of this website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Sri Sankara Peetam Charitable Trust and / or its affiliates, as may be applicable. This licence does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.
                </p>
                <p style={{ textAlign: "justify" }}>
                  This website or any portion of this website (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Sri Sankara Peetam Charitable Trust and / or its affiliates, as may be applicable.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Atmagram and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilising Sri Sankara Peetam Charitable Trust’s and / or its affiliates’ names or trademarks without the express written consent of Sri Sankara Peetam Charitable Trust and / or its affiliates, as applicable. Any unauthorised use terminates the permission or license granted by Sri Sankara Peetam Charitable Trust and / or its affiliates, as applicable.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of Atmagram as long as the link does not portray Sri Sankara Peetam Charitable Trust, Atmagram, their affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Atmagram logo or other proprietary graphic or trademark as part of the link without express written consent of Sri Sankara Peetam Charitable Trust and / or its affiliates, as may be applicable.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  6. Your Conduct
                </p>
                <p style={{ textAlign: "justify" }}>
                  You must not use the website in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way You understand that you, and not Atmagram, are responsible for all electronic communications and content sent from your computer to us and you must use the website for lawful purposes only. You must not use the website for any of the following:
                </p>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    for fraudulent purposes, or in connection with a criminal offense or other unlawful activity
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material
                    that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, paedophilic, or menacing; ethnically objectionable, disparaging or in breach of
                    copyright, trademark, patent, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious
                    to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam; or is patently false and untrue.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    to cause annoyance, inconvenience or needless anxiety
                  </li>
                </ul><p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  7. Reviews, comments, communications and other content
                </p>
                <p style={{ textAlign: "justify" }}>
                  Users of this website may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content. Atmagram reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of Atmagram violates these Terms and Conditions and, or terminate your permission to access or use this website.
                </p>
                <p style={{ textAlign: "justify" }}>
                  If you do post content or submit material, and unless we indicate otherwise, you
                </p>
                <p style={{ textAlign: "justify" }}>
                  1. (a) grant Sri Sankara Peetam Charitable Trust and its affiliates a non-exclusive, royalty-free, irrevocable, perpetual and fully sublicensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and
                </p>
                <p style={{ textAlign: "justify" }}>
                  2. (b) Sri Sankara Peetam Charitable Trust and its affiliates and sublicensees the right to use the name that you submit in connection with such content, if they choose.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your right to be identified as the author of such content and your right to object to derogatory treatment of such content. You agree to perform all further acts necessary to perfect any of the above rights granted by you to Sri Sankara Peetam Charitable Trust, including the execution of deeds and documents, at its request.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You represent and warrant that you own or otherwise control all of the rights to the content that you post or that you otherwise provide on or through the website; that, as at the date that the content or material is submitted to Atmagram: (i) the content and material is accurate; (ii) use of the content and material you supply does not breach any applicable Atmagram policies or guidelines and will not cause injury to any person or entity (including that the content or material is not defamatory); (iii) the content is lawful. You agree to indemnify Sri Sankara Peetam Charitable Trust and its affiliates for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these warranties.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  8. Claims against Objectionable Content
                </p>
                <p style={{ textAlign: "justify" }}>
                  You can refer to the product detail page on Atmagram for checking any product details provided by the seller regarding the following:
                </p>
                <ul>
                  <li>
                    the total price in single figure of any good or service, along with the breakup price for the good or service, showing all the compulsory and voluntary charges such as delivery charges, postage and handling charges, conveyance charges and the applicable tax, as applicable. These details are available on the invoice issued to you.
                  </li>
                  <li>
                    mandatory notices and information provided by applicable laws, and the expiry date of the good being offered for sale, where applicable; .
                  </li>
                  <li>
                    goods and services offered for sale by the seller including country of origin which are necessary for enabling the consumer to make an informed decision at the pre-purchase stage.
                  </li>
                  <li>
                    importer and the guarantees related to the authenticity or genuineness of the imported products; and.
                  </li>
                  <li>
                    guarantees or warranties applicable to such goods or services.
                  </li>
                </ul>
                <p style={{ textAlign: "justify" }}>
                  Since Atmagram lists millions of products for sale offered by sellers on the website and hosts many thousands of comments, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, Atmagram operates on a "notice and takedown" basis. If you believe that any content on the website is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, paedophilic, invasive of another’s privacy or menacing; ethnically objectionable, disparaging; or in breach of a third party’s confidential, proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or in insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource; or is patently false and untrue (" Objectionable Content "), please notify us immediately by contacting us. Once this procedure has been followed, Atmagram will make all reasonable endeavours to remove such Objectionable Content complained about within a reasonable time.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  9. Copyright, authors' rights and database rights
                </p>
                <p style={{ textAlign: "justify" }}>
                  All content included on the website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Sri Sankara Peetam Charitable Trust, its affiliates or its content suppliers and is protected by India and international copyright, authors' rights and database right laws. The compilation of all content on this website is the exclusive property of Sri Sankara Peetam Charitable Trust and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this website is the property Sri Sankara Peetam Charitable Trust, its affiliates or its software suppliers and is protected by India and international copyright and author' rights laws.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You may not systematically extract/ or re-utilise parts of the contents of the website without Sri Sankara Peetam Charitable Trust and / or its affiliate's (as may be applicable) express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this website, without Sri Sankara Peetam Charitable Trust and / or its affiliate's (as may be applicable) express written consent. You may also not create and/ or publish your own database that features substantial (eg: prices and product listings) parts of this website without Sri Sankara Peetam Charitable Trust and / or its affiliate's (as may be applicable) express written consent.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  10. Intellectual Property Claims
                </p>
                <p style={{ textAlign: "justify" }}>
                  Sri Sankara Peetam Charitable Trust and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  11. Disclaimer
                </p>
                <p style={{ textAlign: "justify" }}>
                  You acknowledge and undertake that you are accessing the services on the website and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the website. You further acknowledge and undertake that you will use the website to order products only for your personal use and not for business purposes. We shall neither be liable nor responsible for any actions or inactions of sellers nor any breach of conditions, representations or warranties by the sellers or manufacturers of the products and hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the sellers or manufacturers of the products.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the website. While we have taken precautions to avoid inaccuracies in content, this website, all content, information (including the price of products), software, products, services and related graphics are provided as is, without warranty of any kind. At no time shall any right, title or interest in the products sold through or displayed on the website vest with Atmagram nor shall Atmagram have any obligations or liabilities in respect of any transactions on the website.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  12. Indemnity and Release
                </p>
                <p style={{ textAlign: "justify" }}>
                  You shall indemnify and hold harmless Sri Sankara Peetam Charitable Trust, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms and Conditions or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.
                </p>
                <p style={{ textAlign: "justify" }}>
                  You hereby expressly release Sri Sankara Peetam Charitable Trust and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  13. Other Businesses
                </p>
                <p style={{ textAlign: "justify" }}>
                  Parties other than Sri Sankara Peetam Charitable Trust and its affiliates may operate stores, provide services, or sell product lines on Atmagram. For example, businesses and individuals offer products via Grammerce. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their websites. Sri Sankara Peetam Charitable Trust does not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You can tell when a third-party is involved in your transactions, and we may share customer information related to those transactions with that third-party. You should carefully review their privacy statements and other Terms and Conditions.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  14. Communications
                </p>
                <p style={{ textAlign: "justify" }}>
                  When you visit Atmagram, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website and/or your order placed on the website.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  15. Losses
                </p>
                <p style={{ textAlign: "justify" }}>
                  We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the website.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  16. Alteration of Service or Amendments to the Conditions
                </p>
                <p style={{ textAlign: "justify" }}>
                  We reserve the right to make changes to our website, policies, and these Terms and Conditions at any time. You will be subject to the policies and Terms and Conditions in force at the time that you use the website or that you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  17. Events beyond our reasonable control
                </p>
                <p style={{ textAlign: "justify" }}>
                  We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  18. Waiver
                </p>
                <p style={{ textAlign: "justify" }}>
                  If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  19. Governing law and Jurisdiction
                </p>
                <p style={{ textAlign: "justify" }}>
                  These conditions are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Delhi.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  20. Our Details
                </p>
                <p style={{ textAlign: "justify" }}>
                  This website is operated by of Sri Sankara Peetam Charitable Trust.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  21. Customer Complaint Details
                </p>
                <p style={{ textAlign: "justify" }}>
                  The details pertaining to each customer complaint lodged (including the status of the complaint and tracking details) are provided in the return e-mails which are sent back to the customers.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  Use of Third Party Services
                </p>
                <p style={{ textAlign: "justify" }}>
                  When you use the Atmagram Software, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third party services may be subject to the separate policies, terms of use, and fees of these third parties.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  No Reverse Engineering
                </p>
                <p style={{ textAlign: "justify" }}>
                  You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the Atmagram Software, whether in whole or in part, or create any derivative works from or of the Atmagram Software.
                </p>
                <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                  Updates
                </p>
                <p style={{ textAlign: "justify" }}>
                  In order to keep the Atmagram Software up-to-date, we may offer automatic or manual updates at any time and without notice to you.
                </p>

              </div>
            </div>
          </IonCol>
        </IonRow>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default TermsConditions;