import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import Foot from "./Foot";
import "./Home.css";
import HeadFoot from "./Head";

const News: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Purchase Protection"} />
        <IonRow className="page_content_padding">
          <IonCol>
            <div style={{ margin: "2% 4%" }}>
              <p style={{ textAlign: "justify" }}>
                We are committed to ensure 100% Purchase Protection for customers by offering genuine products, secure payments and easy returns for items shopped on Atmagram.com.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Genuine Products
              </p>
              <p style={{ textAlign: "justify" }}>
                Sellers are committed to sell only genuine products to customers on Atmagram.com. All sellers listing their products on Atmagram.com are required to enter an agreement to list and sell only genuine products.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Secure Payments and Safe Ordering
              </p>
              <p style={{ textAlign: "justify" }}>
                Atmagram.com understands that you care about how information about you is used and shared. We appreciate your trust in us to do that carefully and sensibly.
                <label style={{ fontWeight: "bold" }}> Your privacy is important to us, and we work to keep your information secure.</label>
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                How Secure Is Information I share with Atmagram.com?
              </p>
              <p style={{ textAlign: "justify" }}>
                Our secure-server software encrypts all your personal information including credit or debit card number, name and address. The encryption process takes the characters you enter and converts them into bits of code that are then securely transmitted over the Internet.
              </p>
              <p style={{ textAlign: "justify" }}>
                We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts your information, in addition to maintaining the security of your information as per the International Standard IS/ISO/IEC 27001 on "Information Technology-Security Techniques- Information Security Management System-Requirements".
              </p>
              <p style={{ textAlign: "justify" }}>
                We reveal only the last four digits of your credit card numbers when confirming an order. Of course, we transmit the entire credit card number to the appropriate credit card company during order processing.
              </p>
              <p style={{ textAlign: "justify" }}>
                We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personally identifiable customer information. As per our security procedures, we may occasionally request proof of identity before we disclose personal information to you.
              </p>
              <p style={{ textAlign: "justify" }}>
                It is important for you to prevent unauthorised access to your password and computer. Be sure to
                <text style={{ fontWeight: "bold" }}> sign out when you finish using a shared computer.</text>
                Learn more about our Privacy Policy.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Easy Returns
              </p>
              <p style={{ textAlign: "justify" }}>
                Returns are easy with our online returns centre. While most items can be returned for a refund or replacement within a 10-day return window, return policies may be different across product categories.
              </p>
            </div>
          </IonCol>
        </IonRow>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default News;