import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";

const About: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        {/* <UserHead User="donations" Role={userdata[0]} /> */}
        <div style={{padding:'5%'}}>
        <h5 style={{ justifyContent: 'center', textAlign: 'justify', marginTop: '1%', fontWeight: 'bold',padding:'1%' }}>Pazhavangadi Ganapathi Temple
        </h5>
        <div>
          <p style={{ textAlign: "justify", margin: '1%' }}>Being one of the most popular Ganesh temples of the south,
         the Pazhavangadi Ganapathy Temple holds a special place in the hearts of the locals and the 
         ardent devotees of Lord Ganesh. The devotees who visit this temple are overcome with the tranquil
          calm and relentless peace of the magically pious atmosphere. They say that Lord Ganesh fulfils the
           deepest wishes and fixes the most stubborn problems of his devotees who visit him at the Pazhavangadi 
           Ganapathy Temple. Locate Everything</p>
        </div>
        <div>
          <p style={{ textAlign: "justify", margin: '1%' }}>This opulent Ganpati Temple in Trivandrum is famous
         mainly for two reasons- its exceptional jet-black colour, a rare colour for temples in 
         India, and its unique Ganesh idol. The position of the idol that sits in this temple is
          different from most others. Here, Lord Ganesh relaxes languorously with only his right knee
           folded under him as against his typically seen standing position or his seated position with crossed legs</p>
        </div>
        </div>
         
          {/* {Capacitor.getPlatform() === "web"? */}
         <Foot />
         {/* :<></>} */}
       
      </IonContent>
    </IonPage>
  );
};

export default About;