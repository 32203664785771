import {
  IonCol,
  IonContent,
  IonIcon,
  IonText,
  IonPage,
  IonRow,
  IonButton,
  IonLabel,
  IonInput,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonItemDivider,
  IonGrid,
  IonTextarea,
  useIonAlert,
  IonItem,
  IonImg,
} from "@ionic/react";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import "./Donations.css"
import { checkmarkCircleSharp, close, heart } from "ionicons/icons";
import UserHead from "./UserHead";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import useRazorpay from "react-razorpay";
import UserService from "../Services/auth.service";
import { checkServerIdentity } from "tls";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useHistory, useLocation } from "react-router";
import { Capacitor } from "@capacitor/core";
import { useForm } from "react-hook-form";
import validator from 'validator'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import HeadFoot from "./Head";
import { joinhand, heart1, tick, closeicon } from "../theme/imagePath";
import { AppContext } from "../components/AppContext";
import Breadcrumb from "../components/Breadcrumb";


const Donations: React.FC = () => {

  const [userdata, setUserdata]: any = useState(sessionStorage.getItem("UserData"));
  const [token, setUsertoken]: any = useState(sessionStorage.getItem("token"));

  const [userId, setUserId]: any = useState(sessionStorage.getItem("UserId"));
  const [modalopen, setModalopen] = useState(false);
  const [Dname, setDname]: any = useState("");
  const [email, setEmail]: any = useState("");
  const [Cnumber, setCnumer]: any = useState("");
  const [panNumber, setPanNumber]: any = useState("");
  const [bankName, setBankName]: any = useState("")
  const [Address, setAddress]: any = useState("");
  const [Camount, setCamount]: any = useState("");
  const [Purpose, setPurpose]: any = useState("");
  const [Remark, setRemark]: any = useState("");
  const [Identi, setIdenti]: any = useState(false);
  const [supporter, setSupporter]: any = useState("");
  const mailPat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const namepat = /^[a-zA-Z\s]*$/;
  const panPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
  const amtpat = /^[[0-9]{1,15}]*$/;
  const [Alert] = useIonAlert();
  const Razorpay = useRazorpay();
  const history = useHistory();
  const location: any = useLocation();
  const [add, setAdd]: any = useState(sessionStorage.getItem("Address"));
  const [dmail, setDmail]: any = useState(sessionStorage.getItem("Email"))
  const [dphone, setDphone]: any = useState(sessionStorage.getItem("Phone"));
  const [name, setName]: any = useState(sessionStorage.getItem("Name"));
  const [che, setChe] = useState(false);
  const [signupmodal, setSignupmodal] = useState(false)
  const [loginmodal, setLoginmodal] = useState(false)
  const [iserror, setIserror] = useState(false);
  const [loginemail, setLoginEmail]: any = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const [formErrors, setFormErrors]: any = useState({
  });

  const [box, setBox]: any = useState(false);
  const sameas = (e: any) => {
    const errors: any = {};

    if (e.target.checked) {
      setChe(true)
      if (name != null && name != "") {
        setDname(name)
      } else {
        errors.Dname = "Enter Name at My Profile"
      }
      // if (dmail != null && dmail != "") {
      //   setEmail(dmail)
      // } else {
      //   errors.email = "Enter Email at My Profile"
      // }
      if (dphone != null && dphone != "") {
        if (!dphone.startsWith("+")) {
          try {
            const phoneNumberObject: any = parsePhoneNumberFromString("+91" + dphone);
            setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
            console.log(phoneNumberObject.isValid())
          } catch (error) {
            setIsValidPhoneNumber(false);
            console.log(error)
          }
          setCnumer("+91" + dphone)
        } else {
          try {
            const phoneNumberObject: any = parsePhoneNumberFromString(dphone);
            setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
            console.log(phoneNumberObject.isValid())
          } catch (error) {
            setIsValidPhoneNumber(false);
            console.log(error)
          }
          setCnumer(dphone)
        }
      } else {
        errors.Cnumber = "Enter mobile number at My Profile"
      }
      if (add != null && add != "") {
        setAddress(add)
      } else {
        errors.Address = "Enter Address Details at My Profile"
      }
    } else {
      setChe(false)
      // setEmail("")
      setCnumer("")
      setDname("")
      setAddress("")
    }
    setFormErrors(errors);
    // if (Object.keys(errors).length === 0) {
    //   setChe(false)
    //   setEmail("")
    //   setCnumer("")
    //   setDname("")
    //   setAddress("")
    // }
  }
  const contactnoref = useRef(null)

  const cursorend = (value: any) => {
    const input: any = contactnoref.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      console.log(phoneNumberObject.isValid())
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setCnumer(value)
  };
  const check = (e: any) => {
    if (e.detail.checked) {
      setBox(true)
      setIdenti(true)
      setModalopen(true)
      setFormErrors((prev: any) => {
        return { ...prev, Dname: "", Cnumber: "", Address: "" }
      })
    }
    else {
      setBox(false)
      setIdenti(false)
    }

  }

  const modalclose = () => {
    setIdenti(false)
    setModalopen(false)
  }

  const uFlag: any = useState(sessionStorage.getItem("uFlag"))

  const { amountLimit }: any = useContext(AppContext)!;
  const formatter = new Intl.NumberFormat('en-IN')

  useEffect(() => {

    Authservice.DonationmanageSupport().then((response: any) => {
      console.log(response);
      var res = response.data;
      var sup = res[0].totalSupporters;
      setSupporter(sup);
    })
  }, [])

  const payment = () => {
    const errors: any = {};
    if (Camount == "") {
      errors.Camount = "Enter Contribution Amount ";
    }
    else if (Camount < 1) {
      errors.Camount = "Cannot accept 0 as an Amount";
    }
    else if (amtpat.test(Camount) == false) {
      errors.Camount = "Invalid Contribution Amount";
    }
    else if (Camount > amountLimit) {
      errors.Camount = "Maximum Transaction limit is Rs. " + formatter.format(amountLimit);
    }
    if (Purpose == "") {
      errors.Purpose = "Select Purpose";
    }
    if (panPattern.test(panNumber.toUpperCase()) == false) {
      errors.pan = "Enter valid PAN number";

    }
    if (bankName == "") {
      errors.nameAsBank = "Please enter name as per bank";
    }
    if (email == "") {
      errors.email = 'Enter Email id'
    }
    else if (validator.isEmail(email) == false) {
      errors.email = 'Enter Valid Email Address in Contribute'
    }
    if (Identi === true && Dname == "") {
      errors.revael = 'Please Enter Reveal identity Details'
    }
    else if (Identi === true && namepat.test(Dname) == false) {
      errors.revael = 'Alphabets only Allowed in Name on Contribute'
    }
    else if (Identi === true && Cnumber == "") {
      errors.revael = 'Enter Contact Number in Contribute'
    }
    // else if (Identi === true && !(Cnumber.match('[0-9]{10}'))) {
    //   alert('Enter Valid Mobile Number in Contribute')
    // }
    else if (Identi === true && Address == "") {
      errors.revael = 'Enter Address in Contribute'
    }
    //  else if (Identi === true && token == null) {
    //   setLoginmodal(true)
    // }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      bookpayment(token, userId);

    }
  }
  const bookpayment = (e: any, usrId: any) => {
    setShowLoading(true);
    var dataPay = {};
    // if (Identi) {
    //   dataPay = {
    //     "donorName": Dname,
    //     "contactNumber": Cnumber,
    //     "address": Address,
    //     "contributionAmount": Camount,
    //     "panCardNumber": panNumber,
    //     "nameAsPerBank": bankName,
    //     "purpose": Purpose,
    //     "remarks": Remark,
    //     "revealFlag": Identi,
    //     "emailId": email,
    //     "userModel": {
    //       "id": usrId
    //     },
    //   }
    // } else {
    dataPay = {
      "contributionAmount": Camount,
      "panNumber": panNumber,
      "nameAsInBank": bankName,
      "purpose": Purpose,
      "remarks": Remark,
      "emailId": email,
    }
    // }

    Authservice.DonationPayment(token, usrId, token, dataPay)
      .then((response) => {
        console.log("payresponse" + response);
        var res = response.data;

        // alert('your donation placed successfully')
        // window.location.reload();
        if (response.data.errors === null) {
          var options: any = {
            key: response.data.pgOption.key,
            key_secret: response.data.secretKey,
            amount: response.data.pgOption.amount,
            currency: response.data.pgOption.currency,
            name: response.data.pgOption.name,
            order_id: response.data.pgOption.order_id,
            description: response.data.pgOption.description,
            modal: {
              escape: false, ondismiss: function () {
                // code here 
                Alert({
                  header: "Alert",
                  message: "Payment Failed",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                    }
                  ]
                })
              }
            },
            handler: function (res: any) {
              // alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
              var resData: any = {
                "amount": response.data.pgOption.amount,
                "orderId": res.razorpay_order_id,
                "bookingRefNo": response.data.bookingRefNo,
                "signature": res.razorpay_signature,
                "paymentId": res.razorpay_payment_id
              }
              console.log("resData", resData);
              Authservice.DonationPaymentMultiRes(token, userId, token, resData)
                .then((response) => {
                  console.log("payresponse" + response);
                  Alert({
                    header: "Atmagram",
                    message: "Thanks for your contribution",
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: "ok",
                        handler: () => {
                          window.location.reload()
                        }
                      }
                    ]
                  })
                })
                .catch((error) => {
                  Alert({
                    header: "Alert",
                    message: "Something went wrong, please try again later.",
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: "ok",
                      }
                    ]
                  })
                });

            },
            prefill: {
              name: response.data.pgOption.name,
              email: response.data.email,
              contact: response.data.contact

            },

            theme: {
              color: response.data.theme
            }
          };
          var pay: any = new Razorpay(options)
          pay.on("payment.failed", function (data: any) {
            Alert({
              header: "Alert",
              message: data.error.reason,
              backdropDismiss: false,
              buttons: [
                {
                  text: "ok",
                }
              ]
            })
          });
          setShowLoading(false);
          pay.open()
          console.log(dataPay);
        }
        else {
          setShowLoading(false);
          Alert({
            header: "Atmagram",
            message: response.data.errors[0],
            backdropDismiss: false,
            buttons: ["Ok"]
          })
        }
      })
      .catch((error) => {
        Alert({
          header: "Alert",
          message: "Something went wrong, please try again later.",
          backdropDismiss: false,
          buttons: [
            {
              text: "ok",
            }
          ]
        })
      });
  }

  const restrictE = (e: any) => {
    if (e.key == "e") {
      e.preventDefault()
    }
  }

  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }

  const Validation = (label: any) => {
    console.log(Camount, "kmkm")
    if (label === "pan") {
      var testPan = panPattern.test(panNumber.toUpperCase());
      if (testPan == false) {
        setFormErrors((prev: any) => {
          return { ...prev, pan: "Invalid PAN Number " }
        })
      } else {
        setFormErrors((prev: any) => {
          return { ...prev, pan: "" }
        })
      }
    }
    if (label === "nameAsBank") {
      if (bankName == "") {
        setFormErrors((prev: any) => {
          return { ...prev, nameAsBank: "Enter name as per bank" }
        })
      } else {
        setFormErrors((prev: any) => {
          return { ...prev, nameAsBank: "" }
        })
      }
    }

    if (label === "Camount") {
      if (Camount == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Please Enter Contribution Amount " }
        })
      }
      else if (Camount < 1) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Cannot Accept 0 as an Amount " }
        })
      }
      else if (amtpat.test(Camount) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Invalid Contribution Amount " }
        })
      }
      else if (Camount > amountLimit) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Maximum Transaction limit is Rs. " + formatter.format(amountLimit) }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "" }
        })
      }
    }

    if (label === "Purpose") {
      if (Purpose == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Purpose: "Select Purpose" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Purpose: "" }
        })
      }
    }
    if (label === "email") {
      if (email == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Enter Email id' }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Valid Email id' }
        })
      }
    }

    if (label === "Identify") {
      if (Identi === true && Dname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: "Please Enter Reveal identity Details" }
        })
      }
      else if (Identi === true && namepat.test(Dname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Alphabets only Allowed in Name on Contribute' }
        })
      }
      else if (Identi === true && Cnumber == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Contact Number in Contribute' }
        })
      }
      else if (Identi === true && !isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Valid Contact Number in Contribute' }
        })
      }
      else if (Identi === true && Address == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Address in Contribute' }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, revael: "" }
        })
      }
    }

    if (label === "Dname") {
      if (Dname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "Please Enter Name" }
        })
      }
      else if (namepat.test(Dname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "Alphabets only Allowed in Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "" }
        })
      }
    }

    if (label === "email") {
      if (email == "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please Enter Email ID" }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "contact") {
      if (Cnumber == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "Please Enter Contact Number" }
        })
      }
      else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "Please Enter Valid Contact Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "" }
        })
      }
    }
    // else if (!(Cnumber.match('[0-9]{10}'))) {
    //   errors.Cnumber = 'Enter Valid Mobile Number'
    // }
    if (label === "Address") {
      if (Address == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Address: "Please Enter Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Address: "" }
        })
      }
    }

  }

  const ChangeValidation = (label: any, e: any) => {

    if (label === "Camount") {
      const Camount = e.target.value;
      if (Camount == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Please Enter Contribution Amount " }
        })
      }
      else if (Camount < 1) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Cannot Accept 0 as an Amount " }
        })
      }
      else if (amtpat.test(Camount) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Invalid Contribution Amount " }
        })
      }
      else if (Camount > amountLimit) {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "Maximum Transaction limit is Rs. " + formatter.format(amountLimit) }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Camount: "" }
        })
      }
    }
    if (label === "pan") {
      var testPan = panPattern.test(panNumber.toUpperCase());
      if (testPan == false) {
        setFormErrors((prev: any) => {
          return { ...prev, pan: "Invalid PAN Number " }
        })
      } else {
        setFormErrors((prev: any) => {
          return { ...prev, pan: "" }
        })
      }
    }
    if (label === "nameAsBank") {
      if (bankName == "") {
        setFormErrors((prev: any) => {
          return { ...prev, nameAsBank: "Enter name as per bank" }
        })
      } else {
        setFormErrors((prev: any) => {
          return { ...prev, nameAsBank: "" }
        })
      }
    }

    if (label === "Purpose") {
      const Purpose = e.target.value;
      if (Purpose == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Purpose: "Select Purpose" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Purpose: "" }
        })
      }
    }

    if (label === "email") {
      if (email == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: ' Please Enter Email ID in Contribute' }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Valid Email Address in Contribute' }
        })
      }
    }

    if (label === "Identify") {
      const Identi = e.target.value;
      if (Identi === true && Dname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: "Please Enter Reveal identity Details" }
        })
      }
      else if (Identi === true && namepat.test(Dname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Alphabets only Allowed in Name on Contribute' }
        })
      }
      else if (Identi === true && Cnumber == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Contact Number in Contribute' }
        })
      }
      else if (Identi === true && !isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Valid Contact Number in Contribute' }
        })
      }
      else if (Identi === true && Address == "") {
        setFormErrors((prev: any) => {
          return { ...prev, revael: 'Please Enter Address in Contribute' }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, revael: "" }
        })
      }
    }

    if (label === "Dname") {
      const Dname = e.target.value;
      if (Dname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "Please Enter Name" }
        })
      }
      else if (namepat.test(Dname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "Alphabets only Allowed in Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Dname: "" }
        })
      }
    }

    if (label === "email") {
      const email = e.target.value;
      if (email == "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please Enter Email ID" }
        })
      }
      else if (validator.isEmail(email) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "contact") {
      const Cnumber = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (Cnumber == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "Please Enter Contact Number" }
        })
      }
      else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "Please Enter Valid Contact Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Cnumber: "" }
        })
      }
    }
    // else if (!(Cnumber.match('[0-9]{10}'))) {
    //   errors.Cnumber = 'Enter Valid Mobile Number'
    // }
    if (label === "Address") {
      const Address = e.target.value;
      if (Address == "") {
        setFormErrors((prev: any) => {
          return { ...prev, Address: "Please Enter Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Address: "" }
        })
      }
    }

  }

  const show = () => {
    const errors: any = {};

    if (Dname == "") {
      errors.Dname = "Enter Name"
    }
    else if (namepat.test(Dname) == false) {
      errors.Dname = 'Alphabets only Allowed in Name'
    }
    // if (email == "") {
    //   errors.email = 'Enter Email ID'
    // }
    // else if (validator.isEmail(email) == false) {
    //   errors.email = 'Enter Valid Email Address'
    // }
    if (Cnumber == "") {
      errors.Cnumber = 'Enter Contact Number'
    } else if (!isValidPhoneNumber) {
      errors.Cnumber = 'Please Enter Valid Contact Number'
    }
    // else if (!(Cnumber.match('[0-9]{10}'))) {
    //   errors.Cnumber = 'Enter Valid Mobile Number'
    // }
    if (Address == "") {
      errors.Address = 'Enter Address'
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setModalopen(false)
      setBox(false)

    }
  }
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)
  const useOutsideAlerter = (refer: any, logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setModalopen(false)
        }
        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(false)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(false)
          setLoginmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const donationcontributemodal = useRef(null);
  useOutsideAlerter(donationcontributemodal, loginmodalref, signupmodalref);

  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const namecheck = /^[a-zA-Z\s]*$/

  const [user, setUser]: any = useState("2");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [aadhar1, setAadhar1] = useState("");

  const [firstname1, setFirstname1] = useState("");

  const [phone1, setPhone1] = useState("");

  const [email1, setEmail1] = useState("");
  const [issuccess, setIssuccess] = useState(false);

  const [contribute, setContribute] = useState(false);

  const submit = () => {
    if (user === "") {
      alert("Chosse the User")
    }
    else if (firstname1 == "") {
      alert("Enter First Name")
    }
    else if (firstname1 == "") {
      alert("Enter First Name")
    }
    else if (namecheck.test(firstname1) == false) {
      alert('Alphabets only Allowed in First Name')
    }
    else if (firstname1.charAt(0) == " ") {
      alert('Empty Space at First Name')
    }
    else if (phone1.length !== 10) {
      alert("Enter Valid Phone Nuumber")
    }
    else if (validator.isEmail(email1) === false) {
      alert("Enter Valid Email Address")
    }
    else if (password1 !== confirmpassword) {
      alert("Password and confirm password are not same ")
    }
    else {
      console.log(aadhar1,
        email1,
        phone1,
        password1,
        user,
        user,
        firstname1)
      UserService.Registermaterials(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            setIssuccess(true);
            // setAadhar1("");
            // setAddr("");
            // setArea1("");
            // setCity1("");
            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            setSignupmodal(false)
            setLoginmodal(true)
            alert("Successfully Signed Up");
            console.log(response, "resss =>")
          }
          else {
            alert("EmailId Already Exists");
            setIserror(true);
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          alert("Something went wrong");
          setIserror(true);
        });
    }
  }
  const Searchlog = (value: any) => {
    if (value == "Enter") {
      if (email.length === 0) {
        alert("Enter Email Address")
      }
      else if (validator.isEmail(email) === false) {
        alert("Enter Valid Email Address")
      }
      else if (password.length == 0) {
        alert("Enter your Password")
      }
      else {
        Loginsubmit()
      }
    }
  }

  const Loginsubmit = () => {
    setShowLoading(true);
    UserService.Login(loginemail, password, "")
      .then((response) => {
        setShowLoading(false);
        setLoginmodal(false)
        if (response.data.responseText.startsWith("SUCCESS")) {
          // if (Capacitor.getPlatform() === "web") {
          console.log(response, "ressss")
          if (response.data.userModel.role !== "ROLE_USER") {
            alert("This Account is not a user")
          }
          else
            if (response.data.userModel.uflag === "false") {
              console.log(response.data.userModel.uflag, "uflag")
              alert("Account Not Approved Yet")
            }
            else {
              sessionStorage.setItem("token", response.data.userToken);
              sessionStorage.setItem("UserId", response.data.userModel.id);
              sessionStorage.setItem("UserData", response.data.userModel.role);
              sessionStorage.setItem("uFlag", response.data.userModel.uflag);
              sessionStorage.setItem("Email", response.data.userModel.email);
              sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
              if (response.data.userModel.firstName !== null) {
                sessionStorage.setItem("Name", response.data.userModel.firstName);
              }
              if (response.data.userModel.address !== null) {
                sessionStorage.setItem("Address", response.data.userModel.address);
              }

              setUsertoken(response.data.userToken)
              setUserdata(response.data.userModel.role)
              setUserId(response.data.userModel.id)
              console.log(response.data.userToken)
              alert("Login Successful");
              bookpayment(response.data.userToken, response.data.userModel.id);
            }
          // }
        }
        else {

          alert(response.data.responseText.split("ERROR:")[1]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoginmodal(false)
        setShowLoading(false);
      });
  };

  const targetRef: any = useRef(null);

  const contributeBtn = () => {
    setContribute(true)
    setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    })
  }

  const pageNames = ['Donations'];

  // Breadcrumb click handler
  const handleBreadcrumbClick = (index: any) => {
  };

  return (
    <IonPage>
      <IonContent>
        {/* <UserHead User="donations" Role={userdata[0]} /> */}
        <HeadFoot User={"donations"} Role={'Role'} Name={"Home,Donations"} />
        <Breadcrumb pageNames={pageNames}
          currentPage={0}
          onBreadcrumbClick={null} />
        <Modal show={signupmodal} >
          <Modal.Body ref={signupmodalref}>
            <div className="modal-header">
              <h5 className="modal-title">Atmagram</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => setSignupmodal(false)} />
              </div>

            </div>
            <div>
              <form onSubmit={handleSubmit(submit)}>
                <div>
                  <IonRow className="row_sign">
                    <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal">
                        <IonLabel className="sign_label_user">User</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal">
                        <IonLabel position="floating" className="sign_label">
                          First Name <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          value={firstname1}
                          onIonChange={(e) => setFirstname1(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>



                  <IonRow className="row_sign">
                    <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal">
                        <IonLabel className="sign_label" position="floating">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="tel"
                          inputMode="tel"
                          pattern="[0-9]{10}"
                          value={phone1}
                          maxlength={10}
                          required
                          onIonChange={(e) => setPhone1(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal ">
                        <IonLabel className="sign_label" position="floating">
                          Email <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="email"
                          value={email1}
                          required
                          onIonChange={(e) => setEmail1(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    {/* <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal ">
                        <IonLabel className="sign_label" position="floating">
                          Aadhar Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="tel"
                          inputMode="tel"
                          pattern="[0-9]{12}"
                          required
                          maxlength={12}
                          value={aadhar1}
                          onIonChange={(e) => setAadhar1(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol> */}
                  </IonRow>
                  <IonRow className="row_sign">
                    <IonCol size="12" size-lg="6">
                      <IonItem className="rounded Signup_modal ">
                        <IonLabel className="sign_label" position="floating">
                          Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password"
                          value={password1}
                          clearOnEdit={false}
                          required
                          onIonChange={(e) => setPassword1(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" size-lg="6">
                      <IonItem className=" rounded Signup_modal " >
                        <IonLabel className="sign_label" position="floating">
                          Confirm Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password"
                          clearOnEdit={false}
                          value={confirmpassword}
                          required
                          onIonChange={(e) => setConfirmpassword(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size="12" size-lg="12" className="textalign-center">
                      <IonButton
                        className="submit"
                        type="submit"
                        onClick={() => submit}
                        disabled={
                          firstname1.length === 0 ||
                          phone1.length === 0 ||
                          //   area1.length === 0 ||
                          //   city1.length === 0 ||
                          //   state1.length === 0 ||
                          //   country1.length === 0 ||
                          email1.length === 0 ||
                          //   addr.length === 0 ||
                          password1.length === 0 ||
                          confirmpassword.length === 0
                          //   aadhar1.length === 0 ||
                          //   user == "" ||
                          //   pincode1.length != 6
                        }
                      >
                        Sign Up
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={loginmodal}>
          <Modal.Body ref={loginmodalref}>
            <div className="modal-header">
              <h5 className="modal-title">Atmagram</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => setLoginmodal(false)} />
              </div>


            </div>
            <div style={{ textAlign: "center" }}>
              <form className="top" onSubmit={handleSubmit(Loginsubmit)} onKeyPress={e => Searchlog(e.key)}>
                <div>
                  <h4>Log In</h4>
                  <div>
                    <IonRow className="row_log ">
                      <IonCol>
                        <IonItem className="Login_modal  rounded">
                          <IonLabel position="floating" className="label_align">
                            Email
                          </IonLabel>
                          <IonInput
                            type="email"
                            value={loginemail}
                            onIonChange={(e) => setLoginEmail(e.detail.value!)}
                            required
                          ></IonInput>
                          {/* <span slot="error">Name is required</span> */}
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow className="row_log ">
                      <IonCol>
                        <IonItem className="Login_modal rounded">
                          <IonLabel position="floating" className="label_align">
                            Password
                          </IonLabel>
                          <IonInput
                            type="password"
                            clearOnEdit={false}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                            required
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="12" size-lg="12" className="textalign-center">
                        <IonButton
                          className="submit"
                          type="submit"
                          disabled={email.length === 0 || password.length === 0}
                        >
                          Login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </div>
                </div>
              </form>
              <IonRow style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <IonCol size="3" size-lg="3">
                  <IonText>
                    Don't have an account ?
                  </IonText>
                </IonCol>
                <IonCol size="3" size-lg="3">
                  <IonButton
                    onClick={() => setSignupmodal(true)}
                  >
                    Sign Up
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <div className="donation-container page_content_padding">
          <IonGrid>
            <IonRow>
              <IonCol size-lg="6" sizeMd="6" size="12" className="columns-donation">
                <h5 className="donation-title">Donations</h5>
                <p className="donation-content">
                  Sri Sankara Peetam, the promoter of Atmagram, is engaged in social welfare services for the benefit of the poor and needy. A vast area of services such as education, housing, economic empowerment, cultural upliftment, women and youth empowerment, legal support for the benefit of the underprivileged.
                </p>
                <div>
                  <IonButton onClick={() => contributeBtn()}> <span className="button_padding">Contribute</span></IonButton>
                </div>
              </IonCol>
              <IonCol size-lg="6" sizeMd="6" size="0" className="columns-donation">
                <IonImg src={joinhand} className="joinhand-img" />
                <div className="donation-supporters">
                  <div>
                    <IonImg src={heart1} className="donation-heart-icons" />
                  </div>
                  <div className="supporters-card-txtParent">
                    <p className="supporters-card-txt1">{supporter}</p>
                    <p className="supporters-card-txt2">Happy Supporters</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
            {contribute === true ?
              <div ref={targetRef} className="contribute-donation">
                <h5 className="donation-title">Contribution</h5>
                <IonGrid className="order_pad">
                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">PAN Number <span style={{ color: "red" }}>*</span></IonLabel >
                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonInput className="amt-textBox" value={panNumber} min={0} onFocus={preventdata}
                        onBlur={() => Validation("pan")} type='text'
                        onIonChange={(e: any) => {
                          setPanNumber((e.detail.value).toUpperCase());
                          //ChangeValidation("pan", e) 
                        }} />
                      {formErrors.pan && <span className="alert_input_bottom ">{formErrors.pan}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonText className="contribute-labels" >Email-id <span style={{ color: "red" }}>*</span></IonText>
                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonInput className="amt-textBox" onBlur={() => Validation("email")} value={email} onIonChange={(e: any) => { setEmail(e.detail.value); ChangeValidation("email", e) }} />
                      {formErrors.email && <span className="alert_input_bottom">{formErrors.email}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">Name as per bank<span style={{ color: "red" }}>*</span></IonLabel >
                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonInput className="amt-textBox" value={bankName} min={0} onFocus={preventdata}
                        onBlur={() => Validation("nameAsBank")}
                        type='text' onIonChange={(e: any) => { setBankName(e.detail.value); ChangeValidation("nameAsBank", e) }} />
                      {formErrors.nameAsBank && <span className="alert_input_bottom ">{formErrors.nameAsBank}</span>}
                    </IonCol>
                  </IonRow>



                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">Contribution Amount <span style={{ color: "red" }}>*</span></IonLabel >
                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonInput className="amt-textBox" value={Camount} min={0} onFocus={preventdata} onKeyDown={(e: any) => restrictE(e)} onBlur={() => Validation("Camount")} type='number' onIonChange={(e: any) => { setCamount(e.detail.value); ChangeValidation("Camount", e) }} />
                      {formErrors.Camount && <span className="alert_input_bottom ">{formErrors.Camount}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">Purpose <span style={{ color: "red" }}>*</span></IonLabel >
                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonSelect className="amt-textBox" tabIndex={1} onBlur={() => Validation("Purpose")} onIonChange={(e: any) => { setPurpose(e.detail.value); ChangeValidation("Purpose", e) }} >
                        <IonSelectOption>Education</IonSelectOption>
                        <IonSelectOption>Housing</IonSelectOption>
                        <IonSelectOption>Economic Empowerment</IonSelectOption>
                        <IonSelectOption>Cultural Upliftment</IonSelectOption>
                        <IonSelectOption>
                          <div>Women and Youth Empowerment</div>
                        </IonSelectOption>
                        <IonSelectOption>Legal Support for the Benefit of the underprivileged</IonSelectOption>
                        <IonSelectOption>Others</IonSelectOption>
                      </IonSelect>
                      {formErrors.Purpose && <span className="alert_input_bottom ">{formErrors.Purpose}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">

                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">Remark</IonLabel >

                    </IonCol>
                    <IonCol sizeLg="8" sizeMd="8" sizeSm="6" sizeXs="6">
                      <IonTextarea className="contribution-remark" value={Remark} onIonChange={(e: any) => setRemark(e.detail.value)} />
                    </IonCol>
                  </IonRow>
                  {/* <IonRow className="donation-contribute-row">
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="donation-labels-colm ">
                      <IonIcon src={checkmarkCircleSharp} className="tick" />
                      <IonLabel className="contribute-labels">Reveal Identity</IonLabel >
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="revealColumn">
                      <IonCheckbox checked={Identi === true} tabIndex={1} onBlur={() => Validation("Identify")} onIonChange={(e: any) => { check(e); ChangeValidation("Identify", e) }} />
                    </IonCol>
                  </IonRow> */}
                  <div className="donation_payment">
                    <IonButton onClick={() => payment()}> <span className="button_padding">Proceed to Payment</span></IonButton>
                  </div>
                </IonGrid>
              </div>
              : <></>}
          </IonGrid>
        </div>
        <Modal show={modalopen} className="donation-modal">
          <Modal.Body >
            <div className="donation-modal-header">
              <h5 className="donation-modal-title">User Details</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => modalclose()} />
              </div>
            </div>
            <div className="donation-modal-content">
              <IonRow className="donation-modal-row">
                <IonCol sizeLg="6" sizeMd="5" sizeSm="5" sizeXs="5" className="donation-labels-colm " >

                  <IonIcon src={checkmarkCircleSharp} className="tick" />
                  <IonText className="contribute-labels" >Donor Name <span style={{ color: "red" }}>*</span></IonText>

                </IonCol>
                <IonCol sizeLg="6" sizeMd="7" sizeSm="7" sizeXs="7">
                  <IonInput className="donation-modal-inputs" onBlur={() => Validation("Dname")} value={Dname} onIonChange={(e: any) => { setDname(e.detail.value); ChangeValidation("Dname", e) }} />
                  {formErrors.Dname && <span className="alert_input_bottom">{formErrors.Dname}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="donation-modal-row">
                <IonCol sizeLg="6" sizeMd="5" sizeSm="5" sizeXs="5" className="donation-labels-colm ">

                  <IonIcon src={checkmarkCircleSharp} className="tick" />
                  <IonText className="contribute-labels" >Contact Number <span style={{ color: "red" }}>*</span></IonText>

                </IonCol>
                <IonCol sizeLg="6" sizeMd="7" sizeSm="7" sizeXs="7">
                  <PhoneInput
                    international
                    className="donation-modal-inputs"
                    defaultCountry="IN"
                    value={Cnumber}
                    // flags=false
                    onBlur={() => Validation("contact")}
                    ref={contactnoref}
                    onChange={(value: any) => { cursorend(value); ChangeValidation("contact", value) }} />
                  {formErrors.Cnumber && <span className="alert_input_bottom">{formErrors.Cnumber}</span>}
                  {/* <IonInput className="TextInputStyle3" type="tel" maxlength={10} value={Cnumber} onIonChange={(e: any) => setCnumer(e.detail.value)} /> */}
                </IonCol>
              </IonRow>
              <IonRow className="donation-modal-row">
                <IonCol sizeLg="6" sizeMd="5" sizeSm="5" sizeXs="5" className="donation-labels-colm " >

                  <IonIcon src={checkmarkCircleSharp} className="tick" />
                  <IonText className="contribute-labels" >Address <span style={{ color: "red" }}>*</span></IonText>

                </IonCol>
                <IonCol sizeLg="6" sizeMd="7" sizeSm="7" sizeXs="7">
                  <IonTextarea className="donation-modal-inputs address-donation" autoGrow onBlur={() => Validation("Address")} value={Address} onIonChange={(e: any) => { setAddress(e.detail.value); ChangeValidation("Address", e) }} />
                  {formErrors.Address && <span className="alert_input_bottom">{formErrors.Address}</span>}
                  {userdata ?
                    <IonRow>
                      <IonCol className="donation-sameas">
                        <IonCheckbox onIonChange={(e: any) => sameas(e)} checked={che} tabIndex={1} />
                        <IonLabel>Same As Profile</IonLabel>
                      </IonCol>
                    </IonRow> : <></>}
                </IonCol>
              </IonRow>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton onClick={() => show()}><span className="button_padding">Save</span></IonButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Foot />
      </IonContent>
    </IonPage >
  );
};

export default Donations;