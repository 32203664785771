import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { home, chevronForwardOutline } from 'ionicons/icons';
import "./Breadcrumb.css";


interface BreadcrumbProps {
    pageNames: string[];
    currentPage: number;
    onBreadcrumbClick?: ((index: number) => void) | null;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageNames, currentPage, onBreadcrumbClick }) => {

    useEffect(() => {
        console.log(currentPage, "currentPage")
    }, [currentPage])
    return (
        <div className="breadcrumb-container">
            <nav className="breadcrumb-nav">
                <Link to="/" style={{ cursor: "pointer" }}>
                    <IonIcon icon={home} className="home_icon" />
                </Link>
                {pageNames.slice(0, currentPage + 1).map((pageName, index) => {
                    return (
                        <div key={index} className="breadcrumb-item">
                            <IonIcon icon={chevronForwardOutline} className="breadcrumb-chevron" />
                            <span
                                className={`breadcrumb-link ${onBreadcrumbClick && "cursor_pointer"} ${pageName.length - 1 === index ? "breadcrumb-active" : ""
                                    }`}
                                onClick={() => {
                                    if (onBreadcrumbClick) {
                                        onBreadcrumbClick(index);
                                    }
                                }
                                }
                            >
                                {pageName}
                            </span>
                        </div>
                    )
                })}
            </nav>
        </div>
    );
};

export default Breadcrumb;
