import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonText, useIonAlert } from "@ionic/react";
import HeadFoot from "../Head";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import "./report.css"
import UserService from "../../Services/auth.service";
import Authservice from "../../Services/user.service";
import { saveAs } from 'file-saver';
import { addOutline, search, searchOutline } from "ionicons/icons";
import { AppContext } from "../../components/AppContext";
import CommonDataTable from "../../components/datatable";
import DataTable from "react-data-table-component";
import TawkToWidget from "./Donationmanage";
import CommonModal from "../../components/popipInfo";
import { useHistory } from 'react-router-dom';
import moment from "moment";

const VDC: React.FC = () => {


    const userRole = sessionStorage.getItem("UserData")
    const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
    const [userId]: any = useState(sessionStorage.getItem("UserId"))


    const [Alert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [reportData, setReportData] = useState<any>([]);
    const [filterText, setFilterText] = useState("");
    const { exportCSV } = useContext(AppContext)!

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds]: any = useState([]);
    const [confirmed, setConfirmed] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [actualData, setActualData] = useState<any>([])
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [fdate, setFdate]: any = useState("")
    const [tdate, setTdate]: any = useState("")

    const groupedData = reportData.reduce((acc: any, row: any) => {
        if (row.SrlNo === "1") {
            acc.push({ ...row, details: [] });
        } else {
            const parent = acc.find((header: any) => header.BookingOrderNumber === row.BookingOrderNumber);
            if (parent) {
                parent.details.push(row);
            }
        }
        return acc;
    }, []);


    useEffect(() => {
        if (!usertoken)
            Authservice.getMessagesByCategory(userId, usertoken, usertoken, "VDC").then((res: any) => {
                if (res.data !== "" && res.data !== null) {
                    setCommonModalMesg(res.data)
                    setCommonmodal(true)
                    setCommonmodaltitle("Information")
                    setShowLoading(false)
                }
            })
                .catch((error: any) => {
                    console.log(error, "error")
                    setShowLoading(false)
                })

    }, [])


    const getdata = () => {
        console.log("fdate", fdate, tdate)
        if (fdate && tdate) {
            setShowLoading(true);
            const fdate1 = moment(fdate).format("YYYY-MM-DD HH:mm:ss")
            const tdate1 = moment(tdate).format("YYYY-MM-DD HH:mm:ss")
            UserService.getCommissionDisbursement(usertoken, userId, fdate1, tdate1).then((response: any) => {
                if (response.status === 200) {
                    console.log("response", response)
                    setActualData(response.data)
                    setReportData(response.data);
                    setShowLoading(false);
                }
            }).catch((error: any) => {
                setShowLoading(false);
                console.log("error", error)
            })
        }
    }


    useEffect(() => {
        if (confirmed) {
            console.log('Selected rows:', selectedRowIds, reportData);
            setFilteredData(reportData.filter((row: any) => selectedRowIds.includes(row.BookingOrderNumber)));
        } else {
            setFilteredData(reportData);
        }
    }, [confirmed, selectedRowIds, reportData]);

    const tableData = actualData.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );







    // Header columns
    const headerColumns = [

        {
            name: 'Name',
            selector: (row: any) => row.Name || 'N/A',
            sortable: true,
            width: "600px",
            cell: (row: any) => <div style={{ display: "flex", gap: "10px" }}>
                <IonText>{row.Name}</IonText>
            </div>
        },
        {
            name: 'Amount',
            selector: (row: any) => row.Amount || 'N/A',
            sortable: true,
        },
        {
            name: 'Percentage',
            selector: (row: any) => row.Percentage || 'N/A',
            sortable: true,
        },
    ];

    // Detail columns
    const detailColumns = [
        {
            name: 'Fund Category',
            selector: (row: any) => row.FundCategory || 'N/A',
        },
        {
            name: 'Pooja Code',
            selector: (row: any) => row.PoojaCode || 'N/A',
        },
        {
            name: 'Fund ID',
            selector: (row: any) => row.FundId || 'N/A',
        },
        {
            name: 'Booking Detail Number',
            selector: (row: any) => row.BookingDetailNumber,
        },
        {
            name: 'Amount',
            selector: (row: any) => row.TotalAmount,
            right: true,
        },
    ];

    // Expandable row component
    ;



    const history = useHistory();

    const modalClose = () => {
        history.push("/")
    }

    const exportData = () => {
        exportCSV(headerColumns, actualData, "Fund Report")
    }

    const subHeaderComponent = useMemo(() => {
        return (
            <IonRow className="table_btn_container">
                <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                    <div>
                        <IonText>From </IonText>
                        <IonInput
                            type="date"
                            value={fdate}
                            onIonChange={(e) => {
                                console.log("e", e.detail)
                                setFdate(e.detail.value)
                            }
                            }
                            placeholder="From Date"
                            className="date_input"
                        />
                    </div>
                    <div>
                        <IonText>To  </IonText>
                        <IonInput
                            type="date"
                            value={tdate}
                            onIonChange={(e) =>
                                setTdate(e.detail.value)
                            }
                            placeholder="To Date"
                            className="date_input"
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        <IonIcon
                            icon={searchOutline}
                            className="search-icon"
                            onClick={() => getdata()}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {actualData.length > 0 && <IonButton className="admin_add_btn" onClick={() => exportData()}>
                        Export
                    </IonButton>}
                    {actualData.length > 0 && <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                    }
                </div>
            </IonRow>
        );
    }, [filterText, reportData, fdate, tdate]);

    const handleCancel = () => {
        setConfirmed(false);
        setSelectedRowIds([]);
    };

    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"VDC & Other Disbursed"} />
                <Sidebar /></>}
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,VDC & Other Disbursed' />}
                <IonGrid className={userRole === "ROLE_ADMIN" ? "page-wrapper report_container_middle" : "page_content_padding tableContainev2"}>

                    < CommonDataTable
                        columns={headerColumns}
                        data={tableData}
                        defaultSortFieldId="name"
                        striped
                        noDataComponent={actualData ? "Select dates" : "Work in progress"}
                        isSubHeader={true}
                        pagination={true}
                        subHeaderComponent={subHeaderComponent}
                        selectableRows={false} />

                </IonGrid>
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />

            </IonContent>
        </IonPage>
    )

}
export default VDC;