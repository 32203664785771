import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonIcon, IonImg, IonLoading, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import HeadFoot from "./Head";
import DataTable from "react-data-table-component";
import CommonDataTable from "../components/datatable";
import { data } from "jquery";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";
import { Button, Form, Modal } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { set } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { chevronDownOutline, chevronUpOutline, logoWhatsapp, mail } from 'ionicons/icons'; // Example icons
import Sidebar from "./admin/sidebar";
import AdminHead from "./admin/adminHead";
import CommonModal from "../components/popipInfo";
import { useHistory } from "react-router";


const Volunteer: React.FC = () => {

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const [userRole] = useState(sessionStorage.getItem("UserData"));
    const [showLoading, setShowLoading] = useState(false);
    const [tabledata, settabledata]: any = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showlightBox, setShowLightBox] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedImage, setIsCheckedImage] = useState(false);
    const [selectedImage, setSelectedImage]: any = useState(null);
    const imageref: any = useRef(null)
    const [image, setImage] = useState("");
    const [images, setImages]: any = useState("");
    const [radioValue, setRadioValue]: any = useState("Yes");

    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [rowItem, setRowItem]: any = useState({})
    const [iserror, setiserror]: any = useState()
    const [message, setMessage]: any = useState("")
    const [remarks, setremarks] = useState("")

    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const [modalCloseFun, setModalcloseFun] = useState<() => void>(() => () => null);
    const [screenwidth, setScreenwidth] = useState(window.innerWidth)
    const [showTable, setShowTable] = useState(false)
    const history = useHistory()




    const modalClose = () => {
        if (!mailVerifyFlag) {
            history.push("/profile")
        }
        else if (CommonModalMesg === "Updated Successfully") {
            setCommonmodal(false)
            getPoojaOfferingDetailsForAdmin()
        } else {
            setCommonmodal(false)
        }
    }



    const getMessagesByCategory = () => {
        Authservice.getMessagesByCategory(userId, usertoken, usertoken, "TempleBooking").then((res: any) => {
            setCommonModalMesg(res.data)
            setCommonmodaltitle("Information")
            setCommonmodal(true)
            setShowLoading(false)
            setModalcloseFun(() => () => setCommonmodal(false))
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }

    const users = [
        {
            userId: 201,
            userName: "Rajesh Kumar",
            contact: "+91-9876543210",
            poojas: [
                {
                    poojaName: "Suprabhatam Seva",
                    description: "Early morning seva to awaken the deity with devotional songs.",
                    price: 500,
                    date: "2024-12-07",
                    time: "4:30 AM",
                    status: "Confirmed",
                    id: 1
                },
                {
                    poojaName: "Archana",
                    description: "Personalized prayer offering performed in your name.",
                    price: 200,
                    date: "2024-12-07",
                    time: "7:00 AM",
                    status: "Pending",
                    id: 2
                },
            ],
        },
        {
            userId: 202,
            userName: "Anjali Sharma",
            contact: "+91-9123456789",
            poojas: [
                {
                    poojaName: "Sahasranamarchana",
                    description: "Chanting of 1000 names of the deity.",
                    price: 700,
                    date: "2024-12-08",
                    time: "10:00 AM",
                    status: "Confirmed",
                    id: 3
                },
            ],
        },
    ];




    const [selectedOrders, setSelectedOrders]: any = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [mailVerifyFlag, setMailVerifyFlag] = useState(true)




    useEffect(() => {
        Authservice.getAppConfigs(usertoken, userId, "").then((res: any) => {
            if (res.data !== "" && res.data !== null) {
                const isEmailVerified = res.data.some((item: any) => item.name === "EmailVerification" && item.value !== "False");
                if (isEmailVerified) {
                    checkVerifyEMail();
                    return
                } else {
                    getPoojaOfferingDetailsForAdmin()
                    getMessagesByCategory()
                }
                console.log(res.data, "data")
                setShowLoading(false)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })

    }, [])

    const checkVerifyEMail = () => {
        Authservice.isEmailVerified(userId, null)
            .then((response) => {
                if (response.data === true) {
                    setShowLoading(true);
                    getPoojaOfferingDetailsForAdmin()
                    getMessagesByCategory()
                } else {
                    setCommonModalMesg("Your email has not been verified. Please verify it to proceed further.")
                    setCommonmodal(true)
                    setCommonmodaltitle("Information")
                    setMailVerifyFlag(false)
                }
            })
            .catch((error) => {
                setShowLoading(false)
            });

    }


    const getPoojaOfferingDetailsForAdmin = () => {
        Authservice.getConfirmedBookingOrders(usertoken, userId, usertoken).then((response: any) => {
            console.log(response.data.filter((e: any) => e.OrderNumber === "2024126"));
            settabledata(response.data)
            setShowTable(true)
            setShowLoading(false)
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }


    const handleSelectedRowsChange = ({ selectedRows }: any) => {
        console.log("Selected Rows:", selectedRows);
        setSelectedOrders(selectedRows);
    };


    // const handleCheckboxChange = (poojaId: any) => {
    //     console.log(poojaId)
    //     setSelectedOrders((prev: number[]) => {
    //         const isSelected = prev.includes(poojaId.id);
    //         console.log(`Pooja ID: ${poojaId.id}, Already Selected: ${isSelected}`);
    //         return isSelected
    //             ? prev.filter((id) => id !== poojaId.id) // Remove
    //             : [...prev, poojaId.id];                // Add
    //     });
    // };

    const columns = [
        {
            name: 'Order Number',
            selector: (row: any) => row.head.OrderNumber,
            sortable: true,
            width: "150px"
        },

        {
            name: 'Temple Name',
            selector: (row: any) => row.head.TempleCode,
            sortable: true,
            width: "550px"
        },
        {
            name: 'Order Date',
            selector: (row: any) => formatDate(row.head.PoojaFrom, "1") || 'N/A',
            sortable: true,
        },

        {
            name: 'Temple Admin',
            selector: (row: any) => row.head.TempleAdmin || 'N/A',
            sortable: true,

        },

        {
            name: 'Pooja Amount',
            selector: (row: any) => row.head.PoojaAmount,
            sortable: true,

        },
        {
            name: 'Volunteer Payment On',
            selector: (row: any) => row.head.PoojaTo ? formatDate(row.head.PoojaTo, "1") : 'N/A',
            sortable: true,
            width: "150px"

        },
    ];


    const groupedData = tabledata.reduce((acc: any, item: any) => {
        const orderId = item.OrderNumber;

        if (!acc[orderId]) {
            acc[orderId] = { head: null, details: [] };
        }

        if (item.RecordType === 'HEAD') {
            acc[orderId].head = item;
        } else if (item.RecordType === 'DETL') {
            acc[orderId].details.push(item);
        }
        return acc;
    }, {});

    Object.values(groupedData).forEach((group: any) => {
        if (group.details.length > 0) {
            group.head.TempleCode = group.details[0].TempleCode; // Use the TempleCode from the first detail
        }
    });

    const flatData = Object.entries(groupedData).map(([id, data]: any) => ({
        id,
        head: data.head,
        details: data.details,
    }));
    console.log(flatData)



    // Convert grouped data into an array that will be used in the DataTable
    // const flatData = Object.keys(groupedData).map(orderNumber => {
    //     const { head, details } = groupedData[orderNumber];
    //     return {
    //         id: orderNumber,
    //         head,
    //         details
    //     };
    // });
    console.log(groupedData["202463"])



    const conditionalRowStyles = [
        {
            when: (row: any) => row.isUserHeader, // Highlight user rows
            style: {
                backgroundColor: "#e3eeff",
                fontWeight: "bold",
                borderBottom: "1px solid #d3d3d3",
            },
        },
    ];

    const handleRowClick = (row: any) => {
        setRowItem(row)
        setImage("")
        setImages("")
        setRadioValue("Yes")
        setremarks("")
        setShowModal(true);
    };

    // Handle checkbox change
    const handleCheckboxChange = (e: any, num: any) => {
        console.log(num)
        if (num === "Yes" || num === "No") {
            setRadioValue(num);
        } else {
            setremarks(e.target.value)
        };
    }

    // Handle file input change
    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };


    const dataURItoFile = (dataURI: any, fileName: any) => {
        // Extract the mime type from the Data URI
        let arr = dataURI.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };


    const handleSubmit = () => {
        if (!image) {
            setCommonmodal(true)
            setCommonModalMesg("Please select an image to upload.")
            setCommonmodaltitle("Error")
            setModalcloseFun(() => () => setCommonmodal(false))
            return;
        }
        else if (radioValue === "No" && !remarks) {
            setCommonmodal(true)
            setCommonModalMesg("Please enter the remarks.")
            setCommonmodaltitle("Error")
            setModalcloseFun(() => () => setCommonmodal(false))
            return;
        }
        const radio = radioValue === "Yes" ? true : false
        console.log(images)
        Authservice.SubmitPoojaBooking(usertoken, userId, usertoken, images, rowItem.BookingDetailNumber, radio, rowItem.OrderNumber, remarks).then((response: any) => {
            console.log(response.data);
            if (response.data === "success") {
                setCommonmodal(true)
                setCommonModalMesg("Updated Successfully")
                setCommonmodaltitle("Information")
                setShowModal(false);
                setModalcloseFun(() => () => getPoojaOfferingDetailsForAdmin())
            }
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });

    };

    const handleButtonClick = () => {
        if (imageref.current) {
            imageref.current.click();
        }
    };


    const handleimage = (e: any) => {
        var file: any = e.target.files[0];
        const splited: any = file.name.split(".")
        if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png" || splited[splited.length - 1] === "jpeg") {
            console.log(selectedImage)
            const objectUrl = URL.createObjectURL(file);
            setImages(file);
            setImage(objectUrl);
        } else {
            if (imageref.current) {
                imageref.current.value = null;
            }
            setCommonModalMesg("Invalid file type. Please upload a .jpg, .jpeg, or .png file.")
            setCommonmodal(true)
            setCommonmodaltitle("Error")
            return false;
        }
    };

    const openCamera = async () => {
        setShowModal(false)
        try {
            const image: any = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.DataUrl, // Get the photo as a data URL for web compatibility
                source: CameraSource.Camera, // Opens the camera directly
            });
            console.log(image)
            setImage(image.dataUrl!);
            const file = dataURItoFile(image.dataUrl, "captured-image.jpg");
            console.log("File Object:", file);
            setImages(file);
            setShowModal(true)
        } catch (error) {
            console.error('Camera error:', error);
            setShowModal(true)
        }
    };

    const formatDate = (timestamp: any, num: any) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleShare = (order: any) => {
        console.log(order);
        let shareText = `Order Details:\n`;
        shareText += `Order No: ${order.head.OrderNumber}\n`;
        shareText += `Temple: ${order.head.TempleCode}\n`;
        shareText += `Date: ${new Date(order.head.PoojaFrom).toLocaleDateString()}\n\n`;
        shareText += `Pooja Details:\n`;
        order.details.forEach((booking: any) => {
            shareText += `${booking.PoojaCode || "Pooja"}\n`;
            shareText += `Name: ${booking.Name || "N/A"}\n`;
            shareText += `Star: ${booking.Star || "N/A"}\n`;
            shareText += `Date: ${new Date(booking.PoojaFrom).toLocaleDateString()}\n`;
            shareText += `Amount: ₹${booking.PoojaAmount}\n`;
            if (booking.TempleBookingRemark) {
                shareText += `Remark: ${booking.TempleBookingRemark}\n`;
            }
            if (booking.ImageURL) {
                shareText += `Receipt: ${booking.ImageURL}\n`;
            }
            shareText += `\n`;
        });
        const encodedMessage = encodeURIComponent(shareText);
        const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
        const emailUrl = `mailto:?subject=Temple Booking Details&body=${encodedMessage}`;
        window.open(whatsappUrl, "_blank"); // Opens WhatsApp
        // window.open(emailUrl, "_blank"); // Uncomment for Email
    };



    const ExpandableRow = ({ data }: any) => {

        const formatDate = (timestamp: any) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        const detailColumns = [
            // {
            //     name: 'Booking Number',
            //     selector: (row: any) => row.BookingDetailNumber,
            //     sortable: true,
            // },
            {
                name: 'Pooja Name',
                selector: (row: any) => row.PoojaCode || 'N/A',
                sortable: true,
            },
            {
                name: 'Name',
                selector: (row: any) => row.Name,
                sortable: true,
            },
            {
                name: 'Star',
                selector: (row: any) => row.Star,
                sortable: true,
            },
            {
                name: 'Pooja Date',
                selector: (row: any) => formatDate(row.PoojaFrom),
                sortable: true,
            },
            // {
            //     name: 'Pooja To',
            //     selector: (row: any) => formatDate(row.PoojaTo),
            //     sortable: true,
            // },
            {
                name: 'Prasadam',
                selector: (row: any) => row.PrasadamRequired == "0" ? "NO" : "YES",
                sortable: true,
            },
            {
                name: 'Pooja Amount',
                selector: (row: any) => row.PoojaAmount,
                sortable: true,
            },
            // {
            //     name: 'Amount Received',
            //     selector: (row: any) => row.PoojaAmount,
            //     sortable: true,
            // },
            {
                name: 'Pooja Completed',
                selector: (row: any) => row.BookingDetailStatus === "4" ? "Yes" : "No",
                sortable: true,
            },
            {
                name: 'Receipt Uploaded',
                selector: (row: any) => row.ImageUploaded === "1" ? "Yes" : "No",
                sortable: true,
            },
        ];


        const conditionalRowStyles = [
            {
                when: (row: any) => row.BookingDetailStatus === "4" && row.ImageUploaded === "1", // Highlight active rows
                style: {
                    backgroundColor: '#d4edda', // Light green for ac Ftive rows
                    color: '#155724',
                },
            },
            {
                when: (row: any) => row.BookingDetailStatus !== "4" && row.ImageUploaded === "1",
                style: {
                    backgroundColor: '#f8d7da', // Light red for inactive rows
                    color: '#721c24',
                },
            },
        ];



        return (
            <div style={{ padding: "20px" }}>
                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => {
                        setiserror(false)
                        window.location.reload()
                    }}
                    cssClass="my-custom-class"
                    header={"Success"}
                    message={message}
                    buttons={["Ok"]}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                    <IonIcon icon={logoWhatsapp} size="large" color="success" onClick={() => handleShare(data)} />
                </div>
                <DataTable
                    title="Details"
                    columns={detailColumns}
                    data={data.details}
                    pagination={false}
                    customStyles={{
                        headCells: {
                            style: {
                                backgroundColor: '#e6e6e6',
                            },
                        },
                    }}
                    noHeader
                    onRowClicked={(item: any) => (item.BookingDetailStatus !== "4" && item.BookingDetailStatus !== "7") ? handleRowClick(item) : null}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        );
    };
    const NestedDetails = ({ details }: any) => {
        const formatDate = (timestamp: any) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };
        return (
            <div className="nested-details">
                {details.map((item: any, index: any) => (
                    <div key={index} className="nested-card" onClick={(item: any) => (item.BookingDetailStatus !== "4" && item.BookingDetailStatus !== "7") ? handleRowClick(item) : null}>
                        <div className="nested-card-body">
                            <div className="nested-card-row">
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Name: </strong></div>
                                    <div className="nested-card-value">{item.Name || 'N/A'}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Star: </strong></div>
                                    <div className="nested-card-value">{item.Star}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Pooja Name: </strong></div>
                                    <div className="nested-card-value">{item.PoojaCode || 'N/A'}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Pooja From: </strong></div>
                                    <div className="nested-card-value">{formatDate(item.PoojaFrom)}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Pooja To: </strong></div>
                                    <div className="nested-card-value">{formatDate(item.PoojaTo)}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Prasadam: </strong></div>
                                    <div className="nested-card-value">{item.PrasadamRequired === "0" ? "NO" : "YES"}</div>
                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Pooja Amount: </strong></div>
                                    <div className="nested-card-value">{item.PoojaAmount}</div>
                                </div>
                                {/* <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Amount Received: </strong></div>
                                    <div className="nested-card-value">{item.PoojaAmount}</div>
                                </div> */}
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Pooja Completed: </strong></div>
                                    <div className="nested-card-value">{item.BookingDetailStatus === "4" ? "Yes" : "No"}</div>

                                </div>
                                <div className="nested-card-col">
                                    <div className="nested-card-key"><strong>Receipt Uploaded: </strong></div>
                                    <div className="nested-card-value">{item.ImageUploaded === "1" ? "Yes" : "No"}</div>
                                </div>
                            </div>
                            {/* <div className="nested-card-row">
                               
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    const CardViewRow = ({ data }: any) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleDetails = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className="newCardV2 card">
                <div className="card-header-v2">
                    <div className="card-title">
                        <strong>Order Number: </strong>{data.head.OrderNumber}
                    </div>
                    <div className="dropdown-icon" onClick={toggleDetails}>
                        <IonIcon icon={isOpen ? chevronUpOutline : chevronDownOutline} />
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-row">
                        <strong>Temple Name: </strong> {data.head.TempleCode}
                    </div>
                    <div className="card-row">
                        <strong>Temple Admin: </strong> {data.head.TempleAdmin || 'N/A'}
                    </div>
                    <div className="card-row">
                        <strong>Number Of Pooja's: </strong> {data.details.length}
                    </div>
                    <div className="card-row">
                        <strong>Pooja Amount: </strong> {data.head.PoojaAmount}
                    </div>
                    <div className="card-row">
                        <strong>Volunteer Payment On: </strong> {data.head.PoojaTo ? formatDate(data.head.PoojaTo, "1") : 'N/A'}
                    </div>
                    <div className="card-row" style={{ justifyContent: "space-between", display: "flex" }}>
                        <span><strong>Charges: </strong> {data.head.Charges}</span>
                        <IonIcon icon={logoWhatsapp} size="large" color="success" onClick={() => handleShare(data)} />
                    </div>
                </div>

                {isOpen && (
                    <div className="card-details">
                        <NestedDetails details={data.details} />
                    </div>
                )}
            </div>
        );
    };

    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

    const handleRowClicks = (row: any) => {
        console.log(row)
        setExpandedRows((prev: any) => ({
            ...prev,
            [row.id]: !prev[row.id], // Toggle expand state for clicked row
        }));
    };


    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Temple Booking Management"} />
                <Sidebar /></>}
            <IonContent>
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Pending Temple Bookings' />}
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <div className={userRole === "ROLE_ADMIN" ? "container_middle_table page-wrapper admin_background_color" : "page_content_padding tableContainev2"}>
                    {isDesktop ?
                        <>
                            {showTable &&
                                <CommonDataTable
                                    columns={columns}
                                    data={flatData}
                                    //  loading={showLoading}
                                    noDataComponent={"No More Pending Temple Bookings"}
                                    onRowClick={handleRowClicks}
                                    conditionalRowStyles={conditionalRowStyles}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                    selectableRows={false}
                                    selectableRowDisabled={(row: any) => row.isUserHeader}
                                    expandableRows={true}
                                    expandableRowsComponent={ExpandableRow}
                                    expandedRows={(row: any) => !!expandedRows[row.id]}
                                />}
                        </> : <>
                            {flatData.length === 0 ? (
                                <div className="no-data-message"> No More Pending Temple Bookings</div>
                            ) : (
                                flatData.map((row: any) => (
                                    <CardViewRow key={row.id} data={row} />
                                ))
                            )}
                        </>}
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    aria-labelledby="image-upload-modal"
                    className="expandable-row-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="image-upload-modal">Confirm Pooja</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column p-2 radio_checkbox_container">
                            {/* Radio Options */}
                            <div className="mb-3 d-flex align-items-center">
                                <label htmlFor="poojaBooked" className="label-fonts me-3" style={{ fontWeight: "bold" }}>
                                    Pooja Booked
                                </label>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="poojaBookedYes"
                                        name="poojaBooked"
                                        value="Yes"
                                        checked={radioValue === "Yes"}
                                        onChange={(e) => handleCheckboxChange(e, "Yes")}
                                    />
                                    <label htmlFor="poojaBookedYes">Yes</label>
                                    <input
                                        type="radio"
                                        id="poojaBookedNo"
                                        name="poojaBooked"
                                        value="No"
                                        checked={radioValue === "No"}
                                        onChange={(e) => handleCheckboxChange(e, "No")}
                                    />
                                    <label htmlFor="poojaBookedNo">No</label>
                                </div>
                            </div>

                            {/* Remarks */}
                            <div className="mb-3 d-flex align-items-center">
                                <label htmlFor="remarks" className="label-fonts me-3" style={{ fontWeight: "bold" }}>
                                    Remarks
                                </label>
                                <textarea
                                    id="remarks"
                                    className="temple_inputs temple_textArea flex-grow-1"
                                    style={{ minHeight: "50px" }}
                                    value={remarks}
                                    onChange={(e) => handleCheckboxChange(e, "remarks")}
                                />
                            </div>

                            {/* File Input and Image Preview */}
                            <div className="mb-3 d-flex flex-column">
                                <div className="d-flex gap-2">
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={handleButtonClick}
                                        type="button"
                                    >
                                        Upload Receipt Copy
                                    </IonButton>
                                    {!(screenwidth < 768) && < IonButton
                                        className="admin_add_btn"
                                        onClick={openCamera}
                                        type="button"
                                    >
                                        Open Camera
                                    </IonButton>}
                                </div>

                                {image.length > 0 && (
                                    <div className="adm_image_container mt-3">
                                        <IonImg
                                            className="image_design_deity"
                                            src={image}
                                            onClick={() => setShowLightBox(true)}
                                        />
                                    </div>
                                )}

                                <input
                                    type="file"
                                    ref={imageref}
                                    onChange={(e) => handleimage(e)}
                                    style={{ display: "none" }}
                                />
                            </div>

                            {/* Submit and Cancel Buttons */}
                            <div className="d-flex justify-content-end gap-2">
                                <IonButton
                                    className="admin_add_btn"
                                    onClick={handleSubmit}
                                    type="submit"
                                >
                                    Submit
                                </IonButton>
                                <IonButton
                                    className="admin_cencel_btn"
                                    fill="outline"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </IonButton>
                            </div>
                        </div>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={handleSubmit}
                            disabled={!isChecked || !selectedImage}
                        >
                            Submit
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {showlightBox && (
                    <Lightbox
                        mainSrc={image}
                        onCloseRequest={() => setShowLightBox(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                {userRole !== "ROLE_ADMIN" && <Foot />}
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </IonContent>
        </IonPage >
    )
}

export default Volunteer;