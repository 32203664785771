import { IonContent, IonPage } from "@ionic/react";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useState } from "react";

const AdminHomePage: React.FC = () => {

      const [isOpen, setIsOpen] = useState(false);

      const toggleSidebar = (open: any) => {
            setIsOpen(open);
      };
      return (
            <IonPage>
                  <AdminHead User={"user"} Role={"Role"} Name={"Home"} />
                  <Sidebar />
                  <IonContent>
                  </IonContent>
            </IonPage>
      )
}

export default AdminHomePage;