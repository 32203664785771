import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import "./Downloads.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const Downloads: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Downloads"} />
        <div style={{ padding: "50px" }} className="page_content_padding">
          <div style={{ textAlign: 'center', paddingTop: '16px' }}>
            <button className="btn "> Download</button>
          </div>
        </div>

        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Downloads;