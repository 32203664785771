import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonLoading, useIonAlert, IonSearchbar
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import Head from "../Head";
import Foot from "../Foot";
import axios from "axios";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, addOutline } from "ionicons/icons";
import DataTable, { createTheme } from "react-data-table-component"
import "./Deitymanage.css";
import { useHistory } from "react-router";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Authservice from "../../Services/user.service";
import { Capacitor } from "@capacitor/core";
import customTableStyle from "../../components/CustomTableStyle";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";

const TempAdmin: React.FC = () => {
  console.log("temple called")
  const [presentAlert] = useIonAlert();

  const [comment, setComment] = useState("")
  const columns: any = [
    {
      name: "S.No",
      selector: (item: any, i: any) => item.serial,
      sortable: true,
      width: "90px",
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => onclick(item.templesDto.id)}>
            <div style={{ cursor: "pointer" }}>{item.serial}</div>
        </div>
    )
    },
    {
      name: "Temple Name",
      selector: (item: any) => item.templesDto.name,
      sortable: true,
      cell: (item: any) => (
        <div className="left_col"  onClick={() => onclick(item.templesDto.id)}>
          <div style={{ cursor: "pointer" }} >{item.templesDto.name}</div>
        </div>
      )
    },
    {
      name: "Location",
      selector: (item: any) => item.templesDto.villageorTown + "," + item.templesDto.district,
      sortable: true,
      cell: (item: any) => (
        <div className="left_col"  onClick={() => onclick(item.templesDto.id)}>
          <div style={{ cursor: "pointer" }} >{item.templesDto.villageorTown + "," + item.templesDto.district}</div>
        </div>
      )
    },
    // {
    //   name: "Active Comment",
    //   selector: (item: any) => item.templesDto.activeComment,
    //   sortable: true,
    //   cell: (item: any) => (
    //     <div onClick={() => onclick(item.templesDto.id)}>
    //       <div style={{ cursor: "pointer" }} className="textDes threelineCls">{item.templesDto.activeComment}</div>
    //     </div>
    //   )
    // },
    {
      name: "Comment",
      selector: (item: any) => item.templesDto.inactiveComment,
      sortable: true,
      cell: (item: any) => (
        <div className="left_col" onClick={() => onclick(item.templesDto.id)}>
          <div style={{ cursor: "pointer" }} className="textDes threelineCls">{item.templesDto.inactiveComment}</div>
        </div>
      )
    },
    {
      name: "Status",
      sortable: false,
      width: "150px",
      selector: (item: any) => item.facilitiesDto.temples.activeFlag,
      cell: (item: any) => (
        <div className="center_col_tab">
          {item.facilitiesDto.temples.activeFlag === "true" ? (
            <IonButton color="danger" style={{ textTransform: "none" }} className="datatable-activate-btn" onClick={() =>
              presentAlert({
                header: 'Atmagram',
                message: 'Do you want to deactivate this temple',
                buttons: [
                  {
                    text: 'No',
                    role: 'cancel',
                    handler: () => {
                      // setHandlerMessage('Alert canceled');
                    },
                  },
                  {
                    text: 'Yes',
                    role: 'confirm',
                    handler: (alertData) => {
                      handleStatus(false, item.templesDto.id, alertData.comment)
                    },
                  },
                ],
                inputs: [
                  {
                    name: 'comment',
                    type: 'text',
                    value: comment,
                    placeholder: 'Enter your comment'
                  }
                ]
              })

            }>
              <span className="button_padding">
                Deactivate
              </span>
            </IonButton>
          ) : (
            <IonButton color="success" style={{ textTransform: "none" }} className="datatable-activate-btn" onClick={() => presentAlert({
              header: 'Atmagram',
              message: 'Do you want to activate this temple',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  handler: () => {
                    // setHandlerMessage('Alert canceled');
                  },
                },
                {
                  text: 'Yes',
                  role: 'confirm',
                  handler: (alertData) => {
                    handleStatus(true, item.templesDto.id, alertData.comment)
                  },
                },
              ],
              inputs: [
                {
                  name: 'comment',
                  type: 'text',
                  value: comment,
                  placeholder: 'Enter your comment'
                }
              ]
            })}>
              <span className="button_padding">
                Activate
              </span>
            </IonButton>
          )}
        </div>
      )
    },
    // {
    //   name: "View",
    //   sortable: false,
    //   width: "100px",
    //   cell: (item: any) => (
    //     <>
    //       <IonButton onClick={() => onclick(item.templesDto.id)} style={{ textTransform: "none" }}>View</IonButton>
    //     </>
    //   )
    // },
  ]

  const [usertoken] = useState(sessionStorage.getItem("token"));
  const history = useHistory();
  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const uFlag: any = useState(sessionStorage.getItem("uFlag"))
  const [templelist, setTemplelist]: any = useState([]);
  const [modalopen, setModalopen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isedit, setIsedit] = useState(false);
  const [deityid, setDeityid] = useState("");
  const [id, Setid] = useState("");
  const [images, setImages] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  if (templelist != undefined && templelist.length > 0) {
    templelist.forEach((item: any, index: any) => { item.serial = index + 1; });
  }



  const dataFetchedRef = useRef(false);




  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_TEMPADMIN") {
      history.push("/home")
    }
    else if (uFlag[0] === "false") {
      history.push("/home")
    }
    else {
      showalldeity();
    }

  }, []);
  const {
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const showalldeity = () => {
    setShowLoading(true);
    UserService.Viewtemple(usertoken, "").then((response) => {
      console.log(response, "iuv");
      if (response.data.length > 0) {
        console.log(response.data);
        setTemplelist([])

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].facilitiesDto != null) {
            setTemplelist((e: any) => [
              ...e, response.data[i]
            ])
          }
        }
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }


    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }
  const handleStatus = (flag: any, id: any, comment: any) => {
    Authservice.statusTempAdmTemple(usertoken, flag, id, comment).then((response) => {
      console.log(response)
      window.location.reload()
    })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
      });

  }


  const onclick = (id: any) => {
    Setid(id)
    history.push({
      pathname: "/editTemple",
      state: { tempId: "", admin: 1, id: id, name: "", state: "", city: "", district: "", templeName: "" }
    })
  }

  const addtemple = () => {
    history.push({
      pathname: "/addTemple",
      state: { tempId: "", admin: 1, id: id, name: "", state: "", city: "", district: "", templeName: "" }
    })
  }


  const Templelist = () => {
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    var tableData: any = [];
    if (templelist != undefined && templelist.length > 0) {
      tableData = templelist.filter(
        (item: any) =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );
    }


    const paginationComponentOptions = {
      selectAllRowsItem: true,
      selectAllRowsItemText: "ALL"
    };

    const subHeaderComponent = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText("");
        }
      };

      return (
        <IonRow className="table_btn_container">
          <div>
            <IonButton onClick={addtemple} className="admin_add_btn">
              <IonIcon src={addOutline} />
              <span>Add Temple</span>
            </IonButton>
          </div>
          <div>
            <IonSearchbar
              value={filterText}
              onIonChange={(e: any) => setFilterText(e.detail.value)}
              placeholder="Search"
              className="table_search"
            />
          </div>
        </IonRow>
      );
    }, [filterText, resetPaginationToggle]);
    return (
      <div className="container_middle_table page-wrapper admin_background_color">
        <DataTable
          columns={columns}
          data={tableData}
          // theme="dataTable"
          noDataComponent="Sorry ! No result found"
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          subHeader
          subHeaderComponent={subHeaderComponent}
          customStyles={customTableStyle}
          style={{ width: "100%" }}
          onRowClicked={(item: any) => onclick(item.templesDto.id)}
        />
      </div>
    )
  }
  const [index, setIndex] = useState(0)
  const arr: any = [
    <Templelist />,
    // <TempleAdmDet id={id} edit={isedit} admin={1} tempId={""} name={""} state={""} city={""} district={""} templeName={""} />,
    // <Addtemp id={id} edit={isedit} admin={1} tempId={""} name={""} state={""} city={""} district={""} templeName={""} />
  ]
  const content = [arr[index]]

  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"Temple Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        {/* <Head User="deity" Role={userdata[0]} /> */}
        {content}

        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};
export default TempAdmin;
