import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonText, useIonAlert } from "@ionic/react";
import HeadFoot from "../Head";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import "./report.css"
import UserService from "../../Services/auth.service";
import Authservice from "../../Services/user.service";
import { saveAs } from 'file-saver';
import { addOutline } from "ionicons/icons";
import { AppContext } from "../../components/AppContext";
import CommonDataTable from "../../components/datatable";
import DataTable from "react-data-table-component";
import TawkToWidget from "./Donationmanage";
import CommonModal from "../../components/popipInfo";

const FundReport: React.FC = () => {


    const userRole = sessionStorage.getItem("UserData")
    const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
    const [userId]: any = useState(sessionStorage.getItem("UserId"))


    const [Alert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [reportData, setReportData] = useState<any>([]);
    const [filterText, setFilterText] = useState("");
    const { exportCSV } = useContext(AppContext)!

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds]: any = useState([]);
    const [confirmed, setConfirmed] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")

    const groupedData = reportData.reduce((acc: any, row: any) => {
        if (row.SrlNo === "1") {
            acc.push({ ...row, details: [] });
        } else {
            const parent = acc.find((header: any) => header.BookingOrderNumber === row.BookingOrderNumber);
            if (parent) {
                parent.details.push(row);
            }
        }
        return acc;
    }, []);


    useEffect(() => {
        setShowLoading(true);
        const getCurrentDateTime = () => {
            const now = new Date();
            return now.getFullYear() +
                '-' + String(now.getMonth() + 1).padStart(2, '0') +
                '-' + String(now.getDate()).padStart(2, '0') +
                ' ' + String(now.getHours()).padStart(2, '0') +
                ':' + String(now.getMinutes()).padStart(2, '0') +
                ':' + String(now.getSeconds()).padStart(2, '0');
        };
        const date = getCurrentDateTime();
        console.log("date", date)
        UserService.getFundReport(usertoken, userId, date).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                const data = response.data.reduce((acc: any, row: any) => {
                    if (row.SrlNo === "1") {
                        acc.push({ ...row, details: [] });
                    } else {
                        const parent = acc.find((header: any) => header.BookingOrderNumber === row.BookingOrderNumber);
                        if (parent) {
                            parent.details.push(row);
                        }
                    }
                    return acc;
                }, []);;
                setReportData(data);
                setShowLoading(false);
            }
        }).catch((error: any) => {
            setShowLoading(false);
            console.log("error", error)
        })
    }, []
    )

    useEffect(() => {
        if (confirmed) {
            console.log('Selected rows:', selectedRowIds, reportData);
            setFilteredData(reportData.filter((row: any) => selectedRowIds.includes(row.BookingOrderNumber)));
        } else {
            setFilteredData(reportData);
        }
    }, [confirmed, selectedRowIds, reportData]);

    const tableData = filteredData.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );







    // Header columns
    const headerColumns = [
        {
            name: '',
            cell: (row: any) => (
                <input
                    type="checkbox"
                    checked={selectedRowIds.includes(row.BookingOrderNumber)}
                    onChange={() => handleRowSelect(row.BookingOrderNumber)}
                />
            ),
            ignoreRowClick: true, // Prevents triggering row click when checkbox is clicked
            allowOverflow: true,
            width: '50px',
        },
        {
            name: 'Booking Order Number',
            selector: (row: any) => row.BookingOrderNumber,
            sortable: true,
            width: "300px"
        },
        // {
        //     name: 'Ordered By',
        //     selector: (row: any) => row.OrderedBy || 'N/A',
        //     sortable: true,
        // },
        {
            name: 'First Name',
            selector: (row: any) => row.FirstName || 'N/A',
            sortable: true,
            width: "200px"
        },
        {
            name: 'Total Amount',
            selector: (row: any) => row.TotalAmount,
            sortable: true,
            right: true,
            width: "200px"
        },
    ];

    // Detail columns
    const detailColumns = [
        {
            name: 'Fund Category',
            selector: (row: any) => row.FundCategory || 'N/A',
        },
        {
            name: 'Pooja Name',
            selector: (row: any) => row.PoojaCode || 'N/A',
        },
        {
            name: 'Fund ID',
            selector: (row: any) => row.FundId || 'N/A',
        },
        {
            name: 'Booking Detail Number',
            selector: (row: any) => row.BookingDetailNumber,
        },
        {
            name: 'Amount',
            selector: (row: any) => row.TotalAmount,
            right: true,
        },
    ];

    // Expandable row component
    const ExpandedComponent = ({ data }: any) => (
        <DataTable
            columns={detailColumns}
            data={data.details}
            noHeader
            pagination={false}
        />
    );




    const subHeaderComponent = useMemo(() => {

        return (
            <IonRow className="table_btn_container">
                <div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {/* <IonButton className="admin_add_btn" onClick={() => exportCSV(headerColumns, filteredData, "Fund Report")}>
                        Export
                    </IonButton> */}
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, reportData]);

    const handleRowSelect = (id: any) => {
        console.log('Selected row:', id);
        setSelectedRowIds((prevSelected: any) =>
            prevSelected.includes(id)
                ? prevSelected.filter((rowId: any) => rowId !== id) // Deselect row
                : [...prevSelected, id] // Select row
        );
    };

    const handleConfirm = () => {
        setConfirmed(true);
    };

    const handleDistribute = () => {
        const distributeData: any = [];
        selectedRowIds.forEach((bookingOrderNumber: any) => {
            const selectedRow = reportData.find((row: any) => row.BookingOrderNumber === bookingOrderNumber);
            if (selectedRow) {
                selectedRow.details.forEach((detail: any) => {
                    distributeData.push({
                        orderId: selectedRow.BookingOrderNumber,
                        fundId: detail.FundId,
                    });
                });
            }
        });
        const uniqueDistributeData = Array.from(
            new Map(distributeData.map((item: any) => [`${item.orderId}-${item.fundId}`, item])).values()
        );
        console.log('Distribute data:', uniqueDistributeData, distributeData);
        UserService.procesFundDisb(usertoken, userId, uniqueDistributeData).then((response: any) => {
            if (response.status === 200) {
                console.log("response", response)
                setCommonmodaltitle("Infotmation")
                setCommonModalMesg("Disburse completed successfully - Disbursement Number " + response.data[0]?.fundDetailsDtos[0]?.disbursementNumber)
                setCommonmodal(true)
            }
        }).catch((error: any) => {
        });
    };

    const modalClose = () => {
        console.log("close", filteredData)
        exportCSV(headerColumns, filteredData, "Fund Report")
        window.location.reload()
    }
    const handleCancel = () => {
        setConfirmed(false);
        setSelectedRowIds([]);
    };

    const handleRowClicks = (row: any) => {
        console.log(row)
        setExpandedRows((prev: any) => ({
            ...prev,
            [row.id]: !prev[row.id], // Toggle expand state for clicked row
        }));
    };

    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Fund to be Disbursed"} />
                <Sidebar /></>}
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Fund to be Disbursed' />}
                <IonGrid className={userRole === "ROLE_ADMIN" ? "page-wrapper report_container_middle" : "page_content_padding tableContainev2"}>
                    <CommonDataTable
                        columns={headerColumns}
                        expandableRows={true}
                        data={tableData}
                        defaultSortFieldId="name"
                        striped
                        noDataComponent="Sorry ! No result found"
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                        expandableRowsComponent={ExpandedComponent}
                        onRowClick={handleRowClicks}
                        expandedRows={(row: any) => !!expandedRows[row.id]}
                        selectableRows={false} />
                    <div style={{ marginTop: '20px', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        {!confirmed ?
                            <IonButton
                                onClick={handleConfirm}
                                disabled={selectedRowIds.length === 0}
                                className="admin_add_btn"
                            >
                                Confirm
                            </IonButton> : <IonButton
                                onClick={handleCancel}
                                disabled={selectedRowIds.length === 0}
                                className="admin_add_btn"
                            >
                                Cancel
                            </IonButton>}
                        <IonButton className="admin_add_btn" disabled={!confirmed} onClick={handleDistribute}>Disburse</IonButton>
                    </div>

                </IonGrid>
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </IonContent>
        </IonPage>
    )

}
export default FundReport;